
import './GrowStatus_Info.scss';


import React from 'react'
import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';

import { FormatDate, RoundToNearest, useGetViewSize } from '../../../helpers';
import { useSelector, useDispatch } from 'react-redux'
import { calculateUnitsFromZones, selectGrowBladeZoneUIDs, selectGrowById, selectGrowNumberOfUnits, selectGrowPlanByGrowId, selectRecipeByGrowId } from '../../../redux/entities/Grow';
import { selectAllGrowPlans, selectGrowPlanPlantTypeIds } from '../../../redux/entities/GrowPlans';
import { selectAllRecipes } from '../../../redux/entities/Recipes';
import { MaintainActivePlantTypes, selectAllPlantTypes, selectAllPlantVarieties } from '../../../redux/entities/PlantTypes';
import { GenericLeaf, NavigateTo } from '../../../assets/icons/Icons';
import { selectAllRaftTypes } from '../../../redux/entities/RaftTypes';
import { SimpleGrowBag, StandardGrowOutBoard, StandardGrowOutZone, StandardSeedlingBoard } from '../../../assets/icons/ProductIcons';
import { selectAllBladeZones, selectBladeDisplayNamesForBladeZoneUID, selectBladeZoneDisplayNameByUID, selectBladeZoneTypeByUID } from '../../../redux/entities/service/Blade';

const GrowStatus_InfoContent = ({ growId }) => {

  const [isMobile, isTablet, isDesktop] = useGetViewSize()


  //const activeGrow = useSelector((state) => selectGrowById(state, growId))

  const activeGrowPlan = useSelector((state) => selectGrowPlanByGrowId(state, growId), _.isEqual)
  const activeRecipe = useSelector((state) => selectRecipeByGrowId(state, growId), _.isEqual)



  if (isDesktop) {
    return (<>
      <div className="FlexContent FlexContent-H-20" style={{ padding: 30 }}>
        <div className="FlexContent FlexContent-20" style={{ flex: 1 }}>
          <GrowStatus_InfoContent_TitleBlock growId={growId} />
          <div className="LineBreak-Horizontal"></div>
          <GrowStatus_InfoContent_MetricsBlock growId={growId} />
          <div className="LineBreak-Horizontal"></div>
          <GrowStatus_InfoContent_PlantTypesBlock growId={growId} />
        </div>
        <div className="FlexLineBreak-Vertical"></div>
        <div className="FlexContent FlexContent-20" style={{ flex: 1 }}>
          <GrowStatus_InfoContent_AreaInfoBlock growId={growId} />
          <div className="LineBreak-Horizontal"></div>
          <GrowStatus_InfoContent_ZonesBlock growId={growId} />
        </div>
      </div>
    </>)
  } else if (isTablet) {
    return (<>
      <div className="FlexContent FlexContent-H-20" style={{ padding: 30 }}>
        <div className="FlexContent FlexContent-20">
          <GrowStatus_InfoContent_TitleBlock growId={growId} />
          <div className="LineBreak-Horizontal"></div>
          <GrowStatus_InfoContent_MetricsBlock growId={growId} />
          <div className="LineBreak-Horizontal"></div>
          <GrowStatus_InfoContent_PlantTypesBlock growId={growId} />
        </div>
        <div className="FlexLineBreak-Vertical"></div>
        <div className="FlexContent FlexContent-20">
          <GrowStatus_InfoContent_AreaInfoBlock growId={growId} />
          <div className="LineBreak-Horizontal"></div>
          <GrowStatus_InfoContent_ZonesBlock growId={growId} />
        </div>
      </div>
    </>)
  } else if (isMobile) {
    return (<>
      <div className="FlexContent FlexContent-20" style={{ padding: "10px 20px" }}>
        <GrowStatus_InfoContent_TitleBlock growId={growId} />
        <div className="LineBreak-Horizontal"></div>
        <GrowStatus_InfoContent_MetricsBlock growId={growId} />
        <div className="LineBreak-Horizontal"></div>
        <GrowStatus_InfoContent_PlantTypesBlock growId={growId} />
        <div className="LineBreak-Horizontal"></div>
        <GrowStatus_InfoContent_AreaInfoBlock growId={growId} />
        <div className="LineBreak-Horizontal"></div>
        <GrowStatus_InfoContent_ZonesBlock growId={growId} />
      </div>
    </>)
  }

}




const GrowStatus_InfoContent_TitleBlock = ({ growId }) => {
  const [isMobile, isTablet, isDesktop] = useGetViewSize()

  const activeGrowPlan = useSelector((state) => selectGrowPlanByGrowId(state, growId), _.isEqual)
  const activeRecipe = useSelector((state) => selectRecipeByGrowId(state, growId), _.isEqual)

  return (<>
    <div className="FlexContent">
      <div className={isDesktop ? "Text-SemiBold-H24" : isTablet ? "Text-SemiBold-H20" : "Text-SemiBold-H18"}>{activeGrowPlan?.name}</div>
      <div className="FlexContent-H-5"><div className="Text-S16">Recipe:</div><div className="Text-Hyperlink16">{activeRecipe?.name}</div></div>
    </div>
  </>)
}



const GrowStatus_InfoContent_MetricsBlock = ({ growId }) => {
  const [isMobile, isTablet, isDesktop] = useGetViewSize()

  return (<>
    <div className="FlexContent-20">
      <div className="Text-Medium-S18">Metrics</div>
      <div className="FlexContent-H-20">
        <div className="FlexContent-5" style={{ flex: 1 }}>
          <div className="Text-Alt14">Estimated Cost</div>
          <div className="Text-S24">$89.54</div>
        </div>
        <div className="FlexContent-5" style={{ flex: 1 }}>
          <div className="Text-Alt14">Power Usage</div>
          <div className="Text-S24">294kWh</div>
        </div>
      </div>
      <div className="FlexContent-H-20">
        <div className="FlexContent-5" style={{ flex: 1 }}>
          <div className="Text-Alt14">Water Usage</div>
          <div className="Text-S24">34L</div>
        </div>
        <div className="FlexContent-5" style={{ flex: 1 }}>
          <div className="Text-Alt14">Space Use Effeciency</div>
          <div className="Text-S24">75%</div>
        </div>
      </div>
      <div className="FlexContent-H-20">
        <div className="FlexContent-5" style={{ flex: 1 }}>
          <div className="Text-Alt14">Estimated Yield</div>
          <div className="Text-S24">54kg</div>
        </div>
        <div className="FlexContent-5" style={{ flex: 1 }}>
          <div className="Text-Alt14">Allocated</div>
          <div className="Text-S24">34kg (65%)</div>
          <div className="Text-Hyperlink14">View Customers</div>
        </div>
      </div>
    </div>
  </>)
}



const GrowStatus_InfoContent_PlantTypesBlock = ({ growId }) => {
  const [isMobile, isTablet, isDesktop] = useGetViewSize()

  const activeGrowPlan = useSelector((state) => selectGrowPlanByGrowId(state, growId), _.isEqual)
  const allPlantTypes = useSelector((state) => selectAllPlantTypes(state))
  const allPlantVarieties = useSelector((state) => selectAllPlantVarieties(state))

  const activePlantTypeIds = useSelector((state) => selectGrowPlanPlantTypeIds(state, activeGrowPlan?.id), _.isEqual)


  const PlantTypeCard = ({plantVariant, isSingle}) => {
    const plantType = allPlantTypes.find((pT) => pT.id === plantVariant.plant_type_id)
    const plantVariety = allPlantVarieties.find((pV) => pV.id === plantVariant.plant_variety_id)
    const cardProps = {style:{}}
    if (isDesktop)  {
      cardProps.style.flexBasis = "50%"
    }
    return (<>
      <div key={plantVariant.id} className="GrowStatus-Info-PlantType-Card" {...cardProps}>
        <div className="GrowStatus-Info-PlantType-Card-Content">
          <div className="GrowStatus-Info-PlantType-Card-Icon"></div>
          <div className="GrowStatus-Info-PlantType-Card-Info">
            <div className="FlexContent-H-5">
              <div className="Text-Medium-S16">({plantVariant.ratio}%)</div>
              <div className="Text-Medium-S16">{plantType?.name}</div>
            </div>
            <div className="Text-Alt14">{plantVariety?.name}</div>
          </div>
          <div className="GrowStatus-Info-PlantType-Card-Link">
            <NavigateTo />
          </div>
        </div>
      </div>
      {(isDesktop && isSingle) && <div style={{flexBasis:"50%"}}></div>}
    </>)
  }

  return (<>
    <MaintainActivePlantTypes activePlantTypeIds={activePlantTypeIds} />

    <div className="FlexContent-20">
      <div className="Text-Medium-S18">Planted Crops</div>
      <div className="FlexContent-5">
        {isDesktop && <>
          {(() => {
            const plantVariantRows = activeGrowPlan?.plant_variants.reduce(function (rows, key, index) { 
              return (index % 2 == 0 ? rows.push([key]) 
                : rows[rows.length-1].push(key)) && rows;
            }, []);
            return (<>
              {plantVariantRows?.map((plantVariantRow, index) => {
                return (
                  <div key={index} className="FlexContent-H-5">
                    {plantVariantRow.map((plantVariant) => {
                      return <PlantTypeCard key={plantVariant.id} plantVariant={plantVariant} isSingle={plantVariantRow.length === 1}/>
                    })}
                  </div>
                )
              })}
            </>)
          })()}
        </>}
        {!isDesktop && <>
          {activeGrowPlan?.plant_variants.map((plantVariant) => {
            return <PlantTypeCard key={plantVariant.id} plantVariant={plantVariant}/>
          })}
        </>}
      </div>
    </div>
  </>)
}


const GrowStatus_InfoContent_AreaInfoBlock = ({ growId }) => {
  const [isMobile, isTablet, isDesktop] = useGetViewSize()

  const activeGrowPlan = useSelector((state) => selectGrowPlanByGrowId(state, growId), _.isEqual)
  const activeRecipe = useSelector((state) => selectRecipeByGrowId(state, growId), _.isEqual)
  const allRaftTypes = useSelector((state) => selectAllRaftTypes(state))


  const numberOfUnits = useSelector((state) => selectGrowNumberOfUnits(state, growId), _.isEqual)

  let numberOfSeedlingBoards = 0
  if (numberOfUnits !== 0) {
    if (activeGrowPlan !== undefined) {
      for (let variant of activeGrowPlan?.plant_variants) {
        let foundRaftType = allRaftTypes.find((rT) => rT.id === variant.seedling_raft_type_id)
        if (foundRaftType !== undefined) {
          numberOfSeedlingBoards += Math.ceil((numberOfUnits * (variant.ratio / 100)) / foundRaftType.info.number_of_plant_sites)
        }
      }
    }
  }

  let selectedSeedlingRaftTypes = []
  let selectedGrowOutRaftType = undefined
  if (activeGrowPlan !== undefined)   {
      for (let variant of activeGrowPlan.plant_variants)  {
          let foundRaftType = allRaftTypes.find((rT) => rT.id === variant.seedling_raft_type_id)
          if (foundRaftType !== undefined)    {
              selectedSeedlingRaftTypes.push(foundRaftType)
          }
      }
      selectedGrowOutRaftType = allRaftTypes.find((rT) => rT.id === activeGrowPlan.grow_out_raft_type_id)
  }



  //let numberOfUnitsAvailableWithinUsedZones = calculateUnitsFromZones(activeRecipe, activeBladeType, Math.ceil(numberOfZones), selectedGrowOutRaftType, activeGrowPlan.grow_out_raft_usage)
  //let percentOfAvailableUnitsWithinZones = (numberOfUnits / numberOfUnitsAvailableWithinUsedZones) * 100


  return (<>
    <div className="FlexContent-20">
      <div className="Text-Medium-S18">Grow Area Info</div>
      <div className="FlexContent-H-20">
        <div className="FlexContent-H-10 FlexContent-HCenter" style={{flex:1}}>
          <div className="ScheduleGrowPopup_GrowInfoUnitResults-Icon"><GenericLeaf /></div>
          <div className="Text-S14">{numberOfUnits} Plant Sites</div>
        </div>
        <div className="FlexContent-H-10 FlexContent-HCenter" style={{flex:1}}>
        {(activeRecipe !== undefined && selectedSeedlingRaftTypes.length !== 0 && selectedGrowOutRaftType !== undefined) && <>
            {activeRecipe.grow_out_type === "grow_boards" && <>
              {(() => {
                let numberOfGrowOutBoards = 0
                if (activeGrowPlan !== undefined) {
                  numberOfGrowOutBoards = Math.ceil(numberOfUnits / parseFloat(activeGrowPlan.grow_out_raft_usage))
                }

                return <>
                  <div className="ScheduleGrowPopup_GrowInfoUnitResults-Icon"><StandardGrowOutBoard /></div>
                  <div className="Text-S14">{numberOfGrowOutBoards} Grow Out Boards</div>
                </>
              })()}
            </>}
            {activeRecipe.grow_out_type === "berry_troughs" && <>
              {(() => {
                let numberOfBerryTroughs = 0
                if (activeGrowPlan !== undefined) {
                  numberOfBerryTroughs = Math.ceil(numberOfUnits / parseFloat(activeGrowPlan.grow_out_raft_usage))
                }

                return <>
                  <div className="ScheduleGrowPopup_GrowInfoUnitResults-Icon"><SimpleGrowBag /></div>
                  <div className="Text-S14">{numberOfBerryTroughs} Grow Bags</div>
                </>
              })()}
            </>}
          </>}
        </div>
      </div>
      <div className="FlexContent-H-20">
        <div className="FlexContent-H-10 FlexContent-HCenter" style={{ flex: 1 }}>
          <div className="ScheduleGrowPopup_GrowInfoUnitResults-Icon"><StandardSeedlingBoard /></div>
          <div className="Text-S14">{numberOfSeedlingBoards} Seedling Boards</div>
        </div>
        <div className="FlexContent-H-10 FlexContent-HCenter" style={{ flex: 1 }}>
  
        </div>
      </div>
    </div>
  </>)
}

const GrowStatus_InfoContent_ZonesBlock = ({ growId }) => {
  const [isMobile, isTablet, isDesktop] = useGetViewSize()

  
  const growBladeZoneUIDs = useSelector((state) => selectGrowBladeZoneUIDs(state, growId), _.isEqual)


  const ZoneCard = ({zoneUID, isSingle}) => {
    const bladeZoneDisplayName = useSelector((state) => selectBladeZoneDisplayNameByUID(state, zoneUID), _.isEqual)
    const bladeZoneType = useSelector((state) => selectBladeZoneTypeByUID(state, zoneUID), _.isEqual)
    const bladeDisplayNames = useSelector((state) => selectBladeDisplayNamesForBladeZoneUID(state, zoneUID), _.isEqual)
    
    let zoneSubInfo = <></>
    switch (bladeZoneType) {
      case "nursery":
        if (bladeDisplayNames["nursery"] !== undefined) {
          zoneSubInfo = <>{bladeDisplayNames["nursery"]}</>
        }
        break
      case "grow_boards":
      case "berry_troughs":
        zoneSubInfo = <>{bladeDisplayNames["grow"]} | {bladeDisplayNames["environment"]}</>

        break
      default:
        break
    }

    const cardProps = {style:{}}
    if (isDesktop)  {
      cardProps.style.flexBasis = "50%"
    }
    return (<>
      <div className="GrowStatus-Info-BladeZone-Card" {...cardProps}>
        <div className="GrowStatus-Info-BladeZone-Card-Content">
          <div className="GrowStatus-Info-BladeZone-Card-Info">
            <div className="Text-Medium-S16">{bladeZoneDisplayName}</div>
            <div className="Text-Alt14">
              {zoneSubInfo}
            </div>
          </div>
          <div className="GrowStatus-Info-BladeZone-Card-Link">
            <NavigateTo />
          </div>
        </div>
      </div>
      {(isDesktop && isSingle) && <div style={{flexBasis:"50%"}}></div>}
    </>)
  }


  return (<>

    <div className="FlexContent-20">
      <div className="Text-Medium-S18">Planted Zones</div>
      <div className="FlexContent-5">
        {isDesktop && <>
          {(() => {
            const zoneRows = growBladeZoneUIDs.reduce(function (rows, key, index) { 
              return (index % 2 == 0 ? rows.push([key]) 
                : rows[rows.length-1].push(key)) && rows;
            }, []);
            return (<>
              {zoneRows.map((zoneRow, index) => {
                return (
                  <div key={index} className="FlexContent-H-5">
                    {zoneRow.map((growZoneUID) => {
                      return <ZoneCard key={growZoneUID} zoneUID={growZoneUID} isSingle={zoneRow.length === 1}/>
                    })}
                  </div>
                )
              })}
            </>)
          })()}
        </>}
        {!isDesktop && <>
          {growBladeZoneUIDs.map((growZoneUID) => {
            return <ZoneCard key={growZoneUID} zoneUID={growZoneUID}/>
          })}
        </>}
      </div>
    </div>
  </>)
}





export default GrowStatus_InfoContent