import { CloseDropdown, OpenCell, OpenDropdown, SimpleCheck } from '../../assets/icons/Icons';
import useMeasure from '../../useMeasure';
import './DropDownInput.scss';

import * as React from 'react';



const DropDownInput = ({
        status = "Neutral", placeholder, isOpen, flex = false, disabled = false, 
        displayWidth = "auto", popupMargin = 4, screenMargin = 10, popupBorderThickness = 1,
        scrollMinItems = 3, 
        label, uid, 
        canSearch = false, onSearch,
        hasIcon = false, options = [], optionsKey = "value",  value, defaultValue = "", finalOption,
        prefix = null, suffix = null, 
        onSelectionChange, onContainerClicked}) => {


  const [isOpenState, SetIsOpenState] = React.useState()
  React.useLayoutEffect(() => {
    SetIsOpenState(isOpen)
  }, [isOpen])

  const [currentValue, SetCurrentValue] = React.useState("");

  const handleChange = (e) => {
    SetCurrentValue(e.target.value);

    if (onSelectionChange !== undefined)  {
      onSelectionChange(e.target.value, e)
    }
  };

  React.useEffect(() => {
    if (value !== undefined && value !== currentValue) {
      //let foundOption = options.find((o) => o.value === value)
      //if (foundOption !== undefined)  {
        SetCurrentValue(value)
      //}
    }
  }, [value])

  let selectedOption = options.find((o) => o[optionsKey] === currentValue); 
  

  //Search related functions
  
  const [inputSearch, SetInputSearch] = React.useState("")
  const searchInputFieldRef = React.useRef(null)
  const searchTextChanged = (e) => {
    SetInputSearch(e.target.value)
    SetCurrentValue(null)
    
    if (onSearch !== undefined) {
      onSearch(e.target.value)
    }
  }
  React.useLayoutEffect(() => {
    if (canSearch && searchInputFieldRef.current !== null)  {
      if (isOpenState)  {
        searchInputFieldRef.current.focus()
      }
    }
  }, [isOpenState, canSearch, searchInputFieldRef])

  const containerClicked = (e) => {
    if (disabled) {
      SetIsOpenState(false)
      return
    }
    if (onContainerClicked !== undefined) {
      onContainerClicked(e)
    }
    SetIsOpenState(!isOpenState)
  }

  const closePopup = (e) => {
    SetIsOpenState(false)
  }



  const [dropDownInputContainerHandleRef, {documentLeft: dropDownInputLeft, documentTop: dropDownInputTop, documentRight: dropDownInputRight, documentBottom: dropDownInputBottom, width: dropDownInputWidth, height: dropDownInputHeight}] = useMeasure()
  const [dropDownPopupContainerHandleRef, {width: dropDownPopupWidth, height: dropDownPopupHeight}] = useMeasure()
  const [dropDownPopupContentHandleRef, {width: dropDownPopupContentWidth, height: dropDownPopupContentHeight}] = useMeasure()
  //const [dropDownPopupItemBind, {height: dropDownPopupItemHeight}] = useMeasure()
  let dropDownPopupItemHeight = 30
  const [dropDownPopupMinimumScrollHeight, SetDropDownPopupMinimumScrollHeight] = React.useState(0)

  React.useEffect(() => {
    SetDropDownPopupMinimumScrollHeight(scrollMinItems * dropDownPopupItemHeight)
  }, [dropDownPopupItemHeight])

  const dropDownInputStyleProps = {}
  if (flex) {
    dropDownInputStyleProps.flex = "1"
  }


  const dropDownPopupWrapperProps = {style:{}}
  const dropDownPopupProps = {style:{borderWidth:popupBorderThickness, minWidth: dropDownInputWidth}}
  if (!isOpenState) {
    dropDownPopupWrapperProps.style.height = 0
  }else {
    //dropDownPopupProps.style.height = dropDownPopupHeight
    //Calculate popup position
  }

  let minimumHeightRequired = dropDownPopupMinimumScrollHeight + ((popupBorderThickness * 2) + popupMargin + screenMargin)
  let desiredHeightRequired = dropDownPopupContentHeight + (popupBorderThickness * 2) + (popupMargin + screenMargin)
  if (dropDownInputBottom - minimumHeightRequired > 0)  {
    dropDownPopupWrapperProps.style.top = dropDownInputTop + dropDownInputHeight + popupMargin

    if (isOpenState) {
      if (dropDownInputBottom - desiredHeightRequired > 0)  {
        dropDownPopupProps.style.maxHeight = dropDownPopupContentHeight + popupBorderThickness * 2
      }else {
        dropDownPopupProps.style.maxHeight = dropDownInputBottom - (popupMargin + screenMargin)
      }
    }

  }else if (dropDownInputTop - minimumHeightRequired > 0) {
    dropDownPopupWrapperProps.style.bottom = dropDownInputHeight + dropDownInputBottom + popupMargin

    if (isOpenState) {
      if (dropDownInputTop - desiredHeightRequired > 0)  {
        dropDownPopupProps.style.maxHeight = dropDownPopupContentHeight + popupBorderThickness * 2
      }else {
        dropDownPopupProps.style.maxHeight = minimumHeightRequired
      }
    }
  }else {
    dropDownPopupWrapperProps.style.bottom = screenMargin
    dropDownPopupProps.style.maxHeight = minimumHeightRequired
  }
  let desiredWidthRequired = dropDownPopupContentWidth + (popupBorderThickness * 2) + screenMargin
  if ((dropDownInputRight + dropDownInputWidth) - desiredWidthRequired > 0) {
    dropDownPopupWrapperProps.style.left = dropDownInputLeft
  }else if (dropDownInputLeft + dropDownInputWidth > desiredWidthRequired) {
    dropDownPopupWrapperProps.style.right = dropDownInputRight
  }else {
    //we need to shrink!!!
    dropDownPopupWrapperProps.style.left = screenMargin
    dropDownPopupWrapperProps.style.right = screenMargin
  }



  const dropDownItemDisplayProps = {style:{}}
  if (displayWidth !== "auto")  {
    dropDownItemDisplayProps.style.width = displayWidth
  }

  return (<>
    <div className={"DropDownInput DropDownInput-" + status + (isOpenState ? " DropDownInput-Selected" : "")+ (disabled ? " DropDownInput-Disabled" : "")}
      onClick={containerClicked} style={dropDownInputStyleProps} ref={dropDownInputContainerHandleRef}>
      <div className="DropDownInput-Container">
        <div className="DropDownInput-Field"> 
          {hasIcon && <>
            <div className="DropDownInput-Field-Icon noselect">
              {(selectedOption !== undefined && selectedOption.icon !== undefined) && selectedOption.icon}
            </div>
          </>}
          {canSearch && <>
            <div className="DropDownInput-Field-SelectedItem noselect" {...dropDownItemDisplayProps}>
              {!isOpenState && <>
                {selectedOption !== undefined && 
                  <div className="DropDownInput-Field-SelectedItem noselect" {...dropDownItemDisplayProps}>
                    {selectedOption.label}
                  </div>
                }
                {selectedOption === undefined && <>
                  {inputSearch !== "" && <>
                    <div className="DropDownInput-Field-SelectedItem DropDownInput-Field-SelectedItem-Invalid noselect" {...dropDownItemDisplayProps}>
                      {inputSearch}
                    </div>  
                  </>}
                  {(inputSearch === "" && placeholder !== null) && <>
                    <div className="DropDownInput-Field-Placeholder noselect" {...dropDownItemDisplayProps}>
                      {placeholder}
                    </div>   
                  </>}
                        
                </>}
              </>}
              {isOpenState && <>
                {(() => {
                  let currentInputValue = ""        
                  if (selectedOption !== undefined) {
                    currentInputValue = selectedOption.label 
                  }else {
                    currentInputValue = inputSearch
                  }
                  return <>
                    <input className="DropDownInput-Field-Search" ref={searchInputFieldRef} type={"text"} value={currentInputValue} onChange={searchTextChanged}/>

                  </>
                })()}
              </>}
            </div>
          
          </>}
          {prefix && <>
            <div className="DropDownInput-Field-Prefix noselect" {...dropDownItemDisplayProps}>
              {prefix}
            </div>
          </>}
          {!canSearch && <>
            {selectedOption !== undefined && 
              <div className="DropDownInput-Field-SelectedItem noselect" {...dropDownItemDisplayProps}>
                {selectedOption.label}
              </div>
            }
            {(selectedOption === undefined && placeholder !== null) && 
              <div className="DropDownInput-Field-Placeholder noselect" {...dropDownItemDisplayProps}>
                {placeholder}
              </div>          
            }
          </>}

          <div className="DropDownInput-Field-Arrow">
            {isOpenState && 
              <CloseDropdown/>
            }
            {!isOpenState && 
              <OpenDropdown/>
            }
          </div>
        </div>
      </div>


    </div>

    
    <div className="DropDownInput-Popup-Wrapper" {...dropDownPopupWrapperProps}>
        <div className="DropDownInput-Popup-Container" ref={dropDownPopupContainerHandleRef}>
          <div className="DropDownInput-Popup" {...dropDownPopupProps}>
            <div className="DropDownInput-Popup-ContentWrapper" ref={dropDownPopupContentHandleRef}>
              <div className="DropDownInput-Popup-Content">

                <div className="DropDownInput-Popup-Options">
                  {options.map((option) => {

                    const optionClicked = (e) => {
                      if (isDisabled) {
                        return
                      }
                      SetInputSearch("")
                      if (onSearch !== undefined) {
                        onSearch("")
                      }
                      SetCurrentValue(option[optionsKey])
                      if (onSelectionChange !== undefined)  {
                        onSelectionChange(option[optionsKey], e)
                      }
                      closePopup()
                    }

                    let isSelectedOption = currentValue === option[optionsKey]
                    let isDisabled = option.disabled !== undefined && option.disabled === true

                    return (<div 
                              key={option[optionsKey]}
                              className={"DropDownInput-Popup-Option" + (isSelectedOption ? " DropDownInput-Popup-Option-Selected" : "") + (isDisabled ? " DropDownInput-Popup-Option-Disabled" : "")} 
                              onClick={optionClicked}>

                      <div className="DropDownInput-Popup-Option-Title noselect">
                        {hasIcon && <>
                          <div className="DropDownInput-Popup-Option-Icon noselect">
                            {option.icon !== undefined && option.icon}
                          </div>
                        </>}
                        <div className={"FlexContent-H FlexContent-Center"} style={{gap:4}}>
                          {isSelectedOption && <SimpleCheck/>}
                          {option.label}
                        </div>
                      </div>

                      {(option.info !== undefined && option.info !== null) && 
                        <div className="DropDownInput-Popup-Option-Info noselect">
                          {option.info}
                        </div>
                      }

                    </div>)
                  })}
                  {finalOption !== undefined && finalOption}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOpenState &&
        <div className="DropDownInput-Popup-Closer" onClick={closePopup}></div>
      }
    </>
    
  )
} 


export default DropDownInput