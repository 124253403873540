import './BladeIssuesPopup.scss';
import _ from 'underscore';
import React from 'react';
import PopupModal from '../../../model_components/PopupModal';
import RowGroup from '../../../components/RowGroup/RowGroup';
import { useDispatch, useSelector } from 'react-redux';
import { markActiveIssueAsManuallyResolved, processBladeIssue, selectBladeActiveIssuesById, selectBladeIssuesByBladeId } from '../../../redux/entities/service/Blade';
import RowGroupRow from '../../../components/RowGroup/RowGroupRow';
import { SoftWarning } from '../../../assets/icons/Icons';
import Button from '../../../components/Button';
  

const BladeIssuesPopup = ({bladeId, closeCallback}) => {
   
    const activeBladeIssues = useSelector((state) => selectBladeActiveIssuesById(state, bladeId), _.isEqual)
  
    const dispatch = useDispatch()
    const [markingBladeIssueAsResolved, SetMarkingBladeIssueAsResolved] = React.useState(false)
    const markIssueAsResolved = (issueId) => {
        SetMarkingBladeIssueAsResolved(true)
        dispatch(markActiveIssueAsManuallyResolved({bladeId: bladeId, issueId: issueId, callback: (success) => {
            SetMarkingBladeIssueAsResolved(false)
        }}))
    }

    return (<>
        <PopupModal
            id={"ManageBladeWalkthrough_Popup"}
            title={<>
                <div className="FlexContent-H-10 FlexContent-Center"><SoftWarning fill={"#EC3C3C"}/>Blade Issues</div>
            </>}
            hideMainSeparators={true}
            closeCallback={closeCallback}
            size={"full"}
            footer={<>
                <Button content={"Done"} status={"Primary"} onClick={closeCallback} width={"Flex100"}/>
            </>}>
            <>
                {activeBladeIssues.length === 0 && <>
                    <div>No active issues</div>
                </>} 
                {activeBladeIssues.length > 0 && <>
                    <div className="BladeIssuesPopup-Issues">
                        {activeBladeIssues.map((bladeIssue) => {
                            const [issueName, issueInfo] = processBladeIssue(bladeIssue)
                            let issueDate = new Date(Date.parse(bladeIssue.triggered_on))
                            console.log(issueInfo)
                            return <>
                                <div key={bladeIssue.local_id} className={"BladeIssuesPopup-Issue-Container" + (bladeIssue !== activeBladeIssues[activeBladeIssues.length - 1] ? " Linebreak-20" : "")}>
                                    <div className="BladeIssuesPopup-Issue">
                                        <div className="BladeIssuesPopup-Issue-Title">
                                            <div className="Text-S16">{issueName}</div>
                                            <div className="Text-AltI14">{issueDate.toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</div>
                                        </div>
                                        <div className="Text-S14">{issueInfo}</div>
                                    </div>
                                    <Button status={"Hyperlink"} content={"Mark as resolved"} contentPadding={0} onClick={() => {markIssueAsResolved(bladeIssue.local_id)}} disabled={markingBladeIssueAsResolved}/>
                                </div>
                            </>
                        })}
                    </div>
                </>}
            </>
        </PopupModal>
        
    </>)

}



export default BladeIssuesPopup