import '../BladeInstance.scss';
import variables from '../../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { AdditionalOptions, Pause, Play } from '../../../../assets/icons/Icons';
import DropDownButton from '../../../../components/DropDownButton';
import { getLiveDataItem, getUniqueConfigurationByName, selectAllBladeConfigurationMaps, selectBladeComponentInfo, selectBladeLiveDataByComponentInfo, selectBladeRuntimeInformation, selectBladeUIDById, selectBladeUniqueConfigurationByName, selectBladeZoneRuntimeInformation, selectBladeZonesForBladeUID, setBladeRuntimeProperty, setBladeZoneRuntimeProperty } from '../../../../redux/entities/service/Blade';
import { DataStatus_DataList } from '../../../../components/DataStatus/DataStatus';
import { parseBool } from '../../../../helpers';
import Switch from '../../../../components/Switch';
import NumberInput from '../../../../components/input/NumberInput';
import { selectAllAccountPrivileges } from '../../../../redux/entities/Account';



const DataStatus_LightsCell = ({ bladeId }) => {

    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 1079 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
  

    const dispatch = useDispatch()
      
    
    const bladeUID = useSelector((state) => selectBladeUIDById(state, bladeId))
    const bladeZones = useSelector((state) => selectBladeZonesForBladeUID(state, bladeUID), _.isEqual)

    

    const accountPrivileges = useSelector((state) => selectAllAccountPrivileges(state), _.isEqual)
    const accountHasServicingPriviliges = (accountPrivileges["servicing"] !== undefined)
    const [inServiceModeRuntimeInformation] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "service_mode"), _.isEqual)
    const inServiceMode = accountHasServicingPriviliges ? parseBool(inServiceModeRuntimeInformation) : false

    const [allLightsInOverrideRuntimeInformation] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "all_lights_output_factor_override"), _.isEqual)
    const allLightsInOverride = parseBool(allLightsInOverrideRuntimeInformation)
    const [allLightsOverrideSpeedRuntimeInformation] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "all_lights_output_factor"), _.isEqual)




    const [lightsControlStatus] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "lights_control_status"))
    const isLightsPaused = lightsControlStatus !== "active"
    const bladeZoneResumingOn = useSelector((state) => selectBladeZoneRuntimeInformation(state, bladeZones["environment"], "resume_controls_on"), _.isEqual)

    
      
    const [totalIntensityComponentInfo,
      totalIntensityZ1ComponentInfo, totalIntensityZ2ComponentInfo, totalIntensityZ3ComponentInfo, totalIntensityZ4ComponentInfo, 
      totalIntensityZ5ComponentInfo, totalIntensityZ6ComponentInfo, totalIntensityZ7ComponentInfo] 
    = useSelector((state) => selectBladeComponentInfo(state, bladeId, "LightingController", ["int-a-all", "int-a-1", "int-a-2", "int-a-3", "int-a-4", "int-a-5", "int-a-6", "int-a-7"]), _.isEqual)

    const totalIntensity = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, totalIntensityComponentInfo), _.isEqual)
    const totalIntensityZ1 = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, totalIntensityZ1ComponentInfo), _.isEqual)
    const totalIntensityZ2 = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, totalIntensityZ2ComponentInfo), _.isEqual)
    const totalIntensityZ3 = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, totalIntensityZ3ComponentInfo), _.isEqual)
    const totalIntensityZ4 = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, totalIntensityZ4ComponentInfo), _.isEqual)
    const totalIntensityZ5 = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, totalIntensityZ5ComponentInfo), _.isEqual)
    const totalIntensityZ6 = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, totalIntensityZ6ComponentInfo), _.isEqual)
    const totalIntensityZ7 = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, totalIntensityZ7ComponentInfo), _.isEqual)

    const auxALiquidSystemActiveConfiguration = useSelector((state) => selectBladeUniqueConfigurationByName(state, bladeId, "AuxALiquidSystemInstalled"), _.isEqual)
    const auxBLiquidSystemActiveConfiguration = useSelector((state) => selectBladeUniqueConfigurationByName(state, bladeId, "AuxBLiquidSystemInstalled"), _.isEqual)
    var isAuxAPumpingSystemActive = parseBool(auxALiquidSystemActiveConfiguration ?? false)
    var isAuxBPumpingSystemActive = parseBool(auxBLiquidSystemActiveConfiguration ?? false)


    let dataListItems = [
      {label: "Total Int", value: totalIntensity.value, suffix: "umol/ms/s", visualization: {
        type: "bar", info: {usesRange: true, min: 0, max: 500, status: "normal"}
      }},
    ]

    if (isAuxAPumpingSystemActive || isAuxBPumpingSystemActive) {
      dataListItems.push(...[
        {label: "Total Int (Z1)", value: totalIntensityZ1.value, suffix: "umol/ms/s", visualization: {
          type: "bar", info: {usesRange: true, min: 0, max: 500, status: "normal"}
        }},
        {label: "Total Int (Z2)", value: totalIntensityZ2.value, suffix: "umol/ms/s", visualization: {
          type: "bar", info: {usesRange: true, min: 0, max: 500, status: "normal"}
        }},
        {label: "Total Int (Z3)", value: totalIntensityZ3.value, suffix: "umol/ms/s", visualization: {
          type: "bar", info: {usesRange: true, min: 0, max: 500, status: "normal"}
        }},
        {label: "Total Int (Z4)", value: totalIntensityZ4.value, suffix: "umol/ms/s", visualization: {
          type: "bar", info: {usesRange: true, min: 0, max: 500, status: "normal"}
        }},
        {label: "Total Int (Z5)", value: totalIntensityZ5.value, suffix: "umol/ms/s", visualization: {
          type: "bar", info: {usesRange: true, min: 0, max: 500, status: "normal"}
        }},
        {label: "Total Int (Z6)", value: totalIntensityZ6.value, suffix: "umol/ms/s", visualization: {
          type: "bar", info: {usesRange: true, min: 0, max: 500, status: "normal"}
        }},
        {label: "Total Int (Z7)", value: totalIntensityZ7.value, suffix: "umol/ms/s", visualization: {
          type: "bar", info: {usesRange: true, min: 0, max: 500, status: "normal"}
        }},
        
      ])
    }


    const defaultPauseDuration = 6000
    const [pendingBladeToPause, SetPendingBladeToPause] = React.useState(false)
    const pauseLightsControlClicked = () => {
      //First set the default pause duration if the current pause duration is too short
      const minResumeAt = Math.floor((new Date().getTime() / 1000) + defaultPauseDuration)
      if (bladeZoneResumingOn === undefined || parseFloat(bladeZoneResumingOn) < minResumeAt) {
        dispatch(setBladeZoneRuntimeProperty({
          bladeZoneUID: bladeZones["environment"],
          properties: { "resume_controls_on": minResumeAt.toString() },
          callback: (success) => {
            if (success)  {
              SetPendingBladeToPause(true)
            }else {
  
            }
          }
        }))
      }else {
        SetPendingBladeToPause(true)
      }
    }
    React.useLayoutEffect(() => {
      if (pendingBladeToPause)  {
        SetPendingBladeToPause(false)
        dispatch(setBladeRuntimeProperty({
          bladeId: bladeId,
          properties: { "lights_control_status": "pause" },
          callback: (success) => {
            if (success)  {
  
            }else {
  
            }
          }
        }))
      }
    }, [pendingBladeToPause])
    const resumeLightsControlClicked = () => {
      dispatch(setBladeRuntimeProperty({
        bladeId: bladeId,
        properties: { "lights_control_status": "resume" },
        callback: (success) => {
          
        }
      }))
    }
    
    const allLightsOverrideToggled = (state) => {
      dispatch(setBladeRuntimeProperty({ bladeId: bladeId, properties: { "all_lights_output_factor_override": state ? 1 : 0 } }))
    }
    const allLightsOverrideSpeedChanged = (speed) => {
      dispatch(setBladeRuntimeProperty({ bladeId: bladeId, properties: { "all_lights_output_factor": speed  } }))
    }
  

    return (<>
      <div className="DataStatus-Cell">
        <div className="DataStatus-Cell-Header-Container">
          <div className="DataStatus-Cell-Header-PrimaryContent">
            <div className="Text-H14">Lighting</div>
          </div>
          <div className="DataStatus-Cell-Header-AuxContent">
            {isLightsPaused && <>
              <Button status={"Primary-Inverted"} size={"Small"} content={<Play fill={"#2E72D2"} />} onClick={resumeLightsControlClicked} disabled={!isLightsPaused} />
            </>}
            {!isLightsPaused &&
              <Button status={"Small-IconAction"} size={"Small"} content={<Pause />} onClick={pauseLightsControlClicked} disabled={isLightsPaused} />
            }
            <DropDownButton content={<AdditionalOptions/>} status={"Neutral"}/>
          </div>
        </div>
        <div className="DataStatus-Cell-Content-Container">
          <DataStatus_DataList items={dataListItems}/>
        </div>
              
        {inServiceMode && 
          <div className="DataStatus-Override-Container">
            <div className="DataStatus-Override-Toggle">
              <Switch state={allLightsInOverride} onSwitch={allLightsOverrideToggled}/>
              <div className="Text-Alt14">Override</div>
            </div>
            {allLightsInOverride && 
              <NumberInput min={0} max={100} value={allLightsOverrideSpeedRuntimeInformation} onChange={allLightsOverrideSpeedChanged}/>
            }
          </div>
        }
      </div>
    </>)
  }
  
  export default DataStatus_LightsCell