import './CircularGauge.scss';
import React from 'react';
import { CircularProgressbarWithChildren, buildStyles} from 'react-circular-progressbar';



const CircularGauge = ({
    value, 
    auxText = false, 
    unit = "%", 
    min = 0, 
    max = 100, 
    trendData, 
    applicable = true, 
    valueDisplayFontSize = 24
  }) => {

  const [gaugeColors, setGaugeColors] = React.useState({
    good: {min: 90, max:100, color: "rgba(90, 195, 100, 1)"},
    operational: {min: 75, max:90, color: "rgba(0, 255, 0, 1)"},
    critical: {min: 50, max:75, color: "rgba(255, 122, 44, 1)"},
    fail: {min: 0, max:50, color: "rgba(0, 255, 0, 1)"},
    neutral: {color: "rgba(120, 122, 12, 1)"},
  })


  const [currentValue, SetCurrentValue] = React.useState(0)
  const [gaugeColor, SetGaugeColor] = React.useState(gaugeColors.neutral.color)


  React.useEffect(() => {
    if (!applicable)  {
      SetCurrentValue(100)
      return
    }
    if (value < min)
      value = min
    if (value > max)
      value = max
    if (currentValue !== value) {
      SetCurrentValue(value)
      SetGaugeColor(Object.values(gaugeColors).find((gC) => gC.min <= value && gC.max >= value).color)
    }
  }, [value, applicable])

  return (
    <div className={"CircularGauge"}>
      <CircularProgressbarWithChildren 
        value={currentValue} 
        circleRatio={1}
        strokeWidth={10}
        styles={buildStyles({
          rotation: -value / 100,
          strokeLinecap: "round",
          pathColor: "#1D65F0",
          trailColor: "#DDE3EB",
        })}>
          <div className="CircularGauge-Content">
            <div className="CircularGauge-ValueDisplay" style={{color: "#000", fontSize: valueDisplayFontSize}}>
              {applicable && 
                <>{Math.round(currentValue * 10) / 10}{unit}</>
              }
              {!applicable && 
                <>N/A</>
              }
            </div>
            {!!(applicable && auxText) && <div className="CircularGauge-AuxText">{auxText}</div>}
          </div>
          </CircularProgressbarWithChildren>
    </div>
  )
} 


export default CircularGauge