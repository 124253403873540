import './ControlBar.scss';



const ControlBar = ({
    direction = "horizontal", 
    controls = false, 
    secondaryControls = false, 
    centerControls = false, 
    buttonSpacing = 10
  }) => {

  if (direction === "horizontal") {
    let controlProps = {style:{}}
    controlProps.style.gap = buttonSpacing
    return (
      <div className={"ControlBar-Horizontal" + (centerControls !== false ? " ControlBar-Center" : "")}>
        {centerControls !== false &&
          <div className="ControlBar-Horizontal-Center ControlBar-Horizontal-Overflow" {...controlProps}>
            {centerControls}
          </div>  
        }
        {!centerControls && <>
            <div className="ControlBar-Horizontal-Left ControlBar-Horizontal-Overflow" {...controlProps}>
              {controls}
            </div>
            {secondaryControls && 
              <div className="ControlBar_Horizontal-Right" {...controlProps}>
                {secondaryControls}
              </div>
            }
          </>}
      </div>
    )
  }else if (direction === "vertical") {
    return (
      <>
      </>
    )
  }
} 


export default ControlBar