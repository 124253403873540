import useMeasure from '../../useMeasure';
import './DataStatus.scss';
import React from 'react'
import { remapRange, RoundToNearest } from '../../helpers';

export const DataStatus_DataList = ({
    items,
}) => {



    const [currentLabelWidth, SetCurrentLabelWidth] = React.useState("auto")
    const [currentValueWidth, SetCurrentValueWidth] = React.useState("auto")
    const [currentStandardBarMinLabelWidth, SetCurrentStandardBarMinLabelWidth] = React.useState("auto")
    const [currentStandardBarMaxLabelWidth, SetCurrentStandardBarMaxLabelWidth] = React.useState("auto")

    const [labelWidths, SetLabelWidths] = React.useState({})
    const [valueWidths, SetValueWidths] = React.useState({})
    const [standardBarMinLabelWidths, SetStandardBarMinLabelWidths] = React.useState({})
    const [standardBarMaxLabelWidths, SetStandardBarMaxLabelWidths] = React.useState({})


    const labelSizeChanged = (index, width) => {
        labelWidths[index] = width
        SetLabelWidths({...labelWidths})
    }
    const valueSizeChanged = (index, width) => {
        valueWidths[index] = width
        SetValueWidths({...valueWidths})
    } 
    const standardBarMinLabelSizeChanged = (index, width) => {
        standardBarMinLabelWidths[index] = width
        SetStandardBarMinLabelWidths({...standardBarMinLabelWidths})
    }
    const standardBarMaxLabelSizeChanged = (index, width) => {
        standardBarMaxLabelWidths[index] = width
        SetStandardBarMaxLabelWidths({...standardBarMaxLabelWidths})
    }


    React.useLayoutEffect(() => {
        SetCurrentLabelWidth(Math.max(...Object.values(labelWidths)))
    }, [labelWidths])
    React.useLayoutEffect(() => {
        SetCurrentValueWidth(Math.max(...Object.values(valueWidths)))
    }, [valueWidths])
    React.useLayoutEffect(() => {
        SetCurrentStandardBarMinLabelWidth(Math.max(...Object.values(standardBarMinLabelWidths)))
    }, [standardBarMinLabelWidths])
    React.useLayoutEffect(() => {
        SetCurrentStandardBarMaxLabelWidth(Math.max(...Object.values(standardBarMaxLabelWidths)))
    }, [standardBarMaxLabelWidths])

    return (<>
        <div className="DataStatus-Cell-DataList">
            {items.map((item, index) => {
                if (item.type === undefined || item.type === "standard")  {
                    return (<DataStatus_DataListItem 
                        key={index}
                        index={index}
                        item={item}
                        labelWidths={labelWidths}
                        valueWidths={valueWidths}
                        minLabelWidths={standardBarMinLabelWidths}
                        maxLabelWidths={standardBarMaxLabelWidths}
                        desiredLabelWidth={currentLabelWidth}
                        desiredValueWidth={currentValueWidth}
                        desiredMinLabelWidth={currentStandardBarMinLabelWidth}
                        desiredMaxLabelWidth={currentStandardBarMaxLabelWidth}
                        labelSizeChanged={labelSizeChanged}
                        valueSizeChanged={valueSizeChanged}
                        minLabelSizeChanged={standardBarMinLabelSizeChanged}
                        maxLabelSizeChanged={standardBarMaxLabelSizeChanged} />)
                }else if (item.type === "large_bar") {
                    return (<DataStatus_DataList_LargeBar item={item}/>)
                }else if (item.type === "setpoint_bar")  {
                    return (<DataStatus_DataList_SetpointBar item={item}/>)
                }
            })}
        </div>
    </>)
}
export const DataStatus_DataListItem = ({
    index,
    item,
    labelWidths,
    valueWidths,
    minLabelWidths,
    maxLabelWidths,
    desiredLabelWidth,
    desiredValueWidth,
    desiredMinLabelWidth,
    desiredMaxLabelWidth,
    labelSizeChanged,
    valueSizeChanged,
    minLabelSizeChanged,
    maxLabelSizeChanged,
}) => {

    const [labelRef, { width: labelWidth }] = useMeasure()
    const [valueRef, { width: valueWidth }] = useMeasure()
    React.useLayoutEffect(() => {
        if (labelWidths[index] !== labelWidth)  {
            labelSizeChanged(index, labelWidth)
        }
    }, [labelWidths, labelWidth])
    React.useLayoutEffect(() => {
        if (valueWidths[index] !== valueWidth)  {
            valueSizeChanged(index, valueWidth)
        }
    }, [valueWidths, valueWidth])

    const isValid = item.valid !== undefined ? item.valid : true
    const invalidContent = item.invalidContent !== undefined ? item.invalidContent : "N/A"
    return (<>
        <div className="DataStatus-Cell-DataList-StandardItem">
            <div className="DataStatus-Cell-DataList-StandardItem-Label" style={{width:desiredLabelWidth}}>
                <div className="DataStatus-Cell-DataList-StandardItem-Label-Content" ref={labelRef}>
                    <div className="Text-Alt12 Text-SoftTruncate">{item.label}</div>
                </div>
            </div>
            <div className="DataStatus-Cell-DataList-StandardItem-VisualizationContainer">
                {(item.visualization !== undefined && item.visualization.type === "bar") && <>
                    <DataStatus_DataList_Bar 
                        index={index}
                        value={item.value} 
                        info={item.visualization.info}
                        minLabelWidths={minLabelWidths}
                        maxLabelWidths={maxLabelWidths}
                        desiredMinLabelWidth={desiredMinLabelWidth}
                        desiredMaxLabelWidth={desiredMaxLabelWidth}
                        minLabelSizeChanged={minLabelSizeChanged}
                        maxLabelSizeChanged={maxLabelSizeChanged}
                        />
                </>}
                
                
            </div>
            <div className={"DataStatus-Cell-DataList-StandardItem-Value" + (!isValid ? " DataStatus-Cell-DataList-StandardItem-Value-Invalid" : "")} style={{width:desiredValueWidth}}>
                <div className="DataStatus-Cell-DataList-StandardItem-Value-Content" ref={valueRef}>
                    <div className="Text-Alt12 Text-SoftTruncate">
                        {isValid && <>
                            {item.value}{item.suffix !== undefined && <span className="Text-Alt10">{item.suffix}</span>}                        
                        </>}
                        {!isValid && <>
                            {invalidContent}
                        </>}
                    </div>
                </div>
            </div>
        </div>
    </>)
}







export const DataStatus_DataList_Bar = ({
    index,
    value,
    info,
    minLabelWidths,
    maxLabelWidths,
    desiredMinLabelWidth,
    desiredMaxLabelWidth,
    minLabelSizeChanged,
    maxLabelSizeChanged,
}) => {

    const [minLabelRef, { width: minLabelWidth }] = useMeasure()
    const [maxLabelRef, { width: maxLabelWidth }] = useMeasure()
    React.useLayoutEffect(() => {
        if (minLabelWidths[index] !== minLabelWidth)  {
            minLabelSizeChanged(index, minLabelWidth)
        }
    }, [minLabelWidths, minLabelWidth])
    React.useLayoutEffect(() => {
        if (maxLabelWidths[index] !== maxLabelWidth)  {
            maxLabelSizeChanged(index, maxLabelWidth)
        }
    }, [maxLabelWidths, maxLabelWidth])
 

    let rangeUse = remapRange (value, [info.min, info.max], [0, 1])
    if (rangeUse < 0)
        rangeUse = 0
    else if (rangeUse > 1)
        rangeUse = 1
    
    return (<>
        <div className="DataStatus-Cell-DataList-BarVisualization-Container">
            <div className="DataStatus-Cell-DataList-BarVisualization">
                {(info.usesRange && info.showsRange !== false) && <>
                    <div className="DataStatus-Cell-DataList-BarVisualization-RangeMin" style={{width:desiredMinLabelWidth}}>
                        <div className="DataStatus-Cell-DataList-BarVisualization-RangeMin-Content" ref={minLabelRef}>
                            {info.min !== undefined && <> 
                                <div className="Text-Alt10">{info.min}</div>
                            </>}
                        </div>
                    </div>
                </>}
                <div className="DataStatus-Cell-DataList-Bar-Container">
                    <div className={"DataStatus-Cell-DataList-Bar" + (info.status ? " DataStatus-Cell-DataList-Bar-" + info.status : " DataStatus-Cell-DataList-Bar-normal")}>
                        <div className="DataStatus-Cell-DataList-BarFill" style={{width: (100 * rangeUse ?? 0) + "%"}}></div>
                    </div>
                </div>
                {(info.usesRange && info.showsRange !== false) && <>
                    <div className="DataStatus-Cell-DataList-BarVisualization-RangeMax" style={{width:desiredMaxLabelWidth}}>
                        <div className="DataStatus-Cell-DataList-BarVisualization-RangeMax-Content" ref={maxLabelRef}>
                            {info.max !== undefined && <> 
                                <div className="Text-Alt10">{info.max}</div>
                            </>}
                        </div>
                    </div>
                </>}
            </div>
        </div>
    </>)
}



export const DataStatus_DataList_LargeBar = ({
    item,
}) => {

    let rangeUse = remapRange (item.value, [item.min, item.max], [0, 1])
    if (rangeUse < 0)
        rangeUse = 0 
    else if (rangeUse > 1)
        rangeUse = 1


    
    return (<>
        <div className="DataStatus-Cell-DataList-LargeBarVisualization-Container">
            <div className="DataStatus-Cell-DataList-LargeBarVisualization-Header">
                <div className="DataStatus-Cell-DataList-LargeBarVisualization-Header-Label">
                    <div className="Text-Alt16">{item.label}</div>
                </div>
                <div className="DataStatus-Cell-DataList-LargeBarVisualization-Header-Value">
                    <div className="Text-Alt16">{item.valueToDisplay ?? item.value}{item.suffix !== undefined && item.suffix}</div>
                </div>
            </div>
            <div className="DataStatus-Cell-DataList-LargeBarVisualization-Bar-Container">
                <div className={"DataStatus-Cell-DataList-LargeBarVisualization-Bar" + (item.status ? " DataStatus-Cell-DataList-LargeBarVisualization-Bar-" + item.status : " DataStatus-Cell-DataList-LargeBarVisualization-Bar-normal")}>
                    <div className="DataStatus-Cell-DataList-LargeBarVisualization-BarFill" style={{width: (100 * rangeUse ?? 0) + "%"}}></div>
                    <div className="Text-S20 DataStatus-Cell-DataList-LargeBarVisualization-Display">{RoundToNearest(item.value, 0.1)}%</div>
                </div>
            </div>
        </div>
    </>)
}


export const DataStatus_DataList_SetpointBar = ({
    item,
}) => {

 
    const isSetpointActive = item.setpoint_active !== undefined ? item.setpoint_active : true
    const fromSetpoint = isSetpointActive ? RoundToNearest(item.value - item.setpoint, 0.1) : 0
    
  

        
    let leftFromCenter = 0
    let rightFromCenter = 0
    if (isSetpointActive)   {
        if (fromSetpoint > 0) {
            rightFromCenter = remapRange (item.value - item.setpoint, [0, item.range], [0, 0.5])
        }else {
            leftFromCenter = remapRange (item.setpoint - item.value, [0, item.range], [0, 0.5])
        }
    }

    if (rightFromCenter < 0.02)    {
        rightFromCenter = 0.02
    }else if (rightFromCenter > 0.5)    {
        rightFromCenter = 0.5
    }

    if (leftFromCenter < 0.02)    {
        leftFromCenter = 0.02
    }else if (leftFromCenter > 0.5)    {
        leftFromCenter = 0.5
    }

    
    return (<>
        <div className="DataStatus-Cell-DataList-SetpointBarVisualization-Container">
            <div className="DataStatus-Cell-DataList-SetpointBarVisualization-Header">
                <div className="DataStatus-Cell-DataList-SetpointBarVisualization-Header-Label">
                    <div className="Text-Alt14">{item.label}</div>
                </div>
                <div className="DataStatus-Cell-DataList-SetpointBarVisualization-Header-Value">
                    <div className="Text-S14">
                        {item.value}{item.suffix !== undefined && item.suffix}
                    </div>
                    {isSetpointActive && <>                    
                        <div className="Text-S14">
                            ({fromSetpoint > 0 && <>+</>}
                            {fromSetpoint})
                        </div>
                    </>}
                </div>
            </div>
            <div className="DataStatus-Cell-DataList-SetpointBarVisualization-Bar-Container">
                <div className={"DataStatus-Cell-DataList-SetpointBarVisualization-Bar" + (item.status ? " DataStatus-Cell-DataList-SetpointBarVisualization-Bar-" + item.status : " DataStatus-Cell-DataList-SetpointBarVisualization-Bar-normal")}>
                    <div className="DataStatus-Cell-DataList-SetpointBarVisualization-BarFill" style={{left: (50 - (100 * leftFromCenter)) + "%", right: (50 - (100 * rightFromCenter)) + "%"}}></div>
                    <div className="DataStatus-Cell-DataList-SetpointBarVisualization-BarSetpointIndicator-Locator">
                        <div className="DataStatus-Cell-DataList-SetpointBarVisualization-BarSetpointIndicator"></div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

