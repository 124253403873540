
import './RecipesPage.scss';
import variables from '../../../globals.scss';
import _ from 'underscore'; 

import React from 'react'
import StandardTable from '../../../components/StandardTable.js'
import { Routes, Route, Navigate, useNavigate} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive';

import { selectAllRecipes, getAllRecipes, createRecipe, selectAllRecipeIDs, selectAllAccountIdsForRecipes, MaintainRecipeVersions, selectAllRecipeVersions} from '../../../redux/entities/Recipes'

import SelectRecipes from '../../../model_components/SelectRecipes';

import Badge from '../../../components/Badge.js'
import Button from '../../../components/Button.js'
import StandardList from '../../../components/StandardList/StandardList';
import { FormatDate, useGetViewSize } from '../../../helpers';

import {FaTrashAlt} from 'react-icons/fa'
import CreateRecipePopup from './CreateRecipePopup';
import { InitialLoadAllAccountsBasicInfo } from '../../../redux/entities/Account';
import RowGroup from '../../../components/RowGroup/RowGroup';
import RowGroupRow from '../../../components/RowGroup/RowGroupRow';
import { RecipeTimeline } from './RecipeTimeline';


const RecipesPage = ({pageHeaderHelper, pageHeaderHelperSetup}) => {
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  
  React.useLayoutEffect(() => {
    pageHeaderHelper.current.Reset()
    pageHeaderHelperSetup()
    pageHeaderHelper.current.SetTitle("My Repository")
    pageHeaderHelper.current.SetRightSideContent(() => {
      return (
        <>
          <div className="ControlBar_Horizontal">
            <div className="ControlBar_Horizontal-Right">
              <Button content={"Create Recipe"}  onClick={() => {SetCreatingRecipe(true)}}/>
            </div>
          </div>
        </>
      )
    })
  }, [])

  const dispatch = useDispatch()
  const navigate = useNavigate(); 
  let recipes = useSelector(selectAllRecipes)
  const activeRecipeIds = useSelector(selectAllRecipeIDs, _.isEqual)
  const activeRecipeVersions = useSelector(selectAllRecipeVersions, _.isEqual)
  const recipeAccountIds = useSelector((state) => selectAllAccountIdsForRecipes(state, activeRecipeIds), _.isEqual)
  const allAccounts = useSelector((state) => state.account.accounts)
  let haveInitialData = useSelector((state) => state.recipes.haveInitialData)

  React.useEffect( () =>  { 
    if (!haveInitialData) {
      dispatch(getAllRecipes({}))
    }
  }, [haveInitialData])

  const [creatingRecipe, SetCreatingRecipe] = React.useState(false)
  const closeCreateRecipePopup = () =>  {
    SetCreatingRecipe(false)
  }

  const [postCreatingRecipeTempId, SetPostCreatingRecipeTempId] = React.useState(null)
  const recipeTempId = useSelector((state) => state.recipes.currentRecipeTempId)
  const creatingRecipeFinalized = (finalizedRecipe) =>  {
    finalizedRecipe.type = "universal"
    finalizedRecipe.temp_id = recipeTempId
    finalizedRecipe.version = 0
    finalizedRecipe.created_on = finalizedRecipe.modified_on = new Date().toISOString();
    finalizedRecipe.timeline_items = []
    finalizedRecipe.created_by_account_id = 29 //hacked
    finalizedRecipe.public = false
    finalizedRecipe.enabled = true
    finalizedRecipe.is_active_version = true
    finalizedRecipe.version_name = finalizedRecipe.name + "-Created"
    finalizedRecipe.stack_index = 1
    finalizedRecipe.revision_stack_size = 1
    finalizedRecipe.initialSave = false

    dispatch(createRecipe({recipe: finalizedRecipe}))
    SetCreatingRecipe(false)
    SetPostCreatingRecipeTempId(recipeTempId)
  }
  React.useEffect(() => {
    if (postCreatingRecipeTempId) {
      let foundRecipe = recipes.find((r) => r.temp_id === postCreatingRecipeTempId)
      if (foundRecipe !== undefined && foundRecipe.id !== undefined && foundRecipe.id)  {
        navigate("/repo/recipe/" + foundRecipe.id + "/edit");
      }
    }
  }, [recipes])
  


  const [recipesList, SetRecipesList] = React.useState({})
  const [currentSearchInput, SetSearchInput] = React.useState("")

  React.useEffect(() => {
    let currentRecipesList = {}

    let activeRecipes = recipes.filter((r) => r.is_active_version)
    let currentRecipes = []
    

    if (currentSearchInput === "")  {
      currentRecipes = [...activeRecipes]
    }else {
      currentRecipes.push(...activeRecipes.filter((recipe) => {
        if (recipe.name.indexOf(currentSearchInput) !== -1 && currentRecipes.indexOf(recipe) === -1)  {
          return true
        }
        return false
      }))
    }
    
    
    for (let recipe of currentRecipes) {
      //Check if the recipe is in working mode
      let foundWorkingRecipe = recipes.find((r) => r.id === recipe.id && r.is_working_version)
      let foundCreatedByAccount = allAccounts.find((a) => a.id === recipe.created_by_account_id)
      currentRecipesList[recipe.id] = {
        data: recipe,
      }
    }

    SetRecipesList(currentRecipesList)
  }, [recipes, currentSearchInput])



  const [columnSizes, SetColumnSizes] = React.useState({})

  let columnHeaders = {status: {label: <>Name</>, setPosition: (bounds, contentWidth) => {return {left: isMobile ? bounds.left - 30 : bounds.left, width: bounds.width}}}}
  if (!isMobile) {
      columnHeaders = {...columnHeaders, 
        duration: {label: <>Duration</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width, alignItems:"start"}}},
        creator: {label: <>Creator</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width, alignItems:"start"}}},
        shared_with: {label: <>Shared With</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width, alignItems:"start"}}},
      }
  }
  

  const onSearchInput = (value) => {
    SetSearchInput(value)
  }

  const recipeClicked = React.useCallback((recipe) => {
    if (recipes.find((r) => r.id === recipe.data.id && r.is_working_version) !== undefined)  {
      navigate("/repo/recipe/" + recipe.data.id + "/edit");
    }else {
      navigate("/repo/recipe/" + recipe.data.id);
    }
  })

  
  return ( 
    <>
      <InitialLoadAllAccountsBasicInfo accountIds={recipeAccountIds}/>
      <MaintainRecipeVersions activeRecipes={activeRecipeVersions}/>
      <div id="RecipesPage">
        
        <RowGroup
            hasGroupHeader={false}
            hasSettings={false}
            itemsScroll={true}
            isExpandable={false}
            hasColumnHeaders={true}
            columnHeaders={columnHeaders}
            columnSizes={columnSizes}
            hasSubGroups={true}>

            <RecipesPage_RecipeRow isPlaceholder={true} columnSizesChanged={(columns) => {
                SetColumnSizes(columns)
            }}/>
            {Object.entries(recipesList).map(([recipeIdAsString, recipeInfo]) => {
                let recipeId = parseInt(recipeIdAsString)
                //console.log(growPlanInfo)
                return ( 
                  <RecipesPage_RecipeRow 
                    key={recipeId}
                    recipeKey={recipeId} 
                    recipeInfo={recipeInfo} 
                    columnSizes={columnSizes}/>
                  )
          })}
        </RowGroup>

        {creatingRecipe && 
          <CreateRecipePopup closeCallback={closeCreateRecipePopup} completeCallback={creatingRecipeFinalized}/>
        }
      </div>
    </>
  )
} 

export default RecipesPage



const RecipesPage_RecipeRow = ({recipeKey, recipeInfo, isPlaceholder, isSelected, openTask, columnSizes, columnSizesChanged, onSelectedChanged}) => {
  const [isMobile, isTablet, isDesktop] = useGetViewSize()

  const navigate = useNavigate(); 

  const recipe = isPlaceholder ? null : recipeInfo.data
  const allRecipes = useSelector(selectAllRecipes)
  const allAccounts = useSelector((state) => state.account.accounts)

  let foundWorkingRecipe = undefined
  let foundCreatedByAccount = undefined
  
  if (!isPlaceholder) {
    foundWorkingRecipe = allRecipes.find((r) => r.id === recipe.id && r.is_working_version)
    foundCreatedByAccount = allAccounts.find((a) => a.id === recipe.created_by_account_id)
  }

  let secondaryHeaderContent = []
  if (!isMobile) {
    secondaryHeaderContent.push({
      key: "duration", content: <>
        <div className="noselect" style={{fontSize:14}}>
            <div>
                {(!isPlaceholder ? <>
                  <div className="Text-SoftTruncate"><RecipeTimeline recipe={recipe} showDuration={true}/></div>           
                </> : <>
                  <div className="Text-SoftTruncate"><RecipeTimeline recipe={undefined} showDuration={true}/></div>
                </>
            )}</div>
        </div>
      </>
    })
    secondaryHeaderContent.push({
      key: "creator", content: <>
        <div className="noselect" style={{fontSize:14}}>
            <div>
                {(!isPlaceholder ? <>
                  <div className="Text-SoftTruncate">{foundCreatedByAccount !== undefined ? foundCreatedByAccount.full_name : "N/A"}</div>
                </> : <>
                  <div className="Text-SoftTruncate">Super Long Creator Name</div>
                </>
            )}</div>
        </div>
      </>
    })
    secondaryHeaderContent.push({
      key: "shared_with", content: <>
        <div className="noselect" style={{fontSize:14}}>
            <div>
                {(!isPlaceholder ? <>
                  <div className="Text-SoftTruncate">Noone</div>   
                </> : <>
                  <div className="Text-SoftTruncate">Super Long Shared With</div>
                </>
            )}</div>
        </div>
      </>
    })
  }

  let title = isPlaceholder ? <>This amazing long recipe name</> : recipe.name
  let titleAuxContent = <></>
  
  if (!isPlaceholder)  {  
    if (recipe !== undefined && !recipe.is_active_version)  {    
      titleAuxContent = <Badge fontSize={12} content={"update available"}/>
    }
  }
  let subtitle = isPlaceholder ? 
    <div className="">#1021501250120</div> : 
    <div className="FlexContent-H-5">
      <span>v{recipe.version}</span>
      {foundWorkingRecipe === undefined ?
        <span>
          {FormatDate(new Date(recipe.modified_on), "MM/d/yyyy")}
        </span>
      :
        <span style={{color: variables.textCritical}}>
          Unsaved Changes
        </span>
      }
    </div>
  

  const recipeClicked = () => {
    if (allRecipes.find((r) => r.id === recipe.id && r.is_working_version) !== undefined)  {
      navigate("/repo/recipe/" + recipe.id + "/edit");
    }else {
      navigate("/repo/recipe/" + recipe.id);
    }
  }

  return (<>
    <RowGroupRow
      key={isPlaceholder ? 0 : recipe.id}
      title={title}
      titleFontSize={14}
      titleAuxContent={titleAuxContent}
      cardBackgroundColor={"transparent"}
      cardBorderColor={"transparent"}
      showRowSeperator={true}
      showExpandedStatusContainerStyles={false}
      statusContainerPadding={0}
      statusContainerClicked={() => {}}
      minimumTitleWidth={!isMobile ? "20ch" : "20ch"}
      hasFlexCell={true}
      subtitle={<div className="">{subtitle}</div>}
      isPlaceholderRow={isPlaceholder}
      columnSizesChanged={columnSizesChanged ?? (() => {})}
      columnSizes={columnSizes}
      secondaryContent={secondaryHeaderContent}
      secondaryContentTriggersExpandToggle={true}
      isExpandable={isMobile}
      onRowClicked={recipeClicked}
      hasAdditionalFunctions={false}
      content={<>
        {(() => {
          
        })()}
      </>}
    />
  </>)
}