import './TasksPage.scss';
import React from 'react';

import StandardTable from '../../components/StandardTable.js'

import Badge from '../../components/Badge.js'
import { FormatDate } from '../../helpers'
 

import { Routes, Route, Navigate, useNavigate, useLocation, Outlet  } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { selectAllTasks, getAllTasks, taskUpdate} from '../../redux/entities/Tasks'
import StandardList from '../../components/StandardList/StandardList';
import RowGroup from '../../components/RowGroup/RowGroup';
import { useMediaQuery } from 'react-responsive';
import RowGroupRow from '../../components/RowGroup/RowGroupRow';
import RowGroupSubGroup from '../../components/RowGroup/RowGroupSubGroup';
import { selectAllGrows } from '../../redux/entities/Grow';
import Button from '../../components/Button';


const Tasks_ListPage = ({selectedFacility, pageHeaderHelper, setPageHeader}) => {
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  React.useLayoutEffect(() => {
    setPageHeader()
  }, [setPageHeader])


  const location = useLocation()
  let navigate = useNavigate(); 
  const dispatch = useDispatch()
  let tasks = useSelector(selectAllTasks)
  let haveInitialData = useSelector((state) => state.tasks.haveInitialData)

  React.useEffect( () =>  { 
    //fetchTasks
    if (!haveInitialData) {
      dispatch(getAllTasks({}))
    }
  }, [tasks, haveInitialData])




  const [tasksList, SetTasksList] = React.useState({})
  const [currentSearchInput, SetSearchInput] = React.useState("")

  React.useEffect(() => {
    let currentTasksList = {}

    let currentTasks = []


    let facilityTasks = []
    if (selectedFacility === "all") {
      facilityTasks = [...tasks]
    }else {
      facilityTasks = tasks.filter((t) => t.facility_id === selectedFacility)
    }

    if (currentSearchInput === "")  {
      currentTasks = [...facilityTasks]
    }else {
      currentTasks.push(...facilityTasks.filter((task) => {
        if (task.name.indexOf(currentSearchInput) !== -1 && currentTasks.indexOf(task) === -1)  {
          return true
        }
        return false
      }))
    }
    
    
    for (let task of currentTasks) {
      //Check if the recipe is in working mode
      let sub_group = ""
      if (task.completed) {
        sub_group = "completed"
      }else if (new Date(task.scheduled_for).getTime() < new Date().getTime())  {
        sub_group = "past_due"
      }else {
        sub_group = "to_do"
      }
      currentTasksList[task.id] = {
        data: task,
        datetime_sub_group: sub_group,
      }
    }
    
    SetTasksList(currentTasksList)
  }, [selectedFacility, tasks, currentSearchInput])



  const taskClicked = (task) => {
    navigate(location.pathname + "/" + task.data.id);
  }

  const onSearchInput = (value) => {
    SetSearchInput(value)
  }
  
  

  const [columnSizes, SetColumnSizes] = React.useState({})

  let columnHeaders = {status: {label: <>Tasks</>, setPosition: (bounds, contentBounds) => {return {left: isMobile ? bounds.left - 30 : bounds.left}}}}
  if (!isMobile) {
    columnHeaders = {...columnHeaders, 
      task_status: {label: <>Status</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.contentWidth, alignItems:"center"}}},
      assignee: {label: <>Assignee</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.contentWidth, alignItems:"center"}}},
      date: {label: <>Date</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.contentWidth, alignItems:"center"}}}  
    }
  }
  
  return (
    <>
    
      <div id="Tasks_ListPage">
        <RowGroup
          hasGroupHeader={false}
          hasSettings={false}
          itemsScroll={true}
          hasContainer={false}
          isExpandable={false}
          hasColumnHeaders={true}
          columnHeaders={columnHeaders}
          columnSizes={columnSizes}
          hasSubGroups={true}>
            <Tasks_TaskListRow isPlaceholder={true} columnSizesChanged={(columns) => {
              SetColumnSizes(columns)
            }}/>
            <RowGroupSubGroup title={<>Completed</>} expandedState={false} rows={
                Object.entries(tasksList).filter(([task_id, task]) => task.datetime_sub_group === "completed").map(([task_id, task]) => {
                  return <Tasks_TaskListRow taskKey={task_id} taskInfo={task} openTask={taskClicked} columnSizes={columnSizes}/>
                })
              } numRowsDisplay="tasks" emptyRowsDisplay="no tasks found"/>
            <RowGroupSubGroup title={<>Past Due</>} expandedState={true} rows={
                Object.entries(tasksList).filter(([task_id, task]) => task.datetime_sub_group === "past_due").map(([task_id, task]) => {
                  return <Tasks_TaskListRow taskKey={task_id} taskInfo={task} openTask={taskClicked} columnSizes={columnSizes}/>
                })
              } numRowsDisplay="tasks" emptyRowsDisplay="no tasks found"/>
            <RowGroupSubGroup title={<>To Do</>} expandedState={true} rows={
                Object.entries(tasksList).filter(([task_id, task]) => task.datetime_sub_group === "to_do").map(([task_id, task]) => {
                  return <Tasks_TaskListRow taskKey={task_id} taskInfo={task} openTask={taskClicked} columnSizes={columnSizes}/>
                })
              } numRowsDisplay="tasks" emptyRowsDisplay="no tasks found"/>
        </RowGroup>
        <Outlet/>
      </div>
    </>
  )
} 

export default Tasks_ListPage



const Tasks_TaskListRow = ({taskKey, taskInfo, isPlaceholder, openTask, columnSizes, columnSizesChanged}) => {
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });



  const task = isPlaceholder ? null : taskInfo.data
  

  let additionalFunctions = [
    {key: "manage_lights", label: "Manage Lights"},
    {key: "rename", label: "Rename Rack"},
    {key: "move", label: "Move Rack"},
    {key: "change_sides", label: "Change Sides"},
    {key: "light_up", label: "Light Up Rack"},
    {key: "delete", label: "Delete", status: "Critical"},
  ]



  
  let secondaryHeaderContent = []
  if (!isMobile) {
    secondaryHeaderContent.push({
      key: "task_status", content: <>
      <div className="noselect" style={{fontSize:14}}>
        <div>{(!isPlaceholder ? "Completed" : "Completed")}</div>
      </div>
    </>
    })
    secondaryHeaderContent.push({
      key: "assignee", content: <>
        <div className="noselect" style={{fontSize:14}}>
          <div>{(!isPlaceholder ? "Completed" : "Completed")}</div>
        </div>
      </>
    })
    secondaryHeaderContent.push({
      key: "date", style: {display:"flex", alignSelf:"center",fontSize:14}, content: <>
        <div className="noselect" style={{fontSize:14}}>
          {(!isPlaceholder ? FormatDate(new Date(task.startDate), "MM/dd/yyyy") : "00/00/0000")}
        </div>
      </>
    })
  }

  let title = isPlaceholder ? "Placeholder": task.name
  let titleAuxContent = <Badge fontSize={12} content={isPlaceholder ? "Placeholder" : <> {{
    "grow": "Grows",
    "nds": "NDS",
  }[task.category] || task.category} </>}/>
    
  let subtitle = (isPlaceholder ? <div className="">#1021501250120</div> : <div className=""></div>)
  let grows = useSelector(selectAllGrows)
  if (!isPlaceholder) {
    if (task.category === "grow") {
      subtitle = "#G" + task.reference_id
     
    }


    //console.log(task)
  }
  return (<>
    <RowGroupRow
      key={isPlaceholder ? 0 : task.id}
      title={title}
      titleAuxContent={titleAuxContent}
      titleFontSize={14}
      cardBackgroundColor={"transparent"}
      cardBorderColor={"transparent"}
      showRowSeperator={true}
      showExpandedStatusContainerStyles={false}
      statusContainerPadding={0}
      statusContainerClicked={!isMobile ? () => {openTask(taskInfo)} : () => {}}
      minimumTitleWidth={!isMobile ? "20ch" : "20ch"}
      hasFlexCell={true}
      subtitle={<div className="">{subtitle}</div>}
      showStatusIndicator={false}
      isPlaceholderRow={isPlaceholder}
      columnSizesChanged={columnSizesChanged ?? (() => {})}
      columnSizes={columnSizes}
      secondaryContent={secondaryHeaderContent}
      secondaryContentTriggersExpandToggle={true}
      hasIssues={true}
      numberOfIssues={0}
      isExpandable={isMobile}
      hasAdditionalFunctions={true}
      additionalFunctions={additionalFunctions}
      content={<>
        {(() => {
          if (isMobile && !isPlaceholder) {
            return (<>
              <div className="FlexContent-20" style={{padding: "10px 40px 20px 40px"}}>
                <div className="FlexContent-5">
                  <span>Status</span>
                  <Badge content="To Do"/>
                </div>

                <div className="FlexContent-5">
                  <span>Assignee</span>
                  <span>Stevenson</span>
                </div>

                <div className="FlexContent-5">
                  <span>Date</span>
                  <span>{FormatDate(new Date(task.startDate), "MM/dd/yyyy")}</span>
                </div>
                <Button content="View task" width={"Flex100"} onClick={() => {openTask(taskInfo)}}/>
              </div>
            </>)
          }
      
        })()}
      </>}
    />
  </>)
}