import './FlowsPage.scss';
import variables from '../../../globals.scss';
import React from 'react'
import _ from 'underscore';


import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { InitialLoadAllBladeZones, selectAllBladeZones, selectAllBlades, selectBladeZoneByUID, selectBladeZoneDisplayNameByUID, useMaintainBladeStatus,  } from '../../../redux/entities/service/Blade';
import { RoundToNearest, parseBool } from '../../../helpers';
import Button from '../../../components/Button';
import { AddItem, AdditionalOptions, BadX, GoodCheckmark, HalfFullCup, Pause, Play, Plus, PlusAlt, RSSI, ReorderItem, SoftWarning, Trash } from '../../../assets/icons/Icons';
import { InitialLoadAllFlows, MaintainFlowStatus, getFlowMapVersions, getFlowMaps, getFlowStatusById, selectAllFlow, selectFlowControlDeviceConnectedState, selectFlowCurrentActionByFlowId, selectFlowCurrentActionInfoByFlowId, selectFlowCurrentSubactionByFlowId, selectFlowDisplayNameById, selectFlowNutrientReservoirsByFlowId, selectFlowRuntimeInformation, setFlowProperty, setFlowRuntimeProperty } from '../../../redux/entities/service/Flow';
import AddFlowWalkthrough from './AddFlowWalkthrough';
import RowGroup from '../../../components/RowGroup/RowGroup';
import RowGroupRow from '../../../components/RowGroup/RowGroupRow';
import { useMediaQuery } from 'react-responsive';
import { ScanFlow } from '../../../assets/icons/FlowIcons';
import DropDownButton from '../../../components/DropDownButton';
import TopUpReservoirWalkthrough from './TopUpReservoirWalkthrough';
import { getInventoryItemTypeById, selectAllInventoryItemTypes } from '../../../redux/entities/Inventory';
import FlowZonePortsManager from './FlowZonePortsManager';
import FlowInstance_ReservoirsContent from './FlowInstance_ReservoirsContent';
import FlowInstance_StatusContent from './FlowInstance_StatusContent';
import { selectAllAccountPrivileges } from '../../../redux/entities/Account';
import { TabControl, TabControlTab } from '../../../components/TabControl';
import DropDownInput from '../../../components/input/DropDownInput';




const FlowInstance = ({flowId, isDefaultExpanded, manageZonePortsCallback}) =>  {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop

  const dispatch = useDispatch()


  const accountPrivileges = useSelector((state) => selectAllAccountPrivileges(state), _.isEqual)
  const accountHasServicingPriviliges = (accountPrivileges["servicing"] !== undefined)


  const flowDisplayName = useSelector((state) => selectFlowDisplayNameById(state, flowId), _.isEqual)
  const isFlowOnline = useSelector((state) => selectFlowControlDeviceConnectedState(state, flowId), _.isEqual)

  const [inServiceModeRuntimeInformation] = useSelector((state) => selectFlowRuntimeInformation(state, flowId, "service_mode"), _.isEqual)
  const inServiceMode = accountHasServicingPriviliges ? parseBool(inServiceModeRuntimeInformation) : false



  const isFlowPaused = false
  const [currentSubtitle, SetCurrentSubtitle] = React.useState("idle")
  const [currentAction, SetCurrentAction] = React.useState("idle")




  const cancelOperationClicked = () => {
    dispatch(setFlowRuntimeProperty({flowId: flowId, properties: {"subcontrol_state": "remote_cancel"}, callback: (success) => {
      
    }}))
  }




  /*const solutions = useSelector(selectAllSolutions)
  const solutionsStatus = useSelector((state) => state.solutions.status)
  const nutrientSolutions = useSelector(selectAllNutrientSolutions);
  const nutrientSolutionsStatus = useSelector((state) => state.nutrientSolutions.status)*/
  
  const [flowControlState] = useSelector((state) => selectFlowRuntimeInformation(state, flowId, "control_state"), _.isEqual)
  const flowCurrentAction = useSelector((state) => selectFlowCurrentActionByFlowId(state, flowId), _.isEqual)
  const flowCurrentSubaction = useSelector((state) => selectFlowCurrentSubactionByFlowId(state, flowId), _.isEqual)
  const flowCurrentActionInfo = useSelector((state) => selectFlowCurrentActionInfoByFlowId(state, flowId), _.isEqual)
  

  const [flowDosingZoneUID, SetFlowDosingZoneUID] = React.useState(null)
  const dosingZoneName = useSelector((state) => selectBladeZoneDisplayNameByUID(state, flowDosingZoneUID), _.isEqual)
  //const [activeZoneUIDs, SetActiveZoneUIDs] = React.useState([])
  const zoneStatus = "Idle"




  const [currentContentTab, SetCurrentContentTab] = React.useState("reservoirs")




  React.useEffect(() => {
    if (flowCurrentActionInfo["zone_uid"] !== undefined)  {
      SetFlowDosingZoneUID(flowCurrentActionInfo["zone_uid"].split("-")[0])
    }else {
      SetFlowDosingZoneUID(null)
    }
  }, [flowCurrentActionInfo]) 

  React.useEffect(() => {
    if (flowControlState === "rack_dose") {
      if (flowCurrentActionInfo["zone_uid"] !== undefined && flowCurrentActionInfo["zone_uid"].indexOf("-")) {
        const subZoneKey = flowCurrentActionInfo["zone_uid"].split("-")[1]
        if (subZoneKey === "A") {
          SetCurrentAction("Dosing Zone: " + dosingZoneName + " - Upper")          
        }else if (subZoneKey === "B") {
          SetCurrentAction("Dosing Zone: " + dosingZoneName + " - Lower")
        }else {
          SetCurrentAction("Dosing Zone: " + dosingZoneName)
        }
      }else {
        SetCurrentAction("Dosing Zone: " + dosingZoneName)
      }
    }else {
      SetCurrentAction(flowCurrentAction) 
    }
  }, [flowControlState, flowCurrentAction, flowCurrentActionInfo, dosingZoneName])

  React.useEffect(() => {
    SetCurrentSubtitle(currentAction)
  }, [currentAction])









  const flowActiveIssues = {}//useSelector((state) => selectFlowActiveIssuesById(state, flowId), _.isEqual)
  
  const [viewingIssues, SetViewingIssues] = React.useState(false)
  const issuesClicked = () => {
    SetViewingIssues(true)
  }
  const closeViewGerminationStationIssues = () => {
    SetViewingIssues(false)
  }


  

  const [activatingReservoirIndex, SetActivatingReservoirIndex] = React.useState(null)
  const [activatingFlowReservoir, SetActivatingFlowReservoir] = React.useState(false)
  const activateFlowReservoir = React.useCallback((reservoirIndex) => {
    SetActivatingReservoirIndex(reservoirIndex)
    SetActivatingFlowReservoir(true)
  })

  const activateFlowReservoirWalkthroughCloseCallback = React.useCallback(() => {
    SetActivatingFlowReservoir(false)  
  })

  const [toppingUpReservoirIndex, SetToppingUpReservoirIndex] = React.useState(null)
  const [toppingUpReservoir, SetToppingUpReservoir] = React.useState(false)
  const topUpFlowReservoir = React.useCallback((reservoirIndex) => {
    SetToppingUpReservoirIndex(reservoirIndex)
    SetToppingUpReservoir(true)
  })

  const topUpFlowReservoirWalkthroughCloseCallback = React.useCallback(() => {
    SetToppingUpReservoir(false)  
  })


  const [settingVolumeForReservoirIndex, SetSettingVolumeForReservoirIndex] = React.useState(null)
  const [settingVolumeForReservoir, SetSettingVolumeForReservoir] = React.useState(false)
  const setFlowReservoirVolume = React.useCallback((reservoirIndex) => {
    SetSettingVolumeForReservoirIndex(reservoirIndex)
    SetSettingVolumeForReservoir(true)
  })

  const setReservoirVolumeWalkthroughCloseCallback = React.useCallback(() => {
    SetSettingVolumeForReservoir(false)  
  })



  
  
  let subtitle = ""

  let additionalFunctions = [
    {key: "manage_zone_ports", label: "Manage Ports", callback: () => {manageZonePortsCallback(flowId)}},
    {key: "rename", label: "Rename Flow"},
    {key: "cancel_operation", label: "Cancel Operation", status: "Critical", callback: () => {cancelOperationClicked(flowId)}},
  ]
 

  if (accountHasServicingPriviliges) {
    if (!inServiceMode) {
      additionalFunctions.push(...[
        {
          key: "activate_service_mode", label: "Turn On Service Mode", status: "Critical", callback: () => {
            dispatch(setFlowRuntimeProperty({ flowId: flowId, properties: { "service_mode": 1 } }))
          }
        },
      ])
    } else {
      additionalFunctions.push(...[
        {
          key: "activate_service_mode", label: "Turn Off Service Mode", status: "Critical", callback: () => {
            dispatch(setFlowRuntimeProperty({ flowId: flowId, properties: { "service_mode": 0 } }))
          }
        },
      ])
    }
  }

  
  if (isMobile) {
    additionalFunctions.splice(2, 0, 
      {key: "pause_flow", label: "Pause Flow"}  
    );
  }


  additionalFunctions.push(...[
    { key: "delete", label: "Delete", status: "Critical" },
  ])



  let secondaryHeaderContent = null
  if (!isMobile)  {
    secondaryHeaderContent = [
      {key: "pause_state", content: 
        <div className="FlexContent-H-10">
          {isFlowPaused && 
            <div className="FlexContent-H-20 FlexContent-Center">
              <span className="FlowStatus-Flow-PauseInfo-State">Paused 16:34</span>

              <div className="FlowStatus-Flow-PauseInfo-AddTimeButton"><span>Add Time</span></div>
            </div>
          }
          <div className="FlexContent-H-10">
            {isFlowPaused && <>
              <Button status={"Small-IconAction"} state={true} content={<Play fill={"#2E72D2"}/>}/>
            </>}
            {!isFlowPaused &&
              <Button status={"Small-IconAction"} state={false} content={<Pause/>}/>
            }
          </div>
        </div>
      },
      {key: "rssi", content: <RSSI/>}
    ]
  }else {
    secondaryHeaderContent = <><RSSI/></>
  }

  let extendedHeaderContent = null
  if (isMobile && isFlowPaused) {
    extendedHeaderContent = {content: <>
      <div className="FlexContent-H-10">
        <div className="FlexContent-H-10 FlexContent-Center">
          <div className="FlowStatus-Flow-PauseInfo-AddTimeButton"><span>Paused 16:34</span><PlusAlt/></div>
        </div>
        <div className="FlexContent-H-10">
          <Button status={"Small-IconAction"} state={true} content={<Play fill={"#2E72D2"}/>}/>
        </div>
      </div>
    </>}
  }else {

  }


  const [expandedContentPage, SetExpandedContentPage] = React.useState("reservoirs")
  let tabSelected = (tab) => {
    SetExpandedContentPage(tab)
  }

  return (<>
  <RowGroupRow
    key={flowId}
    title={flowDisplayName}
    hasFlexCell={true}
    subtitle={<div className="FlowStatus-Flow-Subtitle">{currentSubtitle}</div>}
    showStatusIndicator={true}
    status={isFlowOnline ? "Connected" : "Not Connected"}
    statusColor={isFlowOnline ? "#4DBE3B" : "#f00"}
    hasIssues={true}
    numberOfIssues={Object.entries(flowActiveIssues).length}
    issuesClicked={issuesClicked}
    customRowPadding={0}
    isExpandable={true}
    expandedState={isDefaultExpanded}
    hasAdditionalFunctions={true} 
    secondaryContent={secondaryHeaderContent}
    extendedHeaderContent={extendedHeaderContent}
    additionalFunctions={additionalFunctions}
    renderContentOnlyWhenOpen={true}
    content={<>
      <div className="FlowStatus-Flow-Content">
        {!isMobile && <>
          <div className="FlowStatus-Flow-InfoSide">
            <div className="FlowStatus-Flow-CurrentActionCard">
                <div>Current Action:</div>
                <div className="FlowStatus-Flow-CurrentActions">
                  <div>{currentAction}</div>
                  <div>-</div>
                  <div>{flowCurrentSubaction ? flowCurrentSubaction : <>-</>}</div>
                </div>
            </div>
            <div className={"FlowStatus-Flow-OnlineStatusCard" + (isFlowOnline ? " FlowStatus-Flow-OnlineStatusCard-Active" : " FlowStatus-Flow-OnlineStatusCard-Inactive")}>
              <div className="FlowStatus-Flow-OnlineStatusCard-StatusIndicator"></div>
              <div>Status: </div>
              <div>{isFlowOnline ? "Active" : "Stopped"}</div>
            </div>
          </div>
          <TabControl
            onTabSelected={tabSelected}
            tabLocation={"flex"}
            tabs={[
              new TabControlTab("Reservoirs", "reservoirs", "", expandedContentPage === "reservoirs"),
              new TabControlTab("Status", "status", "", expandedContentPage === "status"),
              new TabControlTab("Dosing History", "dosing_history", "", expandedContentPage === "dosing_history"),
              new TabControlTab("Projections", "projections", "", expandedContentPage === "projections"),
            ]}
          />
        </>}
        {isMobile && <>
          <div className="FlexContent-10 FlexContent-HFill FlexContent-HFlex">
            <div className={"FlowStatus-Flow-OnlineStatusCard" + (isFlowOnline ? " FlowStatus-Flow-OnlineStatusCard-Active" : " FlowStatus-Flow-OnlineStatusCard-Inactive")}>
              <div className="FlowStatus-Flow-OnlineStatusCard-StatusIndicator"></div>
              <div>Status: </div>
              <div>{isFlowOnline ? "Active" : "Stopped"}</div>
            </div>
            <div className="FlowStatus-Flow-CurrentActionCard">
                <div>Current Action:</div>
                <div className="FlowStatus-Flow-CurrentActions">
                  <div>{currentAction}</div>
                  <div>{flowCurrentSubaction ? flowCurrentSubaction : <>-</>}</div>
                </div>
            </div>
            <DropDownInput
              status="Neutral"
              value={expandedContentPage}
              options={[
                { key: 'reservoirs', label: 'Reservoirs' },
                { key: 'status', label: 'Status' },
                { key: 'dosing_history', label: 'Dosing History' },
                { key: 'projections', label: 'Projections' },
              ]}
              optionsKey="key"
              onSelectionChange={(action) => {
                SetExpandedContentPage(action)
              }} />
            </div>
        </>}

        {expandedContentPage === "reservoirs" && <>
          <FlowInstance_ReservoirsContent flowId={flowId} activateReservoirCallback={activateFlowReservoir} topUpFlowReservoirCallback={topUpFlowReservoir} setReservoirVolumeCallback={setFlowReservoirVolume} />
        </>}
        {expandedContentPage === "status" && <>
          <FlowInstance_StatusContent flowId={flowId} dosingZoneUID={flowDosingZoneUID} />
        </>}

        {expandedContentPage === "dosing_history" && <>
          Dosing History
        </>}
        {expandedContentPage === "projections" && <>
          Projections
        </>}
      </div>
    </>} />
    {/*viewingIssues && <GerminationStationIssuesPopup flowId={flowId} closeCallback={closeViewGerminationStationIssues} />*/}
    {toppingUpReservoir && <TopUpReservoirWalkthrough flowId={flowId} reservoirIndex={toppingUpReservoirIndex} closeCallback={topUpFlowReservoirWalkthroughCloseCallback}/>}

  </>)
}
export default FlowInstance