import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
//import { useState } from 'react';

import { getAppInfo} from './redux/AppInfo'
import { useSelector, useDispatch } from 'react-redux'

import PageHeader from "./components/PageHeader"
import {SideNavigator} from "./components/SideNavigator";
import Pages from './pages/PageList';
import usePreventZoom from './helpers'


import { LCHost } from "./LC";
import AccountConfirmVerificationCodePage from './pages/Account/AccountConfirmVerificationCodePage'
import AccountLoginPage from './pages/Account/AccountLoginPage'
import AccountRegisterPage from './pages/Account/AccountRegisterPage'
import { AccountManager, CheckForActiveSession, IsSessionActive } from './pages/Account/Common'

function App() {
  //const [activePage, setActivePage] = useState (DefaultActivePage)
  let haveAppInfo = useSelector((state) => state.appInfo.haveAppInfo)
  const dispatch = useDispatch()

  usePreventZoom();

  const hasManufacturingPrivileges = true

  //IsSessionActive()

  React.useEffect( () =>  { 
    //fetchTasks
    if (!haveAppInfo) {
      dispatch(getAppInfo({}))
    } 
  }, [haveAppInfo])



  const isSessionActive = useSelector(state => IsSessionActive(state))


  const navigationHelpersRef = React.useRef(null);
  const pageHelpersRef = React.useRef(null);
  return (
    <>
      <Router>
        <AccountManager/>
        <SideNavigator helperFunctionCallback={helperFunctions => { navigationHelpersRef.current = helperFunctions; }}/> 
        <div id="PageWrapper">
          <div id="PageContainer">
            <PageHeader helperFunctionCallback={helperFunctions => { pageHelpersRef.current = helperFunctions; }} navigationFunctions={navigationHelpersRef}/>
            <div id="PageContent">
                <Routes>
                  <Route exact path="/" element={<Pages.HomePage pageHeaderHelper={pageHelpersRef} />}/>
                  
                  <Route path="/account/login" element={<AccountLoginPage pageHeaderHelper={pageHelpersRef} />}/>
                  <Route path="/account/register" element={<AccountRegisterPage pageHeaderHelper={pageHelpersRef} />}/>
                  <Route path="/account/email_verification" element={<AccountConfirmVerificationCodePage pageHeaderHelper={pageHelpersRef} />}/>
                
                  {isSessionActive && <>
                    <Route path="/tasks/*" element={<Pages.TasksPage pageHeaderHelper={pageHelpersRef} />}/>
                    <Route path="/tasks/:farmID/*" element={<Pages.TasksPage pageHeaderHelper={pageHelpersRef} />}/>
                    <Route path="/growmanager/*" element={<Pages.GrowManagerPage pageHeaderHelper={pageHelpersRef} />}/>
                    <Route path="/growmanager/:farmID/*" element={<Pages.GrowManagerPage pageHeaderHelper={pageHelpersRef} />}/>
                    <Route path="/zonemanager/*" element={<Pages.GrowZoneRecipeManagerPage pageHeaderHelper={pageHelpersRef} />}/>
                    <Route path="/zonemanager/:zoneUID" element={<Pages.GrowZoneRecipeManagerPage pageHeaderHelper={pageHelpersRef} />}/>
                    <Route path="/zonemanager/:zoneUID/*" element={<Pages.GrowZoneRecipeManagerPage pageHeaderHelper={pageHelpersRef} />}/>

                    {/*
                    <Route path="/farms/*" element={<Pages.FarmsPage pageHeaderHelper={pageHelpersRef} />}/>
                    */}
                    
                    <Route path="/farms/*">
                      <Route exact path=":farmID" element={<Pages.FarmsPage pageHeaderHelper={pageHelpersRef} />}/>
                      <Route exact path=":farmID/*" element={<Pages.FarmsPage pageHeaderHelper={pageHelpersRef} />}/>
                      <Route path="*" element={<Pages.FarmsPage pageHeaderHelper={pageHelpersRef} />} />
                    </Route>
                    {/*<Route path="/farms/:farmID" element={<Pages.FarmsPage pageHeaderHelper={pageHelpersRef} />}/>
                    <Route path="/farms/:farmID/:mainTabKey/*" element={<Pages.FarmsPage pageHeaderHelper={pageHelpersRef} />}/>*/}

                    <Route path="/business/*" element={<Pages.BusinessPage pageHeaderHelper={pageHelpersRef} />}/>
                    <Route path="/business/:farmID/*" element={<Pages.BusinessPage pageHeaderHelper={pageHelpersRef} />}/>
                    <Route path="/repo/*" element={<Pages.RecipesAndPlansPage pageHeaderHelper={pageHelpersRef} />}/>
                    <Route path="/growanalytics/*" element={<Pages.GrowAnalyticsPage pageHeaderHelper={pageHelpersRef} />}/>
                    <Route path="/inventory/*" element={<Pages.InventoryPage pageHeaderHelper={pageHelpersRef} />}/>
                    <Route path="/expenses/*" element={<Pages.ExpensesPage pageHeaderHelper={pageHelpersRef} />}/>
                    <Route path="/records/*" element={<Pages.RecordsPage pageHeaderHelper={pageHelpersRef} />}/>
                    <Route path="/reports/*" element={<Pages.ReportsPage pageHeaderHelper={pageHelpersRef} />}/>
                    <Route path="/onlinestore/*" element={<Pages.OnlineStorePage pageHeaderHelper={pageHelpersRef} />}/>
                    <Route path="/crm/*" element={<Pages.CRMPage pageHeaderHelper={pageHelpersRef} />}/>

                    
                    <Route path="/chat/*" element={<Pages.ChatPage pageHeaderHelper={pageHelpersRef} />}/>
                    <Route path="/settings/*" element={<Pages.SettingsPage pageHeaderHelper={pageHelpersRef} />}/>


                    <Route path="/service/*" element={<Pages.ServicePage pageHeaderHelper={pageHelpersRef} />}/>

                    {hasManufacturingPrivileges && <>
                    
                      <Route path="/manufacturing/*" element={<Pages.ManufacturingPage pageHeaderHelper={pageHelpersRef} />}/>
                      <Route path="/manufacturing/:facilityID/*" element={<Pages.ManufacturingPage pageHeaderHelper={pageHelpersRef} />}/>
                    </>}
                  </>}
                  
                </Routes>
            </div>
          </div>
        </div>
      </Router>
    </>
  );
} 

export default App;
 