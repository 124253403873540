import { CloseDropdown, OpenCell, OpenDropdown, SimpleCheck } from '../assets/icons/Icons';
import useMeasure from '../useMeasure';
import './DropDownButton.scss';

import * as React from 'react';




const DropDownButton = ({
    status = "Neutral", 
    content, 
    isOpen, 
    flex = false, 
    disabled = false, 
    popupMargin = 4, 
    screenMargin = 10, 
    popupBorderThickness = 1,
    scrollMinItems = 3, 
    options = [], 
    onOptionSelected, 
    onContainerClicked, 
    showDropDownArrow = false, 
    alwaysShowBorder = false, 
    margin = false
  }) => {


  const [isOpenState, SetIsOpenState] = React.useState(false)
  React.useLayoutEffect(() => {
    SetIsOpenState(isOpen)
  }, [isOpen])

  const [currentValue, SetCurrentValue] = React.useState("");


  const containerClicked = (e) => {
    if (disabled) {
      SetIsOpenState(false)
      return
    }
    if (onContainerClicked !== undefined) {
      onContainerClicked(e)
    }
    //dropDownInputBindTrigger()
    SetIsOpenState(!isOpenState)
  }

  const closePopup = (e) => {
    SetIsOpenState(false)
  }



  const [dropDownInputContainerRef, {documentLeft: dropDownInputLeft, documentTop: dropDownInputTop, documentRight: dropDownInputRight, documentBottom: dropDownInputBottom, width: dropDownInputWidth, height: dropDownInputHeight}] = useMeasure()
  const [dropDownPopupContainerRef, {width: dropDownPopupWidth, height: dropDownPopupHeight}] = useMeasure()
  const [dropDownPopupContentRef, {width: dropDownPopupContentWidth, height: dropDownPopupContentHeight}] = useMeasure()

  //const [dropDownPopupItemBind, {height: dropDownPopupItemHeight}] = useMeasure()
  let dropDownPopupItemHeight = 30
  const [dropDownPopupMinimumScrollHeight, SetDropDownPopupMinimumScrollHeight] = React.useState(0)

  React.useEffect(() => {
    SetDropDownPopupMinimumScrollHeight(scrollMinItems * dropDownPopupItemHeight)
  }, [dropDownPopupItemHeight])

  const dropDownInputStyleProps = {}
  if (flex) {
    dropDownInputStyleProps.flex = "1"
  }
  if (margin) {
    dropDownInputStyleProps.margin = margin
  }


  const dropDownPopupWrapperProps = {style:{}}
  const dropDownPopupProps = {style:{borderWidth:popupBorderThickness}}
  
  let minimumHeightRequired = dropDownPopupMinimumScrollHeight + ((popupBorderThickness * 2) + popupMargin + screenMargin)
  let desiredHeightRequired = dropDownPopupContentHeight + (popupBorderThickness * 2) + (popupMargin + screenMargin)
  if (dropDownInputBottom - minimumHeightRequired > 0)  { //Fits below
    dropDownPopupWrapperProps.style.top = dropDownInputTop + dropDownInputHeight + popupMargin

    if (isOpenState) {
      if (dropDownInputBottom - desiredHeightRequired > 0)  {
        dropDownPopupProps.style.maxHeight = dropDownPopupContentHeight + popupBorderThickness * 2
      }else {
        dropDownPopupProps.style.maxHeight = dropDownInputBottom - (popupMargin + screenMargin)
      }
    }

  }else if (dropDownInputTop - minimumHeightRequired > 0) { //Fits above
    dropDownPopupWrapperProps.style.bottom = dropDownInputHeight + dropDownInputBottom + popupMargin

    if (isOpenState) {
      if (dropDownInputTop - desiredHeightRequired > 0)  {
        dropDownPopupProps.style.maxHeight = dropDownPopupContentHeight + popupBorderThickness * 2
      }else {
        dropDownPopupProps.style.maxHeight = minimumHeightRequired
      }
    }
  }else {
    dropDownPopupWrapperProps.style.bottom = screenMargin
    dropDownPopupProps.style.maxHeight = minimumHeightRequired
  }


  let desiredWidthRequired = dropDownPopupContentWidth + (popupBorderThickness * 2) + screenMargin
  if ((dropDownInputRight + dropDownInputWidth) - desiredWidthRequired > 0) {
    dropDownPopupWrapperProps.style.left = dropDownInputLeft
  }else if (dropDownInputLeft + dropDownInputWidth > desiredWidthRequired) {
    dropDownPopupWrapperProps.style.right = dropDownInputRight

  }else {
    //we need to shrink!!!
    dropDownPopupWrapperProps.style.left = screenMargin
    dropDownPopupWrapperProps.style.right = screenMargin
  }

  if (!isOpenState) {
    dropDownPopupWrapperProps.style.height = 0
  }else {
    //dropDownPopupProps.style.height = dropDownPopupHeight
    //Calculate popup position
  }


  return (<>
    <div className={"DropDownButton DropDownButton-" + status + (isOpenState ? " DropDownButton-Selected" : "") + (disabled ? " DropDownButton-Disabled" : "") + (alwaysShowBorder ? " DropDownButton-WithBorder" : "")}
      onClick={containerClicked} style={dropDownInputStyleProps} ref={dropDownInputContainerRef}>
      <div className="DropDownButton-Container">
        <div className="DropDownButton-Content"> 
          {content}
          {showDropDownArrow && 
            <div className="DropDownButton-Content-Arrow">
              {isOpenState && 
                <CloseDropdown/>
              }
              {!isOpenState && 
                <OpenDropdown/>
              }
            </div>
          }
        </div>
      </div>


    </div>

    
    <div className="DropDownButton-Popup-Wrapper" {...dropDownPopupWrapperProps}>
        <div className="DropDownButton-Popup-Container" ref={dropDownPopupContainerRef}>
          <div className="DropDownButton-Popup" {...dropDownPopupProps}>
            <div className="DropDownButton-Popup-ContentWrapper" ref={dropDownPopupContentRef}>
              <div className="DropDownButton-Popup-Content">

                <div className="DropDownButton-Popup-Options">
                  {(options !== null && options !== undefined) && <>
                  
                    {options.map((option) => {

                      const optionClicked = (e) => {
                        if (isDisabled) {
                          return
                        }
                        if (onOptionSelected !== undefined)  {
                          onOptionSelected(option)
                        }else if (option.callback !== undefined)  {
                          option.callback()
                        }
                        closePopup()
                      }

                      let isActiveOption = option.active ?? false
                      let isSelectedOption = option.selected ?? false
                      let isDisabled = option.disabled !== undefined && option.disabled === true
                      let optionStatus = option.status ?? false

                      return (<div 
                                key={option.key}
                                className={"DropDownButton-Popup-Option" + (isActiveOption ? " DropDownButton-Popup-Option-Active" : "") + (isDisabled ? " DropDownButton-Popup-Option-Disabled" : "" + (optionStatus ? " DropDownButton-Popup-Option-Status-" + optionStatus : ""))} 
                                onClick={optionClicked}>

                        <div className="DropDownButton-Popup-Option-Title noselect">
                          <span>{option.label}</span>
                        </div>

                        {(option.info !== undefined && option.info !== null) && 
                          <div className="DropDownButton-Popup-Option-Info noselect">
                            {option.info}
                          </div>
                        }

                      </div>)
                    })}
                  </>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOpenState &&
        <div className="DropDownButton-Popup-Closer" onClick={closePopup}></div>
      }
    </>
    
  )
} 

export default DropDownButton