import '../GrowZonesPage.scss';
import variables from '../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { AdditionalOptions, Pause, Play } from '../../../assets/icons/Icons';
import DropDownButton from '../../../components/DropDownButton';
import { getLiveDataItem, getUniqueConfigurationByName, selectAllBladeConfigurationMaps, selectBladeComponentInfo, selectBladeIdsForBladeZoneUID, selectBladeLiveDataByComponentInfo, selectBladeRuntimeInformation, selectBladeUniqueConfigurationByName, selectBladeUpperNurseryLiquidSystemStatus, selectBladeZoneRuntimeInformation, selectBladeZoneTypeByUID, setBladeRuntimeProperty, setBladeZoneRuntimeProperty } from '../../../redux/entities/service/Blade';
import { DataStatus_DataList } from '../../../components/DataStatus/DataStatus';
import { parseBool } from '../../../helpers';
import Button from '../../../components/Button';


const GrowZone_StatusContent_NurseryUpperWaterCellForFlow = ({ zoneUID }) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const dispatch = useDispatch()

  
  const zoneType = useSelector((state) => selectBladeZoneTypeByUID(state, zoneUID), _.isEqual)
  const waterReservoirBladeIds = useSelector((state) => selectBladeIdsForBladeZoneUID(state, zoneUID), _.isEqual)
  const waterReservoirBladeId = (zoneType === "nursery" ? waterReservoirBladeIds["nursery"] : waterReservoirBladeIds["environment"])


  const liquidStatus = useSelector((state) => selectBladeUpperNurseryLiquidSystemStatus(state, waterReservoirBladeIds["nursery"]), _.isEqual)

  const reservoirMinConfiguration = useSelector((state) => selectBladeUniqueConfigurationByName(state, waterReservoirBladeIds["nursery"], "AuxAReservoirLowLiquidVolume"), _.isEqual)
  const reservoirMaxConfiguration = useSelector((state) => selectBladeUniqueConfigurationByName(state, waterReservoirBladeIds["nursery"], "AuxAReservoirFullLiquidVolume"), _.isEqual)
  const reservoirMin = reservoirMinConfiguration ?? 0
  const reservoirMax = reservoirMaxConfiguration ?? 20


  const [reservoirVolumeUsageComponentInfo, reservoirVolumeComponentInfo, waterECComponentInfo, waterECSetpointComponentInfo, haveWaterECSetpointComponentInfo, waterPHComponentInfo, waterPHSetpointComponentInfo, haveWaterPHSetpointComponentInfo, waterDOComponentInfo, waterORPComponentInfo] 
  = useSelector((state) => selectBladeComponentInfo(state, waterReservoirBladeId, "AuxAWaterReservoir", ["water_level", "water_volume", "ec", "ec-sp", "ec-hs", "ph", "ph-sp", "ph-hs", "do", "orp"]), _.isEqual)
  const [waterTemperatureComponentInfo] 
  = useSelector((state) => selectBladeComponentInfo(state, waterReservoirBladeId, "AuxAReservoirOWTB", ["t"]), _.isEqual)
  const [liquidPressureComponentInfo] 
  = useSelector((state) => selectBladeComponentInfo(state, waterReservoirBladeId, "AuxALiquidSystemPressureTransducer", ["pressure"]), _.isEqual)

  const reservoirVolume = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, reservoirVolumeComponentInfo), _.isEqual)
  const reservoirVolumeUsage = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, reservoirVolumeUsageComponentInfo), _.isEqual)
  const liquidPressure = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, liquidPressureComponentInfo), _.isEqual)
  const waterTemperature = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, waterTemperatureComponentInfo), _.isEqual)
  const waterEC = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, waterECComponentInfo), _.isEqual)
  const waterECSetpoint = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, waterECSetpointComponentInfo), _.isEqual)
  const haveWaterECSetpoint = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, haveWaterECSetpointComponentInfo), _.isEqual)
  const waterPH = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, waterPHComponentInfo), _.isEqual)
  const waterPHSetpoint = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, waterPHSetpointComponentInfo), _.isEqual)
  const haveWaterPHSetpoint = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, haveWaterPHSetpointComponentInfo), _.isEqual)
  const waterDO = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, waterDOComponentInfo), _.isEqual)
  const waterORP = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, waterORPComponentInfo), _.isEqual)



  let waterTempValid = false
  if (waterTemperature.value !== -999)  {
    waterTempValid = true
  }

  let dataListItems = [
    {
      type: "large_bar", label: "Reservoir Volume", value: reservoirVolumeUsage.value, valueToDisplay: reservoirVolume.value, suffix: "L", min: 0, max: 100, status: "normal"
    },
    {
      type: "setpoint_bar", label: "EC", value: waterEC.value, setpoint: waterECSetpoint.value, setpoint_active: parseBool(haveWaterECSetpoint.value), suffix: "mS/m", range: 500, status: "normal"
    },
    {
      type: "setpoint_bar", label: "pH", value: waterPH.value, setpoint: waterPHSetpoint.value, setpoint_active: parseBool(haveWaterPHSetpoint.value), suffix: "", range: 2, status: "normal"
    },
    {
      label: "Pressure", value: liquidPressure.value, suffix: "psi", visualization: {
        type: "bar", info: { usesRange: true, min: 0, max: 130, status: "normal" }
      }
    },
    {
      label: "Temperature", valid: waterTempValid, value: waterTemperature.value, suffix: "°C"
    },
    {
      label: "DO", value: waterDO.value, suffix: "ppm",
    },
    {
      label: "ORP", value: waterORP.value, suffix: "mV",
    },
  ]


  let additionalFunctions = [
    
  ]


  return (<>
    <div className="DataStatus-Cell">
      <div className="DataStatus-Cell-Header-Container">
        <div className="DataStatus-Cell-Header-PrimaryContent">
          <div className="Text-H14">Zone Reservoir</div>
          <div className="DataStatus-Cell-Header-PrimaryContent-StatusChip">{liquidStatus}</div>
        </div>
        <div className="DataStatus-Cell-Header-AuxContent">
          <DropDownButton content={<AdditionalOptions />} options={additionalFunctions} status={"Neutral"} />
        </div>
      </div>
      <div className="DataStatus-Cell-Content-Container">
        <DataStatus_DataList items={dataListItems} />
      </div>
    </div>
  </>)
}

export default GrowZone_StatusContent_NurseryUpperWaterCellForFlow