import { useMediaQuery } from 'react-responsive';
import './Button.scss';
import React from 'react'

const Button = React.forwardRef(({
    style, content, status, state, 
    alignContent, size, width, height, margin,
    contentPadding,
    onClick, onPointerDown, onPointerMove, onPointerUp,
    hasBorder, disabled, contentRefCallback
  }, ref) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const contentRef = React.useRef();

  React.useEffect(() => {
    if (contentRefCallback !== undefined) {
      contentRefCallback(contentRef)
    }
  }, [contentRef, contentRefCallback])

  let buttonProps = {style: {...style}}
  const buttonClicked = (e) => {
    if (!disabled && onClick !== undefined && onClick !== null)  {
      onClick(e)
    }
  }

  const pointerDown = (e) => {
    if (!disabled && onPointerDown !== undefined && onPointerDown !== null)  {
      onPointerDown(e)
    }
  }

  const pointerMove = (e) => {
    if (!disabled && onPointerMove !== undefined && onPointerMove !== null)  {
      onPointerMove(e)
    }
  }

  const pointerUp = (e) => {
    if (!disabled && onPointerUp !== undefined && onPointerUp !== null)  {
      onPointerUp(e)
    }
  }
  /*if (buttonKey !== null)  {
    buttonProps.key = buttonKey
  }*/

  if (margin) {
    buttonProps.style.margin = margin
  }
  return (
    <div 
      ref={ref}
      className={"Button Button-" + status + " Button-" + alignContent + "Content" + (state ? " Button-" + status + "-Active" : " Button-" + status + "-Inactive") + " Button-" + size + 
      (hasBorder ? " Button-WithBorder" : "") + (width ? " Button-" + width : "") + (disabled ? " Button-Disabled noselect" : "" ) + 
      (isMobile ? " Button-Mobile" : "") + (isTablet ? " Button-Tablet" : "") + (isDesktop ? " Button-Desktop" : "")} {...buttonProps}>
      <div 
        style={{padding:contentPadding}}
        onClick={buttonClicked} 
        onPointerDown={pointerDown}
        onPointerMove={pointerMove} 
        onPointerUp={pointerUp}
        ref={contentRef}
      ><div className="noselect">{content}</div></div>
    </div>
  )
})

Button.defaultProps = {
  content: "",
  status: "Primary",
  size: "Medium",
  alignContent: "Center",
  state: false,
  key: null,
  hasBorder: false,
  disabled: false,
  contentPadding: 9,
  width: false,
  height: false,
  margin: false,
  style: {}
}


export default Button