import './ChangeGrowStartDatePopup.scss';
import React from 'react';
import _ from 'underscore';


import { useSelector, useDispatch} from 'react-redux'
import { useMediaQuery } from 'react-responsive';
import PopupModal from '../../../model_components/PopupModal';
import Button from '../../../components/Button';
import { useGetViewSize } from '../../../helpers';
import { changeGrowStartDate, selectGrowDates, selectGrowDisplayName } from '../../../redux/entities/Grow';
import Calendar from '../../../components/input/Calendar';

const ChangeGrowStartDatePopup = ({
    growId,
    onClosePopup}) => {
  
  const [isMobile, isTablet, isDesktop] = useGetViewSize()

  const navigate = useDispatch()
  const dispatch = useDispatch()


  const closePopup = () =>  {
    onClosePopup()
  }


  const growDisplayName = useSelector((state) => selectGrowDisplayName(state, growId), _.isEqual)
  const growDates = useSelector((state) => selectGrowDates(state, growId, null), _.isEqual)
  const [newGrowStartDate, SetNewGrowStartDate] = React.useState(null)
  React.useLayoutEffect(() => {
    if (newGrowStartDate === null && growDates !== null && growDates.start !== null)  {
      SetNewGrowStartDate(growDates.start)
    }
  }, [growDates, newGrowStartDate])
  const selectedStartDateChanged = (date) => {
    SetNewGrowStartDate(date)
  }


  const hasDateChanged = () => {
    if (newGrowStartDate === null)  {
      return false
    }
    if (growDates !== null && growDates.start !== null) {
      if (growDates.start !== newGrowStartDate) {
        return true
      }
    }
  }

  const [applyingChanges, SetApplyingChanges] = React.useState(false)
  const applyChanges = () => {
    SetApplyingChanges(true)
    dispatch(changeGrowStartDate({growId: growId, newStartDate: newGrowStartDate.toISOString(), callback: (success) => {
      SetApplyingChanges(false)
    }}))
  }

  return (<>
    <PopupModal
      title={growDisplayName}
      subHeaderContent={<div className="Text-AuxSubtext">#G{growId}</div>}
      size="full"
      closeCallback={closePopup}
      hideMainSeparators={false}
      contentVerticalPadding={0}
      contentWrapperVerticalPadding={0}
      footer={<>
        <div className={isMobile ? "FlexContent-5 FlexContent-HFlex" : "FlexContent-H-10 FlexContent-HFlex"}>
          {!isMobile && 
            <Button content="Cancel" status="Neutral" onClick={closePopup} size={"Flex100"}/>
          }
          <Button content="Apply" onClick={applyChanges} size={"Flex100"} disabled={!hasDateChanged() || applyingChanges}/>
        </div>
      </>}
    >
      <div className={"FlexContent-10"}>
        <Calendar 
          isRange={false}
          selectedDate={newGrowStartDate}
          multiMonth={false}
          selectedDateChanged={selectedStartDateChanged}
        />

      </div>
    </PopupModal>
  </>)
} 

export default ChangeGrowStartDatePopup