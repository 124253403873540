import { registerNewAccount } from "./entities/Account"
import { setFlowRuntimeProperty } from "./entities/service/Flow"


//export const Host = "https://3.128.244.115:10051/" //Development Application Server Host
export const WorkingHost = "https://api.crclr.tech:10051/" //Development Application Server Host
export const DevHost = "https://api.crclr.tech:10061/" //Development Application Server Host

const getHost = () =>   {
    if (window.location.origin.indexOf("dev") !== -1)   {
        return DevHost
    }else {
        return WorkingHost
    }
}

export const Repository = {
    AppInfo: {
        GetAppInfo: getHost() + "getAppInfo"
    },
    Common: {
        GetSolutionById: getHost() + "common/getSolutionById",
        GetNutrientSolutionById: getHost() + "common/getNutrientSolutionById",
    },
    Account: {
        GetAccountBasicInfo: getHost() + "account/getAccountBasicInfo",
        AttemptAccountLogin: getHost() + "account/attemptLogin",
        RegisterNewAccount: getHost() + "account/registerNew",
        AccountEmailVerification: getHost() + "account/emailVerification",
        AccountRequestNewEmailVerification: getHost() + "account/requestNewEmailVerification",
        FinalSetup: getHost() + "account/finalSetup",
        Logout: getHost() + "account/logout",
    },
    Facility: {
        GetAllFacilities: getHost() + "facility/getAllFacilities",
        GetAllServicesForFacility: getHost() + "facility/getAllServicesForFacility",
    },
    ControlDevice: {
        GetAllControlDevicesByFacilityId: getHost() + "control_device/getAllControlDevicesByFacilityId",
        CreateNewControlDevice: getHost() + "control_device/createNewControlDevice",
        PerformControlDeviceSoftwareReset: getHost() + "control_device/performControlDeviceSoftwareReset"
    },
    Blade: {
        GetAllBlades: getHost() + "blade/getAllBlades",
        GetAllBladeGroups: getHost() + "blade/getAllBladeGroups",
        GetAllBladeZones: getHost() + "blade/getAllBladeZones",
        GetBladeById: getHost() + "blade/getBladeById",
        GetBladeBySerialNumber: getHost() + "blade/getBladeBySerialNumber",
        GetBladeByUID: getHost() + "blade/getBladeByUID",
        GetBladeByServiceId: getHost() + "blade/getBladeByServiceId",
        GetAllBladeGroupsByFacilityId: getHost() + "blade/getAllBladeGroupsByFacilityId",
        GetBladeGroupById: getHost() + "blade/getBladeGroupById",
        GetBladeZoneById: getHost() + "blade/getBladeZoneById",
        GetBladeZoneByUID: getHost() + "blade/getBladeZoneByUID",
        GetBladeEventsForDateRange: getHost() + "blade/getBladeEventsForDateRange",
        GetBladeStatusById: getHost() + "blade/getBladeStatusById",
        SetBladeRuntimeProperty: getHost() + "blade/setBladeRuntimeProperty",
        SetBladeZoneRuntimeProperty: getHost() + "blade/setBladeZoneRuntimeProperty",
        MarkActiveIssueAsManuallyResolved: getHost() + "blade/markActiveIssueAsManuallyResolved",
        GetBladeLiveDataById: getHost() + "blade/getBladeLiveDataById",
        GetBladeAnalyticsData: getHost() + "blade/getBladeAnalyticsData",
        GetBladeConfigurationMap: getHost() + "blade/getBladeConfigurationMap",
        GetBladeUniqueConfigurationMap: getHost() + "blade/getBladeUniqueConfigurationMap",
        SetBladeUniqueConfiguration: getHost() + "blade/setBladeUniqueConfiguration",
        GetBladeZoneConfigurationMap: getHost() + "blade/getBladeZoneConfigurationMap",

        GetBladeZoneStatusByUID: getHost() + "blade/getBladeZoneStatusByUID",
        GetBladeZoneActiveRecipe: getHost() + "blade/getBladeZoneActiveRecipe",
        StopBladeZoneActiveRecipe: getHost() + "blade/stopBladeZoneActiveRecipe",

        ValidateBladeSystemConnection: getHost() + "blade/validateBladeSystemConnection",
        CreateNewBlade: getHost() + "blade/createNewBlade",
        CreateNewBladeGroup: getHost() + "blade/createNewBladeGroup",
        MoveBladeToGroup: getHost() + "blade/moveBladeToGroup",
        LinkBladeToControlDevice: getHost() + "blade/linkBladeToControlDevice",
        AddBladeToFarm: getHost() + "blade/addBladeToFarm",

        MarkGrowZoneForCleanse: getHost() + "blade/markGrowZoneForCleanse",
        GetBladeCleanseSubtask: getHost() + "blade/getBladeCleanseSubtask",
        SetBladeCleanseSubtaskStage: getHost() + "blade/setBladeCleanseSubtaskStage"
    },
    Flow: {
        GetAllFlows: getHost() + "flow/getAllFlows",
        GetAllFlowForFacility: getHost() + "flow/getFlowForFacility",
        GetFlowById: getHost() + "flow/getFlowById",
        GetFlowByServiceId: getHost() + "flow/getFlowByServiceId",
        GetFlowStatusById: getHost() + "flow/getFlowStatusById",
        GetFlowLiveDataById: getHost() + "flow/getFlowLiveDataById",
        GetFlowConfigurationMap: getHost() + "flow/getFlowConfigurationMap",
        GetFlowUniqueConfigurationMap: getHost() + "flow/getFlowUniqueConfigurationMap",        
        GetFlowProperty: getHost() + "flow/getFlowProperty",
        SetFlowProperty: getHost() + "flow/setFlowProperty",
        SetFlowRuntimeProperty: getHost() + "flow/setFlowRuntimeProperty",
        ManageFlowAssignedBladeZoneEntry: getHost() + "flow/manageFlowAssignedBladeZoneEntry",
        GetFlowMapVersions: getHost() + "flow/getFlowMapVersions",
        GetFlowMaps:  getHost() + "flow/getFlowMaps",
    },
    GerminationStation: {
        GetAllGerminationStations: getHost() + "germination_station/getAllGerminationStations",
        GetGerminationStationById: getHost() + "germination_station/getGerminationStationById",
        GetGerminationStationBySerialNumber: getHost() + "germination_station/getGerminationStationBySerialNumber",
        GetGerminationStationByUID: getHost() + "germination_station/getGerminationStationByUID",
        GetGerminationStationByServiceId: getHost() + "germination_station/getGerminationStationByServiceId",
        GetGerminationStationStatusById: getHost() + "germination_station/getGerminationStationStatusById",
        SetGerminationStationRuntimeProperty: getHost() + "germination_station/setGerminationStationRuntimeProperty",
        GetGerminationStationLiveDataById: getHost() + "germination_station/getGerminationStationLiveDataById",
        GetGerminationStationAnalyticsData: getHost() + "germination_station/getGerminationStationAnalyticsData",
        GetGerminationStationConfigurationMap: getHost() + "germination_station/getGerminationStationConfigurationMap",
        GetGerminationStationUniqueConfigurationMap: getHost() + "germination_station/getGerminationStationUniqueConfigurationMap",

        ValidateGerminationStationSystemConnection: getHost() + "germination_station/validateGerminationStationSystemConnection",
        CreateNewGerminationStation: getHost() + "germination_station/createNewGerminationStation",
        LinkGerminationStationToControlDevice: getHost() + "germination_station/linkGerminationStationToControlDevice",
        AddGerminationStationToFarm: getHost() + "germination_station/addGerminationStationToFarm",
    },
    HVAC: {
        GetAllHVACForFacility: getHost() + "hvac/getHVACForFacility",
        GetHVACById: getHost() + "hvac/getHVACById",
        GetHVACByServiceId: getHost() + "hvac/getHVACByServiceId",
        GetHVACProperty: getHost() + "hvac/getHVACProperty",
        SetHVACProperty: getHost() + "hvac/setHVACProperty",
    },
    Inventory: {
        GetAllInventoryItems: getHost() + "inventory/getAllInventoryItems",
        CreateNewInventoryItem: getHost() + "inventory/createNewInventoryItem",
        GetInventoryItemsByItemTypeGroupId: getHost() + "inventory/getInventoryItemsByItemTypeGroupId",
        GetAllInventoryItemTypes: getHost() + "inventory/getAllInventoryItemTypes",
        GetInventoryItemTypesByGroupId : getHost() + "inventory/getInventoryItemTypesByGroupId",
        GetInventoryItemTypeById: getHost() + "inventory/getInventoryItemTypeById",
        CreateNewInventoryItemType: getHost() + "inventory/createNewInventoryItemType",
        GetAllInventoryItemTypePrimaryGroups: getHost() + "inventory/getAllInventoryItemTypePrimaryGroups",
        GetInventoryItemTypeGroupById: getHost() + "inventory/getInventoryItemTypeGroupById",
        GetInventoryItemTypeGroupsByParentGroupId: getHost() + "inventory/getInventoryItemTypeGroupsByParentGroupId",
        GetInventoryItemTypeGroupByPath: getHost() + "inventory/getInventoryItemTypeGroupByPath",
    },
    Tasks: {
        GetAllTasksForAccount: getHost() + "tasks/getAllTasksForAccount",
        GetTaskById: getHost() + "tasks/getTaskById",
        GetTasksByCategoryAndReferenceId: getHost() + "tasks/getTasksByCategoryAndReferenceId",
        MarkTaskAsStarted: getHost() + "tasks/markTaskAsStarted",
        SetTaskStep: getHost() + "tasks/setTaskStep",
        CompleteTaskStep: getHost() + "tasks/completeTaskStep",
        MarkTaskAsCompleted: getHost() + "tasks/markTaskAsCompleted",
    },
    GrowPlans: {
        GetAllGrowPlans: getHost() + "growPlans/getAllGrowPlans",
        GetGrowPlanById: getHost() + "growPlans/getGrowPlanById",
        Create: getHost() + "growPlans/create",
    },
    PlantTypes: {
        GetAllPlantTypes: getHost() + "plantTypes/getAllPlantTypes",
        GetPlantTypeById: getHost() + "plantTypes/getPlantTypeById",
        GetPlantVarietiesByTypeId: getHost() + "plantTypes/getPlantVarietiesByTypeId",
        GetGrowSeedsByPlantVarietyId: getHost() + "plantTypes/getGrowSeedsByPlantVarietyId",
    },
    PlantVarieties: {
        GetAllPlantVarietiesForAccount: getHost() + "plantVarieties/getAllPlantVarietiesForAccount"
    },
    RaftTypes: {
        GetAllRaftTypes: getHost() + "raftTypes/getAllRaftTypes",
        GetRaftTypeById: getHost() + "raftTypes/getRaftTypeById"
    },
    Grows: {
        GetAllGrows: getHost() + "grow/getAllGrows",
        GetGrowById: getHost() + "grow/getGrowById",
        GetGrowAnalyticsData: getHost() + "grow/getGrowAnalyticsData",
        GetGrowLiveData: getHost() + "grow/getGrowLiveData",
        GetGrowDosingHistory: getHost() + "grow/getGrowDosingHistory",
        ScheduleNewGrow: getHost() + "grow/scheduleNewGrow",
        ChangeGrowStartDate: getHost() + "grow/changeGrowStartDate",

        GetGrowGerminationBoardLocations: getHost() + "grow/getGrowGerminationBoardLocations",
        GetGrowNurseryBoardLocations: getHost() + "grow/getGrowNurseryBoardLocations",
        GetGrowGrowoutLocations: getHost() + "grow/getGrowGrowoutLocations",
        MarkGrowZoneEntryAsHarvested: getHost() + "grow/markGrowZoneEntryAsHarvested",

        CreateTransplantToGrowOutFromNurseryEntry: getHost() + "grow/createTransplantToGrowOutFromNurseryEntry",

        UploadGrowPhoto: getHost() + "grow/uploadGrowPhoto",
        GetAllGrowPhotos: getHost() + "grow/getAllGrowPhotos",
        DownloadGrowPhoto: ({accountId, storageKey}) => {return getHost() + "grow/downloadGrowPhoto?account_id=" + accountId.toString() + "&storage_key=" + storageKey},
        EditGrowPhoto: getHost() + "grow/editGrowPhoto",
        DeleteGrowPhoto: getHost() + "grow/deleteGrowPhoto"
    },
    Recipes: {
        CreateRecipe: getHost() + "recipe/createRecipe",
        RemoveRecipe: getHost() + "recipe/removeRecipe",
        GetAllRecipes: getHost() + "recipe/getAllRecipes",
        GetRecipeById: getHost() + "recipe/getRecipeById",
        GetWorkingRecipeById: getHost() + "recipe/getWorkingRecipeById",
        PushRecipeChange: getHost() + "recipe/pushRecipeChange",
        SetRecipeRevisionIndex: getHost() + "recipe/setRecipeRevisionIndex",
        SaveRecipe: getHost() + "recipe/saveRecipe",
        DiscardRecipeChanges: getHost() + "recipe/discardRecipeChanges",
    }
}

export const FetchPost = (path, payload) =>  {
    return fetch(path, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
    }).then( (response) => response.json() );
}