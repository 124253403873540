
import '../GrowStatusList.scss';
import React from 'react'
import { useMediaQuery } from 'react-responsive';
import { FormatDate, RoundToNearest, useRenderingTrace } from '../../../helpers';
import DropDownInput from '../../../components/input/DropDownInput';
import { useDispatch, useSelector } from 'react-redux';
import { getBladeZoneByUID, selectAllBladeZones } from '../../../redux/entities/service/Blade';
import { selectAllDataRecordingTimePeriodTypes, selectAllRecipeSetpointTypes } from '../../../redux/AppInfo';
import { getGrowAnalyticsData, getGrowNurseryBoardLocations, initializeDataRecordingTimePeriodTypes, selectGrowById } from '../../../redux/entities/Grow';
import { makeFlatTheme } from '@lightningchart/lcjs-themes';
import { AxisTickStrategies, ColorHEX, ColorRGBA, SolidFill, SolidLine, emptyLine, lightningChart } from '@lightningchart/lcjs';
import { getRecipeById, selectAllRecipes } from '../../../redux/entities/Recipes';
import { createLightningChart } from '../../../LC';
import { selectAllGrowPlans } from '../../../redux/entities/GrowPlans';

const GrowStatus_PerformanceContent = ({
    growId,
    zoneOptions, selectedZoneUID, onZoneSelectionChanged,
    dataActive
}) => {

    const isDesktop = useMediaQuery({ minWidth: 1079 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    //console.log(activeGrow)

    const dispatch = useDispatch()

    const activeGrow = useSelector((state) => selectGrowById(state, growId))
    const allBladeZones = useSelector((state) => selectAllBladeZones(state))
    const allGrowPlans = useSelector(selectAllGrowPlans)


    const activeGrowPlan = allGrowPlans.find((gP) => gP.id === activeGrow.grow_plan_id)

    const [isGrowInZones, SetIsGrowInZones] = React.useState(false)
    React.useLayoutEffect(() => {
        SetIsGrowInZones(zoneOptions.length > 0)
    }, [zoneOptions])

    useRenderingTrace("PerformanceContent", [activeGrow])


    React.useEffect(() => {
        if (activeGrow !== undefined) { 
            console.log(activeGrow.grow_out_zone_info )
            let bladeZonesToLoad = []
            if (activeGrow.grow_out_zone_info) {
                for (let zoneInfo of activeGrow.grow_out_zone_info) {
                    let foundBladeZone = allBladeZones.find((z) => z.uid === zoneInfo.zone_uid)
                    if (foundBladeZone === undefined) {
                        bladeZonesToLoad.push(zoneInfo.zone_uid)
                    }
                }
            }

            if (bladeZonesToLoad.length > 0) {
                dispatch(getBladeZoneByUID({ UIDs: bladeZonesToLoad }))
            }
        }
    }, [activeGrow, allBladeZones])



    const [nurseryBoardLocations, SetNurseryBoardLocations] = React.useState(null)
    React.useEffect(() => {
        //loaded_nursery_locations
        if (activeGrow !== undefined) {
            if (activeGrow.loaded_nursery_locations !== undefined && !activeGrow.loaded_nursery_locations && (activeGrow.loading_nursery_locations === undefined || !activeGrow.loading_nursery_locations)) {
                dispatch(getGrowNurseryBoardLocations({ growId: activeGrow.id }))
            } else {
                SetNurseryBoardLocations(activeGrow.nursery_locations)
            }
        }
    }, [activeGrow])


    const [activeBladeZone, SetActiveBladeZone] = React.useState(undefined)
    React.useEffect(() => {
        if (activeGrow !== undefined && selectedZoneUID !== null) {
            SetActiveBladeZone(allBladeZones.find((bZ) => bZ.uid === selectedZoneUID))
        } else {
            SetActiveBladeZone(undefined)
        }
    }, [activeGrow, selectedZoneUID])


    const allRecipes = useSelector(selectAllRecipes)
    const [activeRecipe, SetActiveRecipe] = React.useState(undefined)
    React.useEffect(() => {
        if (activeBladeZone !== undefined) {
            if (activeBladeZone.active_zone_recipe !== undefined && activeBladeZone.active_zone_recipe !== null) {
                let foundRecipe = allRecipes.find((r) => r.id === activeBladeZone.active_zone_recipe.recipe_id && r.version === activeBladeZone.active_zone_recipe.recipe_version)
                if (foundRecipe === undefined) {
                    dispatch(getRecipeById({
                        recipes: [{
                            id: activeBladeZone.active_zone_recipe.recipe_id,
                            version: activeBladeZone.active_zone_recipe.recipe_version
                        }]
                    }))
                } else {
                    SetActiveRecipe(foundRecipe)
                }
            }
        }
    }, [activeBladeZone, allRecipes])





    const haveAppInfo = useSelector((state) => state.appInfo.haveAppInfo)
    const recipeSetpointTypes = useSelector(selectAllRecipeSetpointTypes)
    const dataRecordingTimePeriodTypes = useSelector(selectAllDataRecordingTimePeriodTypes)

    const currentDataRecordingTimePeriodType = dataRecordingTimePeriodTypes.find(type => type.id === 3)

    const [currentTimelineItem, SetCurrentTimelineItem] = React.useState(null)
    const [activeSetpoints, SetActiveSetpoints] = React.useState([])

    const [chartOriginDate, SetChartOriginDate] = React.useState(new Date().getTime());



    const availableDataTileConfig = {
        air: {
            airTemp: {
                displayName: "Air Temp", suffix: "°C", resolution: 0.1, minAxisOffset: 1.0, baseAxisOffset: 4.0, maxAxisOffset: 8.0, identifiers: {
                    ei: "at-ei", eia: "at-eia", value: "airt", setpoint: "at-sp", recipe_setpoint_type: "air_temp",
                }, activeSetpoint: null, chartActualColor: "#00FF00FF", chartSetpointColor: "#000000FF"
            },
            airRH: {
                displayName: "Air RH", suffix: "%", resolution: 0.5, minAxisOffset: 1.0, baseAxisOffset: 2.0, maxAxisOffset: 8.0, identifiers: {
                    ei: "rh-ei", eia: "rh-eia", value: "airrh", setpoint: "rh-sp", recipe_setpoint_type: "air_rh",
                }, activeSetpoint: null, chartActualColor: "#00FF00FF", chartSetpointColor: "#000000FF"
            },
            airVPD: {
                displayName: "Air VPD", suffix: "kpa", resolution: 0.05, minAxisOffset: 0.3, baseAxisOffset: 0.5, maxAxisOffset: 1.0, identifiers: {
                    ei: "vpd-ei", eia: "vpd-eia", value: "airvpd", setpoint: "vpd-sp", recipe_setpoint_type: "air_vpd",
                }, activeSetpoint: null, chartActualColor: "#00FF00FF", chartSetpointColor: "#000000FF"
            },
        },
        root: {
            waterTemp: {
                displayName: "Water Temp", suffix: "°C", resolution: 0.1, minAxisOffset: 1.0, baseAxisOffset: 2.0, maxAxisOffset: 8.0, identifiers: {
                    ei: "wt-ei", eia: "wt-eia", value: "watert", setpoint: "wt-sp", recipe_setpoint_type: "water_temp",
                }, activeSetpoint: null, chartActualColor: "#00FF00FF", chartSetpointColor: "#000000FF"
            },
            ebbFlowRate: {
                displayName: "Ebb&Flow", suffix: "%", resolution: 0.5, minAxisOffset: 2.0, baseAxisOffset: 5.0, maxAxisOffset: 20.0, identifiers: {
                    ei: "erate-ei", eia: "erate-eia", value: "erate-a", setpoint: "erate-sp", recipe_setpoint_type: "ebbflow_frequency",
                }, activeSetpoint: null, chartActualColor: "#00FF00FF", chartSetpointColor: "#000000FF"
            },
            sprayRate: {
                displayName: "Aeroponics", suffix: "%", resolution: 0.5, minAxisOffset: 2.0, baseAxisOffset: 5.0, maxAxisOffset: 20.0, identifiers: {
                    ei: "srate-ei", eia: "srate-eia", value: "srate-a", setpoint: "srate-sp", recipe_setpoint_type: "spray_rate",
                }, activeSetpoint: null, chartActualColor: "#00FF00FF", chartSetpointColor: "#000000FF"
            },
            dripRate: {
                displayName: "Irrigation", suffix: "%", resolution: 0.5, minAxisOffset: 2.0, baseAxisOffset: 5.0, maxAxisOffset: 20.0, identifiers: {
                    ei: "drate-ei", eia: "drate-eia", value: "drate-a", setpoint: "drate-sp", recipe_setpoint_type: "drip_irrigation_rate",
                }, activeSetpoint: null, chartActualColor: "#00FF00FF", chartSetpointColor: "#000000FF"
            },
            ec: {
                displayName: "EC", suffix: "S/m", resolution: 1, minAxisOffset: 100, baseAxisOffset: 400.0, maxAxisOffset: 2000.0, identifiers: {
                    ei: "ec-ei", eia: "ec-eia", value: "waterec", setpoint: "ec-sp", recipe_setpoint_type: "water_ec",
                }, activeSetpoint: null, chartActualColor: "#00FF00FF", chartSetpointColor: "#000000FF"
            },
            pH: {
                displayName: "pH", resolution: 0.1, minAxisOffset: 0.5, baseAxisOffset: 1.0, maxAxisOffset: 3.0, identifiers: {
                    ei: "ph-ei", eia: "ph-eia", value: "waterph", setpoint: "ph-sp", recipe_setpoint_type: "water_ph",
                }, activeSetpoint: null, chartActualColor: "#00FF00FF", chartSetpointColor: "#000000FF"
            },
            orp: {
                displayName: "ORP", suffix: "mV", resolution: 10, minAxisOffset: 1.0, baseAxisOffset: 2.0, maxAxisOffset: 8.0, identifiers: {
                    ei: "orp-ei", eia: "orp-eia", value: "waterorp", setpoint: "orp-sp", recipe_setpoint_type: "water_orp",
                }, activeSetpoint: null, chartActualColor: "#00FF00FF", chartSetpointColor: "#000000FF"
            },
            do: {
                displayName: "DO", suffix: "ppm", resolution: 10, minAxisOffset: 1.0, baseAxisOffset: 2.0, maxAxisOffset: 8.0, identifiers: {
                    ei: "do-ei", eia: "do-eia", value: "waterdo", setpoint: "do-sp", recipe_setpoint_type: "water_do",
                }, activeSetpoint: null, chartActualColor: "#00FF00FF", chartSetpointColor: "#000000FF"
            },
        },
        lights: {
            light_intensity: {
                displayName: "Light Intensity (CLI)", suffix: "mols", resolution: 10, minAxisOffset: 1.0, baseAxisOffset: 2.0, maxAxisOffset: 8.0, identifiers: {
                    ei: "cli-ei-", eia: "cli-a-", value: "cli-a-", setpoint: "cli-sp-", recipe_setpoint_type: "light_intensity",
                }, activeSetpoint: null, chartActualColor: "#00FF00FF", chartSetpointColor: "#000000FF"
            },
        }
    }

    const [dataTileConfig, SetDataTileConfig] = React.useState({})




    React.useEffect(() => {
        if (activeRecipe === undefined || activeBladeZone === undefined) {
            SetCurrentTimelineItem(null)
            return
        }
        if (activeBladeZone.zone_type === "nursery") {
            if (activeGrow.grow_out_zone_info !== undefined && activeGrow.grow_out_zone_info !== null) {
                let foundActiveZoneInfo = null
                for (let zoneInfo of activeGrow.grow_out_zone_info) {
                    if (zoneInfo.zone_uid === activeBladeZone.uid) {
                        foundActiveZoneInfo = zoneInfo
                    }
                }
                if (foundActiveZoneInfo) {
                    //Determine which grow zone cycle we are in
                    let elapsedSinceStart = (new Date().getTime() / 1000) - (new Date(foundActiveZoneInfo.started_on).getTime() / 1000)
                    let currentElapsedTally = 0

                    let foundTimelineItem = false
                    let currentTimelineItem = null



                    let timelineItemList = activeRecipe.timeline_items.filter((tI) => tI.type === "nursery_cycle")
                    for (let timelineItem of timelineItemList) {
                        let currentIteration = 0;
                        while (currentIteration < timelineItem.item.iterations) {
                            currentElapsedTally += (timelineItem.item.duration)
                            if (currentElapsedTally >= elapsedSinceStart) {
                                foundTimelineItem = true
                                currentTimelineItem = timelineItem
                                break
                            }

                            currentIteration++
                        }
                        if (foundTimelineItem)
                            break
                    }

                    if (!foundTimelineItem && timelineItemList.length > 0) {
                        currentTimelineItem = timelineItemList[timelineItemList.length - 1];
                    }
                    SetCurrentTimelineItem(currentTimelineItem)
                } else {
                    SetCurrentTimelineItem(null)
                }

            } else {
                SetCurrentTimelineItem(null)
            }

        } else if (activeBladeZone.zone_type === "grow_boards" || activeBladeZone.zone_type === "berry_troughs") {
            if (activeGrow.grow_out_zone_info !== undefined && activeGrow.grow_out_zone_info !== null) {
                let foundActiveZoneInfo = null
                for (let zoneInfo of activeGrow.grow_out_zone_info) {
                    if (zoneInfo.zone_uid === activeBladeZone.uid) {
                        foundActiveZoneInfo = zoneInfo
                    }
                }
                if (foundActiveZoneInfo) {

                    //Determine which grow zone cycle we are in
                    let elapsedSinceStart = (new Date().getTime() / 1000) - (new Date(foundActiveZoneInfo.started_on).getTime() / 1000)
                    let currentElapsedTally = 0

                    let foundTimelineItem = false
                    let currentTimelineItem = null


                    let timelineItemList = activeRecipe.timeline_items.filter((tI) => tI.type === "grow_zone_cycle")
                    for (let timelineItem of timelineItemList) {
                        let currentIteration = 0;
                        while (currentIteration < timelineItem.item.iterations) {
                            currentElapsedTally += (timelineItem.item.duration)
                            if (currentElapsedTally >= elapsedSinceStart) {
                                foundTimelineItem = true
                                currentTimelineItem = timelineItem
                                break
                            }

                            currentIteration++
                        }
                        if (foundTimelineItem)
                            break
                    }

                    if (!foundTimelineItem) {
                        currentTimelineItem = timelineItemList[timelineItemList.length - 1];
                    }
                    SetCurrentTimelineItem(currentTimelineItem)
                } else {
                    SetCurrentTimelineItem(null)
                }

            } else {
                SetCurrentTimelineItem(null)
            }
        }

    }, [activeRecipe, activeBladeZone])


    React.useEffect(() => {
        if (currentTimelineItem === undefined || currentTimelineItem === null) {
            SetActiveSetpoints([])
            return
        }
        SetActiveSetpoints(currentTimelineItem.item.setpoints)

    }, [currentTimelineItem])


    React.useEffect(() => {
        if (currentTimelineItem === undefined || currentTimelineItem === null || activeBladeZone === undefined) {
            SetActiveSetpoints([])
            SetDataTileConfig({})
            return
        }
        const activeSetpoints = currentTimelineItem.item.setpoint_zones
        SetActiveSetpoints(activeSetpoints)


        let currentDataTileConfigList = {}
        //Prepare the setpoints and miscellaneous details
        for (let [zoneKey, zoneConfigList] of Object.entries(availableDataTileConfig)) {
            currentDataTileConfigList[zoneKey] = {}
            for (let [key, config] of Object.entries(zoneConfigList)) {
                if (key === "light_intensity") {
                    if (activeBladeZone.zone_type === "nursery") {
                        //Determine which lighting zones to use
                        if (nurseryBoardLocations !== null && nurseryBoardLocations[activeBladeZone.uid] !== undefined) {
                            for (let nurseryRowString of Object.keys(nurseryBoardLocations[activeBladeZone.uid])) {
                                let nurseryRow = parseInt(nurseryRowString)
                                currentDataTileConfigList[zoneKey][key + "-" + nurseryRowString] = {
                                    ...config,
                                    is_subzone: true,
                                    zone_index: nurseryRow,
                                    identifiers: {
                                        ...config.identifiers,
                                        ei: config.identifiers.ei + nurseryRowString,
                                        eia: config.identifiers.eia + nurseryRowString,
                                        value: config.identifiers.value + nurseryRowString,
                                        setpoint: config.identifiers.setpoint + nurseryRowString
                                    },
                                    activeSetpoint: currentTimelineItem.item.lighting_intensity_setpoint_zones.find((lZ) => lZ.zone_index === nurseryRow)
                                }
                            }
                        }
                    } else if (activeBladeZone.zone_type === "grow_boards" || activeBladeZone.zone_type === "berry_troughs") {
                        //Only 1 lighting zone for grow out
                        currentDataTileConfigList[zoneKey][key] = {
                            ...config,
                            identifiers: {
                                ...config.identifiers,
                                ei: config.identifiers.ei + "all",
                                eia: config.identifiers.eia + "all",
                                value: config.identifiers.value + "all",
                                setpoint: config.identifiers.setpoint + "all"
                            },
                            activeSetpoint: currentTimelineItem.item.lighting_intensity_setpoint_zones[0]
                        }
                    }
                } else {
                    let foundSetpointType = recipeSetpointTypes.find((rST) => rST.name === config.identifiers.recipe_setpoint_type)
                    if (foundSetpointType !== undefined) {
                        if (activeBladeZone.zone_type === "nursery") {
                            if (zoneKey === "air") {
                                //Ignore zones
                                let foundActiveSetpointZone = activeSetpoints.find((aS) => aS.type_id === foundSetpointType.id)
                                if (foundActiveSetpointZone !== undefined) {
                                    currentDataTileConfigList[zoneKey][key] = { ...config, activeSetpoint: foundActiveSetpointZone.setpoints[0] }
                                } else {
                                    if (key !== "ebbFlowRate" && key !== "dripRate" && key !== "sprayRate") {
                                        currentDataTileConfigList[zoneKey][key] = { ...config, activeSetpoint: null }
                                    }
                                }
                            } else if (zoneKey === "root") {
                                if (nurseryBoardLocations !== null && nurseryBoardLocations[activeBladeZone.uid] !== undefined) {
                                    //console.log(nurseryBoardLocations[activeBladeZone.uid])
                                    for (let nurseryRowString of Object.keys(nurseryBoardLocations[activeBladeZone.uid])) {
                                        let nurseryRow = parseInt(nurseryRowString)
                                        let rootZoneIndex = 0
                                        if (nurseryRow >= 1 && nurseryRow <= 4) {
                                            rootZoneIndex = 1
                                        } else if (nurseryRow >= 3 && nurseryRow <= 7) {
                                            rootZoneIndex = 2
                                        }
                                        //console.log(activeSetpoints)
                                        let foundActiveSetpointZone = activeSetpoints.find((aS) => aS.type_id === foundSetpointType.id && aS.zone_index === rootZoneIndex)
                                        if (foundActiveSetpointZone !== undefined) {
                                            currentDataTileConfigList[zoneKey][key + "-" + rootZoneIndex] = {
                                                ...config,
                                                is_subzone: true,
                                                zone_index: rootZoneIndex,
                                                identifiers: {
                                                    ...config.identifiers,
                                                    ei: config.identifiers.ei + "-" + rootZoneIndex,
                                                    eia: config.identifiers.eia + "-" + rootZoneIndex,
                                                    value: config.identifiers.value + "-" + rootZoneIndex,
                                                    setpoint: config.identifiers.setpoint + "-" + rootZoneIndex
                                                },
                                                activeSetpoint: foundActiveSetpointZone.setpoints[0]
                                            }
                                        } else {
                                            if (key !== "ebbFlowRate" && key !== "dripRate" && key !== "sprayRate") {
                                                currentDataTileConfigList[zoneKey][key + "-" + rootZoneIndex] = {
                                                    ...config,
                                                    is_subzone: true,
                                                    zone_index: rootZoneIndex,
                                                    identifiers: {
                                                        ...config.identifiers,
                                                        ei: config.identifiers.ei + "-" + rootZoneIndex,
                                                        eia: config.identifiers.eia + "-" + rootZoneIndex,
                                                        value: config.identifiers.value + "-" + rootZoneIndex,
                                                        setpoint: config.identifiers.setpoint + "-" + rootZoneIndex
                                                    },
                                                    activeSetpoint: null
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                        } else if (activeBladeZone.zone_type === "grow_boards" || activeBladeZone.zone_type === "berry_troughs") {
                            //Ignore zones
                            let foundActiveSetpointZone = activeSetpoints.find((aS) => aS.type_id === foundSetpointType.id)
                            if (foundActiveSetpointZone !== undefined) {
                                currentDataTileConfigList[zoneKey][key] = { ...config, activeSetpoint: foundActiveSetpointZone.setpoints[0] }
                            } else {
                                if (key !== "ebbFlowRate" && key !== "dripRate" && key !== "sprayRate") {
                                    currentDataTileConfigList[zoneKey][key] = { ...config, activeSetpoint: null }
                                }
                            }
                        }
                    } else {
                        if (activeBladeZone.zone_type === "nursery") {


                        } else if (activeBladeZone.zone_type === "grow_boards" || activeBladeZone.zone_type === "berry_troughs") {
                            //Ignore zones
                            if (key !== "ebbFlowRate" && key !== "dripRate" && key !== "sprayRate") {
                                currentDataTileConfigList[zoneKey][key] = { ...config, activeSetpoint: null }
                            }
                        }
                    }
                }
            }
        }

        let currentTileConfig = {}
        if (activeBladeZone.zone_type === "nursery") {
            //Figure out which water and lighting zones are active
            currentTileConfig = currentDataTileConfigList

        } else if (activeBladeZone.zone_type === "grow_boards" || activeBladeZone.zone_type === "berry_troughs") {
            currentTileConfig = { "all": {} }
            for (let [zoneKey, zoneConfigList] of Object.entries(currentDataTileConfigList)) {
                currentTileConfig["all"] = { ...currentTileConfig["all"], ...zoneConfigList }
            }
        }
        SetDataTileConfig(currentTileConfig)
        //console.log(currentTileConfig)
    }, [currentTimelineItem, recipeSetpointTypes, activeBladeZone, nurseryBoardLocations, activeRecipe])


    React.useEffect(() => {
        if (haveAppInfo && activeGrow.initializedDataRecordingTimePeriodTypes === false) {
            //Validate that the data type has been initialzed for this grow
            dispatch(initializeDataRecordingTimePeriodTypes({ growId: activeGrow.id, dataRecordingTimePeriodTypes: dataRecordingTimePeriodTypes }))
        }
        if (activeGrow.analyticsData !== undefined && activeGrow.analyticsData.timePeriods !== undefined && currentDataRecordingTimePeriodType !== undefined && activeGrow.analyticsData.timePeriods[currentDataRecordingTimePeriodType.index] !== undefined) {
            SetLoadingAnalyticsDataStatus(activeGrow.analyticsData.timePeriods[currentDataRecordingTimePeriodType.index].loadingStatus)
        } else {
            SetLoadingAnalyticsDataStatus("waiting")
        }
    }, [activeGrow, dataRecordingTimePeriodTypes, currentDataRecordingTimePeriodType, haveAppInfo])



    const [haveDataFrom, SetHaveDataFrom] = React.useState(null)
    const [haveDataTo, SetHaveDataTo] = React.useState(null)

    const [loadingAnalyticsDataStatus, SetLoadingAnalyticsDataStatus] = React.useState("waiting")
    const [loadingDataRequestCallback, SetLoadingDataRequestCallback] = React.useState(null)
    const handleDataRequest = () => {
        if (loadingAnalyticsDataStatus !== "pending") {
            SetLoadingDataRequestCallback(null)

            if (activeGrow.analyticsData.timePeriods[currentDataRecordingTimePeriodType.index][selectedZoneUID] !== undefined) {
                let analyticsData = activeGrow.analyticsData.timePeriods[currentDataRecordingTimePeriodType.index][selectedZoneUID]
                //Check if we can make a data request
                if (loadingAnalyticsDataStatus === "idle" || loadingAnalyticsDataStatus === "fulfilled") {
                    let currentDate = new Date().getTime()
                    let toEntryIndex = Math.floor((currentDate / 1000) / (currentDataRecordingTimePeriodType.duration))

                    let currentDataPeriod = { from: currentDate - currentDataRecordingTimePeriodType.duration, to: currentDate }


                    //Check out of these entries which ones we need
                    let requestEntries = {}
                    for (let entryIndex = toEntryIndex - 1; entryIndex <= toEntryIndex; entryIndex++) {
                        if (analyticsData.dataChunks[entryIndex] !== undefined) {
                            if (!analyticsData.dataChunks[entryIndex].completed) {
                                //Lets build a request for the portion of data we don't have
                                let requestRange = { "from": analyticsData.haveDataUpUntil, "to": currentDate }
                                if (requestRange["to"] - requestRange["from"] > 1000) { //minimum request range
                                    requestEntries[entryIndex] = requestRange
                                }
                            }
                        } else {
                            console.log(toEntryIndex, entryIndex, "new")
                            requestEntries[entryIndex] = { "from": currentDate - currentDataRecordingTimePeriodType.duration, "to": currentDate }
                        }
                    }


                    //For now just get data
                    if (Object.keys(requestEntries).length > 0) {
                        //console.log(requestEntries)
                        dispatch(getGrowAnalyticsData({
                            growId: activeGrow.id, dataTimePeriods: {
                                [currentDataRecordingTimePeriodType.index]: [currentDataPeriod]
                            }, entries: {
                                [currentDataRecordingTimePeriodType.index]: {
                                    [selectedZoneUID]: requestEntries
                                }
                            }, timePeriodTypes: dataRecordingTimePeriodTypes, dateOffset: chartOriginDate
                        }))
                    }
                }
            }

        } else {
            if (loadingDataRequestCallback === null) {
                SetLoadingDataRequestCallback(setTimeout(() => { handleDataRequest() }, 5000))
            }
        }
    }

    React.useEffect(() => {
        if (loadingAnalyticsDataStatus === "idle") {
            handleDataRequest()
        } else if (loadingAnalyticsDataStatus === "fulfilled" || loadingAnalyticsDataStatus === "rejected") {
            if (loadingDataRequestCallback === null) {
                SetLoadingDataRequestCallback(setTimeout(() => { handleDataRequest() }, 5000))
            }
        }
    }, [loadingAnalyticsDataStatus, loadingDataRequestCallback])




    let upperRootZoneActive = false
    let lowerRootZoneActive = false
    if (activeBladeZone !== undefined && nurseryBoardLocations !== null && nurseryBoardLocations !== undefined && Object.keys(nurseryBoardLocations).length > 0 && nurseryBoardLocations[activeBladeZone.uid] !== undefined) {
        for (let nurseryRowString of Object.keys(nurseryBoardLocations[activeBladeZone.uid])) {
            const nurseryRow = parseInt(nurseryRowString)
            if (nurseryRow >= 1 && nurseryRow <= 4) {
                upperRootZoneActive = true
            } else if (nurseryRow >= 3 && nurseryRow <= 7) {
                lowerRootZoneActive = true
            }
        }
    }

    return (
        <div className={isMobile ? "FlexContent-30" : "FlexContent-H-10 FlexContent-HFlex"} style={{ padding: "20px 40px" }}>
            <div className="FlexContent-20 FlexContent-HFlex">
                {!isGrowInZones && <>
                    <div className="FlexContent-Center Text-S18">Grow isn't active in any zones yet</div>
                </>}
                {isGrowInZones && <>
                    <div className="FlexContent-5 FlexContent-Center">
                        <DropDownInput
                            value={selectedZoneUID}
                            options={zoneOptions}
                            onSelectionChange={onZoneSelectionChanged} />
                    </div>
                    <div className="FlexContent-30">
                        {Object.entries(dataTileConfig).map(([zoneKey, zoneConfigList]) => {

                            switch (zoneKey) {
                                case "all":
                                    return <>
                                        <div className="FlexWrap FlexWrap-Size2">
                                            {Object.entries(zoneConfigList).map(([key, config]) => {
                                                return <GrowStatusPerformanceTab_DataTile
                                                    key={key}
                                                    dataActive={dataActive}
                                                    zoneUID={selectedZoneUID}
                                                    config={config}
                                                    dataKey={key}
                                                    activeSetpoints={activeSetpoints}
                                                    currentDataRecordingTimePeriodType={currentDataRecordingTimePeriodType}
                                                    grow={activeGrow}
                                                    chartOriginDate={chartOriginDate} />
                                            })}
                                        </div>
                                    </>
                                case "air":
                                    return <>
                                        <div className="FlexContent-10">
                                            <div className="Text-H14">Air Zone</div>
                                            <div className="FlexWrap FlexWrap-Size2">
                                                {Object.entries(zoneConfigList).map(([key, config]) => {
                                                    return <GrowStatusPerformanceTab_DataTile
                                                        key={key}
                                                        dataActive={dataActive}
                                                        zoneUID={selectedZoneUID}
                                                        config={config}
                                                        dataKey={key}
                                                        activeSetpoints={activeSetpoints}
                                                        currentDataRecordingTimePeriodType={currentDataRecordingTimePeriodType}
                                                        grow={activeGrow}
                                                        chartOriginDate={chartOriginDate} />
                                                })}
                                            </div>
                                        </div>
                                    </>
                                case "root":
                                    return <>
                                        <div className="FlexContent-10">
                                            {activeBladeZone.zone_type === "nursery" && <>
                                                {upperRootZoneActive && <>
                                                    <div className="Text-H14">Upper Root Zone</div>
                                                    <div className="FlexWrap FlexWrap-Size2">
                                                        {Object.entries(zoneConfigList).filter(([key, config]) => config.zone_index == 1).map(([key, config]) => {
                                                            return <GrowStatusPerformanceTab_DataTile
                                                                key={key}
                                                                dataActive={dataActive}
                                                                zoneUID={selectedZoneUID}
                                                                config={config}
                                                                dataKey={key}
                                                                activeSetpoints={activeSetpoints}
                                                                currentDataRecordingTimePeriodType={currentDataRecordingTimePeriodType}
                                                                grow={activeGrow}
                                                                chartOriginDate={chartOriginDate} />
                                                        })}
                                                    </div>
                                                </>}
                                                {lowerRootZoneActive && <>
                                                    <div className="Text-H14">Lower Root Zone</div>
                                                    <div className="FlexWrap FlexWrap-Size2">
                                                        {Object.entries(zoneConfigList).filter(([key, config]) => config.zone_index == 2).map(([key, config]) => {
                                                            return <GrowStatusPerformanceTab_DataTile
                                                                key={key}
                                                                dataActive={dataActive}
                                                                zoneUID={selectedZoneUID}
                                                                config={config}
                                                                dataKey={key}
                                                                activeSetpoints={activeSetpoints}
                                                                currentDataRecordingTimePeriodType={currentDataRecordingTimePeriodType}
                                                                grow={activeGrow}
                                                                chartOriginDate={chartOriginDate} />
                                                        })}
                                                    </div>
                                                </>}
                                            </>}
                                            {activeBladeZone.zone_type !== "nursery" && <>
                                                <div className="Text-H14">Root Zone</div>
                                                <div className="FlexWrap FlexWrap-Size2">
                                                    {Object.entries(zoneConfigList).map(([key, config]) => {
                                                        return <GrowStatusPerformanceTab_DataTile
                                                            key={key}
                                                            dataActive={dataActive}
                                                            zoneUID={selectedZoneUID}
                                                            config={config}
                                                            dataKey={key}
                                                            activeSetpoints={activeSetpoints}
                                                            currentDataRecordingTimePeriodType={currentDataRecordingTimePeriodType}
                                                            grow={activeGrow}
                                                            chartOriginDate={chartOriginDate} />
                                                    })}
                                                </div>
                                            </>}
                                        </div>
                                    </>

                                case "lights":
                                    return <>
                                        <div className="FlexContent-10">
                                            <div className="Text-H14">Lighting Zones</div>
                                            <div className="FlexWrap FlexWrap-Size2">
                                                {Object.entries(zoneConfigList).map(([key, config]) => {
                                                    return <GrowStatusPerformanceTab_DataTile
                                                        key={key}
                                                        dataActive={dataActive}
                                                        zoneUID={selectedZoneUID}
                                                        config={config}
                                                        dataKey={key}
                                                        activeSetpoints={activeSetpoints}
                                                        currentDataRecordingTimePeriodType={currentDataRecordingTimePeriodType}
                                                        grow={activeGrow}
                                                        chartOriginDate={chartOriginDate} />
                                                })}
                                            </div>
                                        </div>
                                    </>
                                default:
                                    break
                            }
                        })}

                    </div>
                </>}
            </div>
        </div>
    )
}

GrowStatus_PerformanceContent.defaultProps = {

}


export default GrowStatus_PerformanceContent














const dataChartTheme = makeFlatTheme({
    isDark: false,
    fontFamily: 'Segoe UI, -apple-system, Verdana, Helvetica',
    backgroundColor: ColorHEX("#F7F8FBFF"),
    textColor: ColorHEX('#191C22FF'),
    dataColors: [ColorHEX('#ffff5b'), ColorHEX('#ffcd5b'), ColorHEX('#ff9b5b')],
    axisColor: ColorHEX('#858585FF'),
    gridLineColor: ColorHEX('#303030ff'),
    uiBackgroundColor: ColorRGBA(255, 255, 255, 0),
    uiBorderColor: ColorRGBA(255, 255, 255, 0),
    dashboardSplitterColor: ColorRGBA(255, 255, 255, 0),
})



const GrowStatusPerformanceTab_DataTile = ({ dataActive, zoneUID, config, dataKey, currentDataRecordingTimePeriodType, grow, chartOriginDate }) => {

    const dispatch = useDispatch()
    const haveAppInfo = useSelector((state) => state.appInfo.haveAppInfo)
    const recipeSetpointTypes = useSelector(selectAllRecipeSetpointTypes)
    const currentSetpointType = recipeSetpointTypes.find((rST) => rST.key == "")


    useRenderingTrace("DataTile", [dataActive, zoneUID, config, dataKey, currentDataRecordingTimePeriodType, grow, chartOriginDate])

    const [axisOffset, SetAxisOffset] = React.useState(config.baseAxisOffset)

    const [currentMaxValue, SetCurrentMaxValue] = React.useState(false)
    const [currentMinValue, SetCurrentMinValue] = React.useState(false)


    const [currentSetpointValue, SetCurrentSetpointValue] = React.useState(null)
    React.useEffect(() => {
        handleYAxisRangeUpdate()
    }, [currentSetpointValue, axisOffset])

    const chartRef = React.useRef({});


    React.useEffect(() => {
        if (currentMaxValue !== false && currentMinValue !== false) {

            if (grow.liveData[zoneUID][config.identifiers.setpoint] !== undefined) {
                let currentSetpoint = parseFloat(grow.liveData[zoneUID][config.identifiers.setpoint].value)

                let maxOffsetFromSetpoint = Math.max(currentMaxValue - currentSetpoint, currentSetpoint - currentMinValue, config.minAxisOffset)
                if (maxOffsetFromSetpoint > config.maxAxisOffset) {
                    maxOffsetFromSetpoint = config.maxAxisOffset
                }

                SetAxisOffset(maxOffsetFromSetpoint)
            } else {
                SetAxisOffset(config.baseAxisOffset)
            }
        } else {
            SetAxisOffset(config.baseAxisOffset)
        }



    }, [currentMaxValue, currentMinValue])



    const handleYAxisRangeUpdate = () => {
        if (chartRef.current) {
            if (chartRef.current.yAxis !== undefined) {
                chartRef.current.yAxisRange = { start: currentSetpointValue - axisOffset, end: currentSetpointValue + axisOffset }
                chartRef.current.yAxis.setInterval(chartRef.current.yAxisRange)
            }
        }
    }
    React.useEffect(() => {
        handleYAxisRangeUpdate()
    }, [chartRef, currentSetpointValue, axisOffset])

    const chartLoop = () => {
        if (chartRef.current) {
            if (config.activeSetpoint !== null) {
                if (grow.analyticsData.timePeriods[currentDataRecordingTimePeriodType.index] !== undefined) {

                    let currentData = grow.analyticsData.timePeriods[currentDataRecordingTimePeriodType.index].data[config.identifiers.value]
                    let actualPointData = []
                    if (currentData !== undefined && currentData.length > 1) {
                        //console.log(dataKey, currentData)
                        const maxValue = currentData.reduce(function (prev, current) {
                            return (prev && prev.y > current.y) ? prev : current
                        })
                        const minValue = currentData.reduce(function (prev, current) {
                            return (prev && prev.y < current.y) ? prev : current
                        })

                        SetCurrentMaxValue(maxValue.y)
                        SetCurrentMaxValue(minValue.y)

                        actualPointData = [...currentData, {
                            x: new Date().getTime() - chartOriginDate,
                            y: currentData[currentData.length - 1].y
                        }]
                    } else {
                        SetCurrentMaxValue(false)
                        SetCurrentMinValue(false)
                    }
                    //console.log(actualPointData)
                    //console.log(chartRef.current.actualDataSeries, actualPointData)
                    chartRef.current.actualDataSeries.clear().add(actualPointData)

                    let setpointData = grow.analyticsData.timePeriods[currentDataRecordingTimePeriodType.index].data[config.identifiers.setpoint]
                    let setpointPointData = []
                    if (setpointData !== undefined && setpointData.length > 1) {
                        setpointPointData = [...setpointData, {
                            x: new Date().getTime() - chartOriginDate,
                            y: setpointData[setpointData.length - 1].y
                        }]
                    }
                    // console.log(setpointPointData)
                    chartRef.current.setpointDataSeries.clear().add(setpointPointData)



                } else {
                    SetCurrentMaxValue(false)
                    SetCurrentMinValue(false)
                }


                if (Object.entries(grow.liveData[zoneUID]).length > 0) {
                    if (grow.liveData[zoneUID][config.identifiers.setpoint] !== undefined) {
                        SetCurrentSetpointValue(parseFloat(grow.liveData[zoneUID][config.identifiers.setpoint].value))
                    }
                }


            }


        }

    }


    const chartAnimationFrameRef = React.useRef();
    React.useEffect(() => {
        /*if (dataActive) {
            const handleChartRender = () => {
                chartLoop()
                chartAnimationFrameRef.current = requestAnimationFrame(handleChartRender);
            }
            chartAnimationFrameRef.current = requestAnimationFrame(handleChartRender);
        }
        return () => {
            cancelAnimationFrame(chartAnimationFrameRef.current);
        }*/
        if (dataActive) {
            chartLoop()
        }
    }, [haveAppInfo, chartRef, dataActive, grow]);







    const [chartReady, SetChartReady] = React.useState(false)


    const chartContainerRef = React.useRef(null);
    React.useEffect(() => {
        if (config.activeSetpoint === null || grow.liveData[zoneUID] === undefined) {
            return
        }
        const lc = createLightningChart()
        if (!chartContainerRef.current || !chartContainerRef.current || !lc) {
            return
        }

        const dataChart = lc.ChartXY({
            container: "GrowStatus-DataTile-DataChart-" + dataKey,
            theme: dataChartTheme,
        }).setMouseInteractionRectangleZoom(false)
            .setMouseInteractionRectangleFit(false)
            .setMouseInteractionWheelZoom(false)
            .setTitle("")
            .setPadding({ top: 0, left: 0, right: 0, bottom: 0 })
            .setCursorMode(undefined)
            .setBackgroundFillStyle(new SolidFill({ color: ColorHEX("#F7F8FBFF") }))
            .setSeriesBackgroundFillStyle(new SolidFill({ color: ColorHEX("#F7F8FBFF") }))
            .setSeriesBackgroundStrokeStyle(emptyLine)


        let currentDate = new Date().getTime()

        dataChart.getDefaultAxisY()
            .setMouseInteractions(false)
            .setStrokeStyle(emptyLine)
            .setThickness(0)
            .setTickStrategy(AxisTickStrategies.Empty)

        const defaultChartInterval = { start: (currentDate - (1000 * 60 * 60)) - chartOriginDate, end: currentDate - chartOriginDate }
        const dataChartDateAxis = dataChart.getDefaultAxisX()
        dataChartDateAxis.setTickStrategy(
            AxisTickStrategies.Empty,
            //(tickStrategy) => tickStrategy.setDateOrigin(new Date(chartOriginDate)),
        )
            .setAnimationsEnabled(false)
            .setChartInteractionPanByDrag(false)
            .setChartInteractionZoomByWheel(false)
            .setNibInteractionScaleByWheeling(false)
            .setInterval({ start: defaultChartInterval.start, end: defaultChartInterval.end })
            .setScrollStrategy(undefined)
            .setThickness(0)

        let lastChartInterval = { start: 0, end: 0 }

        const yAxis = dataChart.addAxisY()
        let yAxisRange = { start: 0, end: 100 }
        yAxis.setInterval(yAxisRange)
            .setMouseInteractions(false)
            .setTickStrategy(AxisTickStrategies.Empty)
            .setScrollStrategy(undefined)
            .setStrokeStyle(emptyLine)
            .onIntervalChange((axis, start, end) => {
                if (start !== yAxisRange.start || end !== yAxisRange.end) {
                    //axis.setInterval(yAxisRange)
                }
            })

        const setpointDataSeries = dataChart.addLineSeries({
            dataPattern: { pattern: "ProgressiveX" },
            yAxis: yAxis
        }).setStrokeStyle(new SolidLine({ thickness: 2, fillStyle: new SolidFill({ color: ColorHEX(config.chartSetpointColor) }) }))
            //}).setStrokeStyle(new SolidLine({ thickness: 0.5, color: ColorRGBA(lineColor)}))
            .setMouseInteractions(false)
            .setEffect(false)


        const actualDataSeries = dataChart.addLineSeries({
            dataPattern: { pattern: "ProgressiveX" },
            yAxis: yAxis
        }).setStrokeStyle(new SolidLine({ thickness: 0.5, fillStyle: new SolidFill({ color: ColorHEX(config.chartActualColor) }) }))
            //}).setStrokeStyle(new SolidLine({ thickness: 0.5, color: ColorRGBA(lineColor)}))
            .setMouseInteractions(false)
            .setEffect(false)

        chartRef.current = {
            dataChart,
            dataChartDateAxis,
            lastChartInterval,

            yAxis,
            yAxisRange,
            dataSeries: {},
            actualDataSeries,
            setpointDataSeries,

            lastRenderLoopCompletedOn: 0,
            lastRequestLoopCompletedOn: 0
        }
        SetChartReady(true)
        handleYAxisRangeUpdate()
        chartLoop()

        return () => {
            dataChart.dispose()
            chartRef.current = undefined
        }
    }, [grow, dataChartTheme, config])







    if (grow.liveData[zoneUID] !== undefined && Object.entries(grow.liveData[zoneUID]).length > 0) {

        const scoreColors = {
            good: { min: 75, max: 100, color: "#2E72D2", barBackground: "#E4EAF5", barFill: "#2E72D2", barBorder: "#2E72D2", tileBorder: "#DDE3EB" },
            /*operational: {min: 75, max:90, color: "#2E72D2", barFill: "#E4EAF5", barBorder: "#2E72D2"},*/
            critical: { min: 50, max: 75, color: "#F0B61F", barBackground: "#F8EBD2", barFill: "#E99E0B", barBorder: "#F0B61F", tileBorder: "#E99E0B" },
            fail: { min: 0, max: 50, color: "#EC3C3C", barBackground: "#F5E4E4", barFill: "#EC3C3C", barBorder: "#EC3C3C", tileBorder: "#EC3C3C" },
            neutral: { color: "#2E72D2", barFill: "#E4EAF5", barBorder: "#2E72D2", tileBorder: "#DDE3EB" },
        }


        const limitPercentage = (p) => p < 0 ? 0 : p > 100 ? 100 : p



        let currentSetpoint = "-"
        let currentValue = grow.liveData[zoneUID][config.identifiers.value] !== undefined ? parseFloat(grow.liveData[zoneUID][config.identifiers.value].value) : "-"
        let currentValueError = 0
        let currentValueErrorDirection = false

        if (config.activeSetpoint !== null) {
            if (grow.liveData[zoneUID][config.identifiers.setpoint] !== undefined) {
                currentSetpoint = parseFloat(grow.liveData[zoneUID][config.identifiers.setpoint].value)
                currentValueError = RoundToNearest(currentValue - currentSetpoint, config.resolution)
                currentValueErrorDirection = currentValue > currentSetpoint
            }

        }


        let scorePercentage = 0
        let trailingPercentage = 0
        //console.log(config)
        if (dataKey.indexOf("light_intensity") !== -1) {
            scorePercentage = (Math.round((currentValue / currentSetpoint) * 10000) / 100).toFixed(1)

            //is_subzone
            //zone_index
        } else {
            scorePercentage = grow.liveData[zoneUID][config.identifiers.ei] !== undefined ? limitPercentage(100 - parseFloat(grow.liveData[zoneUID][config.identifiers.ei].value)) : 100
            trailingPercentage = grow.liveData[zoneUID][config.identifiers.eia] !== undefined ? limitPercentage(100 - parseFloat(grow.liveData[zoneUID][config.identifiers.eia].value)) : 100
        }
        if (scorePercentage < 0)    {
            scorePercentage = 0
        }else if (scorePercentage > 100)    {
            scorePercentage = 100
        }else if (isNaN(scorePercentage))   {
            scorePercentage = 0
        }
        //console.log(scorePercentage, scoreColors)
        let scoreColor = Object.values(scoreColors).find((s) => s.min <= scorePercentage && s.max >= scorePercentage)










        let displayName = config.displayName
        if (config.is_subzone && dataKey.indexOf("light_intensity") !== -1) {
            displayName = config.displayName + " (Z" + config.zone_index.toString() + ")"
        }




        return (<div className="FlexWrap-Item">
            <div className="GrowStatus-DataTile-Container" style={{ borderColor: scoreColor.tileBorder }}>
                <div className="GrowStatus-DataTile-Info-Container">
                    <div className="GrowStatus-DataTile-Info-Header">
                        <div className="GrowStatus-DataTile-Info-Title">
                            <span>{displayName}</span>
                        </div>
                        <div className="GrowStatus-DataTile-Info-Score">
                            {config.activeSetpoint !== null && <span style={{ color: scoreColor.color }}>{scorePercentage}%</span>}
                            {config.activeSetpoint === null && <>-</>}
                        </div>
                    </div>
                </div>
                <div className="GrowStatus-DataTile-LiveStat-Container">
                    <div className="GrowStatus-DataTile-LiveStat-Current">
                        <div>Current</div>
                        <div>
                            <div>{currentValue}</div>
                            <div>{config.suffix !== undefined && <>{config.suffix}</>}</div>
                        </div>
                        <div style={{ color: scoreColor.color }}>
                            {config.activeSetpoint !== null && <>({currentValueErrorDirection === true && <>+</>}{currentValueError})</>}
                        </div>
                    </div>
                    <div className="GrowStatus-DataTile-LiveStat-Setpoint">
                        <div>Setpoint</div>
                        <div>
                            <div>{currentSetpoint}</div>
                            <div>{config.suffix !== undefined && <>{config.suffix}</>}</div>
                        </div>
                    </div>
                </div>
                <div className="GrowStatus-DataTile-ScoreVisualization-Container">
                    <div className="GrowStatus-DataTile-ScoreVisualization-BarBackground" style={{ outlineColor: scoreColor.barBorder, backgroundColor: scoreColor.barBackground }}>
                        {config.activeSetpoint !== null && <>{(() => {
                            let currentXPosition = Math.abs(currentValueError) / axisOffset * 100
                            if (currentXPosition < 0) {
                                currentXPosition = 0
                            } else if (currentXPosition > 100) {
                                currentXPosition = 100
                            }
                            let barProps = { style: { backgroundColor: scoreColor.barFill } }
                            if (currentValueError < 0) {
                                barProps.style.right = "50%"
                                barProps.style.width = currentXPosition
                                barProps.style.borderTopLeftRadius = 2
                                barProps.style.borderBottomLeftRadius = 2
                            } else {
                                barProps.style.left = "50%"
                                barProps.style.width = currentXPosition
                                barProps.style.borderTopRightRadius = 2
                                barProps.style.borderBottomRightRadius = 2
                            }

                            return <>
                                <div className="GrowStatus-DataTile-ScoreVisualization-Bar" {...barProps}></div>

                            </>
                        })()}</>}
                    </div>
                    {config.activeSetpoint !== null &&
                        <div className="GrowStatus-DataTile-ScoreVisualization-SetpointLine"></div>
                    }
                </div>
                <div className="GrowStatus-DataTile-DataChart-Container">
                    {config.activeSetpoint !== null &&
                        <div id={"GrowStatus-DataTile-DataChart-" + dataKey} style={{ width: "100%", height: "100%" }} ref={chartContainerRef}></div>
                    }
                    {config.activeSetpoint === null &&
                        <div className="GrowStatus-DataTile-DataChart-Empty"></div>
                    }
                </div>
            </div>
        </div>)
    } else {
        return (<div className="FlexWrap-Item">
            No Data
        </div>)
    }
}