import './FarmsPage.scss';

import React from 'react'
import { Routes, Route, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import {TabControl, TabControlTab} from '../../components/TabControl.js';
import DropDownInput from '../../components/input/DropDownInput.js'
import Button from '../../components/Button.js'

import ExpensesPage from '../Expenses/ExpensesPage';
import RevenuePage from '../Revenue/RevenuePage';
import InventoryPage from '../Inventory/InventoryPage';
import RecordsPage from '../Records/RecordsPage';
import ServicesPage from '../Services/ServicesPage';
import { getAllFacilities, getAllServicesForFacility, InitialLoadAllFacilities, selectAllFarmFacilities } from '../../redux/entities/Facilities.js';
import { useDispatch, useSelector } from 'react-redux';
import BladeInstancesPage from '../Services/GrowRacks/BladeInstancesPage';
import { getBladeByServiceId, selectAllBladeServiceIds, selectAllBlades } from '../../redux/entities/service/Blade';
import { getFlowByServiceId, selectAllFlow } from '../../redux/entities/service/Flow';
import { getHVACByServiceId, selectAllHVAC } from '../../redux/entities/service/HVAC';
import HVACsPage from '../Services/HVACs/HVACsPage';
import FlowsPage from '../Services/Flows/FlowsPage';
import { IsSessionActive } from '../Account/Common.js';
import GrowZonesPage from '../GrowZones/GrowZonesPage.js';
import { useRenderingTrace } from '../../helpers.js';
import GerminationStationsPage from '../Services/GerminationStations/GerminationStationsPage.js';


const FarmsPage = ({pageHeaderHelper}) => {
 

  const navigate = useNavigate()
  const location = useLocation()

  const { farmID, mainTabKey } = useParams();

  const allFarms = useSelector(selectAllFarmFacilities)

  console.log(mainTabKey, farmID)
  

  
  const [availableTabs, SetAvailableTabs] = React.useState([])


  const selectFarm = (newFarmId) => {
    console.log(newFarmId)
    navigate("/farms/" + newFarmId.toString())
  }
  React.useLayoutEffect(() => {
    if ((farmID === undefined || isNaN(farmID.toString())) && allFarms.length > 0) {
      selectFarm(allFarms[0].id)
    }
  }, [farmID, allFarms])


  const selectMainTab = (tabPath) => {
    navigate("/farms/" + farmID.toString() + "/" + tabPath + "/")
  }
  React.useLayoutEffect(() => {
    const splitLocation = location.pathname.split("/");
    if (splitLocation.length === 3 && availableTabs.length > 0) {
      selectMainTab(availableTabs[0].path)
    }
  }, [farmID, location, availableTabs])




  const [facilitySelectionOptions, SetFacilitySelectionOptions] = React.useState([]);
  React.useLayoutEffect(() => {
    //Check location to see if we are in services, if so we need to limit the options to not include all farms
    let currentFacilitySelectionOptions = []
    for (let facility of allFarms)  {
      currentFacilitySelectionOptions.push({
        value: facility.id,
        label: facility.name
      })
    }
    SetFacilitySelectionOptions(currentFacilitySelectionOptions)
  }, [allFarms])



  React.useLayoutEffect(() => {
    if (farmID === undefined) {
      SetAvailableTabs([])
      return
    }
    SetAvailableTabs([
      new TabControlTab("Grow Zone", "grow_zone"),
      new TabControlTab("Blade", "blade"),
      new TabControlTab("Flow", "flow"),
      new TabControlTab("Germ", "germ"),
      new TabControlTab("HVAC", "hvac")
    ])
  }, [farmID, allFarms])
  
  
  const [pageHeaderRightSideContent, SetPageHeaderRightSideContent] = React.useState(<></>)
  const setPageHeader = () => {
    pageHeaderHelper.current.Reset()
    pageHeaderHelper.current.SetTitle("Farms")
    pageHeaderHelper.current.SetHeaderTabControlActivePathIndex(3);
    pageHeaderHelper.current.SetTabControlTabs(availableTabs);
    pageHeaderHelper.current.SetTabControlTabClickCallback((tab) => {
      navigate("/farms/" + farmID + "/" + tab.path + "/")
    });

    pageHeaderHelper.current.SetRightSideContent(() => {
      return (
        <>
          <div className="ControlBar_Horizontal">
            <div className="ControlBar_Horizontal-Right">
              {pageHeaderRightSideContent}
              <DropDownInput placeholder={"Select Facility"} options={facilitySelectionOptions} value={parseInt(farmID)} onSelectionChange={selectFarm}/>
              {/*<Button content={"Create Farm"}/>*/}
            </div>
          </div>
        </>
      )
    })
    
    pageHeaderHelper.current.SubPageUpdateHeader()

  }
  React.useLayoutEffect(() => {
    setPageHeader()
  }, [])
  React.useLayoutEffect(() => {
    setPageHeader()
  }, [location, facilitySelectionOptions, farmID, availableTabs])

  React.useLayoutEffect(() => {
    setPageHeader()
  }, [pageHeaderRightSideContent])


  const SetRightSideContent = (content) =>  {
    SetPageHeaderRightSideContent(content)
  }




  return (<>
    <InitialLoadAllFacilities/>
    {/*<Routes>
      <Route exact path=":mainTabKey">
        <Route path=":farmID"/>
      </Route>


      <Route exact path="/" element={<Navigate to="/grow_zone"/>} />
    </Routes>*/}
    <div id="FarmsPage">
      
      <Routes>
        <Route path="/grow_zone/*" element={<GrowZonesPage setRightSideContent={(content) => SetRightSideContent(content)} selectedFacility={parseInt(farmID)}/>}>

        </Route>
        <Route path="/blade/*">
          <Route exact path=":focusedBladeID" element={<BladeInstancesPage setRightSideContent={(content) => SetRightSideContent(content)} selectedFacility={parseInt(farmID)}/>}/>
          <Route exact path=":focusedBladeID/*" element={<BladeInstancesPage setRightSideContent={(content) => SetRightSideContent(content)} selectedFacility={parseInt(farmID)}/>}/>
          <Route path="*" element={<BladeInstancesPage setRightSideContent={(content) => SetRightSideContent(content)} selectedFacility={parseInt(farmID)}/>} />
        </Route>
        <Route path="/flow/*" element={<FlowsPage setRightSideContent={(content) => SetRightSideContent(content)} selectedFacility={parseInt(farmID)}/>}>

        </Route>
        <Route path="/germ/*" element={<GerminationStationsPage setRightSideContent={(content) => SetRightSideContent(content)} selectedFacility={parseInt(farmID)}/>}>

        </Route>
        <Route path="/hvac/*" element={<HVACsPage setRightSideContent={(content) => SetRightSideContent(content)} selectedFacility={parseInt(farmID)}/>}>

        </Route>


        <Route default element={<Navigate to="/"/>} />
      </Routes>

  

    </div>
  </>)
} 

export default FarmsPage