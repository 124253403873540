import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import  {Repository as APIRepository, FetchPost} from '../../api'
import { getAccountSessionData } from '../../../pages/Account/Common'




const processControlDeviceFromAPI = (state, controlDevice) =>  {
  return controlDevice
}


export const getAllControlDevicesByFacilityId = createAsyncThunk('controlDevice/getAllControlDevicesByFacilityId', async ({facilityId}, { getState }) => {
  return await FetchPost(APIRepository.ControlDevice.GetAllControlDevicesByFacilityId, {
    ...getAccountSessionData(getState()),
    facility_id: facilityId,
  }) 
}, {
  condition: (args, { getState }) => {
    const { controlDevice } = getState()
    if (controlDevice.loadingControlDevicesStatus === 'pending') {
      return false
    }
  },
})



export const createNewControlDevice = createAsyncThunk('controlDevice/createNewControlDevice', async ({accountId, facilityId, controlDeviceInfo, callback}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    facility_id: facilityId,
    control_device_info: controlDeviceInfo,
  }

  return await FetchPost(APIRepository.ControlDevice.CreateNewControlDevice, payload)  
},
{
  condition: (args, { getState }) => {
    const { controlDevice } = getState()
    if (controlDevice.creatingNewControlDevice === 'pending') {
      return false
    }
  },
})




export const performControlDeviceSoftwareReset = createAsyncThunk('controlDevice/performControlDeviceSoftwareReset', async ({controlDeviceUID}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    control_device_uid: controlDeviceUID,
  }

  return await FetchPost(APIRepository.ControlDevice.PerformControlDeviceSoftwareReset, payload)  
},
{
  condition: (args, { getState }) => {
    const { controlDevice } = getState()
    if (controlDevice.performingControlDeviceSoftReset === 'pending') {
      return false
    }
  },
})




export const controlDevicesSlice = createSlice({
  name: 'controlDevice',
  initialState: {
    controlDevices:  [

    ],
    status: 'idle',
    loadingControlDevicesStatus: 'idle',
    haveControlDevicesForFacility: {},
    creatingNewControlDevice: 'idle',
    performingControlDeviceSoftResetStatus: 'idle',
    error: null,
    haveInitialData: false,
    loadingData: false,
  },
  reducers: {
    
  },
  extraReducers: {
    [getAllControlDevicesByFacilityId.pending]: (state) => {
      state.loadingControlDevicesStatus = 'pending';
    },
    [getAllControlDevicesByFacilityId.fulfilled]: (state, action) => {
      state.loadingControlDevicesStatus = 'fulfilled';
      state.haveControlDevicesForFacility[action.meta.arg.facilityId] = true
      
      state.controlDevices = action.payload.control_devices.map(function(controlDevice){ processControlDeviceFromAPI(state, controlDevice); return controlDevice });
    },
    [getAllControlDevicesByFacilityId.rejected]: (state) => {
      state.loadingControlDevicesStatus = 'rejected';
    },

    [createNewControlDevice.pending]: (state) => {
      state.creatingNewControlDevice = 'pending';
    },
    [createNewControlDevice.fulfilled]: (state, action) => {
      state.creatingNewControlDevice = 'fulfilled';
      if (action.payload.control_device) {
        state.controlDevices.push(processControlDeviceFromAPI(state, action.payload.control_device))
        if (action.meta.arg.callback) {
          action.meta.arg.callback(true)
        }
      } else {
        if (action.meta.arg.callback) {
          action.meta.arg.callback(false)
        }  
      }
    },
    [createNewControlDevice.rejected]: (state, action) => {
      state.creatingNewControlDevice = 'rejected';
      if (action.meta.arg.callback) {
        action.meta.arg.callback(false)
      }
    },


    
    [performControlDeviceSoftwareReset.pending]: (state) => {
      state.performingControlDeviceSoftResetStatus = 'pending';
    },
    [performControlDeviceSoftwareReset.fulfilled]: (state, action) => {
      state.performingControlDeviceSoftResetStatus = 'fulfilled';
      if (action.payload.success) {
        if (action.meta.arg.callback) {
          action.meta.arg.callback(true)
        }
      }else {
        if (action.meta.arg.callback) {
          action.meta.arg.callback(false)
        }
      }
    },
    [performControlDeviceSoftwareReset.rejected]: (state, action) => {
      state.performingControlDeviceSoftResetStatus = 'rejected';
      if (action.meta.arg.callback) {
        action.meta.arg.callback(false)
      }
    },
  }
})



// Action creators are generated for each case reducer function
export const { } = controlDevicesSlice.actions

export default controlDevicesSlice.reducer

export const selectAllControlDevices = state => state.controlDevice.controlDevices


export const selectAllBladeControlDevices = createSelector(
  (state) => state.controlDevice,
  (controlDevice) => controlDevice.controlDevices.filter((cD) => cD.type === "blade"),
)