import './GrowZonesPage.scss';
import React from 'react'

import _ from 'underscore';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllBladeGroupsByFacilityId, getBladeZoneByUID, getBladeZoneConfigurationMap, markGrowZoneForCleanse, selectAllBlades, selectAllBladeZoneConfigurationMaps, setBladeRuntimeProperty, setBladeZoneRuntimeProperty, MaintainBladeZoneStatus, MaintainBladeLiveData, selectBladeZoneDisplayNameByUID, selectAllBladeUIDs, getBladeByUID, MaintainBladeStatus, selectBladeUIDsForBladeZoneUID, selectBladeRuntimeInformationForBladeZoneUID, selectBladeDisplayNamesForBladeZoneUID, selectBladeZonePauseState, pauseZone, selectBladeControlDevicesForBladeZoneUID, selectBladeZoneTypeByUID, selectBladeZoneEventsByUID, resumeZone, selectBladeZoneRuntimeInformationByUID, selectBladeIdsForBladeZoneUID, selectBladeZoneRuntimeInformation, selectBladeActiveStatesForBladeZoneUID, pauseBladeFeature, selectBladeActiveCleanseTaskIdsForBladeZoneUID, selectBladeActiveCleanseTaskIds, selectBladeZoneActiveGrowIds, selectBladeZoneActiveRecipeId } from '../../redux/entities/service/Blade';
import RowGroupRow from '../../components/RowGroup/RowGroupRow';
import Button from '../../components/Button';
import { AirSetpoint, LightingSetpoint, LowerWaterSetpoint, Pause, Play, PlusAlt, RSSI, UpperWaterSetpoint, WaterSetpoint } from '../../assets/icons/Icons';
import { useMediaQuery } from 'react-responsive';
import DropDownInput from '../../components/input/DropDownInput';
import TextInput from '../../components/input/TextInput';
import { FormatDate, FormatDurationToShortString, parseBool, useRenderingTrace } from '../../helpers';
import GrowZone_DataAnalytics from './GrowZone_DataAnalytics';
import { TabControl, TabControlTab } from '../../components/TabControl';
import GrowZone_StatusContent from './GrowZone_StatusContent';
import { MaintainTasksById } from '../../redux/entities/Tasks';
import Badge from '../../components/Badge';
import Tag from '../../components/Tag';
import Pill from '../../components/Pill';








const GrowZonesPage_Zone = ({ selectedFacility, zoneUID, onViewingTask }) => {

  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  /* React useEffects */
  const navigate = useNavigate()
  const dispatch = useDispatch()

  /* State */
  const [dataActive, SetDataActive] = React.useState(false)
  const [subtitle, SetSubTitle] = React.useState(<></>)
  const [zoneCleanState, SetZoneCleanState] = React.useState("unknown")

  /* Data */
  const bladeZoneDisplayName = useSelector((state) => selectBladeZoneDisplayNameByUID(state, zoneUID), _.isEqual)
  const bladeZoneType = useSelector((state) => selectBladeZoneTypeByUID(state, zoneUID), _.isEqual)
  const bladeZoneEvents = useSelector((state) => selectBladeZoneEventsByUID(state, zoneUID), _.isEqual)
 

  //const zoneActiveState = useSelector((state) => selectBladeZoneRuntimeInformation(state, zoneUID, ))

  /*const isZonePaused = zoneActiveState["paused"] !== undefined ? zoneActiveState["paused"] : false
  const isZonePausing = zoneActiveState["pausing"] !== undefined ? zoneActiveState["pausing"] : false
  const isZoneResuming = zoneActiveState["resuming"] !== undefined ? zoneActiveState["resuming"] : false*/

  const activeBladeUIDs = useSelector((state) => selectBladeUIDsForBladeZoneUID(state, zoneUID), _.isEqual)
  const bladeIds = useSelector((state) => selectBladeIdsForBladeZoneUID(state, zoneUID), _.isEqual)
  const bladeDisplayNames = useSelector((state) => selectBladeDisplayNamesForBladeZoneUID(state, zoneUID), _.isEqual)
  const bladeRuntimeInformation = useSelector((state) => selectBladeRuntimeInformationForBladeZoneUID(state, zoneUID), _.isEqual)
  const bladeControlDevices = useSelector((state) => selectBladeControlDevicesForBladeZoneUID(state, zoneUID), _.isEqual)
  const bladeActiveCleanseTaskIds = useSelector((state) => selectBladeActiveCleanseTaskIds(state, bladeZoneType === "nursery" ? (bladeIds["nursery"] ?? null) : (bladeZoneType === "grow_boards" || bladeZoneType === "berry_troughs") ? (bladeIds["grow"] ?? null) : null, _.isEqual))
  const activeBladeZoneGrowIds = useSelector((state) => selectBladeZoneActiveGrowIds(state, zoneUID), _.isEqual)
  const bladeActiveStates = useSelector((state) => selectBladeActiveStatesForBladeZoneUID(state, zoneUID), _.isEqual)
  const isZonePaused = Object.values(bladeActiveStates).find((s) => s !== "active") !== undefined
  const [bladeZoneResumingOn] = useSelector((state) => selectBladeZoneRuntimeInformation(state, zoneUID, "resume_controls_on"), _.isEqual)
  const activeRecipeId = useSelector((state) => selectBladeZoneActiveRecipeId(state, zoneUID), _.isEqual)
  const isZoneOnline = true
  const [zoneIssues, SetZoneIssues] = React.useState([])
  React.useEffect(() => {
    let currentZoneIssues = []
    for (let [bladeType, bladeControlDevice] of Object.entries(bladeControlDevices)) {
      if (bladeControlDevice !== undefined && bladeControlDevice !== null) {
        if (!bladeControlDevice.connected) {
          if (bladeType === "nursery") {
            currentZoneIssues.push({ key: "blade_offline" })
          } else if (bladeType === "grow") {
            currentZoneIssues.push({ key: "grow_blade_offline" })
          } else if (bladeType === "environment") {
            currentZoneIssues.push({ key: "environment_blade_offline" })
          }
        }
      }
    }
    SetZoneIssues(currentZoneIssues)
  }, [bladeControlDevices])




  /* Interaction */

  const defaultPauseDuration = 6000
  const [pendingBladeToPause, SetPendingBladeToPause] = React.useState(false)
  const pauseZone = () => {
    //First set the default pause duration if the current pause duration is too short
    const minResumeAt = Math.floor((new Date().getTime() / 1000) + defaultPauseDuration)
    if (bladeZoneResumingOn === undefined || parseFloat(bladeZoneResumingOn) < minResumeAt) {
      dispatch(setBladeZoneRuntimeProperty({
        bladeZoneUID: zoneUID,
        properties: { "resume_controls_on": minResumeAt.toString() },
        callback: (success) => {
          if (success)  {
            SetPendingBladeToPause(true)
          }else {

          }
        }
      }))
    }else {
      SetPendingBladeToPause(true)
    }
  }
  React.useLayoutEffect(() => {
    if (pendingBladeToPause)  {
      SetPendingBladeToPause(false)
      dispatch(setBladeRuntimeProperty({
        bladeId: (bladeZoneType === "nursery") ? bladeIds["nursery"] : bladeIds["environment"],
        properties: { "air_control_status": "pause", "lights_control_status": "pause" },
        callback: (success) => {
          if (success)  {

          }else {

          }
        }
      }))
    }
  }, [pendingBladeToPause])

  const addTime = () => {
    const resumeAt = parseFloat(bladeZoneResumingOn ?? Math.floor((new Date().getTime() / 1000))) + defaultPauseDuration
    dispatch(setBladeZoneRuntimeProperty({
      bladeZoneUID: zoneUID,
      properties: { "resume_controls_on": resumeAt.toString() },
      callback: (success) => {

      }
    }))
  }


  const [bladeFeaturesToResume, SetBladeFeaturesToResume] = React.useState([])
  const resumeZone = () => {

    if (bladeZoneType === "nursery")  {
      SetBladeFeaturesToResume([
        {bladeId: bladeIds["nursery"], features: { "air_control_status": "resume", "lights_control_status": "resume", "aux_a_liquid_control_status": "resume", "aux_b_liquid_control_status": "resume" } }
      ])
       
    }else {
      SetBladeFeaturesToResume([
        {bladeId: bladeIds["grow"], features: { "primary_liquid_control_status": "resume"} },
        {bladeId: bladeIds["environment"], features: {"air_control_status": "resume", "lights_control_status": "resume"} }
      ])
    }
  }
  const settingBladeRuntimePropertyStatus = useSelector((state) => state.blade.settingBladeRuntimeProperty)
  React.useLayoutEffect(() => {
    if (bladeFeaturesToResume.length > 0 && settingBladeRuntimePropertyStatus !== "pending") {
      dispatch(setBladeRuntimeProperty({
        bladeId: bladeFeaturesToResume[0].bladeId,
        properties: bladeFeaturesToResume[0].features,
        callback: (success) => {
          SetBladeFeaturesToResume(bladeFeaturesToResume.shift())
        }
      }))
    }
  }, [bladeFeaturesToResume, settingBladeRuntimePropertyStatus])

  const onManageZoneClicked = () => {
    navigate("/zonemanager/" + zoneUID);
  }

  let manualCleanseClicked = (bladeId, forReservoir) => {
    dispatch(markGrowZoneForCleanse({
      bladeId: bladeId, zoneUID: zoneUID, forReservoir: forReservoir, callback: (success, taskId) => {
        if (success) {
          onViewingTask(taskId)
        }
      }
    }))
  }

  const resumeCleanseClicked = (bladeId, forReservoir) => {
    switch (forReservoir) {
      case "aux_a":
        if (bladeActiveCleanseTaskIds["nursery_upper"] !== undefined) {
          onViewingTask(bladeActiveCleanseTaskIds["nursery_upper"])
        }else {
          //ALERT
        }
        break
      case "aux_b":
        if (bladeActiveCleanseTaskIds["nursery_lower"] !== undefined) {
          onViewingTask(bladeActiveCleanseTaskIds["nursery_lower"])
        }else {
          //ALERT
        }
        break
      case "primary":
        if (bladeActiveCleanseTaskIds["primary"] !== undefined) {
          onViewingTask(bladeActiveCleanseTaskIds["primary"])
        }else {
          //ALERT
        }
        default:
        break

    }
  }




  const [additionalFunctions, SetAdditionalFunctions] = React.useState([])

  React.useLayoutEffect(() => {
    let currentAdditionalFunctions = [
      { key: "rename", label: "Rename Zone" },
    ]
    if (bladeZoneType !== undefined) {
      if (bladeZoneType === "nursery") {
        if (bladeIds["nursery"] !== undefined && bladeRuntimeInformation["nursery"] !== undefined) {
          let upperZoneCleanState = bladeRuntimeInformation["nursery"]["aux_a_reservoir_clean_state"]
          if (upperZoneCleanState === "clean") {
            currentAdditionalFunctions.push(
              { key: "upper_manual_cleanse", label: "Start Upper Zone Manual Cleanse", callback: () => { manualCleanseClicked(bladeIds["nursery"], "aux_a") } }
            )
          } else {
            currentAdditionalFunctions.push(
              { key: "upper_resume_cleanse", label: "Resume Upper Zone Manual Cleanse", callback: () => { resumeCleanseClicked(bladeIds["grow"], "aux_a")} }
            )
          }

          let lowerZoneCleanState = bladeRuntimeInformation["nursery"]["aux_b_reservoir_clean_state"]
          if (lowerZoneCleanState === "clean") {
            currentAdditionalFunctions.push(
              { key: "lower_manual_cleanse", label: "Start Lower Zone Manual Cleanse", callback: () => { manualCleanseClicked(bladeIds["nursery"], "aux_b") } }
            )
          } else {
            currentAdditionalFunctions.push(
              { key: "lower_resume_cleanse", label: "Resume Lower Zone Manual Cleanse", callback: () => { resumeCleanseClicked(bladeIds["grow"], "aux_b") } }
            )
          }
        }
        SetSubTitle(<><div className="FlexContent-H-5 Text-SoftTruncate">{<span className="GrowZonesStatus-VerticalRackGroup-Zone-Rack">{bladeDisplayNames["nursery"] !== undefined ? bladeDisplayNames["nursery"] : "N/A Rack"}</span>}</div></>)
      } else {
        if (bladeIds["grow"] !== undefined && bladeRuntimeInformation["grow"] !== undefined) {
          let cleanState = bladeRuntimeInformation["grow"]["primary_reservoir_clean_state"]
          if (cleanState === "clean") {
            currentAdditionalFunctions.push(
              { key: "manual_cleanse", label: "Start Manual Cleanse", callback: () => { manualCleanseClicked(bladeIds["grow"], "primary") } }
            )
          } else {
            currentAdditionalFunctions.push(
              { key: "resume_cleanse", label: "Resume Manual Cleanse", callback: () => { resumeCleanseClicked(bladeIds["grow"], "primary") } }
            )
          }
          SetZoneCleanState(cleanState)
        } else {
          SetZoneCleanState("unknown")
        }
        SetSubTitle(<><div className="FlexContent-H-5 Text-SoftTruncate">{<span className="GrowZonesStatus-VerticalRackGroup-Zone-Rack">{bladeDisplayNames["grow"] !== undefined ? bladeDisplayNames["grow"] : "N/A Rack"}</span>}<span style={{ fontStyle: "normal" }}>|</span><span className="GrowZonesStatus-VerticalRackGroup-Zone-Rack">{bladeDisplayNames["environment"] !== undefined ? bladeDisplayNames["environment"] : "N/A Rack"}</span></div></>)
      }
    }

    currentAdditionalFunctions.push(
      { key: "delete", label: "Delete", status: "Critical" }
    )
    currentAdditionalFunctions.splice(0, 0,
      { key: "manage_zone", label: "Manage Zone", callback: () => { onManageZoneClicked() } }
    )
    if (isMobile) {
      if (!isZonePaused) {
        currentAdditionalFunctions.splice(3, 0,
          { key: "pause_zone", label: "Pause Zone", callback: () => { pauseZone() } }
        )
      }
    }
    SetAdditionalFunctions(currentAdditionalFunctions)

  }, [bladeZoneType, bladeIds, bladeDisplayNames, bladeRuntimeInformation, isMobile])






  let secondaryHeaderContent = null
  if (!isMobile) {
    secondaryHeaderContent = []
    if (activeBladeZoneGrowIds.length > 0)  {
      secondaryHeaderContent.push({
        key: "grow_ids", content: <>
          <div className="FlexContent-H" style={{gap:4}}>
            {activeBladeZoneGrowIds.map((growId) => {
              return <>
                <Badge status={"Link"} content={<>
                  G{growId}
                  </>}
                  fontSize={12}/>                  
              </>
            })}
          </div>
        </>
      })
    }
    secondaryHeaderContent.push(...[
      { key: "pause_state", content: <GrowZonesPage_Zone_InlineManageZonePause 
          zoneUID={zoneUID} 
          bladeUIDs={activeBladeUIDs}
          pauseZoneCallback={pauseZone}
          resumeZoneCallback={resumeZone}
          addTimeCallback={addTime} /> },
    ])
  } else {
    
  }

  let potentialExtendedHeaderContent = []
  if (isMobile && isZonePaused) {
    potentialExtendedHeaderContent.push(<>
        <GrowZonesPage_Zone_ExtendedManageZonePause 
            zoneUID={zoneUID} 
            bladeUIDs={activeBladeUIDs}
            pauseZone={pauseZone}
            resumeZone={resumeZone} />
      </>
    )
  }
  if (isMobile && activeBladeZoneGrowIds.length > 0) {
    potentialExtendedHeaderContent.push(<>
      <div className="FlexContent" style={{padding: "10px 0"}}>
        <div className="FlexContent-H" style={{gap:4}}>
          {activeBladeZoneGrowIds.map((growId) => {
            return <>
              <Badge status={"Link"} content={<>
                G{growId}
                </>}
                fontSize={12}/>                 
            </>
          })}
        </div>
      </div>
    </>)
  }

  switch (bladeZoneType)  {
    case "nursery":
      if (bladeRuntimeInformation["nursery"] !== undefined)  {
        const upperZoneCleanseActive = bladeRuntimeInformation["nursery"]["aux_a_reservoir_cleanse_active"]
        const upperZoneCleanState = bladeRuntimeInformation["nursery"]["aux_a_reservoir_clean_state"]
        const upperZoneCleanStateStep = bladeRuntimeInformation["nursery"]["aux_a_reservoir_clean_state_step"]
        const lowerZoneCleanseActive = bladeRuntimeInformation["nursery"]["aux_b_reservoir_cleanse_active"]
        const lowerZoneCleanState = bladeRuntimeInformation["nursery"]["aux_b_reservoir_clean_state"]
        const lowerZoneCleanStateStep = bladeRuntimeInformation["nursery"]["aux_b_reservoir_clean_state_step"]
        const viewTaskClicked = (type) => {
          if (bladeActiveCleanseTaskIds[type] !== undefined) {
            onViewingTask(bladeActiveCleanseTaskIds[type])
          }
        }
        if (parseBool(upperZoneCleanseActive) || parseBool(lowerZoneCleanseActive)) {
          potentialExtendedHeaderContent.push(<>
            <div className={(isMobile ? "FlexContent-10" : "FlexContent-H-10") + " FlexContent-HFlex FlexContent-HCenter"} style={{padding: "10px 0"}}>
              {parseBool(upperZoneCleanseActive) && <>
                <div className="FlexContent-H-10 FlexContent-HCenter" style={{padding: "10px 0", cursor: "pointer"}} onClick={() => {viewTaskClicked("nursery_upper")}}>
                  {upperZoneCleanStateStep === "cleanse" && <>
                    {(upperZoneCleanState === "clean" || upperZoneCleanState === "dirty") && <>
                      <div className="Text-Hyperlink">Upper Cleanse Pending</div>
                    </>}
                    {upperZoneCleanState === "cleaning_out" && <>
                      <div className="Text-Hyperlink">Upper Cleaning Out</div>
                    </>}
                    {upperZoneCleanState === "cleaning_outs" && <>
                      <div className="Text-Hyperlink">Upper Cleaning Out</div>
                    </>}
                  </>}
                  {(upperZoneCleanState === "full_cleaning" && upperZoneCleanStateStep !== "bath_time") && <>
                    <div className="Text-Hyperlink">Upper Cleansing</div>
                  </>}
                  {upperZoneCleanStateStep === "bath_time" && <>
                    <div className="FlexContent-H" style={{gap:6}}>
                      <div className="Text-Hyperlink">Upper Cleanse Bath</div>
                      <div className="Text-S14">
                        {(() => {
                          const lastDosedOn = bladeRuntimeInformation["nursery"]["aux_a_reservoir_last_dosed_on"]
                          const bathDuration = bladeRuntimeInformation["nursery"]["aux_a_reservoir_bath_duration"]

                          let timeRemaining = 0
                          if (lastDosedOn !== undefined && bathDuration !== undefined)    {
                              let bathStartedOn = new Date((parseInt(lastDosedOn) * 1000) + (parseInt(bathDuration) * 1000))
                              timeRemaining = (bathStartedOn.getTime() - new Date().getTime())
                              if (timeRemaining < 0)  {
                                  timeRemaining = 0
                              }
                          }
                          return (<>{FormatDurationToShortString(timeRemaining / 1000, true)}</>)
                        })()}
                      </div>
                    </div>
                  </>}
                </div>
                
              </>}
              {parseBool(lowerZoneCleanseActive) && <>
                
                <div className="FlexContent-H-10 FlexContent-HCenter" style={{padding: "10px 0", cursor: "pointer"}} onClick={() => {viewTaskClicked("nursery_lower")}}>
                  {lowerZoneCleanStateStep === "cleanse" && <>
                    {(lowerZoneCleanState === "clean" || lowerZoneCleanState === "dirty") && <>
                      <div className="Text-Hyperlink">Lower Cleanse Pending</div>
                    </>}
                    {lowerZoneCleanState === "cleaning_out" && <>
                      <div className="Text-Hyperlink">Lower Cleaning Out</div>
                    </>}
                    {lowerZoneCleanState === "cleaning_outs" && <>
                      <div className="Text-Hyperlink">Lower Cleaning Out</div>
                    </>}
                  </>}
                  {(lowerZoneCleanState === "full_cleaning" && lowerZoneCleanStateStep !== "bath_time") && <>
                    <div className="Text-Hyperlink">Lower Cleansing</div>
                  </>}
                  {lowerZoneCleanStateStep === "bath_time" && <>
                    <div className="FlexContent-H" style={{gap:6}}>
                      <div className="Text-Hyperlink">Lower Cleanse Bath</div>
                      <div className="Text-S14">
                      {(() => {
                          const lastDosedOn = bladeRuntimeInformation["nursery"]["aux_b_reservoir_last_dosed_on"]
                          const bathDuration = bladeRuntimeInformation["nursery"]["aux_b_reservoir_bath_duration"]

                          let timeRemaining = 0
                          if (lastDosedOn !== undefined && bathDuration !== undefined)    {
                              let bathStartedOn = new Date((parseInt(lastDosedOn) * 1000) + (parseInt(bathDuration) * 1000))
                              timeRemaining = (bathStartedOn.getTime() - new Date().getTime())
                              if (timeRemaining < 0)  {
                                  timeRemaining = 0
                              }
                          }
                          return (<>{FormatDurationToShortString(timeRemaining / 1000, true)}</>)
                        })()}
                      </div>
                    </div>
                  </>}
                </div>
                
              </>}
            </div>
          </>)
        }
      }
      break

    case "grow_boards":
    case "berry_troughs":
      if (bladeRuntimeInformation["grow"] !== undefined)  {
        const zoneCleanseActive = bladeRuntimeInformation["grow"]["primary_reservoir_cleanse_active"]
        const zoneCleanState = bladeRuntimeInformation["grow"]["primary_reservoir_clean_state"]
        const zoneCleanStateStep = bladeRuntimeInformation["grow"]["primary_reservoir_clean_state_step"]
        const viewTaskClicked = () => {
          if (bladeActiveCleanseTaskIds["primary"] !== undefined) {
            onViewingTask(bladeActiveCleanseTaskIds["primary"])
          }
        }
        if (parseBool(zoneCleanseActive)) {
          potentialExtendedHeaderContent.push(<>
            <div className="FlexContent-H-10 FlexContent-HCenter" style={{padding: "10px 0", cursor: "pointer"}} onClick={viewTaskClicked}>
              {zoneCleanStateStep === "cleanse" && <>
                {(zoneCleanState === "clean" || zoneCleanState === "dirty") && <>
                  <div className="Text-Hyperlink">Cleanse Pending</div>
                </>}
                {zoneCleanState === "cleaning_out" && <>
                  <div className="Text-Hyperlink">Cleaning Out</div>
                </>}
                {zoneCleanState === "cleaning_outs" && <>
                  <div className="Text-Hyperlink">Cleaning Out</div>
                </>}
              </>}
              {(zoneCleanState === "full_cleaning" && zoneCleanStateStep !== "bath_time") && <>
                <div className="Text-Hyperlink">Cleansing</div>
              </>}
              {zoneCleanStateStep === "bath_time" && <>
                <div className="FlexContent-H" style={{gap:6}}>
                  <div className="Text-Hyperlink">Cleanse Bath</div>
                  <div className="Text-S14">
                    {(() => {
                      const lastDosedOn = bladeRuntimeInformation["grow"]["primary_reservoir_last_dosed_on"]
                      const bathDuration = bladeRuntimeInformation["grow"]["primary_reservoir_bath_duration"]

                      let timeRemaining = 0
                      if (lastDosedOn !== undefined && bathDuration !== undefined)    {
                          let bathStartedOn = new Date((parseInt(lastDosedOn) * 1000) + (parseInt(bathDuration) * 1000))
                          timeRemaining = (bathStartedOn.getTime() - new Date().getTime())
                          if (timeRemaining < 0)  {
                              timeRemaining = 0
                          }
                      }
                      return (<>{FormatDurationToShortString(timeRemaining / 1000, true)}</>)
                    })()}
                  </div>
                </div>
              </>}
            </div>
          </>)
        }
      }
      break
    default:
      break
  }

  let extendedHeaderContent = null
  if (potentialExtendedHeaderContent.length > 0)  {
    extendedHeaderContent = {
      content: potentialExtendedHeaderContent
    }
  }

  //useRenderingTrace("ZonesPage_Zone", [bladeZoneDisplayName, zoneIssues, dataActive, zoneUID, activeBladeUIDs, bladeRuntimeInformation, bladeIds, bladeControlDevices, bladeDisplayNames])
  //console.log("Rerender - Zones")




  const [expandedContentPage, SetExpandedContentPage] = React.useState("status")
  let tabSelected = (tab) => {
    SetExpandedContentPage(tab)
  }

  return (<>
    <MaintainTasksById taskIds={Object.values(bladeActiveCleanseTaskIds)}/>
    <RowGroupRow
      key={zoneUID}
      title={<>
        <div className="FlexContent-H" style={{gap:5, alignItems: "center"}}>
          <div>{bladeZoneDisplayName}</div>
          {activeRecipeId !== null && <>
            <Badge content={"Active"} status={"Active-Green"} fontSize={10}/>
          </>}
          </div>
      </>}
      hasFlexCell={true}
      subtitle={<div className="GrowZonesStatus-VerticalRackGroup-Zone-Racks">{subtitle}</div>}
      hasIssues={true}
      numberOfIssues={zoneIssues.length}
      isExpandable={true}
      customRowPadding={0}
      hasAdditionalFunctions={true}
      secondaryContent={secondaryHeaderContent}
      extendedHeaderContent={extendedHeaderContent}
      additionalFunctions={additionalFunctions}
      onContentExpandChanged={(state) => SetDataActive(state)}
      renderContentOnlyWhenOpen={true}
      content={<>
        <div style={{ display: "flex", flexDirection: "column", border: "1px solid #DDE3EB", gap: 0, borderRadius: 4, backgroundColor: "#FFF" }}>
          <div style={{ padding: "12px 20px 0px 20px" }}>
            {!isMobile && <>
              <TabControl
                onTabSelected={tabSelected}
                tabLocation={"flex"}
                tabs={[
                  new TabControlTab("Status", "status", "", expandedContentPage === "status"),
                  new TabControlTab("Analytics", "analytics", "", expandedContentPage === "analytics"),
                  new TabControlTab("Events", "events", "", expandedContentPage === "events"),
                ]}
              />
            </>}
            {isMobile && <>
              <DropDownInput
                status="Neutral"
                value={expandedContentPage}
                options={[
                  { key: 'status', label: 'Status' },
                  { key: 'analytics', label: 'Analytics' },
                  { key: 'events', label: 'Events' },
                ]}
                optionsKey="key"
                onSelectionChange={(action) => {
                  SetExpandedContentPage(action)
                }} />
            </>}
          </div>


          {expandedContentPage === "status" && <>
            <GrowZone_StatusContent facilityId={selectedFacility} zoneUID={zoneUID} />
          </>}
          {expandedContentPage === "analytics" && <>
            <GrowZone_DataAnalytics facilityId={selectedFacility} zoneUID={zoneUID} dataActive={dataActive} />
          </>}
          {expandedContentPage === "events" && <>
            Events
          </>}
        </div>

        {/*dataActive && <> 
        
          {/ * Analytics Charting Area * /}
          <div className="RowGroupRow-Content-Card">
            <div className="FlexContent-20 FlexContent-HFill">
              <GrowZone_DataAnalytics facilityId={selectedFacility} zoneUID={zoneUID} dataActive={dataActive} />
              
              <div className="GrowZonesStatus-EventsTable">
                <div className="GrowZonesStatus-EventsTable-TableHeader">
                  <div className="GrowZonesStatus-EventsTable-TableHeader-Title">
                    Events
                  </div>
                  <div className="GrowZonesStatus-EventsTable-TableHeader-SearchContainer">
                    <TextInput value={""} placeHolder="Search Events" icon={<TextInput.SearchIcon/>} onChange={() => {}}/>
                  </div>
                  <div className="GrowZonesStatus-EventsTable-TableHeader-Filters">
                    <DropDownInput value="most_recent"
                      options={[
                        {value: "most_recent", label:"Most Recent"},
                        {value: "oldest", label:"Oldest"}
                      ]}/>
                    <DropDownInput value="most_recent"
                      options={[
                        {value: "most_recent", label:"Most Recent"},
                        {value: "oldest", label:"Oldest"}
                      ]}/>
                    <DropDownInput value="most_recent"
                      options={[
                        {value: "most_recent", label:"Most Recent"},
                        {value: "oldest", label:"Oldest"}
                      ]}/>
                    <DropDownInput value="most_recent"
                      options={[
                        {value: "most_recent", label:"Most Recent"},
                        {value: "oldest", label:"Oldest"}
                      ]}/>
                  </div>
                </div>
                <div className="GrowZonesStatus-EventsTable-TableContent">
                  {bladeZoneEvents.map((eventItem) => {
                    let eventOccurredOn = new Date(eventItem.occurred_on)
                    return (
                      <div className="GrowZonesStatus-EventsTable-EventItem" 
                        key={eventItem.id}>
                        <div className="GrowZonesStatus-EventsTable-EventItem-EventDetails">
                          <div className="GrowZonesStatus-EventsTable-EventItem-EventDetails-Date">
                            <div>
                              {FormatDate(eventOccurredOn, "MM/dd/yy")}
                            </div>
                            <div>
                              {FormatDate(eventOccurredOn, "hh:mm:ss")}
                            </div>
                          </div>
                          <div className="GrowZonesStatus-EventsTable-EventItem-EventDetails-Info">
                            <div className="GrowZonesStatus-EventsTable-EventItem-EventDetails-Info-Type">
                              {eventItem.type_display_name}
                            </div>
                            <div className="GrowZonesStatus-EventsTable-EventItem-EventDetails-Info-Content">
                              {eventItem.content}
                            </div>
                          </div>
                        </div>
                        View
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </>*/}
      </>}/>
    </>)
}


export default GrowZonesPage_Zone









const GrowZonesPage_Zone_InlineManageZonePause = ({ zoneUID, bladeUIDs, pauseZoneCallback, resumeZoneCallback, addTimeCallback }) => {

  /* React useEffects */
  const dispatch = useDispatch()

  /* Data */
  const bladeZoneType = useSelector((state) => selectBladeZoneTypeByUID(state, zoneUID), _.isEqual)
  const bladeZoneRuntimeInformation = useSelector((state) => selectBladeZoneRuntimeInformationByUID(state, zoneUID), _.isEqual)
  const bladeActiveStates = useSelector((state) => selectBladeActiveStatesForBladeZoneUID(state, zoneUID), _.isEqual)
  const isZonePaused = Object.values(bladeActiveStates).find((s) => s !== "active") !== undefined
  const isZoneResuming = Object.values(bladeActiveStates).find((s) => s === "resume") !== undefined

  const activeBladeUIDs = useSelector((state) => selectBladeUIDsForBladeZoneUID(state, zoneUID), _.isEqual)
  const bladeIds = useSelector((state) => selectBladeIdsForBladeZoneUID(state, zoneUID), _.isEqual)
  const bladeDisplayNames = useSelector((state) => selectBladeDisplayNamesForBladeZoneUID(state, zoneUID), _.isEqual)
  const bladeRuntimeInformation = useSelector((state) => selectBladeRuntimeInformationForBladeZoneUID(state, zoneUID), _.isEqual)
  const bladeControlDevices = useSelector((state) => selectBladeControlDevicesForBladeZoneUID(state, zoneUID), _.isEqual)


  const [bladeResumesControlsOn] = useSelector((state) => selectBladeZoneRuntimeInformation(state, zoneUID, "resume_controls_on"), _.isEqual)


  let lightingControlState = ""
  let liquidControlState = ""
  let airControlState = ""
  if (bladeZoneType !== undefined) {
    if (bladeZoneType === "nursery") {
      if (bladeRuntimeInformation["nursery"] !== undefined) {
        lightingControlState = bladeRuntimeInformation["nursery"]["lights_control_status"]
      }
    } else {
      if (bladeRuntimeInformation["environment"] !== undefined) {
        lightingControlState = bladeRuntimeInformation["environment"]["lights_control_status"]
      }
    }

    if (bladeZoneType !== "nursery") {
      if (bladeRuntimeInformation["grow"] !== undefined) {
        liquidControlState = bladeRuntimeInformation["grow"]["primary_liquid_control_status"]
      }
    }

    if (bladeZoneType === "nursery") {
      if (bladeRuntimeInformation["nursery"] !== undefined) {
        airControlState = bladeRuntimeInformation["nursery"]["air_control_status"]
      }
    } else {
      if (bladeRuntimeInformation["environment"] !== undefined) {
        airControlState = bladeRuntimeInformation["environment"]["air_control_status"]
      }
    }


  }

  const toggleLightingPauseState = () => {
    let newState = lightingControlState === "active" ? "paused" : "active"
    dispatch(setBladeRuntimeProperty({
      bladeId: bladeIds[bladeZoneType === "nursery" ? "nursery" : "environment"],
      properties: { "lights_control_status": newState },
      callback: (success) => {

      }
    }))
  }

  const toggleLiquidPauseState = () => {
    let newState = liquidControlState === "active" ? "paused" : "active"
    dispatch(setBladeRuntimeProperty({
      bladeId: bladeIds["grow"],
      properties: { "primary_liquid_control_status": newState },
      callback: (success) => {

      }
    }))
  }

  const toggleAirPauseState = () => {
    let newState = airControlState === "active" ? "paused" : "active"
    dispatch(setBladeRuntimeProperty({
      bladeId: bladeIds[bladeZoneType === "nursery" ? "nursery" : "environment"],
      properties: { "air_control_status": newState },
      callback: (success) => {

      }
    }))
  }

  return <>
    <div className="FlexContent-H-10">
      {isZonePaused && <>
        {/*<Button
          status={"Primary-Control-Toggle"}
          state={lightingControlState === "active"}
          content={<LightingSetpoint fill={"#2E72D2"} />}
          height={17} size={"Custom"}
          contentPadding={"5px 8px"}
          onClick={toggleLightingPauseState}
          disabled={zoneActiveState === "resuming"} />

        {bladeZoneType === "nursery" && <>
          {(() => {
            if (bladeRuntimeInformation["nursery"] !== undefined) {
              let upperLiquidState = bladeRuntimeInformation["nursery"]["aux_b_liquid_control_status"]
              let lowerLiquidState = bladeRuntimeInformation["nursery"]["aux_a_liquid_control_status"]
              const toggleUpperLiquidState = () => {
                let newState = upperLiquidState === "active" ? "paused" : "active"
                dispatch(setBladeRuntimeProperty({
                  bladeId: bladeIds["nursery"],
                  properties: { "aux_b_liquid_control_status": newState },
                  callback: (success) => {

                  }
                }))
              }
              const toggleLowerLiquidState = () => {
                let newState = upperLiquidState === "active" ? "paused" : "active"
                dispatch(setBladeRuntimeProperty({
                  bladeId: bladeIds["nursery"],
                  properties: { "aux_a_liquid_control_status": newState },
                  callback: (success) => {

                  }
                }))
              }
              return <>
                <Button
                  status={"Primary-Control-Toggle"}
                  state={upperLiquidState === "active"}
                  content={<UpperWaterSetpoint
                    fill={"#2E72D2"} />}
                  height={17}
                  size={"Custom"}
                  contentPadding={"5px 8px"}
                  onClick={toggleUpperLiquidState}
                  disabled={zoneActiveState === "resuming"}  />
                <Button
                  status={"Primary-Control-Toggle"}
                  state={lowerLiquidState === "active"}
                  content={<LowerWaterSetpoint
                    fill={"#2E72D2"} />}
                  height={17}
                  size={"Custom"}
                  contentPadding={"5px 8px"}
                  onClick={toggleLowerLiquidState}
                  disabled={zoneActiveState === "resuming"}  />
              </>
            }
          })()}
        </>}
        {bladeZoneType !== "nursery" && <>
          <Button
            status={"Primary-Control-Toggle"}
            state={liquidControlState === "active"}
            content={<WaterSetpoint
              fill={"#2E72D2"} />}
            height={17}
            size={"Custom"}
            contentPadding={"5px 8px"}
            onClick={toggleLiquidPauseState}
            disabled={zoneActiveState === "resuming"} />
        </>}
        <Button
          status={"Primary-Control-Toggle"}
          state={airControlState === "active"}
          content={<AirSetpoint fill={"#2E72D2"} />}
          height={17} size={"Custom"}
          contentPadding={"5px 8px"}
          onClick={toggleAirPauseState}
            disabled={zoneActiveState === "resuming"}  />*/}
        <div className="FlexContent-H-20 FlexContent-Center">
          {(() => {
            if (isZoneResuming) {
              return <>
                <span className="GrowRacksStatus-VerticalRackGroup-Zone-PauseInfo-State">Resuming</span>
                <Button content={"Add Time"} status={"Primary-Inverted"} size={"Small"} disabled={true} />
              </>
            } else {
              let timeRemaining = bladeResumesControlsOn - (new Date().getTime() / 1000)
              if (timeRemaining < 0) {
                timeRemaining = 0
              }
              return <>
                <span className="GrowRacksStatus-VerticalRackGroup-Zone-PauseInfo-State">Paused {Math.floor(timeRemaining / 60)}:{Math.floor(timeRemaining % 60).toString().padStart(2, '0')}</span>
                <Button status={"Primary-Inverted"} size={"Small"} content={"Add Time"} onClick={addTimeCallback} />
              </>
            }
          })()}
        </div>
      </>}
      <div className="FlexContent-H-10">
        {isZonePaused && <>
          <Button status={"Primary-Inverted"} size={"Small"} content={<Play fill={"#2E72D2"} />} onClick={resumeZoneCallback} disabled={!isZonePaused} />
        </>}
        {!isZonePaused &&
          <Button status={"Small-IconAction"} size={"Small"} content={<Pause />} onClick={pauseZoneCallback} disabled={isZonePaused} />
        }
      </div>
    </div>
  </>
}

const GrowZonesPage_Zone_ExtendedManageZonePause = ({ zoneUID, bladeUIDs, pauseZoneCallback, resumeZoneCallback}) => {

  /* React useEffects */
  const dispatch = useDispatch()

  /* Data */
  const bladeZoneType = useSelector((state) => selectBladeZoneTypeByUID(state, zoneUID), _.isEqual)
  const bladeZoneRuntimeInformation = useSelector((state) => selectBladeZoneRuntimeInformationByUID(state, zoneUID), _.isEqual)

  const bladeActiveStates = useSelector((state) => selectBladeActiveStatesForBladeZoneUID(state, zoneUID), _.isEqual)
  const isZonePaused = Object.values(bladeActiveStates).find((s) => s !== "active") !== undefined
  const isZoneResuming = Object.values(bladeActiveStates).find((s) => s === "resume") !== undefined

  const activeBladeUIDs = useSelector((state) => selectBladeUIDsForBladeZoneUID(state, zoneUID), _.isEqual)
  const bladeIds = useSelector((state) => selectBladeIdsForBladeZoneUID(state, zoneUID), _.isEqual)
  const bladeDisplayNames = useSelector((state) => selectBladeDisplayNamesForBladeZoneUID(state, zoneUID), _.isEqual)
  const bladeRuntimeInformation = useSelector((state) => selectBladeRuntimeInformationForBladeZoneUID(state, zoneUID), _.isEqual)
  const bladeControlDevices = useSelector((state) => selectBladeControlDevicesForBladeZoneUID(state, zoneUID), _.isEqual)


  const defaultTimeToPauseFor = 10 * 60
  const [bladeResumesControlsOn] = useSelector((state) => selectBladeZoneRuntimeInformation(state, zoneUID, "resume_controls_on"), _.isEqual)
  const addTimeClicked = () => {
    const resumeAt = parseFloat(bladeResumesControlsOn ?? Math.floor((new Date().getTime() / 1000))) + defaultTimeToPauseFor
    dispatch(setBladeZoneRuntimeProperty({
      bladeZoneUID: zoneUID,
      properties: { "resume_controls_on": resumeAt.toString() },
      callback: (success) => {

      }
    }))
  }



  const pauseZoneClicked = () => {
    //pauseZone(zoneUID, bladeZoneType, dispatch)
  }




  /*const toggleLightingPauseState = () => {
    let newState = lightingControlState === "active" ? "paused" : "active"
    dispatch(setBladeRuntimeProperty({
      bladeId: bladeIds[bladeZoneType === "nursery" ? "nursery" : "environment"],
      properties: { "lights_control_status": newState },
      callback: (success) => {

      }
    }))
  }

  const toggleLiquidPauseState = () => {
    let newState = liquidControlState === "active" ? "paused" : "active"
    dispatch(setBladeRuntimeProperty({
      bladeId: bladeIds["grow"],
      properties: { "primary_liquid_control_status": newState },
      callback: (success) => {

      }
    }))
  }

  const toggleAirPauseState = () => {
    let newState = airControlState === "active" ? "paused" : "active"
    dispatch(setBladeRuntimeProperty({
      bladeId: bladeIds[bladeZoneType === "nursery" ? "nursery" : "environment"],
      properties: { "air_control_status": newState },
      callback: (success) => {

      }
    }))
  }*/

  return <>
    <div className="FlexContent-H-10 FlexContent-Center">
      {/*zoneActiveState !== "active" && <>
        <Button status={"Primary-Inverted"} content={<LightingSetpoint fill={"#2E72D2"} />} onClick={toggleLightingPauseState} disabled={zoneActiveState === "resuming"} />
        <Button status={"Primary-Inverted"} content={<WaterSetpoint fill={"#2E72D2"} />} onClick={toggleLiquidPauseState} disabled={zoneActiveState === "resuming"} />
        <Button status={"Primary-Inverted"} content={<AirSetpoint fill={"#2E72D2"} />} onClick={toggleAirPauseState} disabled={zoneActiveState === "resuming"} />
      </>*/}
      {(() => {
        if (isZoneResuming) {
          return <>
            <span className="GrowRacksStatus-VerticalRackGroup-Zone-PauseInfo-State">Resuming</span>
          </>
        } else {
          let timeRemaining = bladeResumesControlsOn - (new Date().getTime() / 1000)
          if (timeRemaining < 0) {
            timeRemaining = 0
          }
          return <>
            <Button status={"Primary-Inverted"} content={<><div>{Math.floor(timeRemaining / 60)}:{Math.floor(timeRemaining % 60).toString().padStart(2, '0')}</div><PlusAlt /></>} onClick={addTimeClicked}/>
          </>
        }
      })()}
      <Button status={"Primary-Inverted"} content={<Play fill={"#2E72D2"} />} onClick={resumeZoneCallback} disabled={isZoneResuming} />
      
    </div>
  </>
}