import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  {Repository as APIRepository, FetchPost} from '../api'
import _ from 'underscore';

import {binaryClosestIdx} from '../../helpers'
import { GetAccountId, getAccountSessionData, IsSessionActive } from '../../pages/Account/Common'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect } from 'react'
import { selectAllFarmFacilities } from './Facilities'
import { selectAllDataRecordingTimePeriodTypes } from '../AppInfo';
import { selectGrowPlanById } from './GrowPlans';
import { selectTasksByCategoryAndReferenceId } from './Tasks';
import { selectRecipeByIdAndVersion } from './Recipes';


const doseItemColors = [
  "rgb(255, 20, 20)".replace("rgb(", '').replace(")", '').split(','),
  "rgb(55, 20, 20)".replace("rgb(", '').replace(")", '').split(','),
  "rgb(25, 120, 120)".replace("rgb(", '').replace(")", '').split(','),
  "rgb(255, 220, 20)".replace("rgb(", '').replace(")", '').split(','),
  "rgb(255, 20, 20)".replace("rgb(", '').replace(")", '').split(','),
  "rgb(50, 120, 255)".replace("rgb(", '').replace(")", '').split(','),
]





export const numberOfGrowOutRaftsPerZone = (growOutType, bladeType, growOutRaftType) => {
  if (bladeType !== undefined && growOutRaftType !== undefined)   {
    switch (growOutType) {
        case "grow_boards":
            //Make sure we have the grow out board information for the selected blade type
            if (bladeType.props["number_of_grow_out_board_columns"] !== undefined && bladeType.props["number_of_grow_out_board_rows"] !== undefined)   {
                let numberOfGrowBoardsPerZone = parseFloat(bladeType.props["number_of_grow_out_board_columns"]) * parseFloat(bladeType.props["number_of_grow_out_board_rows"])
                return numberOfGrowBoardsPerZone
            }else {
                return 0
            }
            break
        case "berry_troughs":
            if (bladeType.props["number_of_grow_out_berry_troughs"] !== undefined && bladeType.props["grow_out_berry_trough_length"] !== undefined && growOutRaftType.info["length"] !== undefined)   {
                let numberOfGrowBagsPerTrough = Math.floor(parseFloat(bladeType.props["grow_out_berry_trough_length"]) / parseFloat(growOutRaftType.info.length))
                let numberOfTroughsForZone = parseFloat(bladeType.props["number_of_grow_out_berry_troughs"]) * numberOfGrowBagsPerTrough
                return numberOfTroughsForZone
            }else {
                return 0
            }
            break
        default:
            return 0
    }
  }else {
    return 0
  }
}




export const calculateUnitsFromZones = (recipe, bladeType, zones, growOutRaftType, raftUsage) =>  {
  if (recipe !== undefined && bladeType !== undefined && growOutRaftType !== undefined)   {
    switch (recipe.grow_out_type) {
        case "grow_boards":
            //Make sure we have the grow out board information for the selected blade type
            if (bladeType.props["number_of_grow_out_board_columns"] !== undefined && bladeType.props["number_of_grow_out_board_rows"] !== undefined)   {
                let numberOfGrowBoardsPerZone = parseFloat(bladeType.props["number_of_grow_out_board_columns"]) * parseFloat(bladeType.props["number_of_grow_out_board_rows"])
                return raftUsage * numberOfGrowBoardsPerZone * zones
            }else {
                return 0
            }
            break
        case "berry_troughs":
            if (bladeType.props["number_of_grow_out_berry_troughs"] !== undefined && bladeType.props["grow_out_berry_trough_length"] !== undefined && growOutRaftType.info["length"] !== undefined)   {
                let numberOfGrowBagsPerTrough = Math.floor(parseFloat(bladeType.props["grow_out_berry_trough_length"]) / parseFloat(growOutRaftType.info.length))
                let numberOfUnitsPerTrough = parseFloat(raftUsage) * numberOfGrowBagsPerTrough
                let numberOfUnitsForZone = parseFloat(bladeType.props["number_of_grow_out_berry_troughs"]) * numberOfUnitsPerTrough
                //console.log(Math.floor(numberOfUnitsForZone * zones), zones, numberOfGrowBagsPerTrough, numberOfUnitsPerTrough, numberOfUnitsForZone, bladeType.props)
                return Math.floor(numberOfUnitsForZone * zones)
            }else {
                return 0
            }
            break
        default:
            return 0
    }
  }else {
    return 0
  }
}


export const calculateZonesFromUnits = (recipe, bladeType, units, growOutRaftType, raftUsage) => {
  if (recipe !== undefined && bladeType !== undefined && growOutRaftType !== undefined)   {
      //Based on recipe type
      switch (recipe.grow_out_type) {
          case "grow_boards":
              //Make sure we have the grow out board information for the selected blade type
              if (bladeType.props["number_of_grow_out_board_columns"] !== undefined && bladeType.props["number_of_grow_out_board_rows"] !== undefined)   {
                  let numberOfGrowBoardsPerZone = parseFloat(bladeType.props["number_of_grow_out_board_columns"]) * parseFloat(bladeType.props["number_of_grow_out_board_rows"])
                  return units / (parseFloat(raftUsage) * numberOfGrowBoardsPerZone)
              }else {
                  return 0
              }
              break
          case "berry_troughs":
              if (bladeType.props["number_of_grow_out_berry_troughs"] !== undefined && bladeType.props["grow_out_berry_trough_length"] !== undefined && growOutRaftType.info["length"] !== undefined)   {
                  let numberOfGrowBagsPerTrough = Math.floor(parseFloat(bladeType.props["grow_out_berry_trough_length"]) / parseFloat(growOutRaftType.info.length))
                  let numberOfUnitsPerTrough = parseFloat(raftUsage) * numberOfGrowBagsPerTrough
                  let numberOfUnitsForZone = parseFloat(bladeType.props["number_of_grow_out_berry_troughs"]) * numberOfUnitsPerTrough
                  return units / numberOfUnitsForZone
              }else {
                  return 0
              }
          default:
              return 0
      }
  }else {
      return 0
  }
}

const processGrowFromAPI = (state, grow) =>  {
  grow.selected = false
  grow.pinned = true
  grow.duration = 0
  if (grow.completed) {
    grow.duration = new Date(grow.finished_on).getTime() - new Date(grow.started_on).getTime()
  }else if (!grow.pending_start)  {
    grow.duration = new Date().getTime() - new Date(grow.started_on).getTime()
  }
  grow.analyticsData = {
    timePeriods: {},
    dataTypes: {},
    energyDataTypes: {},
    nutrientsTotalData: [],
    nutrientsTotal: 0.0,
    nutrientsTotalVersion: 0
  }
  grow.liveData = {

  }
  grow.lastLiveDataUpdateOn = 0
  grow.loadingLiveData = false

  grow.dosingEvents = {}
  grow.unassignedDosingEvents = {}
  grow.dosingItems = {}
  grow.unassignedDosingItems = {}
  grow.dosingItemTotals = {}

  grow.dosingInstances = []

  grow.haveDosingEventsUpUntil = new Date(grow.started_on).getTime()
  grow.initializedDataRecordingTimePeriodTypes = false


  grow.grow_zone_started_on = new Date("2023-11-08 12:23:31").getTime()


  if (grow.loading_live_data_status === undefined)  {
    grow.loading_live_data_status = "idle"
  }

  if (grow.loading_analytics_data_status === undefined)  {
    grow.loading_analytics_data_status = "idle"
  }

  if (grow.loading_germination_locations === undefined) {
    grow.loading_germination_locations = false
  }
  if (grow.loading_nursery_locations === undefined) {
    grow.loading_nursery_locations = false
  }
  if (grow.loading_growout_locations === undefined) {
    grow.loading_growout_locations = false
  }
  if (grow.loading_all_grow_photos === undefined) {
    grow.loading_all_grow_photos = false
  }
  if (grow.grow_photos === undefined) {
    grow.grow_photos = []
  }
  if (grow.grow_photos_version === undefined) {
    grow.grow_photos_version = 0
  }
  /*if (grow.loading_grow_photos === undefined) {
    grow.loading_grow_photos = []
  }*/
  processNewGrowData(state, grow)
  return grow
}



const processNewGrowData = (state, grow) => {
  grow.totalScore = 100
  grow.zoneScores = {}

  for (let [zoneUID, liveData] of Object.entries(grow.liveData))  {
    grow.zoneScores[zoneUID] = {}

    let zonePercentage = 100
    if (Object.entries(liveData).length > 0) {
      if (liveData["at-ei"] !== undefined) {
        grow.zoneScores[zoneUID].airTemp = parseFloat(liveData["at-ei"].value)
        zonePercentage -= grow.zoneScores[zoneUID].airTemp
      }
      if (liveData["vpd-ei"] !== undefined) {
        grow.zoneScores[zoneUID].airVPD = parseFloat(liveData["vpd-ei"].value)
        zonePercentage -= grow.zoneScores[zoneUID].airVPD
      }
      if (liveData["ec-ei"] !== undefined) {
        grow.zoneScores[zoneUID].waterEC = parseFloat(liveData["ec-ei"].value)
        zonePercentage -= grow.zoneScores[zoneUID].waterEC
      }
      if (liveData["ph-ei"] !== undefined) {
        grow.zoneScores[zoneUID].waterPH = parseFloat(liveData["ph-ei"].value)
        zonePercentage -= grow.zoneScores[zoneUID].waterPH
      }
      if (zonePercentage < 0) {
        zonePercentage = 0
      }
      zonePercentage = (Math.round(zonePercentage * 10) / 10)
    }

    grow.zoneScores[zoneUID].totalScore = zonePercentage
  }

  let totalZoneScore = 0
  let scoreCount = 0
  for (let zoneScoreInfo of Object.values(grow.zoneScores)) {
    scoreCount += 1
    totalZoneScore += zoneScoreInfo.totalScore
  }

  if (scoreCount > 0) {
    grow.totalScore = Math.floor(totalZoneScore / scoreCount)
  }

  return grow
}





const processGrowGroupFromAPI = (growGroup) =>  {
  return growGroup
}

export const getAllGrows = createAsyncThunk('grows/getAllGrows', async ({facilityIds}, { getState }) => {
    return await FetchPost(APIRepository.Grows.GetAllGrows, {
      ...getAccountSessionData(getState()),
      facility_ids: facilityIds,
      have_grows: {}
    })
  }
)



export const getGrowById = createAsyncThunk('grows/getGrowById', async ({growId, growIds}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (growId !== undefined) {
    payload.grow_id = growId
  }
  if (growIds !== undefined) {
    payload.grow_ids = growIds
  }
  return await FetchPost(APIRepository.Grows.GetGrowById, payload)  
},
{
  condition: (args, { getState }) => {
    const { grows } = getState()
    if (grows.loadingGrowsStatus === 'pending') {
      return false
    }
  },
})




export const getGrowAnalyticsData = createAsyncThunk('grows/getGrowAnalyticsData', async ({growId, timePeriodTypes, entries}, { getState }) => {
  return await FetchPost(APIRepository.Grows.GetGrowAnalyticsData, {
    ...getAccountSessionData(getState()),
      grow_id: growId,
      entries: entries
    })  
  }
)

export const getGrowLiveData = createAsyncThunk('grows/getGrowLiveData', async ({growId, growIds}, { getState }) => {
    let payload = {
      ...getAccountSessionData(getState()),
    }
    if (growId !== undefined) {
      payload.grow_id = growId
    }
    if (growIds !== undefined)  {
      payload.grow_ids = growIds
    }

    return await FetchPost(APIRepository.Grows.GetGrowLiveData, payload)  
  }
)


export const getGrowDosingHistory = createAsyncThunk('grows/getGrowDosingHistory', async ({growId, fromTime, toTime}, { getState }) => {
    return await FetchPost(APIRepository.Grows.GetGrowDosingHistory, {
      ...getAccountSessionData(getState()),
      grow_id: growId,
      from_time: fromTime,
      to_time: toTime
    })  
  },
  {
    condition: (args, { getState }) => {
      const { grows } = getState()
      if (grows.loadingGrowHistoryStatus === 'pending') {
        return false
      }
    },
  }
)



export const scheduleNewGrow = createAsyncThunk('grows/scheduleNewGrow', async ({grow, callback}, { getState }) => {
  return await FetchPost(APIRepository.Grows.ScheduleNewGrow, {
    ...getAccountSessionData(getState()),
    new_grow: grow
  })
})


export const changeGrowStartDate = createAsyncThunk('grows/changeGrowStartDate', async ({growId, newStartDate, callback}, { getState }) => {
  return await FetchPost(APIRepository.Grows.ChangeGrowStartDate, {
    ...getAccountSessionData(getState()),
    grow_id: growId,
    start_date: newStartDate
  })
})




export const getGrowGerminationBoardLocations = createAsyncThunk('grows/getGrowGerminationBoardLocations', async ({growId, callback}, { getState }) => {
  return await FetchPost(APIRepository.Grows.GetGrowGerminationBoardLocations, {
    ...getAccountSessionData(getState()),
    grow_id: growId
  })
})



export const getGrowNurseryBoardLocations = createAsyncThunk('grows/getGrowNurseryBoardLocations', async ({growId, callback}, { getState }) => {
  return await FetchPost(APIRepository.Grows.GetGrowNurseryBoardLocations, {
    ...getAccountSessionData(getState()),
    grow_id: growId
  })
})


export const getGrowGrowoutBoardLocations = createAsyncThunk('grows/getGrowGrowoutBoardLocations', async ({growId, callback}, { getState }) => {
  return await FetchPost(APIRepository.Grows.GetGrowGrowoutLocations, {
    ...getAccountSessionData(getState()),
    grow_id: growId
  })
})



export const markGrowZoneEntryAsHarvested = createAsyncThunk('grows/markGrowZoneEntryAsHarvested', async ({ growId, zoneUID, wasPartialHarvest, callback }, { getState }) => {
  return await FetchPost(APIRepository.Grows.MarkGrowZoneEntryAsHarvested, {
    ...getAccountSessionData(getState()),
    grow_id: growId,
    blade_zone_uid: zoneUID,
    was_partial_harvest: wasPartialHarvest,
  })
})



export const getAllGrowPhotos = createAsyncThunk('grows/getAllGrowPhotos', async ({growId, callback}, { getState }) => {
  return await FetchPost(APIRepository.Grows.GetAllGrowPhotos, {
    ...getAccountSessionData(getState()),
    grow_id: growId
  })
})


export const editGrowPhoto = createAsyncThunk('grows/editGrowPhoto', async ({growId, photoId, props, callback}, { getState }) => {
  return await FetchPost(APIRepository.Grows.EditGrowPhoto, {
    ...getAccountSessionData(getState()),
    photo_id: photoId,
    props: props
  })
})

export const deleteGrowPhoto = createAsyncThunk('grows/deleteGrowPhoto', async ({growId, photoId, callback}, { getState }) => {
  return await FetchPost(APIRepository.Grows.DeleteGrowPhoto, {
    ...getAccountSessionData(getState()),
    photo_id: photoId,
  })
})


export const createTransplantToGrowOutFromNurseryEntry = createAsyncThunk('grows/createTransplantToGrowOutFromNurseryEntry', async ({taskId, growId, usedNurseryLocations, toGrowOutZoneUID, numberOfUnits, numberOfGrowOutRafts, growOutType, callback}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    task_id: taskId,
    grow_id: growId,
    used_nursery_locations: usedNurseryLocations,
    to_grow_zone_uid: toGrowOutZoneUID,
    number_of_units: numberOfUnits,
    number_of_grow_out_rafts: numberOfGrowOutRafts,
    zone_type: growOutType
  }
  return await FetchPost(APIRepository.Grows.CreateTransplantToGrowOutFromNurseryEntry, payload)
})


const reduceDateRange = (dateRange, existingDateRanges) => {
  let requestDateRanges = [dateRange]
  

  let haveBefore = false, haveAfter = false
  for (let existingDateRange of existingDateRanges) {
    for (let requestDateRange of requestDateRanges) {
      //Check if this request range is within the existing range
      if (requestDateRange.from >= existingDateRange.from && requestDateRange.to <= existingDateRange.to) {
        requestDateRanges.splice(requestDateRanges.indexOf(requestDateRange), 1)
        break
      }

      //Next check if this requestRange has the existing range within
      if (requestDateRange.from < existingDateRange.from && requestDateRange.to > existingDateRange.to) {
        requestDateRange.to = existingDateRange.from
        requestDateRanges.push(... reduceDateRange({from: existingDateRange.to, to: requestDateRange.to}, existingDateRanges))
        break //safe to move on to next existing date range as this request range encapulated the existing date range
      }

      //Check if this existing ranges *from* overlaps our request ranges *to*
      if (requestDateRange.to >= existingDateRange.from && requestDateRange.to <= existingDateRange.to) {
        requestDateRange.to = existingDateRange.from
      
      
      //Check if this existing ranges *to* overlaps our request ranges *from*
      }else if (requestDateRange.from <= existingDateRange.to && requestDateRange.from >= existingDateRange.from) {
        requestDateRange.from = existingDateRange.to
      }
    }
  }
  
  //console.log(dateRange, JSON.stringify(existingDateRanges), requestDateRanges)
  return requestDateRanges
}


export const growsSlice = createSlice({
  name: 'grows',
  initialState: {
    grows:  [

    ],
    liveData: {}, //Stored by id of grow -> map of zone_uid: dictionary of data
    lastLiveDataUpdateOn: {}, //Stored by id of grow to last time the live data was updated
    totalScore: {},
    zoneScores: {},


    loadedAllGrows: false,
    loadingAllGrowStatus: "idle",
    loadedAllGrowsForFacilities: [],

    currentGrowTempId: 1,
    groups:  [

    ],
    currentGroupTempId: 1,
    initialLoadStatus: 'idle',
    error: null,
    haveInitialData: false,
    haveGrowsForFacility: [],
    loadingData: false,
    
    assignedDosingItemColors: {},
    assignedDosingItemColorIndex: 1,

    loadingGrowsStatus: 'idle',
    loadingGrowAnalyticsDataStatus: 'idle',
    loadingGrowLiveDataStatus: 'idle',
    loadingGrowHistoryStatus: 'idle'
  },
  reducers: {
    

    growUpdate: (state, action) => {
      let grow = state.grows.find((g) => g.id === action.payload.growId)
      if (grow !== undefined)  {
        if (action.payload.prop !== undefined && action.payload.value !== undefined)  {
          grow[action.payload.prop] = action.payload.value
        }
        if (action.payload.properties !== undefined)  {
          for (let [key, value] of Object.entries(action.payload.properties)) {
            grow[key] = value
          }
        }
      }
      if (action.payload.callback !== undefined)  {
        action.payload.callback(true)
      }
    },



    initializeDataRecordingTimePeriodTypes: (state, action) =>  {
      let hasChanged = false
      let newGrows = { ...state, grows: state.grows.map((grow, index) => {
        if (grow.id !== action.payload.growId) {
          return grow
        }

        //let analyticsData = grow.analyticsData
    
        //analyticsData[action.payload.key] = {loadingStatus: false, timePeriods: {}}
        let dataTimePeriods = {...grow.analyticsData, timePeriods: {}}
        for (let dataRecordingTimePeriodType of action.payload.dataRecordingTimePeriodTypes)  {
          dataTimePeriods.timePeriods[dataRecordingTimePeriodType.index] = {
            loadingStatus: "idle",
            data: {},
            dataChunks: {},
            haveNewData: {},
            haveDataUpUntil: 0,
            changedVersion: 0,

            energyData: {},
            energyDataChunks: {},
            haveNewEnergyData: {},
            haveEnergyDataUpUntil: 0,
            changedEnergyVersion: 0


          }
        }
        hasChanged = true

        return {
          ...grow,
          analyticsData: dataTimePeriods,
          initializedDataRecordingTimePeriodTypes: true
        }
      })}

      //console.log(newGrows)

      if (hasChanged) {
        return newGrows
      }

    },
    addZoneInfoToGrow: (state, action) => {
      return {...state, grows: state.grows.map((grow) => {
        if (grow.id !== action.payload.growId)  {
          return grow
        }
        const updatedGrowZoneInfo = grow.grow_out_zone_info ? [...grow.grow_out_zone_info, ...action.payload.zone_info] : action.payload.zone_info
        return {...grow, grow_out_zone_info:updatedGrowZoneInfo}
      })}
    },
    addedNewGrowPhoto: (state, action) => {
      return {...state, grows: state.grows.map((grow) => {
        if (grow.id !== action.payload.growId)  {
          return grow
        }

        return {...grow, grow_photos:[...grow.grow_photos, action.payload.entry], grow_photos_version: grow.grow_photos_version + 1}
      })}
    },
    setPhotoUploadProgress: (state, action) => {
      return {...state, grows: state.grows.map((grow) => {
        if (grow.id !== action.payload.growId)  {
          return grow
        }

        return {...grow, grow_photos:[...grow.grow_photos.map((gP) => {
          if (gP.photo_index !== action.payload.photoIndex)  {
            return gP
          }
          return {...gP, progress: action.payload.progress}
        }), ], grow_photos_version: grow.grow_photos_version + 1}
      })}
    },
    downloadingGrowPhoto: (state, action) => {
      return {...state, grows: state.grows.map((grow) => {
        if (grow.id !== action.payload.growId)  {
          return grow
        }

        return {...grow, grow_photos:[...grow.grow_photos.map((gP) => {
          if (gP.storage_key !== action.payload.storageKey)  {
            return gP
          }
          return {...gP, downloading: true, downloaded: false, src: null, last_download_attempt_on: new Date()}
        }), ], grow_photos_version: grow.grow_photos_version + 1}
      })}
    },

    downloadingGrowPhotoSuccessful: (state, action) => {
      return {...state, grows: state.grows.map((grow) => {
        if (grow.id !== action.payload.growId)  {
          return grow
        }

        return {...grow, grow_photos:[...grow.grow_photos.map((gP) => {
          if (gP.storage_key !== action.payload.storageKey)  {
            return gP
          }
          return {...gP, downloading: false, downloaded: true, src: action.payload.src}
        }), ], grow_photos_version: grow.grow_photos_version + 1}
      })}
    },

    downloadingGrowPhotoFailed: (state, action) => {
      return {...state, grows: state.grows.map((grow) => {
        if (grow.id !== action.payload.growId)  {
          return grow
        }

        return {...grow, grow_photos:[...grow.grow_photos.map((gP) => {
          if (gP.storage_key !== action.payload.storageKey)  {
            return gP
          }
          return {...gP, downloading: false, downloaded: false, src: null}
        }), ], grow_photos_version: grow.grow_photos_version + 1}
      })}
    },
  },
  extraReducers: {
    [getAllGrows.pending]: (state) => {
      state.loadingAllGrowStatus = 'pending';
    },

    [getAllGrows.fulfilled]: (state, action) => {
      state.loadingAllGrowStatus = 'fulfilled';

      if (action.payload.grows) {
        for (let grow of action.payload.grows)  {
          if (grow) {
            let exists = false
            for (let growIndex in state.grows) {
              if (state.grows[growIndex].id === grow.id) {
                //Get the UID

                state.grows[growIndex] = processGrowFromAPI(state, { ...state.grows[growIndex], ...grow })
                exists = true
                break
              }
            }
            if (!exists) {
              state.grows.push(processGrowFromAPI(state, grow))
            }
          }
        }

        for (let faciltyId of action.meta.arg.facilityIds)  {
          if (!state.loadedAllGrowsForFacilities.includes(faciltyId))  {
            state.loadedAllGrowsForFacilities.push(faciltyId)
          }
        }
      }
      /*if (action.payload.grow_groups) {
        action.payload.grow_groups.map(function(growGroup){ processGrowGroupFromAPI(growGroup); return growGroup });
        state.groups = action.payload.grow_groups;
      }*/
    },

    [getAllGrows.rejected]: (state) => {
      state.loadingAllGrowStatus = 'rejected';
    },

    [getGrowById.pending]: (state) => {
      state.loadingGrowsStatus = 'pending';
    },

    [getGrowById.fulfilled]: (state, action) => {
      state.loadingGrowsStatus = 'fulfilled';
      if (action.payload.grows !== null) {
        for (let grow of action.payload.grows)  {
          if (grow) {
            let exists = false
            for (let growIndex in state.grows) {
              if (state.grows[growIndex].id === grow.id) {
                //Get the UID

                state.grows[growIndex] = processGrowFromAPI(state, { ...state.grows[growIndex], ...grow })
                exists = true
                break
              }
            }
            if (!exists) {
              state.grows.push(processGrowFromAPI(state, grow))
            }
          }
        }
      }
    },

    [getGrowById.rejected]: (state) => {
      state.loadingGrowsStatus = 'rejected';
    },
    


    [getGrowAnalyticsData.pending]: (state, action) => {
      state.loadingGrowAnalyticsDataStatus = 'pending';
      state.grows.map((grow) => {
        if (!action.meta.arg.growIds.includes(grow.id))  {
          return grow
        }
        for (let dataRecordingTimePeriodTypeIndex in action.meta.arg.entries)  {
          grow.analyticsData.timePeriods[dataRecordingTimePeriodTypeIndex].loadingStatus = 'pending'
        }
        return grow
      })
    },

    [getGrowAnalyticsData.fulfilled]: (state, action) => {
      state.loadingGrowAnalyticsDataStatus = 'fulfilled';
      state.grows.map((grow) => {
        if (!action.meta.arg.growIds.includes(grow.id))  {
          return grow
        }
        for (let dataRecordingTimePeriodTypeIndex in action.meta.arg.entries)  {
          grow.analyticsData.timePeriods[dataRecordingTimePeriodTypeIndex].loadingStatus = 'fulfilled'
        }

          
        //action.payload.grows.map(function(grow){ processGrowFromAPI(grow); return grow });
        const growStartedOn = new Date(grow.started_on).getTime()
        const growFinishedOn = (grow.completed ? new Date(grow.finished_on).getTime() : new Date().getTime() + 60000)
        for (let dataChunk of action.payload["data"])  {


          const currentDataRecordingTimePeriodType = action.meta.arg.timePeriodTypes.find((dataRecordingTimePeriodType) => { return dataRecordingTimePeriodType.id === dataChunk["time_period_type_id"]; })


          const growAnalyticsData = grow.analyticsData.timePeriods[currentDataRecordingTimePeriodType.index]
          const growAnalyticsDataTypes = grow.analyticsData.dataTypes



          let currentDataChunk = growAnalyticsData.dataChunks[dataChunk["entry_index"]]  
          var newData = false;
          const chunkStartedOn = parseInt(dataChunk["entry_index"]) * currentDataRecordingTimePeriodType.duration * 1000
          
          //console.log(chunkStartedOn, growStartedOn)

          if (currentDataChunk === undefined) {
            currentDataChunk = growAnalyticsData.dataChunks[dataChunk["entry_index"]] = dataChunk
          }
            
          
          if (dataChunk.completed)  {


            const dataItemList = dataChunk.values.split('\n')
            
            const processedData = {}

            //console.log(dataChunk.values)
            for (let dataItemString of dataItemList)  {
              const dataItemInfo = dataItemString.split(',')
              const identifier = dataItemInfo[0]
              let forTime
              if (currentDataRecordingTimePeriodType["max_number_of_points"] == 0)  {
                forTime = chunkStartedOn - growStartedOn + parseInt(dataItemInfo[1])
              }else {
                forTime = chunkStartedOn - growStartedOn + parseInt(dataItemInfo[1] * 1000)
              }
              if (forTime < growFinishedOn) {
                
                const value = parseFloat(dataItemInfo[2])
                const flag = dataItemInfo[3]

                if (processedData[identifier] === undefined) {
                  processedData[identifier] = []            
                }
                processedData[identifier].push({x: forTime - action.meta.arg.dateOffset, y: value})

                growAnalyticsData.haveNewData[identifier] = true
                newData = true;
              }
              
            }

            for (let identifier in processedData)  {
              if (growAnalyticsData.data[identifier] === undefined) {
                  growAnalyticsData.data[identifier] = []            
              }
              if (identifier === "lightr" || identifier === "lightg" || identifier === "lightb" || identifier === "lightfr")  {
                processedData[identifier].sort( function(a , b)  {
                  if(a.x > b.x) 
                    return 1;
                  if(a.x < b.x) 
                    return -1;
                  return 0
                  
                });

                let lastValue = null
                for (let dataItem of processedData[identifier]) {
                  if (!lastValue && growAnalyticsData.data[identifier].length > 0)  {
                    let lastDataItem = binaryClosestIdx(growAnalyticsData.data[identifier], dataItem.x - 1, 'x')
                    if (lastDataItem && growAnalyticsData.data[identifier][lastDataItem] !== undefined) {
                      if (growAnalyticsData.data[identifier][lastDataItem].x < dataItem.x)  {
                        lastValue = growAnalyticsData.data[identifier][lastDataItem].y
                      }
                    }
                  }
                  if (lastValue)  {
                    growAnalyticsData.data[identifier].push({x: dataItem.x - 1 - action.meta.arg.dateOffset, y: lastValue})
                  }
                  growAnalyticsData.data[identifier].push({x: dataItem.x - action.meta.arg.dateOffset, y: dataItem.y})
                  lastValue = dataItem.y
                }
              }else {
                growAnalyticsData.data[identifier].push(...processedData[identifier])
              }
            }



          }else {
            //Live data chunk
            for (let identifier in dataChunk["live_values"]) {
              let isNew = false

              if (growAnalyticsData.data[identifier] === undefined) {
                growAnalyticsData.data[identifier] = []               
                isNew = true
              }

              let lastValue = null
              for (let forTime in dataChunk["live_values"][identifier]) {
                if (forTime < growFinishedOn) {
                  const pointTime = forTime - growStartedOn
                  if (identifier === "lightr" || identifier === "lightg" || identifier === "lightb" || identifier === "lightfr")  {
                    if (!lastValue && growAnalyticsData.data[identifier].length > 0)  {
                      let lastDataItem = binaryClosestIdx(growAnalyticsData.data[identifier], pointTime - 1, 'x')
                      if (lastDataItem && growAnalyticsData.data[identifier][lastDataItem] !== undefined) {
                        if (growAnalyticsData.data[identifier][lastDataItem].x < pointTime)  {
                          lastValue = growAnalyticsData.data[identifier][lastDataItem].y
                        }
                      }
                    }
                    if (lastValue)  {
                      growAnalyticsData.data[identifier].push({x: pointTime - 1 - action.meta.arg.dateOffset, y: lastValue})
                    }
                    growAnalyticsData.data[identifier].push({x: pointTime - action.meta.arg.dateOffset, y: dataChunk["live_values"][identifier][forTime]})
                  }else {
                    growAnalyticsData.data[identifier].push({x: pointTime - action.meta.arg.dateOffset, y: dataChunk["live_values"][identifier][forTime]})
                  }
                  newData = true;
                  growAnalyticsData.haveNewData[identifier] = true
                  
                  if (pointTime > growAnalyticsData.haveDataUpUntil)  {
                    growAnalyticsData.haveDataUpUntil = parseInt(pointTime)
                  }

                  lastValue = dataChunk["live_values"][identifier][forTime]
                }
              }
            }

            currentDataChunk.to = growAnalyticsData.haveDataUpUntil
          }


          if (newData)  { 
            for (let identifier in growAnalyticsData.data)  {
              growAnalyticsData.data[identifier].sort( function(a , b)  {
                if(a.x > b.x) 
                  return 1;
                if(a.x < b.x) 
                  return -1;
                return 0
                
              });
            }

            growAnalyticsData.changedVersion += 1
          }

        }




        //Time for energy
        for (let energyDataChunk of action.payload["energy_data"])  {
          //console.log(energyDataChunk)

          const currentDataRecordingTimePeriodType = action.meta.arg.timePeriodTypes.find((dataRecordingTimePeriodType) => { return dataRecordingTimePeriodType.id === energyDataChunk["time_period_type_id"]; })
    

          const growAnalyticsData = grow.analyticsData.timePeriods[currentDataRecordingTimePeriodType.index]
          const growAnalyticsDataTypes = grow.analyticsData.dataTypes



          let currentEnergyDataChunk = growAnalyticsData.energyDataChunks[energyDataChunk["entry_index"]]  
          var newEnergyData = false;
          const chunkStartedOn = parseInt(energyDataChunk["entry_index"]) * currentDataRecordingTimePeriodType.duration * 1000

          //console.log(chunkStartedOn, growStartedOn)

          if (currentEnergyDataChunk === undefined) {
            currentEnergyDataChunk = growAnalyticsData.energyDataChunks[energyDataChunk["entry_index"]] = energyDataChunk
          }
            
          //growAnalyticsData.dataChunks[dataChunk["entry_index"]] = dataChunk
          
          if (energyDataChunk.completed)  {


            const dataItemList = energyDataChunk.values.split('\n')
            
            //console.log(dataChunk.values)
            for (let dataItemString of dataItemList)  {
              const dataItemInfo = dataItemString.split(',')
              const power = dataItemInfo[0]
              const cost = dataItemInfo[1]
              let forTime
              if (currentDataRecordingTimePeriodType["max_number_of_points"] == 0)  {
                forTime = chunkStartedOn - growStartedOn + parseInt(dataItemInfo[2])
              }else {
                forTime = chunkStartedOn - growStartedOn + parseInt(dataItemInfo[2])
              }
              

              if (growAnalyticsData.energyData["power"] === undefined) {
                growAnalyticsData.energyData["power"] = []            
              }
              if (growAnalyticsData.energyData["cost"] === undefined) {
                growAnalyticsData.energyData["cost"] = []            
              }

              growAnalyticsData.energyData["power"].push({x: forTime, y: power})
              growAnalyticsData.energyData["cost"].push({x: forTime, y: cost})

              growAnalyticsData.haveNewEnergyData = true
              newEnergyData = true;

              
            }

          }else {
            //Live data chunk
            for (let forTime in energyDataChunk["live_values"]) {
              let isNew = false

              const pointTime = forTime - growStartedOn
              if (growAnalyticsData.energyData["power"] === undefined) {
                growAnalyticsData.energyData["power"] = []            
              }
              if (growAnalyticsData.energyData["cost"] === undefined) {
                growAnalyticsData.energyData["cost"] = []            
              }
              growAnalyticsData.energyData["power"].push({x: pointTime, y: energyDataChunk["live_values"][forTime]["power"]})
              growAnalyticsData.energyData["cost"].push({x: pointTime, y: energyDataChunk["live_values"][forTime]["cost"]})
              newEnergyData = true;
              
              if (pointTime > growAnalyticsData.haveDataUpUntil)  {
                growAnalyticsData.haveDataUpUntil = parseInt(pointTime)
              }
            }

            currentEnergyDataChunk.to = growAnalyticsData.haveDataUpUntil
          }


          if (newEnergyData)  {
            growAnalyticsData.energyData["power"].sort( function(a , b)  {
              if(a.x > b.x) 
                return 1;
              if(a.x < b.x) 
                return -1;
              return 0
              
            });
            growAnalyticsData.energyData["cost"].sort( function(a , b)  {
              if(a.x > b.x) 
                return 1;
              if(a.x < b.x) 
                return -1;
              return 0
              
            });


            growAnalyticsData.changedEnergyVersion += 1
          }

        }


        return grow
      })

    },

    [getGrowAnalyticsData.rejected]: (state, action) => {
      state.loadingGrowAnalyticsDataStatus = 'rejected';
      state.grows.map((grow) => {
        if (!action.meta.arg.growIds.includes(grow.id))  {
          return grow
        }
        for (let dataRecordingTimePeriodTypeIndex in action.meta.arg.entries)  {
          grow.analyticsData.timePeriods[dataRecordingTimePeriodTypeIndex].loadingStatus = 'rejected'
        }
        return grow
      })
    },

    

    [getGrowLiveData.pending]: (state, action) => {
      state.grows = state.grows.map((grow) => {
        if (action.meta.arg.growId !== undefined && grow.id === action.meta.arg.growId)  {
          return {...grow, loading_live_data_status: "pending"}
        }
        if (action.meta.arg.growIds !== undefined) {
          for (let growId of action.meta.arg.growIds) {
            if (grow.id === growId)  {
              return {...grow, loading_live_data_status: "pending"}
            }
          }
        }
        return grow
      })
    },

    [getGrowLiveData.fulfilled]: (state, action) => {
      state.grows = state.grows.map((grow) => {
        if (action.meta.arg.growId !== undefined && grow.id === action.meta.arg.growId)  {
          return {...grow, loading_live_data_status: "fulfilled", last_live_data_loaded_on: new Date().getTime()}
        }
        if (action.meta.arg.growIds !== undefined) {
          for (let growId of action.meta.arg.growIds) {
            if (grow.id === growId)  {
              return {...grow, loading_live_data_status: "fulfilled", last_live_data_loaded_on: new Date().getTime()}
            }
          }
        }
        return grow
      })
      

      if (action.meta.arg.growId !== undefined) {
        const data = action.payload.data[action.meta.arg.growId]
        if (data !== undefined) {
          //state.liveData[grow.id] = data;
          //state.lastLiveDataUpdateOn[grow.id] = new Date().getTime()
          //processNewGrowData(state, growId)

          state.grows = state.grows.map((grow) => {
            if (grow.id !== action.meta.arg.growId) {
              return grow
            }
            grow.liveData = {...grow.liveData, ...data}
            //grow.lastLiveDataUpdateOn = new Date().getTime()
            return processNewGrowData(state, grow)
          })
        }
      }
      if (action.meta.arg.growIds !== undefined) {
        state.grows = state.grows.map((grow) => {
          for (let growId of action.meta.arg.growIds) {
            const data = action.payload.data[growId]
            if (data !== undefined) {
              //state.liveData[growId] = data;
              //state.lastLiveDataUpdateOn[growId] = new Date().getTime()
              //processNewGrowData(state, growId)
              if (grow.id === growId) {
                grow.liveData = {...grow.liveData, ...data}
                //grow.lastLiveDataUpdateOn = new Date().getTime()
                return processNewGrowData(state, grow)
              }
            }
          }
          return grow
        })
      }

    },

    [getGrowLiveData.rejected]: (state, action) => {
      state.grows = state.grows.map((grow) => {
        if (action.meta.arg.growId !== undefined && grow.id === action.meta.arg.growId)  {
          return {...grow, loading_live_data_status: "rejected"}
        }
        if (action.meta.arg.growIds !== undefined) {
          for (let growId of action.meta.arg.growIds) {
            if (grow.id === growId)  {
              return {...grow, loading_live_data_status: "rejected"}
            }
          }
        }
        return grow
      })
    },



    [getGrowDosingHistory.pending]: (state) => {
      state.loadingGrowHistoryStatus = 'pending';
    },

    [getGrowDosingHistory.fulfilled]: (state, action) => {
      const grow = state.grows.find((grow) => grow.id === action.meta.arg.growId)
      const growStartedOn = new Date(grow.started_on).getTime()
      let startTime = new Date();
      
      //Process the dosing events and store with the grow
      for (let dosingEvent of action.payload.dose_events)  {
        if (grow.dosingEvents[dosingEvent.id] === undefined)  {
          if (dosingEvent.key == "dosing_started")  {
            let newDosingInstance = {
              startedEvent: dosingEvent,
              injectEvents: [],
              dosingItems: {},
              completedEvent: null,
              startedDosingOn: dosingEvent.occurred_on
            }
            grow.dosingInstances.push(newDosingInstance)
            grow.dosingEvents[dosingEvent.id] = dosingEvent
          }else {
            grow.unassignedDosingEvents[dosingEvent.id] = dosingEvent
          }

          if (grow.haveDosingEventsUpUntil < dosingEvent.occurred_on) {
            grow.haveDosingEventsUpUntil = dosingEvent.occurred_on + 1
          }
        }
      }
      //console.log("B", new Date() - startTime)
      startTime = new Date();
      for (let dosingEvent of Object.values(grow.unassignedDosingEvents))  {
      
        //Find the associated dosing instance
        let foundDosingInstance = null;
        for (let dosingInstance of grow.dosingInstances)  {
          if ((dosingEvent.key !== "dosing_completed" && dosingEvent.key !== "dosing_terminated") || dosingInstance.completedEvent === null)  {
            if (foundDosingInstance === null || (dosingInstance.startedEvent.occurred_on > foundDosingInstance.startedEvent.occurred_on && dosingInstance.startedEvent.occurred_on < dosingEvent.occurred_on))  {
              foundDosingInstance = dosingInstance
            }
          }
        }

        if (foundDosingInstance !== null) {
          if (dosingEvent.key == "dosing_completed" || dosingEvent.key == "dosing_terminated") {
            
            grow.dosingEvents[dosingEvent.id] = dosingEvent
            delete grow.unassignedDosingEvents[dosingEvent.id]


            foundDosingInstance.completedEvent = dosingEvent
          }else if (dosingEvent.key == "inject_start") {
            grow.dosingEvents[dosingEvent.id] = dosingEvent
            delete grow.unassignedDosingEvents[dosingEvent.id]
            foundDosingInstance.injectEvents.push({
              startedEvent: dosingEvent,
              dosingItems: [],
              completedEvent: null
            })
          }else if (dosingEvent.key == "inject_completed")  {
            let foundInjectInstance = null;
            for (let injectEvent of foundDosingInstance.injectEvents)  {
              if (foundInjectInstance === null || (injectEvent.startedEvent.occurred_on > foundInjectInstance.startedEvent.occurred_on && injectEvent.startedEvent.occurred_on < dosingEvent.occurred_on))  {
                foundInjectInstance = injectEvent
              }
            }

            if (foundInjectInstance !== null) {
              grow.dosingEvents[dosingEvent.id] = dosingEvent
              delete grow.unassignedDosingEvents[dosingEvent.id]
              foundInjectInstance.completedEvent = dosingEvent
            }
          }
        }
      }
      //console.log("C", new Date() - startTime)
      startTime = new Date();

 
      for (let dosingItem of action.payload.dose_items)  {
        if (grow.dosingItems[dosingItem.dosed_on] === undefined)  {
          grow.unassignedDosingItems[dosingItem.dosed_on] = dosingItem

          //dosingItem.assignedToInstance = false
          dosingItem.totalVolume = 0

          if (grow.haveDosingEventsUpUntil < dosingItem.dosed_on) {
            grow.haveDosingEventsUpUntil = dosingItem.dosed_on + 1
          }
        }
      }
      //console.log("D", new Date() - startTime)
      startTime = new Date();

      let nutrientsTotalChanged = false
      for (let dosingItem of Object.values(grow.unassignedDosingItems)) {
        let foundDosingInstance = null;
        for (let dosingInstance of grow.dosingInstances)  {
          if (foundDosingInstance === null || (dosingInstance.startedEvent.occurred_on > foundDosingInstance.startedEvent.occurred_on && dosingInstance.startedEvent.occurred_on < dosingItem.dosed_on))  {
            foundDosingInstance = dosingInstance
          }
        }

        if (foundDosingInstance !== null)  {
          
          if (foundDosingInstance.dosingItems[[dosingItem.type, dosingItem.ref_id]] === undefined)  {

            if (state.assignedDosingItemColors[[dosingItem.type, dosingItem.ref_id]] === undefined) {
              state.assignedDosingItemColors[[dosingItem.type, dosingItem.ref_id]] = doseItemColors[(state.assignedDosingItemColorIndex - 1) % doseItemColors.length]
              state.assignedDosingItemColorIndex++
            }

            foundDosingInstance.dosingItems[[dosingItem.type, dosingItem.ref_id]] = {
              type: dosingItem.type,
              ref_id: dosingItem.ref_id,
              volume: 0,
              totalVolume: 0,
              color: state.assignedDosingItemColors[[dosingItem.type, dosingItem.ref_id]]
            }
          }
          if (grow.dosingItemTotals[[dosingItem.type, dosingItem.ref_id]] === undefined)  {
            grow.dosingItemTotals[[dosingItem.type, dosingItem.ref_id]] = 0
          }

          
          grow.dosingItemTotals[[dosingItem.type, dosingItem.ref_id]] += dosingItem.volume


          dosingItem.totalVolume = grow.dosingItemTotals[[dosingItem.type, dosingItem.ref_id]]
          foundDosingInstance.dosingItems[[dosingItem.type, dosingItem.ref_id]].volume += dosingItem.volume
          foundDosingInstance.dosingItems[[dosingItem.type, dosingItem.ref_id]].totalVolume = grow.dosingItemTotals[[dosingItem.type, dosingItem.ref_id]]
          
          if (dosingItem.type === "nutrient_solution" || dosingItem.type === "solution")  {
            grow.analyticsData.nutrientsTotal += parseFloat(dosingItem.volume) / 1000
            grow.analyticsData.nutrientsTotalData.push({x: dosingItem.dosed_on - growStartedOn, y: grow.analyticsData.nutrientsTotal})
            nutrientsTotalChanged = true
          }


          grow.dosingItems[dosingItem.dosed_on] = dosingItem
          delete grow.unassignedDosingItems[dosingItem.dosed_on]        
        }


      }

      if (nutrientsTotalChanged) {
        grow.analyticsData.nutrientsTotalVersion += 1
      }
      //console.log("E", new Date() - startTime)


      //console.log(grow.dosingInstances)

      //action.payload.grows.map(function(grow){ processGrowFromAPI(grow); return grow });
      //state.grows = action.payload.grows;
      state.loadingGrowHistoryStatus = 'fulfilled';

      //console.log(new Date() - startTime)
    },

    [getGrowDosingHistory.rejected]: (state) => {
      state.loadingGrowHistoryStatus = 'rejected';
    },



    [scheduleNewGrow.pending]: (state, action) => {
      //state.loadingGrowAnalyticsDataStatus = 'pending';
      /*state.currentGrowTempId = parseInt(action.meta.arg.grow["temp_id"]) + 1
      state.grows = [...state.grows, processGrowFromAPI({...action.meta.arg.grow})]*/
      
    },

    [scheduleNewGrow.fulfilled]: (state, action) => {
      if (action.payload.error !== undefined && action.payload.error)  {
        console.log(action.payload.error)
        action.meta.arg.callback(false)
      }else {

        if (action.payload.new_grow !== null) {
          console.log(action.payload.new_grow)
          state.grows.push(processGrowFromAPI(state, action.payload.new_grow))
        }
        if (action.meta.arg.callback !== undefined) {
          action.meta.arg.callback(true)
        }
      }
    },

    [scheduleNewGrow.rejected]: (state, action) => {
      /*let foundGrow = state.grows.find((g) => g["temp_id"] === action.meta.arg.grow["temp_id"])
      state.grows.splice(state.grows.indexOf(foundGrow), 1)*/
      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(false)
      }

    },
    

    [changeGrowStartDate.pending]: (state, action) => {
      //state.loadingGrowAnalyticsDataStatus = 'pending';
      /*state.currentGrowTempId = parseInt(action.meta.arg.grow["temp_id"]) + 1
      state.grows = [...state.grows, processGrowFromAPI({...action.meta.arg.grow})]*/
      
    },

    [changeGrowStartDate.fulfilled]: (state, action) => {
      if (action.payload.error !== undefined && action.payload.error)  {
        console.log(action.payload.error)
        action.meta.arg.callback(false)
      }else {

        if (action.payload.success !== null && action.payload.success) {
          //Change grow start date here

          const foundGrow = state.grows.find((g) => g.id === action.meta.arg.growId)
          if (foundGrow !== undefined)  {
            foundGrow.started_on = action.meta.arg.newStartDate
          }

          if (action.meta.arg.callback !== undefined) {
            action.meta.arg.callback(true)
          }
        }else {
          if (action.meta.arg.callback !== undefined) {
            action.meta.arg.callback(false)
          }
        }
      }
    },

    [changeGrowStartDate.rejected]: (state, action) => {
      /*let foundGrow = state.grows.find((g) => g["temp_id"] === action.meta.arg.grow["temp_id"])
      state.grows.splice(state.grows.indexOf(foundGrow), 1)*/
      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(false)
      }

    },
    
    


    [getGrowGerminationBoardLocations.pending]: (state, action) => {
      state.grows = state.grows.map((grow) => {
        if (grow.id !== action.meta.arg.growId)  {
          return grow
        }

        return {...grow, loading_germination_locations:true}
      })
    },

    [getGrowGerminationBoardLocations.fulfilled]: (state, action) => {
      if (action.payload.locations !== undefined) {
        state.grows = state.grows.map((grow) => {
          if (grow.id !== action.meta.arg.growId)  {
            return grow
          }

          return {...grow, loaded_germination_locations: true, loading_germination_locations:false, germination_locations: action.payload.locations}
        })
      }
    },

    [getGrowGerminationBoardLocations.rejected]: (state, action) => {
      state.grows = state.grows.map((grow) => {
        if (grow.id !== action.meta.arg.growId)  {
          return grow
        }

        return {...grow, loaded_germination_locations: false, loading_germination_locations:false, germination_locations: {}}
      })
    },





    [getGrowNurseryBoardLocations.pending]: (state, action) => {
      state.grows = state.grows.map((grow) => {
        if (grow.id !== action.meta.arg.growId)  {
          return grow
        }

        return {...grow, loading_nursery_locations:true}
      })
    },

    [getGrowNurseryBoardLocations.fulfilled]: (state, action) => {
      if (action.payload.locations !== undefined) {
        state.grows = state.grows.map((grow) => {
          if (grow.id !== action.meta.arg.growId)  {
            return grow
          }

          return {...grow, loaded_nursery_locations: true, loading_nursery_locations:false, nursery_locations: action.payload.locations}
        })
      }
    },

    [getGrowNurseryBoardLocations.rejected]: (state, action) => {
      state.grows = state.grows.map((grow) => {
        if (grow.id !== action.meta.arg.growId)  {
          return grow
        }

        return {...grow, loaded_nursery_locations: false, loading_nursery_locations:false, nursery_locations: {}}
      })
    },
    

    [getGrowGrowoutBoardLocations.pending]: (state, action) => {
      state.grows = state.grows.map((grow) => {
        if (grow.id !== action.meta.arg.growId)  {
          return grow
        }

        return {...grow, loading_growout_locations:true}
      })
    },

    [getGrowGrowoutBoardLocations.fulfilled]: (state, action) => {
      if (action.payload.entries !== undefined) {
        state.grows = state.grows.map((grow) => {
          if (grow.id !== action.meta.arg.growId)  {
            return grow
          }

          return {...grow, loaded_growout_locations: true, loading_growout_locations:false, growout_location_entries: action.payload.entries}
        })
      }
    },

    [getGrowGrowoutBoardLocations.rejected]: (state, action) => {
      state.grows = state.grows.map((grow) => {
        if (grow.id !== action.meta.arg.growId)  {
          return grow
        }

        return {...grow, loaded_growout_locations: false, loading_growout_locations:false, growout_location_entries: []}
      })
    },


    
    [markGrowZoneEntryAsHarvested.pending]: (state, action) => {
      /*state.grows = state.grows.map((grow) => {
        if (grow.id !== action.meta.arg.growId)  {
          return grow
        }

        return {...grow, loading_growout_locations:true}
      })*/
    },

    [markGrowZoneEntryAsHarvested.fulfilled]: (state, action) => {
      state.grows = state.grows.map((grow) => {
        if (grow.id !== action.meta.arg.growId)  {
          return grow
        }

        return {...grow, loaded_growout_locations: true, loading_growout_locations:false, growout_location_entries: action.payload.updated_entries}
      })
      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(true)
      }
    },

    [markGrowZoneEntryAsHarvested.rejected]: (state, action) => {
      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(false)
      }
      /*state.grows = state.grows.map((grow) => {
        if (grow.id !== action.meta.arg.growId)  {
          return grow
        }

        return {...grow, loaded_growout_locations: false, loading_growout_locations:false, growout_location_entries: []}
      })*/      
    },

    [getAllGrowPhotos.pending]: (state, action) => {
      state.grows = state.grows.map((grow) => {
        if (grow.id !== action.meta.arg.growId)  {
          return grow
        }

        return {...grow, loading_all_grow_photos:true, grow_photos_version: grow.grow_photos_version + 1}
      })
    },

    [getAllGrowPhotos.fulfilled]: (state, action) => {
      if (action.payload.photos !== undefined) {
        state.grows = state.grows.map((grow) => {
          if (grow.id !== action.meta.arg.growId)  {
            return grow
          }

          return {...grow, loaded_all_grow_photos: true, loading_all_grow_photos:false, grow_photos: action.payload.photos.map((p) => {
            return {...p, downloaded: false, downloading: false, src: null, last_download_attempt_on: undefined}
          }), grow_photos_version: grow.grow_photos_version + 1}
        })
      }
    },

    [getAllGrowPhotos.rejected]: (state, action) => {
      state.grows = state.grows.map((grow) => {
        if (grow.id !== action.meta.arg.growId)  {
          return grow
        }

        return {...grow, loaded_all_grow_photos: false, loading_all_grow_photos:false, grow_photos: [], grow_photos_version: grow.grow_photos_version + 1}
      })
    },

    
    [editGrowPhoto.pending]: (state, action) => {
      state.grows = state.grows.map((grow) => {
        if (grow.id !== action.meta.arg.growId)  {
          return grow
        }

        return {...grow, grow_photos:[...grow.grow_photos.map((gP) => {
          if (gP.id !== action.meta.arg.photoId)  {
            return gP
          }
          return {...gP, editing: true}
        }), ], grow_photos_version: grow.grow_photos_version + 1}
      })
    },

    [editGrowPhoto.fulfilled]: (state, action) => {
      state.grows = state.grows.map((grow) => {
        if (grow.id !== action.meta.arg.growId)  {
          return grow
        }

        return {...grow, grow_photos:[...grow.grow_photos.map((gP) => {
          if (gP.id !== action.meta.arg.photoId)  {
            return gP
          }
          return {...gP, editing: false, ...action.meta.arg.props}
        }), ], grow_photos_version: grow.grow_photos_version + 1}
      })

      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(true)
      }
    },

    [editGrowPhoto.rejected]: (state, action) => {
      state.grows = state.grows.map((grow) => {
        if (grow.id !== action.meta.arg.growId)  {
          return grow
        }

        return {...grow, grow_photos:[...grow.grow_photos.map((gP) => {
          if (gP.id !== action.meta.arg.photoId)  {
            return gP
          }
          return {...gP, editing: true}
        }), ], grow_photos_version: grow.grow_photos_version + 1}
      })
      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(false)
      }
    },
    

    
    [deleteGrowPhoto.pending]: (state, action) => {
      state.grows = state.grows.map((grow) => {
        if (grow.id !== action.meta.arg.growId)  {
          return grow
        }

        return {...grow, grow_photos:[...grow.grow_photos.map((gP) => {
          if (gP.id !== action.meta.arg.photoId)  {
            return gP
          }
          return {...gP, deleting: true}
        }), ], grow_photos_version: grow.grow_photos_version + 1}
      })
    },

    [deleteGrowPhoto.fulfilled]: (state, action) => {
      state.grows = state.grows.map((grow) => {
        if (grow.id !== action.meta.arg.growId)  {
          return grow
        }

        return {...grow, grow_photos:[...grow.grow_photos.filter((gP) => {
          return gP.id !== action.meta.arg.photoId
        }), ], grow_photos_version: grow.grow_photos_version + 1}
      })
      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(true)
      }
    },

    [deleteGrowPhoto.rejected]: (state, action) => {
      state.grows = state.grows.map((grow) => {
        if (grow.id !== action.meta.arg.growId)  {
          return grow
        }

        return {...grow, grow_photos:[...grow.grow_photos.map((gP) => {
          if (gP.id !== action.meta.arg.photoId)  {
            return gP
          }
          return {...gP, deleting: false}
        }), ], grow_photos_version: grow.grow_photos_version + 1}
      })
      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(false)
      }
    },
    


    [createTransplantToGrowOutFromNurseryEntry.pending]: (state, action) => {
      state.grows = state.grows.map((grow) => {
        if (grow.id !== action.meta.arg.growId)  {
          return grow
        }

        return {...grow, performing_growout_transplant:true}
      })
    },

    [createTransplantToGrowOutFromNurseryEntry.fulfilled]: (state, action) => {
      
      if (action.payload.success !== undefined && action.payload.nursery_locations !== undefined && action.payload.growout_location_entries !== undefined && action.payload.success) {
        
        state.grows = state.grows.map((grow) => {
          if (grow.id !== action.meta.arg.growId)  {
            return grow
          }

          return {...grow, performing_growout_transplant:false, nursery_locations: action.payload.nursery_locations, growout_location_entries: action.payload.growout_location_entries}
        })

        if (action.meta.arg.callback !== undefined) {
          action.meta.arg.callback(true)
        }
      }else {
        if (action.meta.arg.callback !== undefined) {
          action.meta.arg.callback(false)
        }
      }
    },

    [createTransplantToGrowOutFromNurseryEntry.rejected]: (state, action) => {
      state.grows = state.grows.map((grow) => {
        if (grow.id !== action.meta.arg.growId)  {
          return grow
        }

        return {...grow, performing_growout_transplant:false, growout_location_entries: []}
      })

      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(false)
      }
    },
    
    
    
  }
})




export const InitializeDataRecordingTimePeriodTypes = ({growIds, dataRecordingTimePeriodTypes}) => {
  const dispatch = useDispatch()
  const activeGrows = useSelector((state) => selectGrowsByIds(state, growIds))
  
  React.useEffect(() => {
    for (let grow of activeGrows) {
      if (grow.initializedDataRecordingTimePeriodTypes === false) {
        //Validate that the data type has been initialzed for this grow
        dispatch(initializeDataRecordingTimePeriodTypes({growId: grow.id, dataRecordingTimePeriodTypes: dataRecordingTimePeriodTypes}))

      }
    }
  }, [activeGrows, dataRecordingTimePeriodTypes])
}

export const MaintainGrowAnalyticsData = ({growIds, mainRange, overviewRange, mainDataRecordingTimePeriodType, overviewDataRecordingTimePeriodType, interval = 2000}) => {
  const dispatch = useDispatch()
  const activeGrows = useSelector((state) => selectGrowsByIds(state, growIds))
  const dataRecordingTimePeriodTypes = useSelector(selectAllDataRecordingTimePeriodTypes)
  //const growAnalyticsData = useSelector((state) => selectGrowAnalyticsDataForGrowIds(state, growIds), _.isEqual)

  const validateGrowAnalyticsDataToLoad = () => {

    let analyticsRequestEntries = {}
    let requestingEntriesForGrows = []

    let growsToLoadAnalyticsData = []
    let currentTime = new Date().getTime()
    for (let grow of activeGrows) {
      if (grow.loading_analytics_data_status === "idle") {
        growsToLoadAnalyticsData.push(grow)
      } else if (grow.loading_analytics_data_status === "fulfilled" || grow.loading_analytics_data_status === "rejected") {
        if (grow.last_analytics_data_loaded_on === undefined || currentTime - grow.last_analytics_data_loaded_on > interval) {
          growsToLoadAnalyticsData.push(grow)
        }
      }
    }
    const addDataRequest = (timePeriodTypeId, requestFrom, requestTo, growId) => {
      let requestKey = requestFrom + "-" + requestTo
      if (analyticsRequestEntries[timePeriodTypeId] === undefined) {
        analyticsRequestEntries[timePeriodTypeId] = {}
      }
      if (analyticsRequestEntries[timePeriodTypeId][requestKey] === undefined) {
        analyticsRequestEntries[timePeriodTypeId][requestKey] = []
      }
      if (analyticsRequestEntries[timePeriodTypeId][requestKey].indexOf(growId) === -1) {
        analyticsRequestEntries[timePeriodTypeId][requestKey].push(growId)
      }
      if (!requestingEntriesForGrows.includes(growId))  {
        requestingEntriesForGrows.push(growId)
      }
    }


    for (let grow of growsToLoadAnalyticsData) {
      if (grow.analyticsData !== undefined && grow.analyticsData.timePeriods !== undefined) {
        let mainAnalyticsData = grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.id]
        let overviewAnalyticsData = grow.analyticsData.timePeriods[overviewDataRecordingTimePeriodType.id]
        //Check if we can load overview data

        if (overviewAnalyticsData !== undefined) {
          let overviewFromEntryIndex = Math.floor((overviewRange.start / 1000) / (overviewDataRecordingTimePeriodType.duration))
          let overviewToEntryIndex = Math.floor((overviewRange.end / 1000) / (overviewDataRecordingTimePeriodType.duration))
          //console.log(overviewAnalyticsData.dataChunks)
          for (let entryIndex = overviewFromEntryIndex; entryIndex <= overviewToEntryIndex; entryIndex++) {
            if (overviewAnalyticsData.dataChunks[entryIndex] !== undefined) {
              //console.log(overviewAnalyticsData.dataChunks[entryIndex])
              if (!overviewAnalyticsData.dataChunks[entryIndex].completed) {
                //Lets build a request for the portion of data we don't have
                let requestRange = { "from": overviewAnalyticsData.haveDataUpUntil + 1, "to": (entryIndex + 1) * overviewDataRecordingTimePeriodType.duration * 1000 }
                if (requestRange["to"] - requestRange["from"] > 1000) { //minimum request range
                  addDataRequest(overviewDataRecordingTimePeriodType.id, requestRange["from"], requestRange["to"], grow.id)
                }
              }
            } else {
              addDataRequest(overviewDataRecordingTimePeriodType.id, overviewRange.start, overviewRange.end, grow.id)
            }

          }
        }
        if (overviewDataRecordingTimePeriodType != mainDataRecordingTimePeriodType) {
          //Check if we can load main data
          let mainFromEntryIndex = Math.floor((mainRange.start / 1000) / (mainDataRecordingTimePeriodType.duration))
          let mainToEntryIndex = Math.floor((mainRange.end / 1000) / (mainDataRecordingTimePeriodType.duration))


          //console.log(mainAnalyticsData.dataChunks)
          for (let entryIndex = mainFromEntryIndex; entryIndex <= mainToEntryIndex; entryIndex++) {
            if (mainAnalyticsData.dataChunks[entryIndex] !== undefined) {
              //console.log(mainAnalyticsData.dataChunks[entryIndex])
              if (!mainAnalyticsData.dataChunks[entryIndex].completed) {
                //Lets build a request for the portion of data we don't have
                let requestRange = { "from": mainAnalyticsData.haveDataUpUntil + 1, "to": (entryIndex + 1) * mainDataRecordingTimePeriodType.duration * 1000 }
                if (requestRange["to"] - requestRange["from"] > 1000) { //minimum request range
                  addDataRequest(mainDataRecordingTimePeriodType.id, requestRange["from"], requestRange["to"], grow.id)
                }
              }
            } else {
              addDataRequest(mainDataRecordingTimePeriodType.id, mainRange.start, mainRange.end, grow.id)
            }

          }
        }
      }
    }

    if (Object.entries(analyticsRequestEntries).length > 0) {
      dispatch(getGrowAnalyticsData({ entries: analyticsRequestEntries, growIds: requestingEntriesForGrows, timePeriodTypes: dataRecordingTimePeriodTypes }))
    }
  }
  
  useEffect(() => {
    const statusLoadInterval = setInterval(() => {
      validateGrowAnalyticsDataToLoad()
    }, interval);
    return () => clearInterval(statusLoadInterval);
  }, [growIds, mainRange, overviewRange, mainDataRecordingTimePeriodType, overviewDataRecordingTimePeriodType]);
}

export const MaintainGrowLiveData = ({growIds, interval=1000}) => {
  const dispatch = useDispatch()
  const allGrows = useSelector((state) => selectAllGrows(state))

  const validateGrowLiveDataToLoad = () => {
    let growsToLoadLiveData = []
    let currentTime = new Date().getTime()

    if (growIds !== undefined)  {
      for (let growId of growIds) {
        let foundGrow = allGrows.find((g) => g.id === growId)
        if (foundGrow !== undefined)  {
          if (foundGrow.loading_live_data_status === "idle") {
            growsToLoadLiveData.push(foundGrow.id)
          }else if (foundGrow.loading_live_data_status === "fulfilled" || foundGrow.loading_live_data_status === "rejected") {
            if (foundGrow.last_live_data_loaded_on === undefined || currentTime - foundGrow.last_live_data_loaded_on > interval) {
              growsToLoadLiveData.push(foundGrow.id)
            }
          }
        }
      }
    }
    if (growsToLoadLiveData.length > 0)  {
      dispatch(getGrowLiveData({growIds: growsToLoadLiveData}))
    }
  }

  useEffect(() => {
    const statusLoadInterval = setInterval(() => {
      validateGrowLiveDataToLoad()
    }, interval / 10);
    validateGrowLiveDataToLoad()
    return () => clearInterval(statusLoadInterval);
  }, [allGrows, growIds]);
}




export const MaintainLoadAllGrows = ({facilityIds}) => {
  const dispatch = useDispatch()

  const isSessionActive = useSelector(state => IsSessionActive(state))
  const loadedAllGrows = useSelector((state) => state.grows.loadedAllGrows)
  const loadedAllGrowsForFacilities = useSelector((state) => state.grows.loadedAllGrowsForFacilities)
  const loadingAllGrowStatus = useSelector((state) => state.grows.loadingAllGrowStatus)
  useEffect(() => {
    if (isSessionActive && loadingAllGrowStatus !== "pending" && !loadedAllGrows)  {
      if (facilityIds === undefined || facilityIds === "all") {
        dispatch(getAllGrows({}))
      }else {
        //Check for loaded all blade zones in the facility TODO
        let facilitiesToLoadGrowsFor = []
        for (const facilityId of facilityIds) {
          if (!loadedAllGrowsForFacilities.includes(facilityId)) {
            facilitiesToLoadGrowsFor.push(facilityId)
          }
        }
        if (facilitiesToLoadGrowsFor.length > 0) {
          dispatch(getAllGrows({facilityIds: facilitiesToLoadGrowsFor}))
        }
      }
    }    
  }, [facilityIds, isSessionActive, loadedAllGrows, loadedAllGrowsForFacilities, loadingAllGrowStatus])
}





// Action creators are generated for each case reducer function
export const {
  growUpdate, 
  initializeDataRecordingTimePeriodTypes,
  addZoneInfoToGrow,
  addedNewGrowPhoto,
  setPhotoUploadProgress,
  downloadingGrowPhoto,
  downloadingGrowPhotoSuccessful,
  downloadingGrowPhotoFailed,
} = growsSlice.actions

export default growsSlice.reducer

export const selectAllGrows = state => state.grows.grows
export const selectAllGrowGroups = state => state.grows.groups

export const selectAllGrowIds = state => state.grows.grows.map((g) => g.id)
export const selectAllGrowIdsSortedByScheduledOn = state => state.grows.grows.toSorted((a, b) => new Date(a.scheduled_for).getTime() > new Date(b.scheduled_for).getTime()).map((g) => g.id)
/*state =>  state.grows.grows.map((g) => {
  console.log(new Date(g.scheduled_for).getTime())
  return g
})*/

export const selectAllGrowIdsCategorized = state => {
  let categorizedGrows = {
    "completed": [],
    "active": []
  }
  for (let grow of state.grows.grows) {
    if (grow.completed) {
      categorizedGrows["completed"].push(grow.id)
    }else {
      categorizedGrows["active"].push(grow.id)
    }
  }

  return categorizedGrows
}
export const selectAllGrowIdsCategorizedAndSortedByDate = state => {
  let categorizedGrows = {
    "completed": [],
    "active": []
  }
  for (let grow of state.grows.grows.toSorted((a, b) => {
    if (a.started_on !== null && b.started_on !== null)  {
      return new Date(a.started_on).getTime() > new Date(b.started_on).getTime()
    }else if (a.started_on !== null)  {
      return new Date(a.started_on).getTime() > new Date(b.scheduled_for).getTime()
    }else if (b.started_on !== null)  {
      return new Date(a.scheduled_for).getTime() > new Date(b.started_on).getTime()
    }
    return new Date(a.scheduled_for).getTime() > new Date(b.scheduled_for).getTime()
  })) {
    if (grow.completed) {
      categorizedGrows["completed"].push(grow.id)
    }else {
      categorizedGrows["active"].push(grow.id)
    }
  }

  return categorizedGrows
}

export const selectAllGrowPlanIdsForGrows = (state, growIds) => {
  let growPlanIds = []
  for (let grow of state.grows.grows.filter((g) => growIds.includes(g.id))) {
    if (!growPlanIds.includes(grow.grow_plan_id))  {
      growPlanIds.push(grow.grow_plan_id)
    }
  }
  return growPlanIds
}

export const selectAllRecipeInfoForGrows = (state, growIds) => {
  let recipeInfo = []
  for (let grow of state.grows.grows.filter((g) => growIds.includes(g.id))) {
    if (recipeInfo.find((rI) => rI.id === grow.recipe_id && rI.version === grow.recipe_version) === undefined)  {
      recipeInfo.push({
        id: grow.recipe_id,
        version: grow.recipe_version
      })
    }
  }
  return recipeInfo
}

export const selectGrowLastLiveDataLoadedOn = (state, ids) => {
  let ld = {}
  for (let grow of state.grows.grows) {
    ld[grow.id] = grow.lastLiveDataUpdateOn
  }
  return ld
}

export const selectGrowAnalyticsDataForGrowIds = (state, growIds) => {
  let analyticsData = {}
  for (let grow of state.grows.grows.filter((g) => growIds.includes(g.id))) {
    analyticsData[grow.id] = grow.analyticsData
  }
  return analyticsData
}

export const selectGrowById = (state, growId) =>
  state.grows.grows.find(grow => grow.id === growId)

export const selectGrowsByIds = (state, growIds) =>
  state.grows.grows.filter(grow => growIds.includes(grow.id))


export const selectGrowPlanByGrowId = (state, growId) => {
  const foundGrow = state.grows.grows.find(grow => grow.id === growId)
  if (foundGrow !== undefined)  {
    return selectGrowPlanById(state, foundGrow.grow_plan_id)
  }
  return undefined
}
export const selectRecipeByGrowId = (state, growId) => {
  const foundGrow = state.grows.grows.find(grow => grow.id === growId)
  if (foundGrow !== undefined)  {
    return selectRecipeByIdAndVersion(state, foundGrow.recipe_id, foundGrow.recipe_version)
  }
  return undefined
}

export const selectGrowNumberOfUnits = (state, growId) => {
  const foundGrow = state.grows.grows.find(grow => grow.id === growId)
  if (foundGrow !== undefined)  {
    return foundGrow.number_of_units
  }
  return 0
}



export const selectGrowDates = (state, growId, recipe_duration = null) => {
  const foundGrow = state.grows.grows.find(grow => grow.id === growId)
  if (foundGrow !== undefined)  {
    let dates = {start: null, end: null}
    if (foundGrow.pending_start)  {
      if (foundGrow.scheduled_type === "nad" || foundGrow.scheduled_type === "start_date") {
        dates.start = new Date()
        if (recipe_duration !== null) {
          dates.end = new Date(dates.start.getTime() + (recipe_duration * 1000))
        }
      }else if (recipe_duration !== null) {
        if (new Date().getTime() + (recipe_duration * 1000) > new Date(foundGrow.scheduled_for).getTime()) {
          dates.start = new Date()
          dates.end = new Date(dates.start.getTime() + (recipe_duration * 1000))
        } else {
          dates.end = new Date(foundGrow.scheduled_for)
          dates.start = new Date(dates.end.getTime() - (recipe_duration * 1000))
        }
      }
    }else {
      dates.start = new Date(foundGrow.started_on)
      if (foundGrow.completed) {
        dates.end = new Date(foundGrow.finished_on)
      } else if (recipe_duration !== null) {
        dates.end = new Date(dates.start.getTime() + recipe_duration * 1000)
      }
    }
    return dates
  }
  return null
}



export const selectGrowDurationsForGrowIds = (state, growIds) => {
  let growDurations = {}
  for (let growId of growIds) {
    let grow = state.grows.grows.find((g) => g.id === growId)
    if (grow !== undefined) {
      growDurations[growId] = grow.duration
    }
  }

  return growDurations
}
export const selectGrowTotalScore = (state, growId)  => {
  const foundGrow = state.grows.grows.find((g) => g.id === growId)
  if (foundGrow !== undefined) {
    return foundGrow.totalScore
  }
  return 100
}
export const selectGrowDisplayName = (state, growId) => {
  const foundGrow = state.grows.grows.find((g) => g.id === growId)
  if (foundGrow !== undefined) {
    const foundGrowPlan = selectGrowPlanById(state, foundGrow.grow_plan_id)
    if (foundGrowPlan !== undefined)  {
      return foundGrowPlan.name
    }
  }
  return "Unknown Grow Plan"
}
export const selectGrowBladeZoneUIDs = (state, growId) => {
  const foundGrow = state.grows.grows.find((g) => g.id === growId)
  if (foundGrow !== undefined && foundGrow.grow_out_zone_info !== null) {
    return [...new Set(foundGrow.grow_out_zone_info.map((gO) => gO.zone_uid))]
  }
  return []
}
export const selectGrowBladeZoneInfo = (state, growId) => {
  const foundGrow = state.grows.grows.find((g) => g.id === growId)
  if (foundGrow !== undefined) {
    return foundGrow.grow_out_zone_info
  }
  return []
}

export const selectGrowStatus = (state, growId) => {
  const foundGrow = state.grows.grows.find((g) => g.id === growId)
  if (foundGrow !== undefined) {
    switch(foundGrow.stage) {
      case "pending":
        return "Waiting to Seed"
      case "in_germination":
        return "Germinating"
      case "in_nursery":
        return "In Nursery"
      case "in_growout":
        return "In Growout"
      default:
        return "Pending"
    }
  }
  return "Unknown Status"
}

export const selectGrowUpcomingTaskId = (state, growId) => {
  const foundGrow = state.grows.grows.find((g) => g.id === growId)
  if (foundGrow !== undefined) {
    const growTasks = selectGrowTasks(state, growId)
    //console.log(growTasks)
    switch(foundGrow.stage) {
      case "pending":
        return growTasks.find((t) => t.key === "grow_plant_seeds_for_germination")?.id
      case "in_germination":
        return growTasks.find((t) => t.key === "grow_transplant_to_nursery_from_germination")?.id
      case "in_nursery":
        return growTasks.find((t) => t.key === "grow_transplant_to_grow_zone_from_nursery")?.id
      case "in_growout":
        return growTasks.find((t) => t.key === "grow_harvest")?.id
      default:
        return null
    }
  }
  return null
}

export const selectGrowTasks = (state, growId) => {
  return selectTasksByCategoryAndReferenceId(state, "grow", growId)
}