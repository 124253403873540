import '../BladeInstance.scss';
import variables from '../../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { AdditionalOptions } from '../../../../assets/icons/Icons';
import DropDownButton from '../../../../components/DropDownButton';
import { getLiveDataItem, selectAllBladeConfigurationMaps, selectBladeComponentInfo, selectBladeLiveDataByComponentInfo, selectBladeRuntimeInformation, setBladeRuntimeProperty } from '../../../../redux/entities/service/Blade';
import { DataStatus_DataList } from '../../../../components/DataStatus/DataStatus';
import { selectAllAccountPrivileges } from '../../../../redux/entities/Account';
import { parseBool } from '../../../../helpers';
import Switch from '../../../../components/Switch';
import NumberInput from '../../../../components/input/NumberInput';



const DataStatus_PrecoolCoilCell = ({ bladeId }) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const dispatch = useDispatch()

  const accountPrivileges = useSelector((state) => selectAllAccountPrivileges(state), _.isEqual)
  const accountHasServicingPriviliges = (accountPrivileges["servicing"] !== undefined)
  const [inServiceModeRuntimeInformation] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "service_mode"), _.isEqual)
  const inServiceMode = accountHasServicingPriviliges ? parseBool(inServiceModeRuntimeInformation) : false

  const [precoolEEVInOverrideRuntimeInformation] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "precool_eev_position_override"), _.isEqual)
  const precoolEEVInOverride = parseBool(precoolEEVInOverrideRuntimeInformation)
  const [precoolEEVOverrideSpeedRuntimeInformation] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "precool_eev_position"), _.isEqual)

  const [precoolPRVInOverrideRuntimeInformation] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "precool_prv_position_override"), _.isEqual)
  const precoolPRVInOverride = parseBool(precoolPRVInOverrideRuntimeInformation)
  const [precoolPRVOverrideSpeedRuntimeInformation] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "precool_prv_position"), _.isEqual)


  const [leavingTempComponentInfo, superheatComponentInfo] 
  = useSelector((state) => selectBladeComponentInfo(state, bladeId, "PrecoolCoil", ["l-temp", "sh"]), _.isEqual)
  const [EEVPositionComponentInfo] 
  = useSelector((state) => selectBladeComponentInfo(state, bladeId, "PrecoolCoilEEV", ["output_signal"]), _.isEqual)
  const [PRVPositionComponentInfo] 
  = useSelector((state) => selectBladeComponentInfo(state, bladeId, "PrecoolCoilPRV", ["output_signal"]), _.isEqual)
  const [coilPressureComponentInfo] 
  = useSelector((state) => selectBladeComponentInfo(state, bladeId, "AccumulatorSubcoolPressureTransducer", ["pressure", "sh"]), _.isEqual)

  let EEVPosition = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, EEVPositionComponentInfo), _.isEqual)
  let PRVPosition = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, PRVPositionComponentInfo), _.isEqual)
  let leavingTemp = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, leavingTempComponentInfo), _.isEqual)
  let coilPressure = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, coilPressureComponentInfo), _.isEqual)
  let superheat = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, superheatComponentInfo), _.isEqual)

  let dataListItems = [
    {
      label: "EEV Position", value: EEVPosition.value, suffix: "%", visualization: {
        type: "bar", info: { usesRange: true, min: 0, max: 100, status: "normal" }
      }
    },
    {
      label: "PRV Position", value: PRVPosition.value, suffix: "%", visualization: {
        type: "bar", info: { usesRange: true, min: 0, max: 100, status: "normal" }
      }
    },
    {
      label: "Pressure", value: coilPressure.value, suffix: "psi", visualization: {
        type: "bar", info: { usesRange: true, min: 95, max: 250, status: "normal" }
      }
    },
    {
      label: "Superheat", value: superheat.value, suffix: "°C", visualization: {
        type: "bar", info: { usesRange: true, min: 0, max: 30, status: "normal" }
      }
    },
    {
      label: "Leaving Temp", value: leavingTemp.value, suffix: "°C",
    },
  ]




  const precoolEEVOverrideToggled = (state) => {
    dispatch(setBladeRuntimeProperty({ bladeId: bladeId, properties: { "precool_eev_position_override": state ? 1 : 0 } }))
  }
  const precoolEEVOverrideSpeedChanged = (speed) => {
    dispatch(setBladeRuntimeProperty({ bladeId: bladeId, properties: { "precool_eev_position": speed  } }))
  }

  const precoolPRVOverrideToggled = (state) => {
    dispatch(setBladeRuntimeProperty({ bladeId: bladeId, properties: { "precool_prv_position_override": state ? 1 : 0 } }))
  }
  const precoolPRVOverrideSpeedChanged = (speed) => {
    dispatch(setBladeRuntimeProperty({ bladeId: bladeId, properties: { "precool_prv_position": speed  } }))
  }
  return (<>
    <div className="DataStatus-Cell">
      <div className="DataStatus-Cell-Header-Container">
        <div className="DataStatus-Cell-Header-PrimaryContent">
          <div className="Text-H14">Precool Coil</div>
        </div>
        <div className="DataStatus-Cell-Header-AuxContent">
          <DropDownButton content={<AdditionalOptions />} status={"Neutral"} />
        </div>
      </div>
      <div className="DataStatus-Cell-Content-Container">
        <DataStatus_DataList items={dataListItems} />
      </div>
      {inServiceMode && <>
        <div className="DataStatus-Override-Container">
          <div className="DataStatus-Override-Toggle">
            <Switch state={precoolEEVInOverride} onSwitch={precoolEEVOverrideToggled}/>
            <div className="Text-Alt14">EEV Override</div>
          </div>
          {precoolEEVInOverride && 
            <NumberInput min={0} max={100} value={precoolEEVOverrideSpeedRuntimeInformation} onChange={precoolEEVOverrideSpeedChanged}/>
          }
        </div>
        <div className="DataStatus-Override-Container">
          <div className="DataStatus-Override-Toggle">
            <Switch state={precoolPRVInOverride} onSwitch={precoolPRVOverrideToggled}/>
            <div className="Text-Alt14">PRV Override</div>
          </div>
          {precoolPRVInOverride && 
            <NumberInput min={0} max={100} value={precoolPRVOverrideSpeedRuntimeInformation} onChange={precoolPRVOverrideSpeedChanged}/>
          }
        </div>
      </>}
    </div>
  </>)
}

export default DataStatus_PrecoolCoilCell