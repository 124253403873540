import '../BladeInstance.scss';
import variables from '../../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { AdditionalOptions } from '../../../../assets/icons/Icons';
import DropDownButton from '../../../../components/DropDownButton';
import { getLiveDataItem, selectAllBladeConfigurationMaps, selectBladeComponentInfo, selectBladeLiveDataByComponentInfo, selectBladeRuntimeInformation, setBladeRuntimeProperty } from '../../../../redux/entities/service/Blade';
import { DataStatus_DataList } from '../../../../components/DataStatus/DataStatus';
import { selectAllAccountPrivileges } from '../../../../redux/entities/Account';
import { parseBool } from '../../../../helpers';
import Switch from '../../../../components/Switch';
import NumberInput from '../../../../components/input/NumberInput';



const DataStatus_BypassDamperCell = ({ bladeId }) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const dispatch = useDispatch()
  
  const accountPrivileges = useSelector((state) => selectAllAccountPrivileges(state), _.isEqual)
  const accountHasServicingPriviliges = (accountPrivileges["servicing"] !== undefined)
  const [inServiceModeRuntimeInformation] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "service_mode"), _.isEqual)
  const inServiceMode = accountHasServicingPriviliges ? parseBool(inServiceModeRuntimeInformation) : false

  const [bypassDamperInOverrideRuntimeInformation] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "bypass_damper_position_override"), _.isEqual)
  const bypassDamperInOverride = parseBool(bypassDamperInOverrideRuntimeInformation)
  const [bypassDamperOverrideSpeedRuntimeInformation] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "bypass_damper_position"), _.isEqual)

  const [damperPositionComponentInfo] = useSelector((state) => selectBladeComponentInfo(state, bladeId, "BypassDamper", ["position"]), _.isEqual)
  const damperPosition = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, damperPositionComponentInfo), _.isEqual)
  
  
  let dataListItems = [
    {
      label: "Position", value: damperPosition.value, suffix: "%", visualization: {
        type: "bar", info: { usesRange: true, min: 0, max: 100, status: "normal" }
      }
    },
  ]
  

  const bypassDamperOverrideToggled = (state) => {
    dispatch(setBladeRuntimeProperty({ bladeId: bladeId, properties: { "bypass_damper_position_override": state ? 1 : 0 } }))
  }
  const bypassDamperOverrideSpeedChanged = (speed) => {
    dispatch(setBladeRuntimeProperty({ bladeId: bladeId, properties: { "bypass_damper_position": speed  } }))
  }


  return (<>
    <div className="DataStatus-Cell">
      <div className="DataStatus-Cell-Header-Container">
        <div className="DataStatus-Cell-Header-PrimaryContent">
          <div className="Text-H14">Bypass Damper</div>
        </div>
        <div className="DataStatus-Cell-Header-AuxContent">
          <DropDownButton content={<AdditionalOptions />} status={"Neutral"} />
        </div>
      </div>
      <div className="DataStatus-Cell-Content-Container">
        <DataStatus_DataList items={dataListItems} />
      </div>
      {inServiceMode && 
        <div className="DataStatus-Override-Container">
          <div className="DataStatus-Override-Toggle">
            <Switch state={bypassDamperInOverride} onSwitch={bypassDamperOverrideToggled}/>
            <div className="Text-Alt14">Override</div>
          </div>
          {bypassDamperInOverride && 
            <NumberInput min={0} max={100} value={bypassDamperOverrideSpeedRuntimeInformation} onChange={bypassDamperOverrideSpeedChanged}/>
          }
        </div>
      }
    </div>
  </>)
}

export default DataStatus_BypassDamperCell