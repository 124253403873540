import '../BladeInstance.scss';
import variables from '../../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { AdditionalOptions } from '../../../../assets/icons/Icons';
import DropDownButton from '../../../../components/DropDownButton';
import { getLiveDataItem, selectAllBladeConfigurationMaps, selectBladeComponentInfo, selectBladeLiveDataByComponentInfo, selectBladeRuntimeInformation, setBladeRuntimeProperty } from '../../../../redux/entities/service/Blade';
import { DataStatus_DataList } from '../../../../components/DataStatus/DataStatus';
import { parseBool } from '../../../../helpers';
import { selectAllAccountPrivileges } from '../../../../redux/entities/Account';
import NumberInput from '../../../../components/input/NumberInput';
import Switch from '../../../../components/Switch';



const DataStatus_CompressorCell = ({ bladeId }) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const dispatch = useDispatch()
    

    const accountPrivileges = useSelector((state) => selectAllAccountPrivileges(state), _.isEqual)
    const accountHasServicingPriviliges = (accountPrivileges["servicing"] !== undefined)
    const [inServiceModeRuntimeInformation] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "service_mode"), _.isEqual)
    const inServiceMode = accountHasServicingPriviliges ? parseBool(inServiceModeRuntimeInformation) : false

    const [compressorInOverrideRuntimeInformation] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "compressor_speed_override"), _.isEqual)
    const compressorInOverride = parseBool(compressorInOverrideRuntimeInformation)
    const [compressorOverrideSpeedRuntimeInformation] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "compressor_speed"), _.isEqual)


  const [airTempManagementStatus] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "air_temp_management_mode"))
  const [airRHManagementStatus] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "air_rh_management_mode"))

  const [compressorSpeedComponentInfo, compressorRPMComponentInfo, compressorStateComponentInfo]
    = useSelector((state) => selectBladeComponentInfo(state, bladeId, "Compressor", ["control", "rpm", "state", "sh"]), _.isEqual)

  const [lowPressureComponentInfo]
    = useSelector((state) => selectBladeComponentInfo(state, bladeId, "CompressorInletPressureTransducer", ["pressure"]), _.isEqual)

  const [dischargeTempComponentInfo]
    = useSelector((state) => selectBladeComponentInfo(state, bladeId, "ACDischargeOWTB", ["t"]), _.isEqual)
  const compressorSpeed = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, compressorSpeedComponentInfo), _.isEqual)
  const lowPressure = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, lowPressureComponentInfo), _.isEqual)
  const dischargeTemp = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, dischargeTempComponentInfo), _.isEqual)

  const compressorState = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, compressorStateComponentInfo), _.isEqual)
 
  let ACStatus = ""
  if (airTempManagementStatus === "Cooling") {
    ACStatus += "Cooling"
  }else if (airTempManagementStatus === "Heating")  {
    ACStatus += "Heating"
  }

  if (airRHManagementStatus === "Dehumidify") {
    if (ACStatus != "") {
      ACStatus += " and "
    }
    ACStatus += "Dehumid"
  }else if (airRHManagementStatus === "Humidify") {
    if (ACStatus != "") {
      ACStatus += " and "
    }
    ACStatus += "Humidify"
  }
  
  if (ACStatus === "")  {
    ACStatus = "Off"
  }



  let dataListItems = [
    {
      label: "Speed", value: compressorSpeed.value, suffix: "%", visualization: {
        type: "bar", info: { usesRange: true, min: 0, max: 100, status: "normal" }
      }
    },
    {
      label: "L. Pressure", value: lowPressure.value, suffix: "psi", visualization: {
        type: "bar", info: { usesRange: true, min: 90, max: 220, status: "normal" }
      }
    },
    {
      label: "Discharge Temp", value: dischargeTemp.value, suffix: "°C", visualization: {
        type: "bar", info: { usesRange: true, min: 40, max: 95, status: "normal" }
      }
    },
  ]
  
  const compressorOverrideToggled = (state) => {
    dispatch(setBladeRuntimeProperty({ bladeId: bladeId, properties: { "compressor_speed_override": state ? 1 : 0 } }))
  }
  const compressorOverrideSpeedChanged = (speed) => {
    dispatch(setBladeRuntimeProperty({ bladeId: bladeId, properties: { "compressor_speed": speed  } }))
  }



  return (<>
    <div className="DataStatus-Cell">
      <div className="DataStatus-Cell-Header-Container">
        <div className="DataStatus-Cell-Header-PrimaryContent">
          <div className="Text-H14">Compressor</div>
          <div className="DataStatus-Cell-Header-PrimaryContent-StatusChip">{ACStatus}</div>
        </div>
        <div className="DataStatus-Cell-Header-AuxContent">
          <DropDownButton content={<AdditionalOptions />} status={"Neutral"} />
        </div>
      </div>
      <div className="DataStatus-Cell-Content-Container">
        <DataStatus_DataList items={dataListItems} />
      </div>
      
      {inServiceMode && 
        <div className="DataStatus-Override-Container">
          <div className="DataStatus-Override-Toggle">
            <Switch state={compressorInOverride} onSwitch={compressorOverrideToggled}/>
            <div className="Text-Alt14">Override</div>
          </div>
          {compressorInOverride && 
            <NumberInput min={0} max={100} value={compressorOverrideSpeedRuntimeInformation} onChange={compressorOverrideSpeedChanged}/>
          }
        </div>
      }
    </div>
  </>)
}

export default DataStatus_CompressorCell