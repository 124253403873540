import './FlowsPage.scss';
import variables from '../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { getInventoryItemTypeById, selectAllInventoryItemTypes } from '../../../redux/entities/Inventory';
import { selectFlowNutrientReservoirsByFlowId, selectFlowRuntimeInformation, setFlowRuntimeProperty } from '../../../redux/entities/service/Flow';
import { RoundToNearest } from '../../../helpers';
import DropDownButton from '../../../components/DropDownButton';
import Button from '../../../components/Button';
import { AdditionalOptions, HalfFullCup, Plus } from '../../../assets/icons/Icons';
import Tag from '../../../components/Tag';
import Pill from '../../../components/Pill';




const FlowInstance_ReservoirsContent = ({ flowId, activateReservoirCallback, topUpFlowReservoirCallback, setReservoirVolumeCallback }) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const dispatch = useDispatch()

  const allInventoryItemTypes = useSelector((state) => selectAllInventoryItemTypes(state))
  const loadingItemTypesStatus = useSelector((state) => state.inventory.loadingItemTypesStatus)


  const flowNutrientReservoirs = useSelector((state) => selectFlowNutrientReservoirsByFlowId(state, flowId), _.isEqual)
  const [dosingReservoirs, SetDosingResevoirs] = React.useState({})
  React.useEffect(() => {

    let dosingReservoirs = {}
    for (let [index, info] of Object.entries(flowNutrientReservoirs)) {
      dosingReservoirs[index] = {...info}
      let referenceId = parseInt(info["reference_id"]);

      let foundInventoryItemType = allInventoryItemTypes.find((iT) => iT.id === referenceId)
      if (foundInventoryItemType !== undefined)  {
        dosingReservoirs[parseInt(index)]["item"] = foundInventoryItemType
      }
    }
  


    SetDosingResevoirs(dosingReservoirs)

  }, [flowNutrientReservoirs, allInventoryItemTypes])



  React.useEffect(() => {
    let inventoryItemTypesToLoad = []

    for (let [index, info] of Object.entries(dosingReservoirs)) {
      if (info.connected) {
        let referenceId = parseInt(info["reference_id"]);

        let foundInventoryItemType = allInventoryItemTypes.find((iT) => iT.id === referenceId)
        if (foundInventoryItemType === undefined)  {
          if (inventoryItemTypesToLoad.indexOf(referenceId) === -1)  {
            inventoryItemTypesToLoad.push(referenceId)
          }          
        }
      }
    }

    if (loadingItemTypesStatus === "idle" || loadingItemTypesStatus === "fulfilled")  {
      if (inventoryItemTypesToLoad.length > 0) {
        dispatch(getInventoryItemTypeById({itemTypes: inventoryItemTypesToLoad}))
      }
    }
  }, [dosingReservoirs, allInventoryItemTypes, loadingItemTypesStatus])






  let numberOfReservoirsPerRow = 1;
  if (isTablet) {
    numberOfReservoirsPerRow = 2;
  }else if (isDesktop && !isWideDesktop)  {
    numberOfReservoirsPerRow = 3;
  }else if (isWideDesktop) {
    numberOfReservoirsPerRow = 4;
  }
  return (<>
    <div className="FlowStatus-Flow-Reservoirs-List-Container">
      <div className={"FlowStatus-Flow-Reservoirs-List FlowStatus-Flow-Reservoirs-ListSize" + numberOfReservoirsPerRow}>
        {Object.entries(dosingReservoirs).map(([index, info]) => {
          return (<FlowInstance_ReservoirsContent_Reservoir 
            flowId={flowId} reservoirIndex={index} reservoirInfo={info} 
            topUpFlowReservoirCallback={topUpFlowReservoirCallback} setReservoirVolumeCallback={setReservoirVolumeCallback}
          />)
        })}
      </div>
    </div>
  </>)
}

export default FlowInstance_ReservoirsContent



const FlowInstance_ReservoirsContent_Reservoir = ({
  flowId, reservoirIndex, reservoirInfo, 
  activateReservoirCallback, topUpFlowReservoirCallback, setReservoirVolumeCallback
}) => {

  const dispatch = useDispatch()

  const reservoirRuntimeInformationKey = "dosing_reservoir_info_" + reservoirIndex.toString()
  const [, , pendingReservoirInfoUpdatesString] = useSelector((state) => selectFlowRuntimeInformation(state, flowId, reservoirRuntimeInformationKey))

  const markReservoirForPrime = (reservoirIndex) => {
    let pendingReservoirInfoUpdates = {}
    if (pendingReservoirInfoUpdatesString !== "")   {
        pendingReservoirInfoUpdates = JSON.parse(pendingReservoirInfoUpdatesString)
    }
    pendingReservoirInfoUpdates["primed"] = 0
    dispatch(setFlowRuntimeProperty({flowId: flowId, properties: {
        [reservoirRuntimeInformationKey]: JSON.stringify({...pendingReservoirInfoUpdates})
    }, callback: (success) => {
      
    }}))
  }

  
  const markReservoirAsInstalled = (reservoirIndex) => {
    let pendingReservoirInfoUpdates = {}
    if (pendingReservoirInfoUpdatesString !== "")   {
        pendingReservoirInfoUpdates = JSON.parse(pendingReservoirInfoUpdatesString)
    }
    pendingReservoirInfoUpdates["installed"] = 1
    dispatch(setFlowRuntimeProperty({flowId: flowId, properties: {
        [reservoirRuntimeInformationKey]: JSON.stringify({...pendingReservoirInfoUpdates})
    }, callback: (success) => {
      
    }}))
  }
  const markReservoirAsUninstalled = (reservoirIndex) => {
    let pendingReservoirInfoUpdates = {}
    if (pendingReservoirInfoUpdatesString !== "")   {
        pendingReservoirInfoUpdates = JSON.parse(pendingReservoirInfoUpdatesString)
    }
    pendingReservoirInfoUpdates["installed"] = 0
    pendingReservoirInfoUpdates["primed"] = 0
    dispatch(setFlowRuntimeProperty({flowId: flowId, properties: {
        [reservoirRuntimeInformationKey]: JSON.stringify({...pendingReservoirInfoUpdates})
    }, callback: (success) => {
      
    }}))
  }
  
  let reservoirAdditionalOptions = []
  if (reservoirInfo.connected === true) {
    reservoirAdditionalOptions.push(
      {key: "top_up", label: "Top Up"},
      {key: "change_contents", label: "Change Contents"},
      {key: "set_volume", label: "Set Volume"},
    )


    if (reservoirInfo.runtime_information["installed"] !== 1) {
      reservoirAdditionalOptions.push(
        {key: "reinstall", label: "Reinstall"},
      )
    }else {
      if (reservoirInfo.runtime_information["primed"] === 1) {
        reservoirAdditionalOptions.push(
          {key: "prime", label: "Mark For Prime"},
        )
      }else {
  
      }
      reservoirAdditionalOptions.push(
        {key: "uninstall", label: "Uninstall"},
      )
    }
  
    if (reservoirInfo["connected"] === true) {
      reservoirAdditionalOptions.push(
        {key: "deactivate", label: "Deactivate"},
      )
    }
      
  }else {
    reservoirAdditionalOptions.push(
      {key: "activate", label: "Activate"},
    )
  }

  
  
  const handleReservoirAdditionalOptionSelected = (reservoirIndex, option) =>  {
    switch (option) {
      case "top_up":
        topUpFlowReservoirCallback(reservoirIndex)
        break
      case "change_contents":
        break

      case "prime":
        markReservoirForPrime(reservoirIndex)
        break

      case "set_volume":
        setReservoirVolumeCallback(reservoirIndex)
        break

      case "uninstall":
        markReservoirAsUninstalled(reservoirIndex)
        break

      case "reinstall":
        markReservoirAsInstalled(reservoirIndex)
        break

      default:
        break
    }
  }



  let reservoirVolume = 0
  if (reservoirInfo["reservoir_volume"] !== undefined)  {
    reservoirVolume = parseFloat(reservoirInfo["reservoir_volume"]) / 1000;
  } 
  let reservoirLowVolume = 0
  if (reservoirInfo["reservoir_low_volume"] !== undefined)  {
    reservoirLowVolume = parseFloat(reservoirInfo["reservoir_low_volume"]) / 1000;
  }

  let volumeRemaining = 0
  if (reservoirInfo["runtime_information"]["volume_remaining"] !== undefined)  {
    volumeRemaining = parseFloat(reservoirInfo["runtime_information"]["volume_remaining"]) / 1000;
  }

  let reservoirDisplayName = "-"
  if (reservoirInfo["item"]["name"] !== undefined) {
    reservoirDisplayName = reservoirInfo["item"]["name"]
  }



  let reservoirStatusInfo = {}
  if (reservoirInfo["connected"] !== true) {
    reservoirStatusInfo.activated = false
    reservoirStatusInfo = {...reservoirStatusInfo, color: "#3C3C3C", status: "Unused", borderColor: "#DDE3EB"}
  }else {
    reservoirStatusInfo.activated = true

    if (reservoirInfo.runtime_information["installed"] !== 1) {
      reservoirStatusInfo.installedState = false
      reservoirStatusInfo = {...reservoirStatusInfo, color: "#EC3C3C", status: "Not Installed", borderColor: "#EC3C3C"}
    }else {


      reservoirStatusInfo.installedState = true

      if (reservoirInfo.runtime_information["primed"] !== 1) {
        reservoirStatusInfo.primedState = false
        if (reservoirInfo.runtime_information["priming"] === 1) {
          reservoirStatusInfo = {...reservoirStatusInfo, color: "#E99E0B", status: "Priming", borderColor: "#E99E0B"}
        }else {
          reservoirStatusInfo = {...reservoirStatusInfo, color: "#E99E0B", status: "Not Primed", borderColor: "#E99E0B"}
        }
      }else {
        reservoirStatusInfo.primedState = true
        reservoirStatusInfo = {...reservoirStatusInfo, color: "#4F5562", status: "Primed", borderColor: "#DDE3EB"}
      }

    }
  }


  let reservoirVolumeState = "Okay"
  if (reservoirStatusInfo.activated) {
    if (volumeRemaining == 0) {
      reservoirVolumeState = "Empty"
    }else if (volumeRemaining < reservoirLowVolume) {
      reservoirVolumeState = "Low"
    }
  }


  return (<>
    <div className="FlowStatus-Flow-Reservoirs-Reservoir-Container" key={reservoirIndex}>
      <div className="FlowStatus-Flow-Reservoirs-Reservoir" style={{borderColor: reservoirStatusInfo.borderColor}}>
        <div className="FlowStatus-Flow-Reservoirs-Reservoir-MainContent">

          <div className="FlowStatus-Flow-Reservoirs-Reservoir-MainContent-Header">
            <div className="Text-Alt14">Reservoir {reservoirIndex}</div>
            <DropDownButton
              content={<div style={{display:"flex", padding:"7px 0px"}}><AdditionalOptions/></div>} 
              options={reservoirAdditionalOptions}
              onOptionSelected={((option) => {
                handleReservoirAdditionalOptionSelected(reservoirIndex, option.key)
              })}/>
          </div>

          <div className="FlowStatus-Flow-Reservoirs-Reservoir-VolumeAvailable">
            {reservoirStatusInfo.activated && <>
              <div className="Text-Medium-S24">
                {RoundToNearest(volumeRemaining, 0.1)}
              </div>
              <div className="Text-S14">
                /
              </div>
              <div className="Text-Alt14">
                {RoundToNearest(reservoirVolume, 0.1)}L
              </div>
            </>}
            {!reservoirStatusInfo.activated && <>-</>}
          </div>

          <div className="FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Container">
            <div className={"FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Background FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Background-" + reservoirVolumeState}></div>
            {reservoirStatusInfo.activated && <>
              <div className={"FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Bar FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Bar-" + reservoirVolumeState}
                style={{width: ((volumeRemaining / reservoirVolume) * 100) + "%"}}
                ></div>
              <div className="FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-LowVolumeIndicator"
                style={{left: ((reservoirLowVolume / reservoirVolume) * 100) + "%"}}></div>
            </>}
          </div>
          <div className="FlowStatus-Flow-Reservoirs-Reservoir-SolutionInfo-Container">
            <div className="FlowStatus-Flow-Reservoirs-Reservoir-SolutionInfo-Name Text-S16">{reservoirDisplayName}</div>
            <Pill content={reservoirStatusInfo.status} color={reservoirStatusInfo.color}/>
          </div>


          
            
            {!reservoirStatusInfo.activated && <>
              <div className="FlowStatus-Flow-Reservoirs-Reservoir-Functions-Container">
                <Button
                  content={<div style={{display:"flex", gap: 10, alignItems: "center"}}><Plus width={14.5} fill={"#4D5563"}/><span>Activate</span></div>} 
                  status="Small-IconAction" 
                  width={"Flex100"}
                  onClick={() => {activateReservoirCallback(reservoirIndex)}}/>
              </div>
            </>}
            
        </div>

      </div>
    </div>
  </>)
}