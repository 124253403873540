import './ManufacturingPage.scss';

import React from 'react'
import { Routes, Route, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import {TabControl, TabControlTab} from '../../components/TabControl.js';
import DropDownInput from '../../components/input/DropDownInput.js'
import Button from '../../components/Button.js'

import ExpensesPage from '../Expenses/ExpensesPage';
import RevenuePage from '../Revenue/RevenuePage';
import InventoryPage from '../Inventory/InventoryPage';
import RecordsPage from '../Records/RecordsPage';
import ServicesPage from '../Services/ServicesPage';
import { getAllFacilities, getAllServicesForFacility, selectAllFacilities } from '../../redux/entities/Facilities.js';
import { useDispatch, useSelector } from 'react-redux';
import { getBladeByServiceId, selectAllBlades } from '../../redux/entities/service/Blade';
import { getFlowByServiceId, selectAllFlows } from '../../redux/entities/service/Flow';
import { getHVACByServiceId, selectAllHVAC } from '../../redux/entities/service/HVAC';
import GrowRacksPage from './GrowRacks/GrowRacksPage.js';
import FlowsPage from './Flows/FlowsPage.js';
import ControlDevicesPage from './ControlDevices/ControlDevicesPage.js';


const ManufacturingPage = ({pageHeaderHelper}) => {

  
 

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const selectedTab = splitLocation.length >= 3 ? splitLocation[3] : null

  const { facilityID } = useParams();
  const [selectedFacility, SetSelectedFacility] = React.useState(facilityID ? parseInt(facilityID) : null);
  React.useLayoutEffect(() => {
    SetSelectedFacility(facilityID ? parseInt(facilityID) : null)
  }, [facilityID])
  const allFacilities = useSelector(selectAllFacilities)
  //console.log(allFacilities)
  const blades = useSelector(selectAllBlades)
  const Flows = useSelector(selectAllFlows)
  let haveInitialFacilitiesData = useSelector((state) => state.facilities.haveInitialData)

  React.useEffect( () =>  { 
    if (!haveInitialFacilitiesData) {
      dispatch(getAllFacilities({}))
    }
  }, [allFacilities, haveInitialFacilitiesData])


  const validateLocation = () =>  {
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    if (splitLocation.length == 2 && !Number.isNaN(splitLocation[2]))  {
      navigate("/manufacturing/" + splitLocation[2] + "/blade")
    }else if (splitLocation.length >= 2 && Number.isNaN(splitLocation[2]))  {
      navigate("/manufacturing/")
    }else {
      //SetSelectedFacility(parseInt(splitLocation[2]))
    }
  }
  React.useEffect(() => {
    validateLocation()
  }, [location])
  React.useLayoutEffect(() => {
    validateLocation()
  }, [])








  const [facilitySelectionOptions, SetFacilitySelectionOptions] = React.useState([]);
  const selectedFacilityChanged = (value) => {
    const { pathname } = location;
    let splitLocation = pathname.split("/");
    splitLocation[2] = value
    navigate(splitLocation.join("/"))
    //SetIsAllSelected(value === "all")
    //SetSelectedFacility(value)
  }
  
  React.useEffect(() => {
    //Check location to see if we are in services, if so we need to limit the options to not include all farms
    let currentFacilitySelectionOptions = []
    let availableFacilities = allFacilities.filter((f) => f.type === "manu")
    for (let facility of availableFacilities)  {
      currentFacilitySelectionOptions.push({
        value: facility.id,
        label: facility.name
      })
    }
    
    if (selectedFacility === null) {
      if (availableFacilities.length > 0) {
        selectedFacilityChanged(currentFacilitySelectionOptions[0].value)
      }else {
        //Uh oh
      }
    }


    SetFacilitySelectionOptions(currentFacilitySelectionOptions)
  }, [allFacilities, selectedFacility, location])




  
  const tabs = [new TabControlTab("Blade", "blade"),
                new TabControlTab("Flow", "flow"),
                new TabControlTab("Control Boards", "control_devices")]
  
  const tabControlTabClickedRef = React.useRef(null);
  const [pageHeaderRightSideContent, SetPageHeaderRightSideContent] = React.useState(<></>)
  const setPageHeader = () => {
    pageHeaderHelper.current.Reset()
    pageHeaderHelper.current.SetTitle("Manufacturing")
    
    pageHeaderHelper.current.SetTabControlTabs(tabs);
    pageHeaderHelper.current.SetHeaderTabControlActivePathIndex(3);
    pageHeaderHelper.current.SetTabControlTabClickCallback((tab) => {
      if (tabControlTabClickedRef.current) {
        tabControlTabClickedRef.current(tab);
      }
    });

    pageHeaderHelper.current.SetRightSideContent(() => {
      return (
        <>
          <div className="ControlBar_Horizontal">
            <div className="ControlBar_Horizontal-Right">
              {pageHeaderRightSideContent}
              <DropDownInput placeholder={"Select Facility"} options={facilitySelectionOptions} value={selectedFacility} onSelectionChange={selectedFacilityChanged}/>
              {/*<Button content={"Create Farm"}/>*/}
            </div>
          </div>
        </>
      )
    })
    
    pageHeaderHelper.current.SubPageUpdateHeader()

  }
  React.useLayoutEffect(() => {
    setPageHeader()
  }, [])
  React.useLayoutEffect(() => {
    setPageHeader()
  }, [facilitySelectionOptions])

  React.useLayoutEffect(() => {
    setPageHeader()
  }, [pageHeaderRightSideContent])


  const SetRightSideContent = (content) =>  {
    SetPageHeaderRightSideContent(content)
  }


  

  React.useEffect(() => {
    const { pathname } = location;
    let splitLocation = pathname.split("/");
    let serviceType = splitLocation[4]

    let foundFacility = allFacilities.find((f) => f.id === selectedFacility)
    if (foundFacility !== undefined)  {
      if (!foundFacility.initialServicesLoaded)  {
        dispatch(getAllServicesForFacility({facilityId: foundFacility.id}))
      }else {
      
        let bladeServicesToLoad = []
        let bladeServiceUIDs = {}
        for (let service of foundFacility.services.filter((s) => s.service_type === "blade")) {
          let foundBladeService = blades.find((b) => b.serviceId === service.id)
          if (foundBladeService === undefined)  {
            bladeServicesToLoad.push(service.id)
            bladeServiceUIDs[service.id] = service.uid
          }
        }
        if (bladeServicesToLoad.length > 0) {
          console.log(bladeServicesToLoad)
          dispatch(getBladeByServiceId({bladeServiceIds: bladeServicesToLoad, bladeServiceUIDs: bladeServiceUIDs}))
        }

        let FlowServicesToLoad = []
        for (let service of foundFacility.services.filter((s) => s.service_type === "nds")) {
          let foundFlowService = Flows.find((nds) => nds.serviceId === service.id)
          if (foundFlowService === undefined)  {
            FlowServicesToLoad.push(service.id)
          }
        }
        if (FlowServicesToLoad.length > 0) {
          dispatch(getFlowByServiceId({FlowServiceIds: FlowServicesToLoad}))
        }



      }
      //console.log(foundFacility.services)
    }
  }, [allFacilities, selectedFacility, location])






  return (
    <div className="PageWrapper_Standard">
      <TabControl activePathIndex={3} tabs={tabs}
        hideTabs={true}
        tabClickedRef={(ref) => {tabControlTabClickedRef.current=ref;}}
        content={
          <div id="ManufacturingPage">
            
            <div id="ManufacturingPage_TabContent">
              <Routes>
                <Route path="/blade" element={
                  <GrowRacksPage setRightSideContent={(content) => SetRightSideContent(content)} selectedFacility={selectedFacility}/>
                }/>
                <Route path="/flow" element={
                  <FlowsPage setRightSideContent={(content) => SetRightSideContent(content)} selectedFacility={selectedFacility}/>
                }/>
                <Route path="/control_devices" element={
                  <ControlDevicesPage setRightSideContent={(content) => SetRightSideContent(content)} selectedFacility={selectedFacility}/>
                }/>
                
                <Route path="*" element={<Navigate to="" replace />} />
              </Routes>
            </div>
          </div>
        }/>
    </div>
  )
} 

export default ManufacturingPage