

 

export const ConnectToBluetoothSlave = async (name, serviceUID) => {
    try {
        const device = await navigator.bluetooth.requestDevice({
        filters: [
            {
            name: name,
            },
        ],
        optionalServices: [serviceUID],
        });
        const server = await device.gatt?.connect();
        const service = await server.getPrimaryService( serviceUID );
        if (service !== undefined)  {
            return [service, true, null]
        }
    }catch (error)  {
        console.error(error);
        return [null, false, error]
    }
};

export const ConnectToControlDeviceBluetoothSlave = async (serialNumber, type) => {
    switch (type)   {
        case "blade":
            return await ConnectToBluetoothSlave("BLADE-" + serialNumber, "c2f18d24-51cb-412b-9df4-95e52f0e11e2")
        case "nds":
            return await ConnectToBluetoothSlave("FLOW-" + serialNumber, "c2f18d24-51cb-412b-9df4-95e52f0e11e2")
        default:
            return null
    }
}

export const SendWiFiCredentialsToBladeBluetoothSlave = async (service, wifiSSID, wifiPassword) => {
    try {
        const manageWiFiSSIDCharacteristic = await service.getCharacteristic( "fb1a967f-6386-40e4-a94e-4be783fcf296" );
        const manageWiFiPasswordCharacteristic = await service.getCharacteristic( "f05989b8-0df3-437a-8a4b-63bdca89813a" );

        const encoder = new TextEncoder('utf-8');
        const encodedWiFiSSID = encoder.encode(wifiSSID);
        await manageWiFiSSIDCharacteristic.writeValue(encodedWiFiSSID);

        const encodedWiFiPass = encoder.encode(wifiPassword);
        await manageWiFiPasswordCharacteristic.writeValue(encodedWiFiPass);

        return [true, null]

    } catch (error)  {
        console.error(error);
        return [false, error]
    }
}



  
export const SendBladeUIDToBladeBluetoothSlave = async (service, bladeUID) => {
    try {
      const manageBladeUIDCharacteristic = await service.getCharacteristic( "2716011a-1ca5-4d68-a5d6-fe286b3e5092" );

      const encoder = new TextEncoder('utf-8');
      const encodedBladeUID = encoder.encode(bladeUID);
      await manageBladeUIDCharacteristic.writeValue(encodedBladeUID);     

      return [true, null]
    } catch (error)  {
      console.error(error);
      return [false, error]
    }
  }