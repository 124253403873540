import './BladeInstance.scss';
import variables from '../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import RowGroup from '../../../components/RowGroup/RowGroup';
import { FormatDate, FormatDurationToShortString, useGetViewSize } from '../../../helpers';
import RowGroupRow from '../../../components/RowGroup/RowGroupRow';
import { MaintainBladeEvents, processBladeIssue, selectBladeIssuesByBladeId } from '../../../redux/entities/service/Blade';
import Tag from '../../../components/Tag';
import Pill from '../../../components/Pill';
import SearchBox from '../../../components/SearchBox';
import DropDownInput from '../../../components/input/DropDownInput';
import DateTimePicker from '../../../components/DateTimePicker';
import { CloseDropdown, Filter, OpenDropdown } from '../../../assets/icons/Icons';
import Button from '../../../components/Button';
import DropDownButton from '../../../components/DropDownButton';

 


const BladeInstance_EventsContent = ({ bladeId }) => {
  const [isMobile, isTablet, isDesktop] = useGetViewSize()

  

  const bladeIssues = useSelector((state) => selectBladeIssuesByBladeId(state, bladeId), _.isEqual)
  


  const [searchKey, SetSearchKey] = React.useState(null)
  const setSearchKey = (key) => {
    SetSearchKey(key)
  }
  
  const [sortBy, SetSortBy] = React.useState("date")
  const setSortBy = (sortBy) => {
    SetSortBy(sortBy)
  }
  const sortByOptions = [
    {label: "Date", value: "date"},
    {label: "Name", value: "name"},
  ]


  const [filterEventsByType, SetFilterEventsByType] = React.useState("all")
  const setFilterEventsByType = (eventsByType) => {
    SetFilterEventsByType(eventsByType)
  }
  const filterEventsByTypeOptions = [
    {label: "All Event Types", value: "all"},
    {label: "Issues", value: "issues"},
  ]



  const [viewingEventsRangeType, SetViewingEventsRangeType] = React.useState("last_24hr")
  const [viewingEventsRange, SetViewingEventsRange] = React.useState({from: parseInt(new Date().getTime() / 1000) - (60 * 60 * 24), to: parseInt(new Date().getTime() / 1000)})
  const [activeEventsList, SetActiveEventsList] = React.useState([])

  React.useLayoutEffect(() => {
    let currentEventsList = []

    //Issues
    let bladeIssuesInTime = Object.values(bladeIssues).filter((bI) => bI.processed_time >= viewingEventsRange.from && bI.processed_time <= viewingEventsRange.to)
    //console.log(bladeIssuesInTime)
    let availableIssues = []
    if (searchKey === null)  {
      availableIssues = bladeIssuesInTime
    }else {
      availableIssues = bladeIssuesInTime.filter((bI) => bI.processed_name.toLowerCase().includes(searchKey.toLowerCase()))
    }


    for (let issue of availableIssues)  {
      let newEventItem = {type: "issue", issue: issue, date: new Date(issue["triggered_on"]).getTime(), name: issue.processed_name}
      
      currentEventsList.push(newEventItem)
    }

    
    let filteredEventsList = []
    switch (filterEventsByType) {
      case "all":
        filteredEventsList = currentEventsList
        break
      case "issues":
        filteredEventsList = currentEventsList.filter((e) => e.type === "issue")
        break
      default:
        break
    }   



    switch (sortBy) {
      case "date":
        filteredEventsList.sort(function(a, b) { 
          return b.date - a.date;
        })
        break
      case "name":
        filteredEventsList.sort(function(a, b) { 
          return a.name.localeCompare(b.name)
        })
        break

      default:
        break
    }

    SetActiveEventsList(filteredEventsList)
  }, [bladeIssues, viewingEventsRange, searchKey, sortBy, filterEventsByType])


  const [columnSizes, SetColumnSizes] = React.useState({})
  const [standardRowHeight, SetStandardRowHeight] = React.useState(null)

  let columnHeaders = {status: {label: <>Events ({activeEventsList.length})</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width}}}}
  if (!isMobile) {
      columnHeaders = {...columnHeaders, 
        duration: {label: <>Duration</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width, alignItems:"start"}}},
        date: {label: <>Date</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width, alignItems:"start"}}},
      }
  }
  

  const [showingFilters, SetShowingFilters] = React.useState(false)
  const toggleShowingFilters = () => {
    SetShowingFilters(!showingFilters)
  }

  return (<>
    <MaintainBladeEvents bladeIds={[bladeId]} dateRange={viewingEventsRange}/>
    <div className="BladeInstance-EventsContent">
      <div className="BladeInstance-EventsContent-Header">
        {isMobile && <>
          <div className="FlexContent-H" style={{gap: 2, alignItems: "center", alignSelf: "stretch", overflowX: "hidden"}}>
            <SearchBox value={searchKey} onChange={setSearchKey}/>
            <Button 
              content={<Filter/>} 
              state={showingFilters}
              status="Action-Neutral"
              margin={1} 
              onClick={toggleShowingFilters}/>
          </div>
        </>}
        {isTablet && <>
          <div className="FlexContent-H FlexContent-HFlex" style={{justifyContent: "space-between", alignSelf: "stretch"}}>
            <div className="FlexContent-H" style={{gap: 2, alignItems: "center", overflowX: "hidden"}}>
              <SearchBox value={searchKey} onChange={setSearchKey}/>
              <Button 
                content={
                  <div className="FlexContent-H" style={{gap:6, alignItems:"center"}}>
                    <Filter/>
                    <div className="Text-Alt12">Filters</div>
                    {showingFilters && 
                      <CloseDropdown/>
                    }
                    {!showingFilters && 
                      <OpenDropdown/>
                    }
                  </div>} 
                margin={1}
                status="Action-Neutral"
                onClick={toggleShowingFilters}/>
            </div>
            <DateTimePicker 
              isRange={true}
              rangeType={viewingEventsRangeType} 
              range={viewingEventsRange} 
              onRangeTypeSelected={SetViewingEventsRangeType} 
              onRangeChanged={SetViewingEventsRange}/>
          </div>        
        </>}
        {isDesktop && <>
          <div className="FlexContent-H FlexContent-HFlex" style={{justifyContent: "space-between", alignSelf: "stretch"}}>
            <div className="FlexContent-H-10" style={{alignItems: "center"}}>
              <SearchBox value={searchKey} onChange={setSearchKey}/>
              <DropDownInput prefix={"Sort By:"} options={sortByOptions} value={sortBy} onSelectionChange={setSortBy}/>
              <DropDownInput options={filterEventsByTypeOptions} value={filterEventsByType} onSelectionChange={setFilterEventsByType}/>
            </div>
            <DateTimePicker 
              isRange={true}
              rangeType={viewingEventsRangeType} 
              range={viewingEventsRange} 
              onRangeTypeSelected={SetViewingEventsRangeType} 
              onRangeChanged={SetViewingEventsRange}/>
          </div>
        </>}
      </div>
      {isMobile && <>
        <div className="FlexContent FlexContent-HFlex" style={{alignItems: 'stretch'}}>
          <DateTimePicker 
            isRange={true}
            rangeType={viewingEventsRangeType} 
            range={viewingEventsRange} 
            onRangeTypeSelected={SetViewingEventsRangeType} 
            onRangeChanged={SetViewingEventsRange}/>
        </div>
      </>}
      {(showingFilters && (isTablet || isMobile)) && <>
        <div className="BladeInstance-EventsContent-Filters">
          <DropDownInput prefix={"Sort By:"} options={sortByOptions} value={sortBy} onSelectionChange={setSortBy}/>
          <DropDownInput options={filterEventsByTypeOptions} value={filterEventsByType} onSelectionChange={setFilterEventsByType}/>
        </div>
      </>}
      <div className="BladeInstance-EventsContent-EventsRowGroup">

      <RowGroup
        hasGroupHeader={false}
        hasSettings={false}
        containerHasBorder={false}
        hasContainer={false}
        itemsScroll={true}
        isExpandable={false}
        hasColumnHeaders={true}
        columnHeaders={columnHeaders}
        columnSizes={columnSizes}
        standardRowHeight={standardRowHeight}
        maxItemsHeight={50}
        maxItemsHeightBasedOnScreen={!isMobile}
        hasSubGroups={false}>
            <BladeInstance_EventsContent_EventRow isPlaceholder={true} columnSizesChanged={(columns) => {
                SetColumnSizes(columns)
            }} rowHeightChanged={(height) => SetStandardRowHeight(height)}/>
            {activeEventsList.map((eventInfo) => {
                //console.log(growPlanInfo)
                return ( 
                  <BladeInstance_EventsContent_EventRow 
                    key={eventInfo.id}
                    eventInfo={eventInfo}
                    isPlaceholder={false}
                    columnSizes={columnSizes}/>
                  )
          })}
        

      </RowGroup>

      </div>
    </div>
  </>)
}

export default BladeInstance_EventsContent



const BladeInstance_EventsContent_EventRow = ({bladeId, eventInfo, isPlaceholder, isSelected, columnSizes, columnSizesChanged, rowHeightChanged, onSelectedChanged}) => {
  const [isMobile, isTablet, isDesktop] = useGetViewSize()

  //console.log(eventInfo)

  

  let eventInfoContent = <><div style={{flex:1}}>Really long title</div></>
  let eventDuration = 20810100
  let eventDate = new Date()
  if (!isPlaceholder) {
    switch(eventInfo.type)  {
      case "issue":
        if (eventInfo.issue.active) {
          eventDuration = (new Date().getTime() - new Date(eventInfo.issue.triggered_on).getTime()) / 1000
        }else {
          eventDuration = (new Date(eventInfo.issue.resolved_on).getTime() - new Date(eventInfo.issue.triggered_on).getTime()) / 1000
        }
        eventDate = new Date(eventInfo.issue.triggered_on)


        eventInfoContent = <>
          {isMobile && <>
            <div className="FlexContent-10">
              <div className="FlexContent" style={{gap:2}}>
                <div className="Text-Medium-S14 Text-Wrap"><Pill content={"Level 1"}/>{eventInfo.issue.processed_name}</div>
                <div className="Text-S14 Text-Wrap">{eventInfo.issue.processed_info}</div>
              </div>
              <div className="FlexContent-H-5">
                <div className="Text-SemiBold-S14">Duration:</div>
                <div className="Text-S14">{FormatDurationToShortString(eventDuration)}</div>
              </div>
              <div className="FlexContent-H-10">
                <div className="Text-Alt14">{FormatDate(eventDate, "MM/dd/yy")}</div>
                <div className="Text-Alt14">{FormatDate(eventDate, "HH:mm:ss")}</div>
              </div>
            </div>
          </>}
          {!isMobile && <>          
            <span>
              <span className="Text-Medium-S14 Text-Wrap">
                <Pill content={"Level 1"} inline={true}/>
                &nbsp;
                {eventInfo.issue.processed_name}
              </span>
              &nbsp;
              &nbsp;
              <span className="Text-S14 Text-Wrap">{eventInfo.issue.processed_info}</span>
            </span>
          </>}
        </>
        break
      default:
        eventInfoContent = <>Unknown Event</>
        break
    }
  }

  
  


  
  let secondaryHeaderContent = []
  if (!isMobile) {
    secondaryHeaderContent.push({
      key: "duration", content: <>
        <div className="noselect" style={{fontSize:14}}>
          <div className="Text-SoftTruncate">{FormatDurationToShortString(eventDuration)}</div>
        </div>
      </>
    })
    secondaryHeaderContent.push({
      key: "date", content: <>
        <div className="noselect" style={{fontSize:14}}>
          <div className="Text-SoftTruncate">
            {isPlaceholder && <>00/00/00 00:00:00</>}
            {!isPlaceholder && <>{FormatDate(eventDate, "MM/dd/yy HH:mm:ss")}</>}
          </div>
        </div>
      </>
    })
  }
  const eventClicked = () => {
    
  }

  return (<>
    <RowGroupRow
      title={eventInfoContent}
      titleFontSize={14}
      cardBackgroundColor={"transparent"}
      cardBorderColor={"transparent"}
      primaryCellFlex={true}
      secondaryCellFlex={false}
      customRowPadding={0}
      isPlaceholderRow={isPlaceholder}
      columnSizesChanged={columnSizesChanged ?? (() => {})}
      rowHeightChanged={rowHeightChanged ?? (() => {})}
      columnSizes={columnSizes}
      secondaryContent={secondaryHeaderContent}
      isExpandable={false}
      onRowClicked={eventClicked}
      hasAdditionalFunctions={false}
      content={<>
        {(() => {
          
        })()}
      </>}
    />
  </>)
}