import './PageHeader.scss';
import React from 'react'

import { useLocation, useNavigate } from 'react-router-dom';
import {MdArrowBack} from 'react-icons/md'
import { useMediaQuery } from 'react-responsive';
import { BsList } from 'react-icons/bs';
import { MenuButton } from '../assets/icons/Icons';

const PageHeader = ({helperFunctionCallback, navigationFunctions}) => {

  const [pageStack, SetPageStack] = React.useState([{title: "", subTitle: "", path: ""}]);
  const [condensed, SetCondensed] = React.useState(true);
  const [pageTitle, SetPageTitle] = React.useState("");
  const [pageSubtitle, SetPageSubtitle] = React.useState("");
  const [navigateBackActive, SetNavigateBackActive] = React.useState(false);
  const [navigateBackLocation, SetNavigateBackLocation] = React.useState(null);
  const [rightSideContent, SetRightSideContent] = React.useState(() => { return (<></>)});
  const [functionsContainerCanScroll, SetFunctionsContainerCanScroll] = React.useState(() => { return (<></>)});
  
  const [subPageUpdateHeader, SetSubPageUpdateHeader] = React.useState(() => { });

  const [headerTabControlTabs, SetHeaderTabControlTabs] = React.useState(null);
  const [headerTabControlActivePathIndex, SetHeaderTabControlActivePathIndex] = React.useState(null)
  const headerTabControlTabClickCallback = React.useRef(null);


  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const location = useLocation()
  

  if (helperFunctionCallback !== undefined) {
    helperFunctionCallback({
      Reset: () =>  {
        SetPageTitle("")
        SetPageSubtitle("")
        SetRightSideContent(() => { return (<></>)})
        SetCondensed(true)
        SetNavigateBackActive(false);
        SetHeaderTabControlTabs(null);
        SetHeaderTabControlActivePathIndex(null);
        SetFunctionsContainerCanScroll(true);
        SetSubPageUpdateHeader(() => {});
        headerTabControlTabClickCallback.current = null;
      },
      SetTitle: (title) =>  {
        SetPageTitle(title)
      },
      SetSubtitle: (subtitle) =>  {
        SetPageSubtitle(subtitle)
      },

      SetNavigateBackActive: (state) => {
        SetNavigateBackActive(state)
      },

      SetNavigateBackLocation: (location) => {
        SetNavigateBackLocation(location)
      },

      SetRightSideContent: (content) => {
        SetRightSideContent(content)
      },

      SetCondensed: (state) =>  {
        SetCondensed(state)
      },

      SetTabControlTabs: (tabs) => {
        SetHeaderTabControlTabs(tabs);
      },
      SetHeaderTabControlActivePathIndex: (index) => {
        SetHeaderTabControlActivePathIndex(index)
      },
      SetTabControlTabClickCallback: (callback) => {
        headerTabControlTabClickCallback.current = callback
      },
      SetFunctionsContainerCanScroll: (state) => {
        SetFunctionsContainerCanScroll(state)
      },

      SetSubPageUpdateHeader: (method) => {
        SetSubPageUpdateHeader(method)
      },
      SubPageUpdateHeader: () => {
        if (subPageUpdateHeader !== undefined)  {
          subPageUpdateHeader()
        }
      }
      
    })
  }

  const navigate = useNavigate();
  const goBack = () =>  {
    //Check for pending changes first
    if (navigateBackLocation) {
      navigate(navigateBackLocation);
    }else {
      navigate(-1);
    }
  }

  const toggleNavigation = () =>  {
    navigationFunctions.current.ToggleNavigation()
  }

  return (
    <div className={"PageHeader" + (condensed ? " PageHeaderCondensed" : " PageHeaderLarge")}>
      <div className="PageHeader-Wrapper">
        <div className="PageHeader-MainContent">
          <div className="PageHeaderNavigationContainer" onClick={toggleNavigation}>
            {isMobile &&
              <div className="PageHeaderNavigationToggle">
                  <MenuButton/>
              </div> 
            }
            {/* Reserved space for navigation back button */}
            {navigateBackActive &&
              <div className="PageHeaderNavigationBackButton" onClick={goBack}>
                  <MdArrowBack className="noselect"/>
              </div>
            }
            {/* Reserved space for page title, subtitle, and info */}
            <div className="PageHeaderNavigationTitleContainer">
              <div className="PageHeaderNavigationTitle">
                <div className="PageHeaderNavigationTitle-Title">{pageTitle}</div>
                {pageSubtitle !== "" && 
                  <div className="t_Caption">{pageSubtitle}</div>
                }
              </div>
            </div>
          </div>
          
            
            {(!isMobile && headerTabControlTabs) && 
              <div className="PageHeader_MiddleContent">
                <div className="PageHeader_TabControl_Tabs">
                      {headerTabControlTabs.map((tab) => {
                          const onClickHandler = () => {
                            if (headerTabControlTabClickCallback.current) {
                              headerTabControlTabClickCallback.current(tab)
                            }
                          }
                          const isTabSelected = location.pathname.split("/")[headerTabControlActivePathIndex] === tab.path

                          return (
                            <div onClick={onClickHandler} key={tab.key} className={"TabControl_Tab" + (isTabSelected === true ? " TabControl_Tab-Selected" : "")}>
                              <div className="TabControl_TabLabel noselect">{tab.name}</div>
                              {isTabSelected === true &&
                                <div className="TabControl_TabSelectedIndicator"/>
                              }
                            </div>
                          )
                      })}
                  </div>
              </div>
            }





          <div className={"PageHeaderFunctionContainer" + (functionsContainerCanScroll ? " PageHeaderFunctionContainer-WithOverflow" : "")}>
            <div className="PageHeaderFunctions">
              {rightSideContent}
            </div>
         </div>
        </div>
        {(isMobile && headerTabControlTabs) && 
          <div className="PageHeader-SubTabContent">
            <div className="PageHeader_SubTabControl_Tabs">
                {headerTabControlTabs.map((tab) => {
                    const onClickHandler = () => {
                      if (headerTabControlTabClickCallback.current) {
                        headerTabControlTabClickCallback.current(tab)
                      }
                    }
                    const isTabSelected = location.pathname.split("/")[headerTabControlActivePathIndex] === tab.path
                    return (
                      <div onClick={onClickHandler} key={tab.key} className={"TabControl_Tab" + (isTabSelected === true ? " TabControl_Tab-Selected" : "")}>
                        <div className="TabControl_TabLabel noselect">{tab.name}</div>
                        {isTabSelected === true &&
                          <div className="TabControl_TabSelectedIndicator"/>
                        }
                      </div>
                    )
                })}
              </div>
          </div>
        }
      </div>
    </div>
  )
}

export default PageHeader