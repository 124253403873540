import './Pill.scss';


const Pill = ({content, status, fontSize, size, color, inline}) => {

  let customProps = {style:{}}
  if (inline) {
    customProps.style.display = "inline-block"
  }
  return (
    <div className={"Pill Pill-" + status + " Pill-" + size + " noselect"} {...customProps}>
      <div><span style={{fontSize: fontSize}}>{content}</span></div>
    </div>
  )
} 

Pill.defaultProps = {
  content: "",
  status: "Neutral",
  color: false, 
  fontSize: 10,
  size: "Medium",
  inline: false
}


export default Pill