import { SearchIcon } from '../assets/icons/Icons';
import TextInput from './input/TextInput';
import './SearchBox.scss';


const SearchBox = ({value, placeHolder, status, size, showIcon, onChange}) => {

  return (
    <div className={"SearchBox SearchBox-" + status + " SearchBox-" + size + " noselect"}>
      <TextInput prefix={<SearchIcon/>} value={value} placeHolder={placeHolder} onChange={onChange}/>
    </div>
  )
} 

SearchBox.defaultProps = {
  value: "",
  placeHolder: "Search",
  status: "Neutral",
  size: "Medium",
  showIcon: true,
}


export default SearchBox