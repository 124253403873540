import './TasksPage.scss';
import React from "react";

import { useDispatch, useSelector } from 'react-redux'
//import { updateTasks } from './counterSlice'


import Button from '../../components/Button.js'
import TextInput from '../../components/input/TextInput.js'
import DropDownInput from '../../components/input/DropDownInput.js'


import { Routes, Route, Navigate, useNavigate, useLocation, useParams} from 'react-router-dom'
import {TabControl, TabControlTab} from '../../components/TabControl.js';

import TasksTimelinePage from './TasksTimelinePage';
import TasksListPage from './TasksListPage';


import TaskPopup from './TaskPopup'
import { getAllFacilities, selectAllFacilities, selectAllFarmFacilities } from '../../redux/entities/Facilities.js';


const TasksPage = ({pageHeaderHelper}) => {


  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const selectedTab = splitLocation.length >= 3 ? splitLocation[3] : null

  const { farmID } = useParams();
  const [selectedFacility, SetSelectedFacility] = React.useState(farmID === "all" ? farmID : parseInt(farmID));
  const facilities = useSelector(selectAllFarmFacilities)
  let haveInitialFacilitiesData = useSelector((state) => state.facilities.haveInitialData)

  React.useEffect( () =>  { 
    if (!haveInitialFacilitiesData) {
      dispatch(getAllFacilities({}))
    }
  }, [facilities, haveInitialFacilitiesData])


  const validateLocation = () =>  {
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    if (splitLocation.length <= 2 || Number.isNaN(splitLocation[2]))  {
      navigate("/tasks/all/list")
    }else if (splitLocation[2] !== "all" && Number.isNaN(parseInt(splitLocation[2]))) {
      if (splitLocation.length <= 3) {
        navigate("/tasks/all/list")
      }else {
        navigate("/tasks/all/" + splitLocation[3])
      }
    }else {
      SetSelectedFacility(splitLocation[2] === "all" ? splitLocation[2] : parseInt(splitLocation[2]))
    }
  }
  React.useEffect(() => {
    validateLocation()
  }, [location])
  React.useLayoutEffect(() => {
    validateLocation()
  }, [])


  









  const [facilitySelectionOptions, SetFacilitySelectionOptions] = React.useState([]);
  const selectedFacilityChanged = (value) => {
    const { pathname } = location;
    let splitLocation = pathname.split("/");
    splitLocation[2] = value
    navigate(splitLocation.join("/"))
    //SetIsAllSelected(value === "all")
    //SetSelectedFacility(value)
  }
  
  React.useEffect(() => {
    //Check location to see if we are in services, if so we need to limit the options to not include all farms
    let currentFacilitySelectionOptions = [{value: "all", label: "All Farms"}]
    for (let facility of facilities)  {
      currentFacilitySelectionOptions.push({
        value: facility.id,
        label: facility.name
      })
    }
   

    SetFacilitySelectionOptions(currentFacilitySelectionOptions)
  }, [facilities, location])




  const tabs = [
    new TabControlTab("List", "list", true),
    new TabControlTab("Groups", "groups"),
    new TabControlTab("Timeline", "timeline"),  ]

  const tabControlTabClickedRef = React.useRef(null);


  const setPageHeader = () => {
    pageHeaderHelper.current.Reset()
    pageHeaderHelper.current.SetTitle("Tasks")

    pageHeaderHelper.current.SetRightSideContent(() => {
      return (
        <>
          <div className="ControlBar_Horizontal">
            <div className="ControlBar_Horizontal-Right">
              <DropDownInput placeholder={"Select Facility"} options={facilitySelectionOptions} value={selectedFacility} onSelectionChange={selectedFacilityChanged}/>
              {/*<Button content={"Create Farm"}/>*/}
            </div>
          </div>
        </>
      )
    })
    
    pageHeaderHelper.current.SetTabControlTabs(tabs);
    pageHeaderHelper.current.SetHeaderTabControlActivePathIndex(3);
    pageHeaderHelper.current.SetTabControlTabClickCallback((tab) => {
      if (tabControlTabClickedRef.current) {
        tabControlTabClickedRef.current(tab);
      }
    });
  }

  React.useLayoutEffect(() => {
    setPageHeader();
  }, [pageHeaderHelper])


  return (
    <div className="PageWrapper_Standard">
      <TabControl 
        tabs={tabs}
        tabClickedRef={(ref) => {tabControlTabClickedRef.current=ref;}}
        activePathIndex={2}
        hideTabs={true}
        content={
          <>
            <div className="ControlBar_Horizontal">
              <div className="ControlBar_Horizontal-Left ControlBar_Horizontal-Overflow">
                <TextInput placeHolder="Search" icon={<TextInput.SearchIcon/>}/>
                <DropDownInput options={[{ value: 'all', label: 'All Status' }]} defaultValue="all"/>
                <DropDownInput options={[{ value: 'all', label: 'All Assignee' }]} defaultValue="all"/>
                <DropDownInput options={[{ value: 'all', label: 'All Years' }]} defaultValue="all"/>
                <DropDownInput options={[{ value: 'date', label: 'Date' }]} defaultValue="date" prefix="Sort By"/>
              </div>
              <div className="ControlBar_Horizontal-Right">

              </div>
            </div>
            <Routes>
              <Route exact path="/timeline" element={
                <TasksTimelinePage setPageHeader={setPageHeader}/>
              }/>
              <Route path="/groups" element={
                <div>
                  Groups
                </div>
              }/>
              <Route path="/list" element={
                <TasksListPage selectedFacility={selectedFacility} pageHeaderHelper={pageHeaderHelper} setPageHeader={setPageHeader}/>
              }>
                <Route path=":URLTaskId" element={
                  <TaskPopup/>
                } />
              </Route>


              <Route path="*" element={<Navigate to="list" replace />} />
            </Routes>
          </>
        }/>
      </div>
  )
} 

export default TasksPage