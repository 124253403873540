import './GrowRacksPage.scss';
import variables from '../../../globals.scss';
import React from 'react'

import _ from 'underscore';


import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getAllServicesForFacility, selectAllFacilities } from '../../../redux/entities/Facilities';
import { activateTemporaryEditMode, addRackToGroup, createNewGroup, getLiveDataItem, getUniqueConfigurationByName, getBladeConfigurationMap, getBladeByServiceId, getBladeLiveDataById, getBladeStatusById, hideRackStatusContent, removeGroup, selectAllBladeConfigurationMaps, selectAllBlades, setBladeRuntimeProperty, showRackStatusContent, selectAllBladeGroups, selectAllBladeZones, getBladeGroupById, getAllBladeGroupsByFacilityId, MaintainBladeLiveData, MaintainBladeStatus, MaintainBladeUniqueConfigurationMaps, InitialLoadAllBlades, InitialLoadAllBladeGroups, MaintainBladeConfigurationMaps } from '../../../redux/entities/service/Blade';
import ControlBar from '../../../components/ControlBar';
import CircularGauge from '../../../components/CircularGauge';
import { GridTile } from '../../../components/GridTile';
import NumberInput from '../../../components/input/NumberInput';
import Switch from '../../../components/Switch';
import { RoundToNearest, convertToBool, parseBool } from '../../../helpers';
import Button from '../../../components/Button';
import TextInput from '../../../components/input/TextInput';
import ToggleCellButton from '../../../components/ToggleCellButton';
import { AddItem, BadX, GoodCheckmark, Pause, Play, PlusAlt, RSSI, ReorderItem, SoftWarning, Trash } from '../../../assets/icons/Icons';
import BasicAlert from '../../../components/BasicAlert';
import AddBladeWalkthrough from './AddBladeWalkthrough';
import RowGroup from '../../../components/RowGroup/RowGroup';
import RowGroupRow from '../../../components/RowGroup/RowGroupRow';
import { useMediaQuery } from 'react-responsive';
import BladeLightsManager from './BladeLightsManager';
import BladeIssuesPopup from './BladeIssuesPopup';
import { selectAllAccountPrivileges } from '../../../redux/entities/Account';
import BladeInstance from './BladeInstance';






const BladeInstancesPage = ({ selectedFacility, setRightSideContent }) => {

  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const {focusedBladeID } = useParams();
  const activeBladeIds = useSelector((state) => state.blade.blade.filter((b) => b.facility_id === selectedFacility).map((b) => b.id), _.isEqual)
  const bladeUIDsToIds = useSelector((state) => state.blade.bladeUIDsToIds)

  const bladeGroups = useSelector(state => state.blade.groups.filter((bG) => bG.facility_id === selectedFacility), _.isEqual)
 
  const updateHeader = React.useCallback(() => {
    if (setRightSideContent === undefined)
      return
    setRightSideContent(
      <>
        {activeBladeIds.length > 0 &&
          <Button content="Add Blade" status="Primary" onClick={addNewRack} />
        }
      </>
    )
  })

  React.useLayoutEffect(() => {
    updateHeader()
  }, [])
  React.useLayoutEffect(() => {
    updateHeader()
  }, [bladeGroups])



  const [addingNewRack, SetAddingNewRack] = React.useState(false)
  const addNewRack = React.useCallback(() => {
    SetAddingNewRack(true)
  })



  const addBladeWalkthroughCloseCallback = React.useCallback(() => {
    SetAddingNewRack(false)
  })

  
  return (<>
    <InitialLoadAllBladeGroups/>
    <InitialLoadAllBlades/>
    <MaintainBladeStatus bladeIds={activeBladeIds} />
    <MaintainBladeConfigurationMaps bladeIds={activeBladeIds} />
    <MaintainBladeUniqueConfigurationMaps bladeIds={activeBladeIds} />
    <MaintainBladeLiveData bladeIds={activeBladeIds} interval={500} />

    <Routes>
      <Route path=":bladeID"/>
    </Routes>
    <div id="GrowRacksStatus">

      {activeBladeIds.length === 0 &&
        <div className="GrowRacksStatus-NoGroupsContent">
          <span>No active blades</span>
          <Button content={"Add your first blade"} onClick={addNewRack} />
        </div>
      }
      {bladeGroups.length > 0 &&
        <div className="GrowRacksStatus-VerticalRackGroups">
          {bladeGroups.map((bladeGroup) => {


            let otherGroupProps = {}
            if (bladeGroups.length === 1) {
              otherGroupProps.expandedState = true
            }
            return (

              <RowGroup
                key={bladeGroup.id}
                title={bladeGroup.display_name}
                hasSettings={true}
                isExpandable={true}
                expandCellContent={"Show " + Object.values(bladeGroup.blades).length + " Blades"}
                collapseCellContent={"Hide Blades"}
                settingsOptions={[
                  { key: "rename", label: "Rename Group" },
                  { key: "add", label: "Add Rack" },
                  { key: "delete", label: "Delete", status: "Critical" },
                ]}
                hasColumnHeaders={false}
                {...otherGroupProps}>

                {bladeGroup.blades.map((bladeUID) => {
                  if (bladeUIDsToIds[bladeUID] !== undefined) {
                    return <BladeInstance bladeId={bladeUIDsToIds[bladeUID]} isInitialFocused={parseInt(focusedBladeID) === bladeUIDsToIds[bladeUID]}/>
                  }
                })}

              </RowGroup>
            )

          })}
        </div>
      }
    </div>
    {addingNewRack && <AddBladeWalkthrough facilityId={selectedFacility} completeCallback={addBladeWalkthroughCloseCallback} closeCallback={addBladeWalkthroughCloseCallback} />}
  </>)
}

export default BladeInstancesPage