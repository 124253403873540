import '../BladeInstance.scss';
import variables from '../../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { AdditionalOptions } from '../../../../assets/icons/Icons';
import DropDownButton from '../../../../components/DropDownButton';
import { getLiveDataItem, selectAllBladeConfigurationMaps, selectBladeComponentInfo, selectBladeLiveDataByComponentInfo, selectBladeRuntimeInformation, setBladeRuntimeProperty } from '../../../../redux/entities/service/Blade';
import { DataStatus_DataList } from '../../../../components/DataStatus/DataStatus';
import NumberInput from '../../../../components/input/NumberInput';
import Switch from '../../../../components/Switch';
import { selectAllAccountPrivileges } from '../../../../redux/entities/Account';
import { parseBool } from '../../../../helpers';



const DataStatus_DehumidCoilCell = ({ bladeId }) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const dispatch = useDispatch()


  const accountPrivileges = useSelector((state) => selectAllAccountPrivileges(state), _.isEqual)
  const accountHasServicingPriviliges = (accountPrivileges["servicing"] !== undefined)
  const [inServiceModeRuntimeInformation] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "service_mode"), _.isEqual)
  const inServiceMode = accountHasServicingPriviliges ? parseBool(inServiceModeRuntimeInformation) : false

  const [dehumidEEVInOverrideRuntimeInformation] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "dehumid_eev_position_override"), _.isEqual)
  const dehumidEEVInOverride = parseBool(dehumidEEVInOverrideRuntimeInformation)
  const [dehumidEEVOverrideSpeedRuntimeInformation] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "dehumid_eev_position"), _.isEqual)

  
const [leavingTempComponentInfo, superheatComponentInfo] 
  = useSelector((state) => selectBladeComponentInfo(state, bladeId, "DehumidCoil", ["l-temp", "sh"]), _.isEqual)

const [EEVPositionComponentInfo] 
  = useSelector((state) => selectBladeComponentInfo(state, bladeId, "DehumidCoilEEV", ["output_signal"]), _.isEqual)

const [coilPressureComponentInfo] 
  = useSelector((state) => selectBladeComponentInfo(state, bladeId, "CompressorInletPressureTransducer", ["pressure"]), _.isEqual)
  

  let EEVPosition = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, EEVPositionComponentInfo), _.isEqual)
  let leavingTemp = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, leavingTempComponentInfo), _.isEqual)
  let coilPressure =useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, coilPressureComponentInfo), _.isEqual)
  let superheat = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, superheatComponentInfo), _.isEqual)


  let dataListItems = [
    {
      label: "EEV Position", value: EEVPosition.value, suffix: "%", visualization: {
        type: "bar", info: { usesRange: true, min: 0, max: 100, status: "normal" }
      }
    },
    {
      label: "Superheat", value: superheat.value, suffix: "°C", visualization: {
        type: "bar", info: { usesRange: true, min: 0, max: 30, status: "normal" }
      }
    },
    {
      label: "Leaving Temp", value: leavingTemp.value, suffix: "°C",
    },
  ]



  const dehumidEEVOverrideToggled = (state) => {
    dispatch(setBladeRuntimeProperty({ bladeId: bladeId, properties: { "dehumid_eev_position_override": state ? 1 : 0 } }))
  }
  const dehumidEEVOverrideSpeedChanged = (speed) => {
    dispatch(setBladeRuntimeProperty({ bladeId: bladeId, properties: { "dehumid_eev_position": speed  } }))
  }
  return (<>
    <div className="DataStatus-Cell">
      <div className="DataStatus-Cell-Header-Container">
        <div className="DataStatus-Cell-Header-PrimaryContent">
          <div className="Text-H14">Dehumid Coil</div>
        </div>
        <div className="DataStatus-Cell-Header-AuxContent">
          <DropDownButton content={<AdditionalOptions />} status={"Neutral"} />
        </div>
      </div>
      <div className="DataStatus-Cell-Content-Container">
        <DataStatus_DataList items={dataListItems} />
      </div>
      {inServiceMode && <>
        <div className="DataStatus-Override-Container">
          <div className="DataStatus-Override-Toggle">
            <Switch state={dehumidEEVInOverride} onSwitch={dehumidEEVOverrideToggled}/>
            <div className="Text-Alt14">EEV Override</div>
          </div>
          {dehumidEEVInOverride && 
            <NumberInput min={0} max={100} value={dehumidEEVOverrideSpeedRuntimeInformation} onChange={dehumidEEVOverrideSpeedChanged}/>
          }
        </div>
      </>}
    </div>
  </>)
}

export default DataStatus_DehumidCoilCell