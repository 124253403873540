import './GrowManagerPage.scss';
import variables from '../../globals.scss';
 
import React from 'react'
import 'react-circular-progressbar/dist/styles.css';
import { useMediaQuery } from 'react-responsive';
import GanttChart from '../../components/GanttChart';
import { useDispatch, useSelector } from 'react-redux';
import { getAllGrows, scheduleNewGrow, selectAllGrowGroups, selectAllGrows } from '../../redux/entities/Grow';
import { getGrowPlanById, selectAllGrowPlans, selectGrowPlanById } from '../../redux/entities/GrowPlans';
import { getRecipeById, selectAllRecipes } from '../../redux/entities/Recipes';
import { useMeasure } from '../../helpers';
import { AdditionalOptions, SkipBack, SkipForward } from '../../assets/icons/Icons';
import TextInput from '../../components/input/TextInput';
import DropDownInput from '../../components/input/DropDownInput';
import Button from '../../components/Button';
import ControlBar from '../../components/ControlBar';
import ScheduleGrowPopup from './ScheduleGrowPlanWalkthroughSteps/ScheduleGrowPopup';







const GrowSchedulingPage = ({selectedFacility, pageHeaderHelper}) => { 
  
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });


  const dispatch = useDispatch()
  let allGrows = useSelector(selectAllGrows)
  let allGrowPlans = useSelector(selectAllGrowPlans)
  let growPlans = useSelector(selectAllGrowPlans)
  let recipes = useSelector(selectAllRecipes)


  const [growsList, SetGrowsList] = React.useState({})
  let haveInitialData = useSelector((state) => state.grows.haveInitialData)
  const [growGroupsList, SetGrowGroupsList] = React.useState({})


  const [currentSearchInput, SetSearchInput] = React.useState("")

  
  React.useEffect( () =>  { 
    if (!haveInitialData) {
      dispatch(getAllGrows())
    }
  }, [haveInitialData])


  
  /* Load grow plans */
  const growPlansLoadingStatus = useSelector((state) => state.growPlans.loadingGrowPlansStatus)
  const [validateGrowPlansPendingCallback, SetValidateGrowPlansPendingCallback] = React.useState(null)
  const validateGrowPlansToLoad = () => {
    if (growPlansLoadingStatus !== "pending") {
      SetValidateGrowPlansPendingCallback(null)
      
      let growPlanDataToRequest = []
      for (let grow of allGrows) {
        if (grow.initialSave !== false) {

          let foundGrowPlan = allGrowPlans.find((gP) => gP.id === grow.grow_plan_id)
          if (foundGrowPlan === undefined)  {
            if (growPlanDataToRequest.indexOf(grow.grow_plan_id) === -1)  {
              growPlanDataToRequest.push(grow.grow_plan_id)
            }
          }
    }      
      }

      if (growPlanDataToRequest.length > 0) {
        console.log("Loading grow plans", growPlanDataToRequest)
        dispatch(getGrowPlanById({growPlans: growPlanDataToRequest}))
      }
  }else {
      if (validateGrowPlansPendingCallback === null)    {
        SetValidateGrowPlansPendingCallback(setTimeout(() => {validateGrowPlansToLoad()}, 5000))
      }
    }
  }

  React.useEffect(() => {
      if (growPlansLoadingStatus === "idle") {
        validateGrowPlansToLoad()
      }else if (growPlansLoadingStatus === "fulfilled" || growPlansLoadingStatus === "rejected") {
        if (validateGrowPlansPendingCallback === null)    {
          SetValidateGrowPlansPendingCallback(setTimeout(() => {validateGrowPlansToLoad()}, 5000))
        }
      }
  }, [allGrows, allGrowPlans, growPlansLoadingStatus, validateGrowPlansPendingCallback])



  /* Load Recipes */
  const allRecipes = useSelector((state) => selectAllRecipes(state))
  const recipeLoadingStatus = useSelector((state) => state.recipes.status)
  const [validateRecipesPendingCallback, SetValidateRecipesPendingCallback] = React.useState(null)
  const validateRecipesToLoad = () => {
    if (recipeLoadingStatus !== "pending") {
      SetValidateRecipesPendingCallback(null)
        
      let recipeDataToRequest = []
 
      for (let grow of allGrows) {
        if (grow.initialSave !== false) {
          
          if (grow.recipe_id !== undefined && grow.recipe_id)  {
            let foundRecipe = recipes.find((r) => r.id === grow.recipe_id && r.version === grow.recipe_version)
            if (foundRecipe === undefined)  {
              recipeDataToRequest.push({id: grow.recipe_id, version: grow.recipe_version})
            }
          }
        }      
      }


      
      if (recipeDataToRequest.length > 0) {
        dispatch(getRecipeById({recipes: recipeDataToRequest}))
      }
        
    }else {
      if (validateRecipesPendingCallback === null)    {
          SetValidateRecipesPendingCallback(setTimeout(() => {validateRecipesToLoad()}, 5000))
      }
    }
  }

  React.useEffect(() => {
    if (recipeLoadingStatus === "idle") {
      validateRecipesToLoad()
    }else if (recipeLoadingStatus === "fulfilled" || recipeLoadingStatus === "rejected") {
      if (validateRecipesPendingCallback === null)    {
          SetValidateRecipesPendingCallback(setTimeout(() => {validateRecipesToLoad()}, 5000))
      }
    }
  }, [allGrows, allRecipes, recipeLoadingStatus, validateRecipesPendingCallback])





  React.useEffect(() => {
    //let currentGrowsList = {}
    //let currentGrowGroupsList = {}

    let currentGrows = []
    let currentGrowGroups = {}
    
    for (let grow of allGrows) {
      let growFiltered = false
      if (currentSearchInput === "" || (grow.name.indexOf(currentSearchInput) !== -1 && currentGrows.indexOf(grow) === -1))  {
        growFiltered = true
      }
      if (growFiltered) {
        let foundRecipe = recipes.find((r) => r.id === grow.recipe_id && r.version === grow.recipe_version)
        if (foundRecipe !== undefined)  {
          let growListItem = {data: {...grow}, is_group: false, key: "g-" + grow.id}

          growListItem.data.duration = foundRecipe.expected_duration
          if (grow.started_on)  {
            growListItem.data.startDate = new Date(grow.started_on).getTime()
            if (grow.completed) {
              growListItem.data.endDate =  new Date(grow.finished_on).getTime()
            }else {
              growListItem.data.endDate =  growListItem.data.startDate + growListItem.data.duration * 1000
            }
          }else {
            if (grow.scheduled_type === "nad" || grow.scheduled_type === "start") {
              growListItem.data.startDate = new Date(grow.scheduled_for).getTime()
              growListItem.data.endDate = growListItem.data.startDate + (growListItem.data.duration * 1000) 
            }else {
              growListItem.data.endDate = new Date(grow.scheduled_for).getTime()
              growListItem.data.startDate = growListItem.data.endDate - (growListItem.data.duration * 1000)
            }
          }

          
          if (grow.group_id === null)  {
            currentGrows.push(growListItem)
          }else {
            let foundGroup = currentGrows.find((g) => g.data.id === grow.group_id)
            if (foundGroup === undefined) {
              let growGroupListItem = {data: {}, is_group: true, key: "gg-" + grow.group_id, grows: [growListItem]}
              currentGrows.push(growGroupListItem)
            }else {
              foundGroup.grows.push(growListItem)
            }
          }

        }
      
      }
      
    }
    


    SetGrowsList(currentGrows)
    

  }, [allGrows, growPlans, recipes, currentSearchInput])


  
  
  
  
  
  
  
  
  
  const forceListScroll = React.useRef(null);
  


















  const [schedulingGrow, SetSchedulingGrow] = React.useState(false)
  const closeSchedulingGrowPopup = () =>  {
    SetSchedulingGrow(false)
  }

  const schedulingGrowFinalized = (finalizedGrow) =>  {
    dispatch(scheduleNewGrow({grow: finalizedGrow}))
    SetSchedulingGrow(false)
  }

  

  return (<>
    <ControlBar controls={<>
        <TextInput placeHolder="Search" icon={<TextInput.SearchIcon/>}/>
        <DropDownInput status="AltNeutral" options={[{ value: 'all', label: 'All Categories' }]} defaultValue="all"/>
        <DropDownInput status="AltNeutral" options={[{ value: 'all', label: 'All Years' }]} defaultValue="all"/>
        <DropDownInput status="AltNeutral" options={[{ value: 'grouped_by_crop_name', label: 'Grouped By Crop Name' }]} defaultValue="grouped_by_crop_name"/>
        <DropDownInput status="AltNeutral" options={[{ value: 'name', label: 'Name' }]} defaultValue="name" prefix="Sort By"/>
      </>} secondaryControls={<>
        <Button content="Start Grow" onClick={() => {SetSchedulingGrow(true)}}/>
      </>}
    />
    <div id="GrowManager-Timeline-Content">                   
      <GanttChart 
        id="GrowManager-Timeline-GanttChart" 
        //listScrollPosition={growRepoDimensions ? growRepoDimensions.contentScrollY : 0}
        /*data={{...growsList, ...growGroupsListProcessed}}*/
        barMargin={75}
        onScroll={(offset) => {forceListScroll.current && forceListScroll.current(offset)}}
        content={
          Object.entries(growsList).map(([growKey, grow]) => {
            return {key: grow.key, fromDate: grow.data.startDate, toDate: grow.data.endDate, 
              render: (props) => {
                return <GrowSchedulingItem grow={grow} {...props}/>
              }
            }
          })
        }/>
    </div>
    {schedulingGrow && 
      <ScheduleGrowPopup selectedFacility={selectedFacility} closeCallback={closeSchedulingGrowPopup} growCompleteCallback={schedulingGrowFinalized}/>
    }  
  </>)
}




const GrowSchedulingItem = ({grow, x1, x2, chartWidth, minDayWidth, jumpToStartTime, jumpToEndTime}) => {
  //Config
  const minDisplayNameWidthWithinBar = 50;
  const minDisplayNameWidthOutsideBar = 20;


  const [displayNameContainerBind, {width: displayNameContainerWidth}] = useMeasure()
  const [optionsButtonBind, {width: optionsButtonWidth}] = useMeasure()
  const [jumpForwardArrowBind, {width: jumpForwardArrowWidth}] = useMeasure()
  const [jumpBackArrowBind, {width: jumpBackArrowWidth}] = useMeasure()
  const [displayNameContainerOutsideBarBind, {width: displayNameContainerOutsideBarWidth}] = useMeasure()
  

  const [isOpen, SetIsOpen] = React.useState(false)

  //Visual widths
  const containerPadding = 5
  const containerBorderWidth = 1
  const jumpBackArrowContainerWidth = jumpBackArrowWidth
  const jumpForwardArrowContainerWidth = jumpForwardArrowWidth

  //Deterministic
  let isGrowStartBeforeChartStart = false
  let isGrowEndBeforeChartStart = false
  let isGrowStartAfterChartEnd = false
  let isGrowEndAfterChartEnd = false
  let isChartWithinGrow = false

  if (x1 < 0) {
    isGrowStartBeforeChartStart = true
  }
  if (x2 < 0) {
    isGrowEndBeforeChartStart = true
  }
  if (x1 > chartWidth)  {
    isGrowStartAfterChartEnd = true
  }
  if (x2 > chartWidth)  {
    isGrowEndAfterChartEnd = true
  }

  if (isGrowStartBeforeChartStart && isGrowEndAfterChartEnd)  {
    isChartWithinGrow = true
  }

  //Decided visual
  let isGrowTooFarBack = false
  let isGrowSemiTooFarBack = false
  let isGrowWithinChart = false
  let isGrowTooFarForward = false
  let isGrowSemiTooFarForward = false


  //Check if too far back, min width is the width of the options button
  if (x2 < jumpBackArrowContainerWidth + optionsButtonWidth)  {
    isGrowTooFarBack = true
  }
  if (x1 < 0) {
    isGrowSemiTooFarBack = true
  }

  if (x1 > chartWidth - jumpForwardArrowContainerWidth - optionsButtonWidth)  {
    isGrowTooFarForward = true
  }
  if (x2 > chartWidth) {
    isGrowSemiTooFarForward = true
  }





  let foundGrowPlan = undefined
  let allGrowPlans = useSelector(selectAllGrowPlans)
  if (grow.data.grow_plan_id)  {
    foundGrowPlan = allGrowPlans.find((gP) => gP.id === grow.data.grow_plan_id)
  }else {

  }
  //console.log(x1, x2)

  
  //Visual logic
  //If bar starts in past then 0 otherwise if bar is too far forward then chartWidth - width of forward button otherwise position of x1
  const barLeft = ((isGrowTooFarBack || isGrowSemiTooFarBack || isChartWithinGrow) ? 0 : (isGrowTooFarForward ? chartWidth - jumpForwardArrowContainerWidth : x1))
  
  //If bar is too far fortward then width of forward button otherwise if semi forward then remaining width after x1 otherwise position of x2
  let barWidth = (isChartWithinGrow ? chartWidth : (isGrowTooFarBack) ? jumpBackArrowContainerWidth : (isGrowTooFarForward) ? jumpForwardArrowContainerWidth : (isGrowSemiTooFarForward ? (chartWidth - x1) : (isGrowSemiTooFarBack ? x2 :  (x2 - x1 < optionsButtonWidth ? optionsButtonWidth : x2 - x1))))
  /*if (barWidth < minDayWidth) {
    barWidth = minDayWidth
  }*/
  const barRight = (x2 < 0 ? 0 : x1)
  const showBarLeftBorder = (!isGrowTooFarBack && !isGrowSemiTooFarBack)
  const showBarRightBorder = (!isGrowTooFarForward && !isGrowSemiTooFarForward)
  const showDisplayNameInBar = (displayNameContainerWidth > minDisplayNameWidthWithinBar)


  const growItemBarProps = {style: {
    width: barWidth,
    borderLeftWidth: showBarLeftBorder ? 1 : 0,
    borderRadius: (showBarLeftBorder ? "4px " : "0px ") + (showBarRightBorder ? "4px " : "0px ") + (showBarRightBorder ? "4px " : "0px ") + (showBarLeftBorder ? "4px" : "0px"),
    borderRightWidth: showBarRightBorder ? "1px" : 0,
  }}

  const displayNameContainerWithinBarProps = {style:{}, ...displayNameContainerBind}
  const displayNameContentWithinBarProps = {style:{visibility: showDisplayNameInBar ? "visible": "hidden"}}
  const displayNameContainerOutsideBarProps = {...displayNameContainerOutsideBarBind}
  const displayNameContentOutsideBarProps = {style:{visibility: (!showDisplayNameInBar && displayNameContainerOutsideBarWidth >= minDisplayNameWidthOutsideBar) ? "visible": "hidden"}}

  const displayNameContentOutsideBeforeBarProps = {style:{visibility: (!showDisplayNameInBar && displayNameContainerOutsideBarWidth <= minDisplayNameWidthOutsideBar) ? "visible": "hidden"}}


  const optionsButtonProps = {style:{width: (!isGrowTooFarBack && !isGrowTooFarForward) ? "auto" : "0"}}
  const jumpBackArrowProps = {style:{width: (isGrowTooFarBack || isGrowSemiTooFarBack) ? "auto" : "0"}} 
  const jumpForwardArrowProps = {style:{width: (isGrowTooFarForward || isGrowSemiTooFarForward) ? "auto" : "0"}}

  return (
    <div key={grow.key} className="GrowScheduling-GrowItem-Container" style={{marginLeft: barLeft}}>
      <div className="GrowScheduling-GrowItem-Main noselect">

        <div className="GrowScheduling-GrowItem-OutsideContent">
          <div className="GrowScheduling-GrowItem-OutsideBeforeBar-GrowDisplayName-Container" {...displayNameContentOutsideBeforeBarProps}>
            <div className="GrowScheduling-GrowItem-OutsideBeforeBar-GrowDisplayName-Content">
              <div className="GrowScheduling-GrowItem-OutsideBeforeBar-GrowDisplayName">
                <span>
                  {foundGrowPlan !== undefined && foundGrowPlan.name}
                  {foundGrowPlan === undefined && <>Loading Grow Plan</>}
                </span>
              </div>
            </div>
          </div>

        </div>
          

        <div className="GrowScheduling-GrowItem-Content">

          <div className="GrowScheduling-GrowItem-Bar" {...growItemBarProps}>

            <div className="GrowScheduling-GrowItem-Bar-JumpBackArrow-Container" {...jumpBackArrowProps}>
              <div className="GrowScheduling-GrowItem-Bar-JumpBackArrow-Content"
                onClick={() => {
                  if (jumpToStartTime !== undefined) {
                    jumpToStartTime(grow.data.startDate)
                  }
                }}
              {...jumpBackArrowBind}>
                <div className="GrowScheduling-GrowItem-Bar-JumpBackArrow">
                  <SkipBack/>
                </div>
              </div>
            </div>

            <div className="GrowScheduling-GrowItem-Bar-GrowDisplayName-Container" {...displayNameContainerWithinBarProps}>
              <div className="GrowScheduling-GrowItem-Bar-GrowDisplayName-Content" {...displayNameContentWithinBarProps}>
                <div className="GrowScheduling-GrowItem-Bar-GrowDisplayName">
                  <span>
                    {foundGrowPlan !== undefined && foundGrowPlan.name}
                    {foundGrowPlan === undefined && <>Loading Grow Plan</>}
                  </span>
                </div>
              </div>
            </div>
            
            <div className="GrowScheduling-GrowItem-Options-Container" {...optionsButtonProps}>
              <div className="GrowScheduling-GrowItem-Options-Content" {...optionsButtonBind}>
                <div className="GrowScheduling-GrowItem-Options">
                  <AdditionalOptions/>
                </div>
              </div>
            </div>

            <div className="GrowScheduling-GrowItem-Bar-TrailingContent">
              <div className="GrowScheduling-GrowItem-Bar-JumpForwardArrow-Container" {...jumpForwardArrowProps}>
                <div className="GrowScheduling-GrowItem-Bar-JumpForwardArrow-Content" 
                  onClick={() => {
                    if (jumpToEndTime !== undefined) {
                      jumpToEndTime(grow.data.startDate, grow.data.endDate - grow.data.startDate)
                    }
                  }}
                  {...jumpForwardArrowBind}>
                  <div className="GrowScheduling-GrowItem-Bar-JumpForwardArrow">
                    <SkipForward/>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="GrowScheduling-GrowItem-GrowDisplayName-Container" {...displayNameContainerOutsideBarProps}>
            <div className="GrowScheduling-GrowItem-GrowDisplayName-Content" {...displayNameContentOutsideBarProps}>
              <div className="GrowScheduling-GrowItem-GrowDisplayName">
                <span>
                  {foundGrowPlan !== undefined && foundGrowPlan.name}
                  {foundGrowPlan === undefined && <>Loading Grow Plan</>}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpen && 
        <div className="noselect">
          Expand Content
        </div>
      }
    </div>
  )
}


GrowSchedulingPage.defaultProps = {
  isPlaceholder: false,
}

export default GrowSchedulingPage

