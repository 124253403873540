import './TopUpReservoirWalkthrough.scss';
import _ from 'underscore';

import React from 'react'; 
import { useSelector } from 'react-redux';
import Walkthrough from '../../../components/Walkthrough';
import ProgressBar from '../../../components/ProgressBar';
import { selectAllFlows, selectFlowById, selectFlowDosingReservoirByIndex, selectFlowRuntimeInformation } from '../../../redux/entities/service/Flow';
import ConfirmReservoirRemovalWalkthroughStep from './TopUpReservoirWalkthroughSteps/ConfirmReservoirRemovalWalkthroughStep';
import ConfirmReservoirReinstallWalkthroughStep from './TopUpReservoirWalkthroughSteps/ConfirmReservoirReinstallWalkthroughStep';
import SelectResevoirTopUpTypeWalkthroughStep from './TopUpReservoirWalkthroughSteps/SelectResevoirTopUpTypeWalkthroughStep';
import ConfirmInitialReservoirVolumeWalkthroughStep from './TopUpReservoirWalkthroughSteps/ConfirmInitialReservoirVolumeWalkthroughStep';
import ConfirmReservoirWaterTopUpWalkthroughStep from './TopUpReservoirWalkthroughSteps/ConfirmReservoirWaterTopUpWalkthroughStep';
import TopUpInternalReservoirVolumeWalkthroughStep from './TopUpReservoirWalkthroughSteps/TopUpInternalReservoirVolumeWalkthroughStep';
import ConfirmExternalReservoirWaterVolumeWalkthroughStep from './TopUpReservoirWalkthroughSteps/ConfirmExternalReservoirWaterVolumeWalkthroughStep';
import ConfirmExternalReservoirMixWalkthroughStep from './TopUpReservoirWalkthroughSteps/ConfirmExternalReservoirMixWalkthroughStep';
import ConfirmInternalReservoirMixWalkthroughStep from './TopUpReservoirWalkthroughSteps/ConfirmInternalReservoirMixWalkthroughStep';
import ConfirmFinalReservoirVolumeWalkthroughStep from './TopUpReservoirWalkthroughSteps/ConfirmFinalReservoirVolumeWalkthroughStep';
import ConfirmReservoirSolutionTopUpWalkthroughStep from './TopUpReservoirWalkthroughSteps/ConfirmReservoirSolutionTopUpWalkthroughStep';
import ConfirmExternalReservoirWaterTopUpWalkthroughStep from './TopUpReservoirWalkthroughSteps/ConfirmExternalReservoirWaterTopUpWalkthroughStep';
import { selectAllInventoryItemTypes } from '../../../redux/entities/Inventory';


   

const TopUpReservoirWalkthrough = ({facilityId, flowId, reservoirIndex, completeCallback, closeCallback}) => {

    const [numberOfSteps, SetNumberOfSteps] = React.useState(7)
    const [currentStepIndex, SetCurrentStepIndex] = React.useState(1)

    const reservoirInfo = useSelector((state) => selectFlowDosingReservoirByIndex(state, flowId, reservoirIndex), _.isEqual)
    const allInventoryItemTypes = useSelector((state) => selectAllInventoryItemTypes(state))
    const activeInventoryItemType = allInventoryItemTypes.find(iT => iT.id === reservoirInfo["reference_id"])

    const reservoirItemName = activeInventoryItemType.name ?? "Unknown Item"

    //console.log(reservoirInfo)
    const [initialReservoirVolume, SetInitialReservoirVolume] = React.useState(0)

    const [volumeAfterWaterMixed, SetVolumeAfterWaterMixed] = React.useState(0)

    const [topUpType, SetTopUpType] = React.useState(null)

    const closePopup = () =>  {
        if (closeCallback !== undefined)  {
            closeCallback()
        }
    }
    const confirmedCancelClicked = (e) =>  {
        

        if (completeCallback !== undefined) {
            completeCallback()
        }
    }

    
    const currentTheme = "white"
    



    


    //Animation
    let [currentWalkthroughStep, SetCurrentWalkthroughStep] = React.useState(["confirm_reservoir_removal", null])
    let [previousWalkthroughStep, SetPreviousWalkthroughStep] = React.useState(null)
    let [nextWalkthroughStep, SetNextWalkthroughStep] = React.useState(null)
    const walkthroughTransitionToPreviousStepCompletedCallback = React.useCallback(() =>  {
        SetPreviousWalkthroughStep(null)
        SetNextWalkthroughStep(null)
    })
    const walkthroughTransitionToNextStepCompletedCallback = React.useCallback(() =>  {
        SetPreviousWalkthroughStep(null)
        SetNextWalkthroughStep(null)
    })

    const transitionToPreviousStep = (stepKeys) =>  {
        SetCurrentWalkthroughStep(stepKeys)
        SetPreviousWalkthroughStep(stepKeys)
    }
    const transitionToNextStep = (stepKeys) =>  {
        SetCurrentWalkthroughStep(stepKeys)
        SetNextWalkthroughStep(stepKeys)
    }


    React.useEffect(() => {
        const stepKey = currentWalkthroughStep[0]
        switch (stepKey)  {
            case "confirm_reservoir_removal":
                if (currentStepIndex !== 1) {
                    SetCurrentStepIndex(1)
                }
                break
            case "confirm_current_reservoir_volume":
                if (currentStepIndex !== 2) {
                    SetCurrentStepIndex(2)
                }
                break
            
            case "update_current_reservoir_volume":
                if (currentStepIndex !== 3) {
                    SetCurrentStepIndex(3)
                }
                break
            
            case "select_topup_type":

                break
    
            case "confirm_reservoir_water_topup":

                break
            
            case "top_up_internal_reservoir":
                break
            
            case "confirm_external_reservoir_water_volume":
            
                break


            case "confirm_internal_mix":
                
                break

            case "confirm_external_mix":
                
                break

            case "confirm_final_volume":
                if (currentStepIndex !== numberOfSteps - 1) {
                    SetCurrentStepIndex(numberOfSteps - 1)
                }
                break

            case "confirm_reservoir_reinstall":
                if (currentStepIndex !== numberOfSteps) {
                    SetCurrentStepIndex(numberOfSteps)
                }
                break
            default:
                if (currentStepIndex !== 1) {
                    SetCurrentStepIndex(1)
                }
                break
        }
    }, [currentWalkthroughStep, currentStepIndex])


    let showsPopupHeader = true


    
    const reservoirRemovalConfirmed = () => {
        //Mark reservoir as removed and no longer primed

    }
    const currentReservoirVolumeConfirmed = () =>   {
        //Keep track of current reservoir volume for later calculation

    }

    
    const initialReservoirVolumeConfirmed = (reservoirVolume) => {
        SetInitialReservoirVolume(reservoirVolume)
    }

    const topUpTypeSelected = (topUpType) => {
        SetTopUpType(topUpType)
        if (topUpType === "")   {

        }else {

        }
    }

    React.useEffect(() => {
        let addedSteps = 0
        if (topUpType === "within_reservoir")    {
            addedSteps += 1
        }
        SetNumberOfSteps(7 + addedSteps)
    }, [topUpType])


    const toppedUpInternalReservoirVolumeConfirmed = (waterVolume) => {
        SetVolumeAfterWaterMixed(waterVolume)
    }

    const externalReservoirWaterVolumeConfirmed = (waterVolume) => {
        SetVolumeAfterWaterMixed(waterVolume)
    }

    const finalReservoirVolumeConfirmed = (finalReservoirVolume) => {
        
    }

    const cancelReservoirTopup = () => {
        //Do nothing
    }

    const attachReservoirLater = () => {
        //Just close out, we aren't reinstalling right now
        closePopup()
    }
    
    const reservoirReinstallConfirmed = () => {
        //Mark reservoir as installed, will automatically prime now
        closePopup()
    }
    

    const [selectedUnit, SetSelectedUnit] = React.useState("litres")
    const selectedUnitChanged = (unit) => {
        SetSelectedUnit(unit)
    }
    
    const loadWalkthroughStep = (stepKeys) =>    {
        const stepKey = stepKeys[0]
        const subStepKey = stepKeys[1]

        switch (stepKey)    {
            case "confirm_reservoir_removal":
                return (<ConfirmReservoirRemovalWalkthroughStep flowId={flowId} reservoirInfo={reservoirInfo} reservoirItemName={reservoirItemName} reservoirRemovalConfirmedCallback={reservoirRemovalConfirmed} transitionToPreviousStep={transitionToPreviousStep} transitionToNextStep={transitionToNextStep} />) 
            
            case "confirm_initial_reservoir_volume":
                return (<ConfirmInitialReservoirVolumeWalkthroughStep flowId={flowId} reservoirInfo={reservoirInfo} reservoirItemName={reservoirItemName} initialReservoirVolumeConfirmedCallback={initialReservoirVolumeConfirmed} selectedUnit={selectedUnit} selectedUnitChanged={selectedUnitChanged} transitionToPreviousStep={transitionToPreviousStep} transitionToNextStep={transitionToNextStep} />) 
    
            //solids
            case "select_topup_type":
                return (<SelectResevoirTopUpTypeWalkthroughStep flowId={flowId} reservoirInfo={reservoirInfo} reservoirItemName={reservoirItemName} topUpTypeSelectedCallback={topUpTypeSelected} transitionToPreviousStep={transitionToPreviousStep} transitionToNextStep={transitionToNextStep} />) 
            
            //solids -- internal
            case "confirm_reservoir_water_topup":
                return (<ConfirmReservoirWaterTopUpWalkthroughStep flowId={flowId} reservoirInfo={reservoirInfo} reservoirItemName={reservoirItemName} transitionToPreviousStep={transitionToPreviousStep} transitionToNextStep={transitionToNextStep} />) 
                    
            case "top_up_internal_reservoir":
                return (<TopUpInternalReservoirVolumeWalkthroughStep flowId={flowId} reservoirInfo={reservoirInfo} reservoirItemName={reservoirItemName} originalVolume={initialReservoirVolume} selectedUnit={selectedUnit} selectedUnitChanged={selectedUnitChanged} toppedUpInternalReservoirVolumeConfirmedCallback={toppedUpInternalReservoirVolumeConfirmed} transitionToPreviousStep={transitionToPreviousStep} transitionToNextStep={transitionToNextStep} />) 

            case "confirm_internal_mix":
                return (<ConfirmInternalReservoirMixWalkthroughStep flowId={flowId} reservoirInfo={reservoirInfo} reservoirItemName={reservoirItemName} waterBeingMixed={volumeAfterWaterMixed - initialReservoirVolume} transitionToPreviousStep={transitionToPreviousStep} transitionToNextStep={transitionToNextStep} />) 

            //solids -- external
            case "confirm_external_reservoir_water_topup":
                return (<ConfirmExternalReservoirWaterTopUpWalkthroughStep flowId={flowId} initialReservoirVolume={initialReservoirVolume} reservoirInfo={reservoirInfo} reservoirItemName={reservoirItemName} selectedUnit={selectedUnit} transitionToPreviousStep={transitionToPreviousStep} transitionToNextStep={transitionToNextStep} />) 

            case "confirm_external_reservoir_water_volume":
                return (<ConfirmExternalReservoirWaterVolumeWalkthroughStep flowId={flowId} reservoirInfo={reservoirInfo} reservoirItemName={reservoirItemName} selectedUnit={selectedUnit} selectedUnitChanged={selectedUnitChanged} externalReservoirWaterVolumeConfirmedCallback={externalReservoirWaterVolumeConfirmed} transitionToPreviousStep={transitionToPreviousStep} transitionToNextStep={transitionToNextStep} />) 
                        
            case "confirm_external_mix":
                return (<ConfirmExternalReservoirMixWalkthroughStep flowId={flowId} reservoirInfo={reservoirInfo} reservoirItemName={reservoirItemName} waterBeingMixed={volumeAfterWaterMixed} transitionToPreviousStep={transitionToPreviousStep} transitionToNextStep={transitionToNextStep} />) 
            
            case "confirm_final_volume":
                return (<ConfirmFinalReservoirVolumeWalkthroughStep flowId={flowId} expectedReservoirVolume={(topUpType === "within_reservoir") ? volumeAfterWaterMixed : initialReservoirVolume + volumeAfterWaterMixed} reservoirInfo={reservoirInfo} reservoirItemName={reservoirItemName} topUpType={topUpType} selectedUnit={selectedUnit} selectedUnitChanged={selectedUnitChanged} finalReservoirVolumeCallback={finalReservoirVolumeConfirmed} transitionToPreviousStep={transitionToPreviousStep} transitionToNextStep={transitionToNextStep} />) 
            
            //liquids
            case "confirm_reservoir_solution_topup":
                return (<ConfirmReservoirSolutionTopUpWalkthroughStep flowId={flowId} reservoirInfo={reservoirInfo} reservoirItemName={reservoirItemName} transitionToPreviousStep={transitionToPreviousStep} transitionToNextStep={transitionToNextStep} />) 

            case "confirm_final_solution_volume":
                return (<ConfirmFinalReservoirVolumeWalkthroughStep flowId={flowId} expectedReservoirVolume={initialReservoirVolume} reservoirInfo={reservoirInfo} reservoirItemName={reservoirItemName} topUpType={topUpType} selectedUnit={selectedUnit} selectedUnitChanged={selectedUnitChanged} finalReservoirVolumeCallback={finalReservoirVolumeConfirmed} transitionToPreviousStep={transitionToPreviousStep} transitionToNextStep={transitionToNextStep} />) 
    
                
            case "confirm_reservoir_reinstall":
                return (<ConfirmReservoirReinstallWalkthroughStep flowId={flowId} reservoirInfo={reservoirInfo} reservoirItemName={reservoirItemName} attachReservoirLaterCallback={attachReservoirLater} reservoirReinstallConfirmedCallback={reservoirReinstallConfirmed} transitionToPreviousStep={transitionToPreviousStep} transitionToNextStep={transitionToNextStep} />) 

            default:
                return <></>
        }
    }




    return (<>

        <Walkthrough
            id={"TopUpReservoirWalkthrough_Popup"}
            title={<>
                {showsPopupHeader && <>
                    <span>Reservoir {reservoirInfo["index"]} Top Up</span>
                    {/*<span>Step {currentStepIndex}/7</span>*/}
                </>}
            </>}
            subHeaderContent={<>
                <div className="FlexContent-HFill" style={{flex:1}}>
                    <ProgressBar numberOfSteps={numberOfSteps} currentStep={currentStepIndex} completedBarFill={"#ABC7ED"}/>
                </div>
            </>}
            hideMainSeparators={true}
            closeCallback={closePopup}
            size={"full_screen"}
            timelineStep={currentWalkthroughStep[0]}
            initialWalkthroughStep={["confirm_reservoir_removal", null]}
            transitionToWalkthroughStep={nextWalkthroughStep}
            transitionBackToWalkthroughStep={previousWalkthroughStep}
            buildWalkthroughStepCallback={loadWalkthroughStep}
            transitionPreviousCompletedCallback={walkthroughTransitionToPreviousStepCompletedCallback}
            transitionNextCompletedCallback={walkthroughTransitionToNextStepCompletedCallback}
        />
    </>)
}



export default TopUpReservoirWalkthrough