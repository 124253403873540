
import './DateTimePicker.scss';
import * as React from 'react';
import useMeasure from '../useMeasure';
import { FormatDate, useGetViewSize } from '../helpers';
import { CalendarIcon } from '../assets/icons/Icons';
import Button from './Button';
import PopupModal from '../model_components/PopupModal';
import TextInput from './input/TextInput';
import DropDownButton from './DropDownButton';
import Calendar from './input/Calendar';





const DateTimePicker = ({
  isRange, rangeType, range, 
  onRangeTypeSelected, onRangeChanged,
  buttonType = "normal", isOpen = null, customButtonContent = null,
}) => {
  const [isMobile, isTablet, isDesktop] = useGetViewSize()

  const [isOpenState, SetIsOpenState] = React.useState()
  React.useLayoutEffect(() => {
    if (isOpen !== null)  {
      SetIsOpenState(isOpen)
    }
  }, [isOpen])
  const toggleOpenState = () => {
    SetIsOpenState(!isOpenState)
  }


  const [activeRange, SetActiveRange] = React.useState()
  React.useLayoutEffect(() => {
    SetActiveRange(range)
  }, [range])
  const changeActiveRangeTo = (range) => {
    SetActiveRange({...range})
    if (onRangeChanged !== undefined)  {
      onRangeChanged(range)
    }
  }
  const [activeRangeType, SetActiveRangeType] = React.useState()
  React.useLayoutEffect(() => {
    SetActiveRangeType(rangeType)
  }, [rangeType])
  const switchActiveRangeType = (rangeType) => {
    if (rangeType === activeRangeType)
      return

    SetActiveRangeType(rangeType)
    if (onRangeTypeSelected !== undefined)  {
      onRangeTypeSelected(rangeType)
    }
    let currentDate = new Date()
    switch(rangeType) {
      case "last_24hr":
        currentDate.setHours(currentDate.getHours() - 24)
        changeActiveRangeTo({from: parseInt(currentDate.getTime() / 1000), to: parseInt(new Date().getTime() / 1000)})
        break
      case "last_week":
        currentDate.setDate(currentDate.getDate() - 7)
        changeActiveRangeTo({from: parseInt(currentDate.getTime() / 1000), to: parseInt(new Date().getTime() / 1000)})
        break
      case "last_month":
        currentDate.setMonth(currentDate.getMonth() - 1)
        changeActiveRangeTo({from: parseInt(currentDate.getTime() / 1000), to: parseInt(new Date().getTime() / 1000)})
        break
      case "last_year":
        currentDate.setFullYear(currentDate.getFullYear() - 1)
        changeActiveRangeTo({from: parseInt(currentDate.getTime() / 1000), to: parseInt(new Date().getTime() / 1000)})
        break
    
      default:
        break
    }
  }

  const rangeDateChanged = (dateRange) => {
    if (rangeType !== "custom_range") {
      SetActiveRangeType("custom_range")
      if (onRangeTypeSelected !== undefined)  {
        onRangeTypeSelected("custom_range")
      }
    }

    changeActiveRangeTo({from: parseInt(dateRange.from.getTime() / 1000), to: parseInt(dateRange.to.getTime() / 1000)})
  }

  const iconButtonContent = <>
    <CalendarIcon/> 
  </>
  let normalButtonContent = <></>
  if (customButtonContent)  {
    normalButtonContent = <><div className="Text-Alt12">{customButtonContent}</div></>
  }else {
    switch (rangeType)  {
      case "last_24hr":
        normalButtonContent = <><div className="Text-Alt12">Last 24H</div></>
        break
      case "last_week":
        normalButtonContent = <><div className="Text-Alt12">Last Week</div></>
        break
      case "last_month":
        normalButtonContent = <><div className="Text-Alt12">Last Month</div></>
        break
      case "last_year":
        normalButtonContent = <><div className="Text-Alt12">Last Year</div></>
        break
      case "custom_range":
        normalButtonContent = <><div className="Text-Alt12">
          {FormatDate(new Date(activeRange.from * 1000), "MMM dth, yyyy")}
          &nbsp;-&nbsp;
          {FormatDate(new Date(activeRange.to * 1000), "MMM dth, yyyy")}
        </div></>
        break
      default:
        break
    }
  }

  return (<>
    <Button content={<>
      {buttonType === "normal" && normalButtonContent}
      {buttonType === "icon" && iconButtonContent}
    </>} status="Action-Neutral" margin={1} onClick={toggleOpenState}/>
    {isOpenState && <>
      <PopupModal 
        title={"Date Range"}
        closeCallback={() => {SetIsOpenState(false)}}
      >
        <div className={isMobile ? "FlexContent-30" : "FlexContent-20"}>
          <div className="FlexContent-10">
            <div className="FlexContent-H" style={{gap:4}}>
              <Button 
                content={isMobile ? "1D" : "Past Day"} 
                onClick={() => {switchActiveRangeType("last_24hr")}}
                status="Primary-Toggle"
                width={"Flex100"}
                state={activeRangeType === "last_24hr"}/>
              <Button 
                content={isMobile ? "1W" : "Past Week"} 
                onClick={() => {switchActiveRangeType("last_week")}}
                status="Primary-Toggle"
                width={"Flex100"}
                state={activeRangeType === "last_week"}/>
              <Button 
                content={isMobile ? "1M" : "Past Month"} 
                onClick={() => {switchActiveRangeType("last_month")}}
                status="Primary-Toggle"
                width={"Flex100"}
                state={activeRangeType === "last_month"}/>
              <Button 
                content={isMobile ? "1Y" : "Past Year"} 
                onClick={() => {switchActiveRangeType("last_year")}}
                status="Primary-Toggle"
                width={"Flex100"}
                state={activeRangeType === "last_year"}/>
              <Button 
                content={isMobile ? "Custom" : "Custom Range"} 
                onClick={() => {switchActiveRangeType("custom_range")}}
                status="Primary-Toggle"
                width={"Flex100"}
                state={activeRangeType === "custom_range"}/>
            </div>
            <div>
              <Calendar 
                isRange={true}
                selectedDateRange={{from: new Date(activeRange.from * 1000), to: new Date(activeRange.to * 1000)}}
                dateRange
                multiMonth={!isMobile}
                selectedDateRangeChanged={rangeDateChanged}
              />
            </div>
          </div>
          <div className={isMobile ? "FlexContent-20" : "FlexContent-H-30"} style={{padding: isMobile ? "" : "0 10px"}}>
            <div className="FlexContent-5 FlexContent-HFlex">
              <div className="Text-S14">Start Date</div>
              <div className="FlexContent-H-5">
                <TextInput value={FormatDate(new Date(activeRange.from * 1000), "MMM dth yyyy")} inactive={true} flex={true}/>
                <DropDownButton options={[]} content={FormatDate(new Date(activeRange.from * 1000), "HH:mm:ss")} margin={1} showDropDownArrow={true}/>
              </div>
            </div>
            <div className="FlexContent-5 FlexContent-HFlex">
              <div className="Text-S14">End Date</div>
              <div className="FlexContent-H-5">
                <TextInput value={FormatDate(new Date(activeRange.to * 1000), "MMM dth yyyy")} inactive={true} flex={true}/>
                <DropDownButton options={[]} content={FormatDate(new Date(activeRange.to * 1000), "HH:mm:ss")} margin={1} showDropDownArrow={true}/>
              </div>
            </div>
          </div>
        </div>
      </PopupModal>
      {/*isMobile && <>
      
      </>}
      {!isMobile && <>
      
      </>*/}
    </>}
  </>)
} 

DateTimePicker.defaultProps = {
  buttonType: "normal", 
  isOpen: null,
  customButtonContent: null
}

export default DateTimePicker