import './GerminationStationsPage.scss';
import variables from '../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';

import { parseBool } from '../../../helpers';
import { selectBladeUniqueConfigurationByName } from '../../../redux/entities/service/Blade';
import DataStatus_HumidificationCell from './GerminationStationInstance_StatucContent_Cells/GerminationStationInstance_StatusContent_HumidificationCell';
import GerminationStationInstance_StatusContent_CanopyAirStatusCell from './GerminationStationInstance_StatucContent_Cells/GerminationStationInstance_StatusContent_CanopyAirStatusCell';
import GerminationStationInstance_StatusContent_Fan from './GerminationStationInstance_StatucContent_Cells/GerminationStationInstance_StatusContent_Fan';
import GerminationStationInstance_StatusContent_Auxiliary from './GerminationStationInstance_StatucContent_Cells/GerminationStationInstance_StatusContent_Auxiliary';


 


const GerminationStationInstance_StatusContent = ({ germinationStationId }) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });


  let tileColumns = []
  if (isWideDesktop) {
    tileColumns.push(["canopy_air", "auxiliary"])
    tileColumns.push(["fan", "humidification"])
  } else if (isDesktop) {
    tileColumns.push(["canopy_air", "auxiliary"])
    tileColumns.push(["fan", "humidification"])
  } else if (isTablet) {
    tileColumns.push(["canopy_air", "auxiliary"])
    tileColumns.push(["fan", "humidification"])
  } else {
    tileColumns.push(["canopy_air", "fan", "humidification", "auxiliary"])
  }



  return (<>
    <div className="BladeInstance_StatusContent">
      {tileColumns.map((cellKeys) => {

        return (<>
          <div className="BladeInstance_StatusContent-Column">
            {cellKeys.map((cellKey) => {
              switch (cellKey) {
                case "canopy_air":
                  return <GerminationStationInstance_StatusContent_CanopyAirStatusCell germinationStationId={germinationStationId}/>
                case "fan":
                  return <GerminationStationInstance_StatusContent_Fan germinationStationId={germinationStationId}/>
                case "humidification":
                  return <DataStatus_HumidificationCell germinationStationId={germinationStationId}/>
                case "auxiliary":
                  return <GerminationStationInstance_StatusContent_Auxiliary germinationStationId={germinationStationId}/>
                default:
                  break
              }
            })}
          </div>
        </>)
      })}
    </div>
  </>)
}

export default GerminationStationInstance_StatusContent