import '../BladeInstance.scss';
import variables from '../../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { AdditionalOptions, Pause, Play } from '../../../../assets/icons/Icons';
import DropDownButton from '../../../../components/DropDownButton';
import { getLiveDataItem, getUniqueConfigurationByName, selectAllBladeConfigurationMaps, selectBladeComponentInfo, selectBladeLiveDataByComponentInfo, selectBladePrimaryLiquidSystemStatus, selectBladeRuntimeInformation, selectBladeUIDById, selectBladeUniqueConfigurationByName, selectBladeZoneRuntimeInformation, selectBladeZonesForBladeUID, setBladeRuntimeProperty, setBladeUniqueConfiguration, setBladeZoneRuntimeProperty } from '../../../../redux/entities/service/Blade';
import { DataStatus_DataList } from '../../../../components/DataStatus/DataStatus';
import { parseBool } from '../../../../helpers';
import { selectAllAccountPrivileges } from '../../../../redux/entities/Account';



const DataStatus_PrimaryLiquidCell = ({ bladeId }) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const dispatch = useDispatch()



  const accountPrivileges = useSelector((state) => selectAllAccountPrivileges(state), _.isEqual)
  const accountHasServicingPriviliges = (accountPrivileges["servicing"] !== undefined)
  
  
  const liquidStatus = useSelector((state) => selectBladePrimaryLiquidSystemStatus(state, bladeId), _.isEqual)
  const bladeUID = useSelector((state) => selectBladeUIDById(state, bladeId))
  const bladeZones = useSelector((state) => selectBladeZonesForBladeUID(state, bladeUID), _.isEqual)

  const [liquidControlStatus] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "primary_liquid_control_status"))
  const isLiquidPaused = liquidControlStatus !== "active"
  const bladeZoneResumingOn = useSelector((state) => selectBladeZoneRuntimeInformation(state, bladeZones["grow"], "resume_controls_on"), _.isEqual)


  const liquidLevelSensorCalibrated = useSelector((state) => selectBladeUniqueConfigurationByName(state, bladeId, "PrimaryLiquidLevelSensorCalibrated"), _.isEqual)
  const reservoirMinConfiguration = useSelector((state) => selectBladeUniqueConfigurationByName(state, bladeId, "PrimaryReservoirLowLiquidVolume"), _.isEqual)
  const reservoirMaxConfiguration = useSelector((state) => selectBladeUniqueConfigurationByName(state, bladeId, "PrimaryReservoirFullLiquidVolume"), _.isEqual)
  const reservoirMin = reservoirMinConfiguration ?? 0
  const reservoirMax = reservoirMaxConfiguration ?? 20


  const [reservoirVolumeUsageComponentInfo, reservoirVolumeComponentInfo, waterECComponentInfo, waterECSetpointComponentInfo, haveWaterECSetpointComponentInfo, waterPHComponentInfo, waterPHSetpointComponentInfo, haveWaterPHSetpointComponentInfo, waterDOComponentInfo, waterORPComponentInfo] 
  = useSelector((state) => selectBladeComponentInfo(state, bladeId, "PrimaryWaterReservoir", ["water_level", "water_volume", "ec", "ec-sp", "ec-hs", "ph", "ph-sp", "ph-hs", "do", "orp"]), _.isEqual)
  const [waterTemperatureComponentInfo] 
  = useSelector((state) => selectBladeComponentInfo(state, bladeId, "PrimaryReservoirOWTB", ["t"]), _.isEqual)
  const [liquidPressureComponentInfo] 
  = useSelector((state) => selectBladeComponentInfo(state, bladeId, "PrimaryLiquidSystemPressureTransducer", ["pressure"]), _.isEqual)

  const reservoirVolume = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, reservoirVolumeComponentInfo), _.isEqual)
  const reservoirVolumeUsage = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, reservoirVolumeUsageComponentInfo), _.isEqual)
  const liquidPressure = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, liquidPressureComponentInfo), _.isEqual)
  const waterTemperature = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, waterTemperatureComponentInfo), _.isEqual)
  const waterEC = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, waterECComponentInfo), _.isEqual)
  const waterECSetpoint = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, waterECSetpointComponentInfo), _.isEqual)
  const haveWaterECSetpoint = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, haveWaterECSetpointComponentInfo), _.isEqual)
  const waterPH = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, waterPHComponentInfo), _.isEqual)
  const waterPHSetpoint = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, waterPHSetpointComponentInfo), _.isEqual)
  const haveWaterPHSetpoint = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, haveWaterPHSetpointComponentInfo), _.isEqual)
  const waterDO = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, waterDOComponentInfo), _.isEqual)
  const waterORP = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, waterORPComponentInfo), _.isEqual)

  let waterTempValid = false
  if (waterTemperature.value !== -999)  {
    waterTempValid = true
  }

  let dataListItems = [ 
    {
      type: "large_bar", label: "Reservoir Volume", value: reservoirVolumeUsage.value, valueToDisplay: reservoirVolume.value, suffix: "L", min: 0, max: 100, status: "normal"
    },
    {
      type: "setpoint_bar", label: "EC", value: waterEC.value, setpoint: waterECSetpoint.value, setpoint_active: parseBool(haveWaterECSetpoint.value), suffix: "mS/m", range: 500, status: "normal"
    },
    {
      type: "setpoint_bar", label: "pH", value: waterPH.value, setpoint: waterPHSetpoint.value, setpoint_active: parseBool(haveWaterPHSetpoint.value), suffix: "", range: 2, status: "normal"
    },
    {
      label: "Pressure", value: liquidPressure.value, suffix: "psi", visualization: {
        type: "bar", info: { usesRange: true, min: 0, max: 130, status: "normal" }
      }
    },
    {
      label: "Temperature", valid: waterTempValid, value: waterTemperature.value, suffix: "°C"
    },
    {
      label: "DO", value: waterDO.value, suffix: "ppm",
    },
    {
      label: "ORP", value: waterORP.value, suffix: "mV",
    },
  ]


  let additionalFunctions = [
    {
      key: "fill_reservoir", label: "Water Fill", callback: () => {
        dispatch(setBladeRuntimeProperty({ bladeId: bladeId, properties: { "primary_reservoir_requires_water_fill": 1 } }))
      }
    },
    {
      key: "drain_reservoir", label: "Water Drain", callback: () => {
        dispatch(setBladeRuntimeProperty({ bladeId: bladeId, properties: { "primary_reservoir_requires_drain": 1 } }))
      }
    },
    {
      key: "mark_for_dose", label: "Request Dose", callback: () => {
        dispatch(setBladeRuntimeProperty({ bladeId: bladeId, properties: { "primary_reservoir_requires_dose": 1 } }))
      }
    },

  ]

  if (accountHasServicingPriviliges)  {

    

    if (liquidLevelSensorCalibrated !== undefined && parseBool(liquidLevelSensorCalibrated)) {
      additionalFunctions.push(...[
        {
          key: "reset_level_sensor_calibration", label: "Reset Level Sensor Calibration", status: "Critical", callback: () => {
            dispatch(setBladeUniqueConfiguration({ bladeId: bladeId, properties: { "pllsfc": 0 } }))
          }
        },
      ])
    }

  }

  const [liquidSystemDoseLockedOutRuntimeInfo] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "primary_dose_locked_out"), _.isEqual)
  const isLiquidSystemDoseLockedOut = parseBool(liquidSystemDoseLockedOutRuntimeInfo)
  if (isLiquidSystemDoseLockedOut) {
    additionalFunctions.push(...[
      {
        key: "restore_dose_lockout", label: "Allow Dosing", status: "Critical", callback: () => {
          dispatch(setBladeRuntimeProperty({ bladeId: bladeId, properties: { "primary_dose_locked_out": 0 } }))
        }
      },
    ])
  }

  
  const [liquidSystemAllFunctionsLockedOutRuntimeInfo] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "primary_all_functions_locked_out"), _.isEqual)
  const isLiquidSystemAllFunctionsLockedOut = parseBool(liquidSystemAllFunctionsLockedOutRuntimeInfo)
  if (isLiquidSystemAllFunctionsLockedOut) {
    additionalFunctions.push(...[
      {
        key: "restore_all_functions_lockout", label: "Restore Operation", status: "Critical", callback: () => {
          dispatch(setBladeRuntimeProperty({ bladeId: bladeId, properties: { "primary_all_functions_locked_out": 0 } }))
        }
      },
    ])
  }


  
  const defaultPauseDuration = 6000
  const [pendingBladeToPause, SetPendingBladeToPause] = React.useState(false)
  const pauseLiquidControlClicked = () => {
    //First set the default pause duration if the current pause duration is too short
    const minResumeAt = Math.floor((new Date().getTime() / 1000) + defaultPauseDuration)
    if (bladeZoneResumingOn === undefined || parseFloat(bladeZoneResumingOn) < minResumeAt) {
      dispatch(setBladeZoneRuntimeProperty({
        bladeZoneUID: bladeZones["grow"],
        properties: { "resume_controls_on": minResumeAt.toString() },
        callback: (success) => {
          if (success)  {
            SetPendingBladeToPause(true)
          }else {

          }
        }
      }))
    }else {
      SetPendingBladeToPause(true)
    }
  }
  React.useLayoutEffect(() => {
    if (pendingBladeToPause)  {
      SetPendingBladeToPause(false)
      dispatch(setBladeRuntimeProperty({
        bladeId: bladeId,
        properties: { "primary_liquid_control_status": "pause" },
        callback: (success) => {
          if (success)  {

          }else {

          }
        }
      }))
    }
  }, [pendingBladeToPause])
  const resumeLiquidControlClicked = () => {
    dispatch(setBladeRuntimeProperty({
      bladeId: bladeId,
      properties: { "primary_liquid_control_status": "resume" },
      callback: (success) => {
        
      }
    }))
  }

  return (<>
    <div className="DataStatus-Cell">
      <div className="DataStatus-Cell-Header-Container">
        <div className="DataStatus-Cell-Header-PrimaryContent">
          <div className="Text-H14">Primary Liquid</div>
          <div className="DataStatus-Cell-Header-PrimaryContent-StatusChip">
            {isLiquidSystemAllFunctionsLockedOut && <>
              <div className="Text-Color-Critical">Locked Out</div>
            </>}
            {!isLiquidSystemAllFunctionsLockedOut && <>
              {liquidStatus}            
            </>}
          </div>
        </div>
        <div className="DataStatus-Cell-Header-AuxContent">
          {isLiquidPaused && <>
            <Button status={"Primary-Inverted"} size={"Small"} content={<Play fill={"#2E72D2"} />} onClick={resumeLiquidControlClicked} disabled={!isLiquidPaused} />
          </>}
          {!isLiquidPaused &&
            <Button status={"Small-IconAction"} size={"Small"} content={<Pause />} onClick={pauseLiquidControlClicked} disabled={isLiquidPaused} />
          }
          <DropDownButton content={<AdditionalOptions />} options={additionalFunctions} status={"Neutral"} />
        </div>
      </div>
      <div className="DataStatus-Cell-Content-Container">
        <DataStatus_DataList items={dataListItems} />
      </div>
    </div>
  </>)
}

export default DataStatus_PrimaryLiquidCell