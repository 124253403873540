import './ControlDevicesPage.scss';
import variables from '../../../globals.scss';
import React from 'react'


import { useDispatch, useSelector } from 'react-redux';
import CircularGauge from '../../../components/CircularGauge';
import { GridTile } from '../../../components/GridTile';
import NumberInput from '../../../components/input/NumberInput';
import Switch from '../../../components/Switch';
import { RoundToNearest, parseBool } from '../../../helpers';
import Button from '../../../components/Button';
import { AddItem, BadX, GoodCheckmark, Pause, Play, PlusAlt, RSSI, ReorderItem, SoftWarning, Trash } from '../../../assets/icons/Icons';
import RowGroup from '../../../components/RowGroup/RowGroup';
import RowGroupRow from '../../../components/RowGroup/RowGroupRow';
import { useMediaQuery } from 'react-responsive';
import { getAllControlDevicesByFacilityId, selectAllControlDevices } from '../../../redux/entities/service/ControlDevice';
import CreateControlDeviceWalkthrough from './CreateControlDeviceWalkthrough';
import SetWiFiCredentialsForControlDeviceWalkthrough from './SetWiFiCredentialsForControlDeviceWalkthrough';






const ControlDevicesPage = ({selectedFacility, setRightSideContent}) => {
  
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  
  




  const dispatch = useDispatch()

  const activeFacility = useSelector(state => state.facilities.facilities.find((f) => f.id === selectedFacility))
  const allControlDevices = useSelector(selectAllControlDevices)


  
  const haveControlDevicesForFacility = useSelector(state => state.controlDevice.haveControlDevicesForFacility)
  const loadingControlDevicesStatus = useSelector(state => state.controlDevice.loadingControlDevicesStatus)
  React.useEffect(() => {
    if (haveControlDevicesForFacility[selectedFacility] !== true && loadingControlDevicesStatus !== "pending") {
      dispatch(getAllControlDevicesByFacilityId({facilityId: selectedFacility}))
    }
  }, [haveControlDevicesForFacility, loadingControlDevicesStatus])


  
  //console.log(allGroups)
  const [controlDevices, SetControlDevices] = React.useState([])
  React.useEffect(() => {
    SetControlDevices(allControlDevices.filter((cD) => cD.facility_id === selectedFacility))
  }, [selectedFacility, allControlDevices])
  

  const [controlDeviceGroups, SetControlDeviceGroups] = React.useState([
    {id:1, name: "Blade", key: "blade"},
    {id:2, name: "Flow", key: "nds"},
  ])

  const updateHeader = React.useCallback(() =>  {
    if (setRightSideContent === undefined)
      return
    setRightSideContent(
      <>
        {activeFacility !== undefined && 
          <Button content="Create Control Device" status="Primary" onClick={createNewControlDevice}/>  
        }
      </>
    )
  })

  React.useLayoutEffect(() => {
    updateHeader()
  }, [])
  React.useLayoutEffect(() => {
    updateHeader()
  }, [activeFacility])



  
  
  const [creatingNewControlDevice, SetcreatingNewControlDevice] = React.useState(false)
  const createNewControlDevice = React.useCallback(() => {
    SetcreatingNewControlDevice(true)
  })

  

  const createNewBladeWalkthroughCloseCallback = React.useCallback(() => {
    SetcreatingNewControlDevice(false)  
  })
  const createNewBladeWalkthroughCompletedCallback = () => {
    SetcreatingNewControlDevice(false)  
  }



  

  const [settingWiFiCredentailsForDevice, SetSettingWiFiCredentialsForDevice] = React.useState(null)
  const setWiFiCredentailsClicked = (controlDevice) => {
    SetSettingWiFiCredentialsForDevice(controlDevice)
  }
  const doneSettingWifiCredentialsForDevice = () => {
    SetSettingWiFiCredentialsForDevice(null)
  }

  return (<>
    <div id="ControlDevicesStatus">
    
        <div className="ControlDevicesStatus-ControlDeviceGroups">
          {controlDeviceGroups.map((controlDeviceGroup) => {

            let controlDevices = allControlDevices.filter((cD) => cD.type === controlDeviceGroup.key)

            return (
              
              <RowGroup
                key={controlDeviceGroup.id}
                title={controlDeviceGroup.name}
                hasSettings={true} 
                isExpandable={true}
                expandCellContent={"Show " + controlDevices.length + " " + controlDeviceGroup.name + (controlDevices.length !== 1 ? "s" : "")}
                collapseCellContent={"Hide " + controlDeviceGroup.name + (controlDevices.length !== 1 ? "s" : "")}
                hasColumnHeaders={false}>
              
                {controlDevices.map((controlDevice) => {



                  let subtitle = <>{controlDevice.serial_number}</>

                  let additionalFunctions = [
                    {key: "set_wifi", label: "Set WiFi Credentials", callback: () => {setWiFiCredentailsClicked(controlDevice)}},
                  ]
                  /*if (isMobile) {
                    additionalFunctions.splice(2, 0, 
                      {key: "pause_rack", label: "Pause Rack"}  
                    );
                  }*/

                  let secondaryHeaderContent = null
                  if (!isMobile)  {
                    secondaryHeaderContent = [
                      {key: "rssi", content: <RSSI/>}
                    ]
                  }else {
                    secondaryHeaderContent = <><RSSI/></>
                  }

                  


                  return (
                    <RowGroupRow 
                      key={controlDevice.id}
                      title={"Device #" + controlDevice.serial_number}
                      hasFlexCell={true}
                      subtitle={<div className="GrowRacksStatus-VerticalRackGroup-Rack-Sides">{controlDevice.connected ? "Connected" : "Not Connected"}</div>}
                      showStatusIndicator={true}
                      status={controlDevice.connected ? "Connected" : "Not Connected"}
                      statusColor={controlDevice.connected ? "#4DBE3B" : "#f00"}
                      hasIssues={true}
                      numberOfIssues={0} 
                      customRowPadding={0}
                      isExpandable={false}
                      hasAdditionalFunctions={true}
                      secondaryContent={secondaryHeaderContent}
                      additionalFunctions={additionalFunctions}/>
                  )
                })}
              </RowGroup>
            )
          
          })}
        </div>
    </div>
    {creatingNewControlDevice && <CreateControlDeviceWalkthrough facilityId={selectedFacility} closeCallback={createNewBladeWalkthroughCloseCallback} completeCallback={createNewBladeWalkthroughCompletedCallback}/>}
    {settingWiFiCredentailsForDevice &&  <SetWiFiCredentialsForControlDeviceWalkthrough controlDevice={settingWiFiCredentailsForDevice} closeCallback={doneSettingWifiCredentialsForDevice} completeCallback={doneSettingWifiCredentialsForDevice}/>}
  </>)
} 

export default ControlDevicesPage