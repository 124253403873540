import '../GerminationStationInstance.scss';
import variables from '../../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { AdditionalOptions } from '../../../../assets/icons/Icons';
import DropDownButton from '../../../../components/DropDownButton';
import { getLiveDataItem, selectAllGerminationStationConfigurationMaps, selectGerminationStationComponentInfo, selectGerminationStationLiveDataByComponentInfo, selectGerminationStationRuntimeInformation } from '../../../../redux/entities/service/GerminationStation';
import { DataStatus_DataList } from '../../../../components/DataStatus/DataStatus';
import { parseBool } from '../../../../helpers';



const GerminationStationInstance_StatusContent_Auxiliary = ({ germinationStationId }) => {

    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 1079 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
  
  
    const [airSensorHeatingModeComponentInfo] 
    = useSelector((state) => selectGerminationStationComponentInfo(state, germinationStationId, "CanopyAir", ["hms"]), _.isEqual)

    const [leftDoorSafetySwitchComponentInfo] 
      = useSelector((state) => selectGerminationStationComponentInfo(state, germinationStationId, "LeftDoorSafetySwitch", ["state"]), _.isEqual)
    
    const [rightDoorSafetySwitchComponentInfo] 
      = useSelector((state) => selectGerminationStationComponentInfo(state, germinationStationId, "RightDoorSafetySwitch", ["state"]), _.isEqual)

    const airSensorHeatingModeState = useSelector((state) => selectGerminationStationLiveDataByComponentInfo(state, germinationStationId, airSensorHeatingModeComponentInfo), _.isEqual)
    const leftDoorSafetySwitchState = useSelector((state) => selectGerminationStationLiveDataByComponentInfo(state, germinationStationId, leftDoorSafetySwitchComponentInfo), _.isEqual)
    const rightDoorSafetySwitchState = useSelector((state) => selectGerminationStationLiveDataByComponentInfo(state, germinationStationId, rightDoorSafetySwitchComponentInfo), _.isEqual)


    let dataListItems = [
      {
        label: "Air Sensor Heat", value: parseBool(airSensorHeatingModeState.value) ? "On" : "Off", suffix: "",
      },
      {
        label: "Left Door", value: parseBool(leftDoorSafetySwitchState.value) ? "Open" : "Closed", suffix: "",
      },
      {
        label: "Right Door", value: parseBool(rightDoorSafetySwitchState.value) ? "Open" : "Closed", suffix: "",
      },
    ]


    return (<>
      <div className="DataStatus-Cell">
        <div className="DataStatus-Cell-Header-Container">
          <div className="DataStatus-Cell-Header-PrimaryContent">
            <div className="Text-H14">Auxiliary</div>
          </div>
          <div className="DataStatus-Cell-Header-AuxContent">
            <DropDownButton content={<AdditionalOptions/>} status={"Neutral"}/>
          </div>
        </div>
        <div className="DataStatus-Cell-Content-Container">
          <DataStatus_DataList items={dataListItems}/>
        </div>
      </div>
    </>)
  }
  
  export default GerminationStationInstance_StatusContent_Auxiliary